import styled from 'styled-components';

export default styled.div`
    min-width: 320px;
    position: relative;

    .pop-up-close {
        position: absolute;
        top: var(--spacing-8);
        right: var(--spacing-8);
        border-radius: 50%;
        height: 2rem;
        width: 2rem;
        z-index: 1;
    }
`;
