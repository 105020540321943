import { getServiceUrl, httpGet, httpPost } from '../../services/api';
import { ActiveProgram, OptedInProgram } from '../../types/casino-payback/types';

const getUrl = (url) => getServiceUrl('payback', url);

export async function getPaybackAvailableProgram(): Promise<ActiveProgram> {
    return httpGet(getUrl('fo/current-program'));
}

export async function optInCasinoPayback(userProgramId: number): Promise<OptedInProgram> {
    return httpPost(getUrl('fo/activate-user-program'), { userProgramId });
}
