import React from 'react';
import Wrapper from './styles';
import Loader from '../Loader';

export default function LoaderPage() {
    return (
        <Wrapper>
            <Loader timeout={1000} />
        </Wrapper>
    );
}
