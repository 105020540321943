import React, { useEffect, useMemo } from 'react';
import Wrapper from './styles';
import CrmMessagePreviewMascot from './mascot/CrmMessagePreviewMascot';
import UiButton from '../../ui/button/UiButton';
import UiCloseButton from '../../ui/close-button/UiCloseButton';
import { stores } from '../../../stores';
import * as classnames from 'classnames';
import {
    closeCrmMessageAndUpdate,
    handleCtaClick,
    openMessageByUser,
    updateMessageOnSeen,
} from '../../../services/crm';
import { getStoreValue } from '../../../stores/store/utils';
import { filterStyleProps } from '../../../styles/utils';
import { useStore } from '../../../hooks/useStore';
import { ONSITE_MESSAGE_DISPLAY_TYPE, OnsiteMessage } from '../../../microservices/crm/types';
import { toast } from 'react-toastify';
import CrmMessageInfo from '../message/info/CrmMessageInfo';
import { getRoute, useRouter } from '../../../services/router';
import { scrollToTop } from '../../../services/util';
import { crm } from '../../../stores/crm';
import { getIsToasterMessage } from '../../../services/onsite-messages';
import CrmInboxFreeSpinBonus from '../free-spin-bonus/CrmFreeSpinBonus';
import CrmMessagePreviewPopup from './popup/CrmMessagePreviewPopup';

interface Props {
    previewMessage?: OnsiteMessage;
    $previewModal?: boolean;
}

export default function CrmMessagePreview({ previewMessage, $previewModal = false, ...rest }: Props) {
    const { navigateTo } = useRouter();
    const [storeOnsiteMessage] = useStore(stores.crm.onsite);
    const onsiteMessage = useMemo(() => previewMessage || storeOnsiteMessage, [previewMessage, storeOnsiteMessage]);
    const isToasterMessage = getIsToasterMessage(onsiteMessage);
    const isMessageOpen = Boolean(onsiteMessage);

    useEffect(() => {
        if (!onsiteMessage) {
            return;
        }

        if (isToasterMessage) {
            if (previewMessage) {
                toast.dismiss();
            }
            toast.info(<CrmMessageInfo message={onsiteMessage} onMessageClick={handleMessageClick} />, {
                autoClose: false,
            });
            crm.onsite.set(undefined);
        } else {
            updateMessageOnSeen(onsiteMessage);
        }
    }, [onsiteMessage]);

    async function handleMessageClick() {
        if (!onsiteMessage) {
            return;
        }

        await openMessageByUser(onsiteMessage);
        navigateTo(getRoute('inbox'));
        scrollToTop();
    }

    if (!onsiteMessage || isToasterMessage || getStoreValue(stores.applicationType) === 'poker-download') {
        return null;
    }

    if (onsiteMessage.displayType === ONSITE_MESSAGE_DISPLAY_TYPE.POP_UP) {
        return <CrmMessagePreviewPopup message={onsiteMessage} />;
    }

    return (
        <Wrapper
            {...filterStyleProps({ ...rest })}
            $hasMascot={Boolean(onsiteMessage.mascotMessage)}
            $hasFreeSpinBonus={Boolean(onsiteMessage.freeSpinBonus)}
            $isPreviewModal={$previewModal}
        >
            <div className={classnames(['crm-message', isMessageOpen ? 'visible' : 'hidden'])}>
                <UiCloseButton className="close-button" onClick={closeCrmMessageAndUpdate} data-test="close-button" />

                {onsiteMessage.mascotMessage && (
                    <CrmMessagePreviewMascot
                        mascot={onsiteMessage.mascotMessage}
                        type={onsiteMessage.type}
                        avatar={onsiteMessage.mascotAvatar}
                        className={classnames([' animated', isMessageOpen ? 'fadeIn' : 'fadeOutDown'])}
                    />
                )}
                {(onsiteMessage.title || onsiteMessage.description) && (
                    <div
                        className={classnames([
                            'crm-message-content animated',
                            isMessageOpen ? 'fadeIn' : 'fadeOutDown',
                        ])}
                    >
                        {onsiteMessage.image && (
                            <img className="crm-message-image" src={onsiteMessage.image} alt="message_icon" />
                        )}

                        {onsiteMessage.description && (
                            <div
                                className="crm-message-text"
                                dangerouslySetInnerHTML={{ __html: onsiteMessage.description }}
                            />
                        )}

                        {onsiteMessage.ctaLink && (
                            <div className="crm-message-cta" onClick={() => handleCtaClick(onsiteMessage)}>
                                <a href={onsiteMessage.ctaLink}>
                                    <UiButton color="primary" block size="large">
                                        {onsiteMessage.ctaTitle}
                                    </UiButton>
                                </a>
                            </div>
                        )}
                    </div>
                )}
                {onsiteMessage.freeSpinBonus && <CrmInboxFreeSpinBonus freeSpinBonus={onsiteMessage.freeSpinBonus} />}
            </div>

            {isMessageOpen && <div className="message-blur" />}
        </Wrapper>
    );
}
