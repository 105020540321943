import React from 'react';
import { Helmet } from 'react-helmet';
import compact from 'lodash/compact';
import { getDocumentHost } from '../../services/util';
import isEmpty from 'lodash/isEmpty';
import { getClient } from '../../services/environment';
import { environment } from '../../stores/environment/environment';
import { useStore } from '../../hooks/useStore';

interface Props {
    title?: string;
    description?: string;
    image?: string;
    alternateLinks?: { hrefLang: string; rel: string; href: string }[] | string[];
    meta?: string[];
    preRenderDoNotIndex?: boolean;
}

export default function Seo({ title, description, image, meta = [], preRenderDoNotIndex = false }: Props) {
    const [{ FACEBOOK_ADS_KEY, FACEBOOK_APP_ID, ROOT_URL }] = useStore(environment);
    const facebookAdsKey = FACEBOOK_ADS_KEY;
    const imageOrDefault = image || `${ROOT_URL}/assets/images/general/${getClient()}-share-img.jpg`;

    const mapToValue = (content) => (name) => ({ name, content });

    const facebookTags = compact([
        title && { property: 'og:title', content: title },
        description && { property: 'og:description', content: description },
        imageOrDefault && { property: 'og:image', content: imageOrDefault },
        {
            property: 'og:type',
            content: 'website',
        },
        {
            property: 'og:image:width',
            content: '470',
        },
        {
            property: 'og:image:height',
            content: '246',
        },
        {
            property: 'og:url',
            content: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
        },
        {
            property: 'fb:app_id',
            content: FACEBOOK_APP_ID,
        },
    ]);

    const titles = (title && ['name', 'twitter:title'].map(mapToValue(title))) || [];
    const descriptions = (description && ['description', 'twitter:description'].map(mapToValue(description))) || [];
    const images = ['image', 'twitter:image:src'].map(mapToValue(imageOrDefault));

    const composedMeta = [
        ...titles,
        ...descriptions,
        ...images,
        ...meta,
        ...facebookTags,
        preRenderDoNotIndex && {
            name: 'prerender-status-code',
            content: '404',
        },
        {
            name: 'twitter:card',
            content: 'summary_large_image',
        },
        {
            name: 'twitter:site',
            content: `www.${getDocumentHost()}`,
        },
        {
            name: 'twitter:creator',
            content: `www.${getDocumentHost()}`,
        },
        {
            name: 'title_short',
            content: 'Coolbet - Sport and Casino',
        },
        facebookAdsKey &&
            !isEmpty(facebookAdsKey[getDocumentHost()]) && {
                name: 'facebook-domain-verification',
                content: facebookAdsKey[getDocumentHost()],
            },
    ].filter(Boolean);

    return <Helmet title={title} meta={composedMeta} />;
}
