import { Country } from '@staycool/location';
import isEmpty from 'lodash/isEmpty';
import { SyntheticEvent } from 'react';
import { stores } from '../../stores';
import { casino } from '../../stores/casino';
import { getStoreValue } from '../../stores/store/utils';
import { getActiveCurrency } from '../currency';
import { getDeviceCategory } from '../device';
import { getLicence, LICENCE_NAME_BY_LICENCE_CODE } from '../licence';
import { logger } from '../logger';
import { translate } from '../translate';
import { LiveLobbyLauncherByType } from './provider-live-lobby-types';
import { CasinoProviderIntegrationNames, CasinoSubproviderIntegrationNames } from './providers';
import { ProviderInfo } from './types';

// Please don't add here any casino object specific code. We have separate typescript file for every type of casino object (game, category, feature, provider etc).

export function getDefaultQueryParams() {
    let country = getStoreValue(stores.ipCountry);
    const user = getStoreValue(stores.user);

    const APP_STORE_TEST_USERS = ['CndWLHdq', 'HKYhQVWJ'];
    if (user && APP_STORE_TEST_USERS.includes(user.id)) {
        country = Country.MEXICO;
    }

    return {
        country,
        userCountry: user?.country || country,
        province: undefined,
        licence: getLicence(),
        device: getDeviceCategory().toLowerCase(),
        currency: getActiveCurrency(),
        language: getStoreValue(stores.language),
    };
}

document.addEventListener('webkitfullscreenchange', fullScreenChange, false);
document.addEventListener('mozfullscreenchange', fullScreenChange, false);
document.addEventListener('fullscreenchange', fullScreenChange, false);
document.addEventListener('MSFullscreenChange', fullScreenChange, false);

export const descriptionTranslations = {
    live: {
        Blackjack: (name) => translate('blackjack.live', 'casino.meta.description', { name }),
        Roulette: (name) => translate('roulette.live', 'casino.meta.description', { name }),
        Baccarat: (name) => translate('baccarat.live', 'casino.meta.description', { name }),
        Poker: (name) => translate('poker.live', 'casino.meta.description', { name }),
        Dice: (name) => translate('dice.live', 'casino.meta.description', { name }),
        Gameshow: (name) => translate('gameshow.live', 'casino.meta.description', { name }),
    },
    common: {
        Slots: (name) => translate('slots', 'casino.meta.description', { name }),
        Blackjack: (name) => translate('blackjack', 'casino.meta.description', { name }),
        Roulette: (name) => translate('roulette', 'casino.meta.description', { name }),
        Baccarat: (name) => translate('baccarat', 'casino.meta.description', { name }),
        Poker: (name) => translate('poker', 'casino.meta.description', { name }),
        'Video Poker': (name) => translate('video-poker', 'casino.meta.description', { name }),
        'Video Bingo': (name) => translate('video-bingo', 'casino.meta.description', { name }),
        Other: (name) => translate('other', 'casino.meta.description', { name }),
    },
};

export function goFullScreen(element?: HTMLElement | SyntheticEvent) {
    let gameLayout: HTMLElement | null;

    if (element instanceof HTMLElement) {
        gameLayout = element;
    } else {
        gameLayout = document.getElementById('casino-game-desktop-layout-game');
    }

    if (getStoreValue(stores.responsibleGaming.isResponsibleGamingPanelVisible)) {
        gameLayout = document.documentElement;
    }

    if (!gameLayout) {
        return;
    }
    const options = { navigationUI: 'hide' } as FullscreenOptions;

    try {
        gameLayout.requestFullscreen(options).catch(() => {});
    } catch (error) {
        logger.error('CasinoCasinoService', 'goFullScreen', error);
    }
}

export function goSemiFullScreen() {
    try {
        goFullScreen(document.documentElement);
    } catch (error) {
        logger.error('CasinoCasinoService', 'goSemiFullScreen', error);
    }
}

export function exitFullScreen() {
    if (getStoreValue(casino.isFullScreen)) {
        try {
            document?.exitFullscreen().catch(() => {});
        } catch (error) {
            logger.error('CasinoCasinoService', 'exitFullScreen', error);
        }
    }
}

function fullScreenChange() {
    if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreenElement &&
        !document.msFullscreenElement &&
        !document.fullscreenElement
    ) {
        casino.isFullScreen.set(false);
    } else {
        casino.isFullScreen.set(true);
    }
}

export function getGeneralErrorMessage() {
    return `${translate('Could not start casino game.', 'ui.casino')}${translate(
        'Please try again later.',
        'ui.common',
    )}`;
}

export function getFromConfigByLicenseCountry(configRoot) {
    return configRoot[LICENCE_NAME_BY_LICENCE_CODE[getLicence()]] || configRoot.MALTA;
}

export function getGameNameFromEvent(event: any): string {
    const [pathname] = decodeURI(event.target.location.pathname).split('/').slice(3);
    const splittedPathName = pathname.split('-');
    splittedPathName.pop();
    return splittedPathName.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function getProviderInfo(providerName: string, lobbyLauncherByType: LiveLobbyLauncherByType, type: string) {
    const providerId = Object.keys(CasinoProviderIntegrationNames).find(
        (providerId) => CasinoProviderIntegrationNames[providerId] === providerName,
    );

    if (!providerId) {
        return getSubproviderInfo(providerName, lobbyLauncherByType, type);
    }

    if (isEmpty(lobbyLauncherByType)) {
        return null;
    }

    return lobbyLauncherByType[type]?.find(
        (providerInfo: ProviderInfo) => providerInfo.providerId === Number(providerId),
    );
}

function getSubproviderInfo(subproviderName: string, lobbyLauncherByType: LiveLobbyLauncherByType, type: string) {
    const subproviderId = Object.keys(CasinoSubproviderIntegrationNames).find(
        (subproviderId) => CasinoSubproviderIntegrationNames[subproviderId] === subproviderName,
    );

    if (!subproviderId || isEmpty(lobbyLauncherByType)) {
        return null;
    }

    return lobbyLauncherByType[type]?.find(
        (providerInfo: ProviderInfo) => providerInfo.subProviderId === Number(subproviderId),
    );
}
