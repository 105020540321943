import styled from 'styled-components';

export default styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 1.2rem;
    height: 1.2rem;

    background-color: var(--color-info);
    border-radius: 50%;

    font-size: 0.65rem;
    color: #fff;
    text-align: center;
    font-weight: var(--font-weight-medium);
    line-height: 1rem;

    transform: translate(0, 0);
    transition: transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
`;
