export enum FANTASY_LANGUAGE {
    CANADIAN = 'ca',
    CHILEAN = 'cl',
    PERUVIAN = 'pe',
    ECUADORIAN = 'ec',
    MEXICAN = 'mx',
    US = 'us',
    EUROPEAN = 'eu',
}

export enum REAL_LANGUAGE {
    ENGLISH = 'en',
    ESTONIAN = 'et',
    FINNISH = 'fi',
    ICELANDIC = 'is',
    RUSSIAN = 'ru',
    SPANISH = 'es',
    SWEDISH = 'sv',
    DEFAULT_LATAM = 'cl',
    EUROPEAN = 'eu',
}

export const LANGUAGE = { ...FANTASY_LANGUAGE, ...REAL_LANGUAGE };
export type Language = FANTASY_LANGUAGE | REAL_LANGUAGE;

export enum LOCALE {
    EN = 'en_GB',
    NO = 'nb_NO',
    SE = 'sv_SE',
    FI = 'fi_FI',
    EE = 'et_EE',
    RU = 'ru_RU',
    DE = 'de_DE',
    ES = 'es_ES',
    IS = 'is_IS',
    CL = 'es_CL',
    PE = 'es_PE',
    DK = 'da_DK',
    CA = 'en_CA',
    ON = 'en_CA',
    EC = 'es_EC',
    PY = 'es_PY',
    MX = 'es_MX',
    US = 'en_US',
    EU = 'en_EU',
}
