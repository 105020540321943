import moment from 'moment';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';

export const DATE_FORMAT = 'DD MM';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const DATE_YEAR_FORMAT = `${DATE_FORMAT} YYYY`;
export const DATE_YEAR_TIME_FORMAT = `${DATE_YEAR_FORMAT} ${TIME_FORMAT}`;
export const MONTH_DAY_YEAR_FORMAT = 'mm-dd-yyyy';

function getUSTimeFormat(format: string) {
    const newFormat = format
        .replace(/[d+|D+]+.M+/, 'MM-DD')
        .replace(/[^a-zA-Z\n/]([^h|H|m])/g, '/$1')
        .replace(/[h|H]+.[m]+/, 'h:mm A');
    return newFormat;
}

export function getFormattedDate({
    date,
    format,
    useMonthLetters,
    useDots,
}: {
    date?: string | Date;
    format: string;
    useMonthLetters?: boolean;
    useDots?: boolean;
}) {
    const userSettings = getStoreValue(stores.sports.userSettings);
    const isAmericanTimeFormat = userSettings?.is_american_time_format;
    if (useMonthLetters) {
        format = format.replace('MM', 'MMM');
    }
    if (useDots) {
        format = format.replace(' ', '.').replace(' ', '.');
    }
    return moment(date).format(isAmericanTimeFormat ? getUSTimeFormat(format) : format);
}
