import React from 'react';
import Wrapper from './styles';
import Snippet from '../../snippet/Snippet';
import { PRODUCT, snippetAndDescriptionByProduct } from '../../../types/common';
import { ObjectValues } from '../../../services/ts-utils';

interface Props {
    product: ObjectValues<typeof PRODUCT>;
}

export default function UiProductClosed({ product }: Props) {
    return (
        <Wrapper className={product}>
            <div className="noize" />
            <div className="message-box">
                <div className="message-box-text">
                    <Snippet snippetKey={snippetAndDescriptionByProduct[product].snippet} />
                </div>
            </div>
        </Wrapper>
    );
}
