export const enum Currency {
    CAD = 'CAD',
    CLP = 'CLP',
    DKK = 'DKK',
    EUR = 'EUR',
    MXN = 'MXN',
    NOK = 'NOK',
    PEN = 'PEN',
    PYG = 'PYG',
    SEK = 'SEK',
    USD = 'USD',
    USDT = 'USDT',
    VCD = 'VCD',
}

export type Transaction = {
    id: string;
    state: TransactionState;
    reason: string | null;
    created: Date;
    updated: Date;
    user_id: string;
    product: TransactionProduct;
    type: string;
    description: string;
    device: string;
    app: string;
    login_session_id: string;
    amount_uc: number;
    balance_uc: number;
    currency: any;
    ticket_id: string;
    ticket_display_id: string;
    bet_id: string;
    prev_trans_id: string | null;
    next_trans_id: string | null;
    init_trans_id: string | null;
    external_transaction_id: string;
    group_id: string;
    gameName?: string;
    isLockedWithdrawal?: boolean;
    isCancellable?: boolean;
};

export const enum TransactionProduct {
    SPORTSBOOK = 'SPORTSBOOK',
    CASINO = 'CASINO',
    POKER = 'POKER',
    VIRTUAL_SPORTS = 'VIRTUAL_SPORTS',
    DEPOSIT = 'DEPOSIT',
    WITHDRAWAL = 'WITHDRAWAL',
    BONUS = 'BONUS',
    CASINO_IN_POKER = 'CIP',
    RACEBOOK = 'RACEBOOK',
}

export const enum TransactionState {
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
    PENDING = 'PENDING',
}

export type WalletBalance = {
    id: string;
    created: string;
    updated: string;
    balance_uc: number;
    balance: number;
    currency: Currency;
    has_deposited: boolean;
};
