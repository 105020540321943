import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import PaymentDepositProviderTruelayerScripts from '../../../provider/truelayer/scripts/PaymentDepositProviderTruelayerScripts';
import { logger } from '../../../../../../services/logger';
import { isProduction } from '../../../../../../services/util';

interface Props {
    response: Record<string, string>;
    setIsError: (value: boolean) => void;
}

export default function PaymentDepositPayAndPlayProvidersTruelayer({ response, setIsError }: Props) {
    const [hasScriptsLoaded, setHasScriptsLoaded] = useState(false);

    useEffect(() => {
        if (hasScriptsLoaded) {
            tryDeposit();
        }
    }, [hasScriptsLoaded]);

    async function tryDeposit() {
        try {
            const target = document.getElementById('truelayer-pnp-container-id');
            const payment = await Truelayer.Payment({
                ...response,
                target: target!,
                production: isProduction(),
                onDone: () => target?.remove(),
                onAbort: () => target?.remove(),
            });
            payment.start();
        } catch (error) {
            logger.error('PaymentDepositPayAndPlayProvidersTruelayer', 'tryDeposit', error);
            setIsError(true);
        }
    }

    return (
        <Wrapper id="truelayer-pnp-container-id">
            <PaymentDepositProviderTruelayerScripts
                onError={() => setIsError(true)}
                onScriptsLoaded={() => setHasScriptsLoaded(true)}
            />
        </Wrapper>
    );
}

declare let Truelayer: {
    Payment: (...args: unknown[]) => Promise<{
        start: () => void;
    }>;
};
