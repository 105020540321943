import { createStores } from './store/utils';
import { OnsiteMessageWithCampaign, OnsiteMessagesByTab, UserCrmPreferences } from '../microservices/crm/types';
import { getMessagesByType } from '../services/onsite-messages';

export const crm = createStores({
    messagesByType: getMessagesByType([]) as OnsiteMessagesByTab,
    areMessagesLoading: false,
    onsite: undefined as OnsiteMessageWithCampaign | undefined,
    selectedMessage: undefined as OnsiteMessageWithCampaign | undefined,
    isInboxOpen: false,
    openedBy: 'system' as 'user' | 'system',
    userPreferences: undefined as UserCrmPreferences | undefined | Omit<UserCrmPreferences, 'user_id'>,
});
