import invert from 'lodash/invert';

export const suffixMap = {
    facebook: 'F',
    facebookMessenger: 'C',
    link: 'L',
    email: 'E',
    sms: 'M',
} as const;

export const STATUS = {
    REGISTERED: 'registered',
    DEPOSITED: 'deposited',
    PENDING: 'pending',
    REWARDED: 'rewarded',
};

export function getCodeWithoutProvider(code) {
    return code.split('-')[0];
}

export function getCodeProvider(code) {
    const providerSuffix = code.split('-')[1];
    return invert(suffixMap)[providerSuffix];
}

export const PARTICIPANT_STATUS = {
    ENABLED: 'enabled',
    REQUEST: 'request',
    DISABLED: 'disabled',
    REJECTED: 'rejected',
};
