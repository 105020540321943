import React from 'react';
import { stores } from '../../../stores';
import UiModal from '../../ui/modal/UiModal';
import AuthLoginButton from '../login/button/AuthLoginButton';
import { translate } from '../../../services/translate';
import Svg from '../../svg/Svg';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

export default function AuthMultipleSessionModal() {
    const [isMultipleSessionModalVisible, setIsMultipleSessionModalVisible] = useStore(
        stores.modals.isMultipleSessionModalOpen,
    );
    const [{ isPhone }] = useStore(media);

    function onClose() {
        setIsMultipleSessionModalVisible(false);
    }

    return (
        <UiModal
            open={isMultipleSessionModalVisible}
            onClose={onClose}
            className="new-modal"
            title={translate('You have been logged out. Please log in again.', 'ui.common')}
            icon={<Svg icon="logout" size={3} />}
            mode={isPhone ? 'drawer' : 'default'}
            maxWidth={300}
            minWidth={300}
        >
            <AuthLoginButton block />
        </UiModal>
    );
}
