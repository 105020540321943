import styled, { css } from 'styled-components';

const positions = {
    top: css`
        .ui-language-select-mini-board-container-overflow {
            bottom: 100%;
        }
    `,
    bottom: css`
        .ui-language-select-mini-board-container-overflow {
            top: 100%;
        }
    `,
};
const shifts = {
    center: css`
        .ui-language-select-mini-board-container-overflow {
            left: 50%;
            transform: translateX(-50%);
        }

        .ui-language-select-mini-board-container-arrow {
            left: 50%;
            transform: translateX(-50%);
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent var(--surface-level-2-line-color) transparent;
        }

        .ui-language-select-mini-board-container {
            transform-origin: top;
        }
    `,
    right: css`
        .ui-language-select-mini-board-container-overflow {
            left: 0;
        }

        .ui-language-select-mini-board-container-arrow {
            transform: translateX(50%);
            border-width: 10px 10px 0 10px;
            border-color: transparent transparent transparent var(--surface-level-2-line-color);
        }

        .ui-language-select-mini-board-container {
            transform-origin: top left;
        }

        .ui-language-select-mini-board {
            border-radius: 0 5px 5px 5px;
        }
    `,
    left: css`
        .ui-language-select-mini-board-container-overflow {
            right: 0;
        }

        .ui-language-select-mini-board-container-arrow {
            right: 0;
            border-width: 10px 10px 0 10px;
            border-color: transparent var(--surface-level-2-line-color) transparent transparent;
        }

        .ui-language-select-mini-board-container {
            transform-origin: top right;
        }

        .ui-language-select-mini-board {
            border-radius: 5px 0 5px 5px;
        }
    `,
};

type StyledProps = {
    $position?: 'top' | 'bottom';
    $shift?: 'center' | 'right' | 'left';
};

export default styled.div<StyledProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: default;
    .ui-language-select-mini-text {
        margin-left: var(--spacing-8);
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 1px;
        color: var(--font-color-secondary);
        text-transform: uppercase;
    }

    .ui-language-select-mini-image {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
    }

    .ui-language-select-mini-board-container-overflow {
        position: absolute;
        z-index: 500;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s, visibility 0s 0.2s step-end;

        .ui-language-select-mini-board-container {
            transform: scale(0.5);
            transition: transform 0.2s;
        }

        &.opened {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.2s, visibility 0s;
            .ui-language-select-mini-board-container {
                transform: scale(1);

                @media (max-width: 1200px) {
                    transform: scale(1.5);
                }
            }
        }
    }

    .ui-language-select-mini-board-container-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        top: -10px;
        transform: translateX(-50%);
    }

    .ui-language-select-mini-board {
        margin: 10px;
        padding: 5px;
        background-color: var(--surface-level-2-bg);
        border-radius: var(--ui-radius);
        display: flex;
        width: 151px;
        flex-wrap: wrap;
        box-shadow: rgba(0, 0, 0, 0.56) 0 22px 70px 4px;

        &-column {
            flex-direction: column;
            width: 80px;
            .ui-language-select-mini-board-language-container {
                cursor: pointer;
                font-size: 15px;
                font-weight: 700;
                letter-spacing: 1px;
                &:hover {
                    color: var(--button-primary-bg);
                }
            }
        }
    }

    .ui-language-select-mini-board-language-container {
        flex: 1 0 33%;
    }

    .ui-language-select-mini-board-language {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px 0;
    }

    .flag-container {
        position: relative;
        cursor: pointer;
        .ui-language-select-mini-board-language-text {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            opacity: 0;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.4);
            text-transform: uppercase;
            font-weight: 700;
            transition: opacity 0.2s;
            span {
                display: block;
                line-height: 25px;
            }
        }
        &:hover {
            .ui-language-select-mini-board-language-text {
                opacity: 1;
            }
        }
    }

    .flag {
        height: 25px;
        width: 25px;
        border-radius: 50%;
    }

    ${({ $position }) => $position && positions[$position]}
    ${({ $shift }) => $shift && shifts[$shift]}
`;
