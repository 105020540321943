import React, { useEffect } from 'react';
import { stores } from '../../../stores';
import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from '../../../microservices/pusher';
import { getBannerMessages } from '../../../microservices/cms';
import { getStoreValue } from '../../../stores/store/utils';
import { useStore } from '../../../hooks/useStore';
import { logger } from '../../../services/logger';
import { storageGet } from '../../../services/storage';
import moment from 'moment';

enum ChangeType {
    add = 'add',
    remove = 'remove',
    update = 'update',
}

export default function SystemBannerMessage() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [user] = useStore(stores.user);
    const [bannerMessages, setBannerMessages] = useStore(stores.cms.bannerMessages);
    const [bannerMessageLastRequest, setBannerMessageLastRequest] = useStore(stores.cms.bannerMessageLastRequest);

    useEffect(() => {
        load();
    }, [isAuthenticated]);

    async function load() {
        if (!isAuthenticated || !user) {
            setBannerMessages([]);
            setBannerMessageLastRequest('');
            return;
        }
        const isLessThan10MinutesAgo =
            bannerMessageLastRequest && moment().isBefore(moment(bannerMessageLastRequest).add(10, 'minutes'));
        if (isLessThan10MinutesAgo) {
            return bannerMessages;
        }
        try {
            const response = await getBannerMessages(user.market);
            response.forEach((message) => {
                const hiddenEndTime = storageGet(`banner-${message.id}`);
                message.isHidden = Number(hiddenEndTime) > new Date().getTime();
            });
            setBannerMessages(response);
            setBannerMessageLastRequest(new Date().toISOString());
        } catch (error) {
            logger.error('SystemBannerMessage', 'load', error);
        }
    }

    function onBannerMessageUpdate({ bannerMessage, changeType }) {
        const bannerMessages = getStoreValue(stores.cms.bannerMessages);
        const user = getStoreValue(stores.user);
        if (bannerMessage.market !== user?.market) {
            return;
        }
        let messages = [...bannerMessages];
        if (changeType === ChangeType.remove) {
            messages = bannerMessages.filter((message) => message.id !== bannerMessage.id);
        } else if (changeType === ChangeType.update) {
            messages = bannerMessages.map((message) => {
                if (message.id === bannerMessage.id) {
                    return bannerMessage;
                }
                return message;
            });
        } else if (changeType === ChangeType.add) {
            messages.push(bannerMessage);
        }
        stores.cms.bannerMessages.set(messages);
    }
    useSocketSubscribeUnsubscribe('public', {
        params: {
            service: 'cms',
            channel: 'banner-message',
        },
        guardFunction: () => isAuthenticated,
        watchParams: [isAuthenticated],
    });

    useSocketTopicEvents('message-update', onBannerMessageUpdate, []);
    return <></>;
}
