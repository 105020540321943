import styled from 'styled-components';
import { ClientName } from '../../../../../../services/utils/types';

type StyledProps = {
    isAuthenticated?: boolean;
};

export default styled.header<StyledProps>`
    position: relative;

    display: flex;
    align-items: center;

    font-size: 0.8rem;

    height: 60px;
    z-index: 2;
    background: var(--color-header-bg);
    box-shadow: var(--ui-shadow);
    padding: var(--spacing-12) 0;
    .logo-menu-wrapper {
        display: flex;
        align-items: center;
        gap: var(--spacing-4);
        flex: 1;
    }
    .hamburger-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
    }
    .balance-info-wrapper {
        display: flex;
        align-items: center;
        gap: var(--spacing-12);
        color: var(--color-highlight);
    }
    .balance-label {
        color: var(--font-color-subtle);
        text-align: right;
    }
    .balance-amount {
        color: var(--color-highlight);
        font-weight: var(--font-weight-bold);
    }
    .mobile-profile-wrapper {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        gap: var(--spacing-12);
        padding: 0 var(--spacing-12);
    }
    .logo-${ClientName.IVC} img {
        height: 3rem;
    }
`;
