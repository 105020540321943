import styled from 'styled-components';
import { mediaQuery } from '../../styles/utils';

export default styled.div`
    .main-container {
        z-index: 1;
        position: relative;
        max-width: 1920px;
        margin: var(--spacing-8) auto;
        padding: 0 ${({ theme }) => theme.layout.gutterHalf};

        @media ${mediaQuery.isPhone} {
            padding: 0 ${({ theme }) => theme.layout.gutterMobile} ${({ theme }) => theme.layout.gutterMobile}
                ${({ theme }) => theme.layout.gutterMobile};
        }
    }

    .message-blur {
        transform: translate(0px, 0px, 0px);
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        left: 0;
        z-index: 100;
        background: rgb(2, 0, 36);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.45) 35%, rgba(0, 0, 0, 0.2) 70%);
        @media ${mediaQuery.isLaptop} {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 0.1) 60%);
        }
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.8) 45%, rgba(0, 0, 0, 0.7) 100%);
        }
    }
`;
