import React, { PropsWithChildren } from 'react';
import { getRoute, isActiveRoute } from '../../services/router';
import MainLayout from '../main/MainLayout';
import Wrapper from './styles';

export default function PokerLayout({ children }: PropsWithChildren) {
    const isPokerLobbyPage = isActiveRoute(getRoute('poker'));

    return (
        <Wrapper $isPokerLobbyPage={isPokerLobbyPage}>
            <MainLayout>{children}</MainLayout>
        </Wrapper>
    );
}
