import styled, { css } from 'styled-components';

type StyledProps = {
    $isOnSurface?: boolean;
    selected?: boolean;
    $badge?: number;
    $block?: boolean;
    size: 'default' | 'large' | 'small' | 'tiny';
};

export default styled.button<StyledProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: ${({ $isOnSurface }) => ($isOnSurface ? 'var(--card-button-on-surface-bg)' : 'var(--card-button-bg)')};

    height: 5.5rem;
    min-width: 6rem;
    max-width: 6rem;
    padding: 0.8rem;
    border: ${({ $isOnSurface }) =>
        $isOnSurface ? 'var(--card-button-on-surface-border)' : 'var(--card-button-border)'};
    border-radius: var(--ui-radius);

    font-size: 0.625rem;
    color: ${({ $isOnSurface }) =>
        $isOnSurface ? 'var(--card-button-on-surface-text-color)' : 'var(--card-button-text-color)'};
    font-weight: var(--button-font-weight);
    text-transform: uppercase;
    letter-spacing: 0.03em;
    cursor: pointer;

    text-shadow: var(--card-button-shadow);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;

    &:hover {
        background-color: ${({ $isOnSurface }) =>
            $isOnSurface ? 'var(--card-button-on-surface-bg)' : 'var(--card-button-bg-hover)'};
        color: ${({ $isOnSurface }) =>
            $isOnSurface ? 'var(--card-button-on-surface-text-color)' : 'var(--card-button-text-color-hover)'};
        .button-badge {
            background-color: var(--color-highlight);
            border-color: var(--color-highlight);
            color: var(--color-on-highlight) !important;
        }
    }

    .button-icon,
    .button-badge {
        margin-bottom: var(--spacing-4);
    }

    .button-icon,
    .button-text,
    .button-badge {
        display: flex;
        align-items: center;
        text-align: center;
        min-height: 1.75rem;
    }

    .button-text {
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering}
    }

    .button-badge {
        min-height: 1.75rem;
        width: 1.75rem;
        justify-content: center;
        border-radius: 50%;
        background-color: var(--ticket-badge-live-bg);
        border: var(--ticket-badge-live-border);
        color: var(--ticket-badge-live-text-color) !important;
    }
    .button-badge {
        font-size: ${({ $badge }) => ($badge && $badge > 99 ? '0.625rem' : '0.875rem')};
    }

    ${({ size }) => sizes[size].css};

    ${({ $block }) =>
        $block &&
        css`
            width: 100%;
        `};

    ${({ selected }) => {
        if (selected) {
            return states.active;
        }
        if (selected === undefined) {
            return css`
                .active > & {
                    ${states.active}
                }
            `;
        }
    }};

    &[disabled] {
        pointer-events: none;
        cursor: not-allowed;
        filter: contrast(0.8);
        opacity: 0.6;
        box-shadow: none;
    }
`;

const sizes = {
    default: {
        css: css`
            .button-icon svg {
                height: 24px;
            }
        `,
    },
    tiny: {
        css: css`
            min-width: 3.5rem;
            max-width: 3.5rem;
            height: 4.5rem;
            font-size: 0.5rem;
            .button-icon,
            .button-badge {
                margin-bottom: var(--spacing-4);
                svg {
                    height: 1.5rem;
                }
            }
        `,
    },
    small: {
        css: css`
            min-width: 5rem;
            max-width: 5rem;
            height: 5rem;
            font-size: 0.625rem;
            .button-icon,
            .button-badge {
                margin-bottom: var(--spacing-4);
                svg {
                    height: 1.5rem;
                }
            }
        `,
    },
    large: {
        css: css`
            font-size: ${({ theme }) => theme.buttons2.largeSizeFont};
            .button-icon svg {
                height: 21px;
            }
        `,
    },
};

const states = {
    active: css<{ $isOnSurface?: boolean }>`
        background-color: ${({ $isOnSurface }) =>
            $isOnSurface ? 'var(--card-button-on-surface-active-bg)' : 'var(--card-button-active-bg)'};
        border: var(--card-button-active-border);
        box-shadow: var(--card-button-shadow);
        color: ${({ $isOnSurface }) =>
            $isOnSurface ? 'var(--card-button-on-surface-active-text-color)' : 'var(--card-button-active-text-color)'};
        &:hover {
            background-color: ${({ $isOnSurface }) =>
                $isOnSurface ? 'var(--card-button-on-surface-active-bg)' : 'var(--card-button-active-bg)'};
            color: ${({ $isOnSurface }) =>
                $isOnSurface
                    ? 'var(--card-button-on-surface-active-text-color)'
                    : 'var(--card-button-active-text-color)'};
        }
        .button-badge {
            background-color: var(--color-highlight);
            border-color: var(--color-highlight);
            color: var(--color-on-highlight) !important;
        }
    `,
};
