import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

type StyledProps = {
    $zebraStyle?: boolean;
    $gap?: number;
    $isFirstColumnSticky?: boolean;
};

export default styled.div<StyledProps>`
    //TODO: fix row background-color in mobile view
    display: flex;
    flex-wrap: wrap;
    background-color: var(--surface-level-2-bg);
    border-radius: var(--ui-radius);
    color: var(--font-color-primary);
    scrollbar-width: auto;

    @media ${mediaQuery.isPhone} {
        padding: 0;
        overflow-y: hidden;
        width: 100%;
        z-index: 10;
        flex: 0 0 auto;
    }
    .table-row {
        width: 100%;
        display: flex;
        align-items: stretch;
        font-size: 0.825rem;
        position: relative;
        background-color: var(--surface-level-2-bg);

        .table-cell {
            background-color: var(--surface-level-2-bg);

            ${({ $zebraStyle }) =>
                $zebraStyle &&
                css`
                    background-color: var(--table-zebra-light-color);
                `}
        }

        &:nth-of-type(odd) {
            .table-cell {
                background-color: var(--surface-level-2-bg);

                ${({ $zebraStyle }) =>
                    $zebraStyle &&
                    css`
                        background-color: var(--table-zebra-dark-color);
                    `}
            }
        }

        &.heading {
            color: var(--color-highlight);
            background: var(--surface-level-1-bg);
            font-size: 0.875rem;
            position: sticky;
            top: 0;
            z-index: 2;

            .table-cell {
                color: var(--color-highlight);
                ${({ $zebraStyle }) =>
                    $zebraStyle &&
                    css`
                        background: var(--table-zebra-dark-color);
                    `}

                ${({ $gap }) =>
                    $gap &&
                    css`
                        border-top: ${$gap}px solid var(--table-zebra-border-color);
                    `}
            }
        }
    }
    .table-cell {
        box-sizing: border-box;
        flex-grow: 1;
        padding: var(--spacing-8) var(--spacing-16);
        overflow: hidden; // Or flex might break
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 33.333%;
        //background-color: var(--surface-level-2-bg);
        border-bottom: var(--surface-level-2-border);

        ${({ $zebraStyle }) =>
            $zebraStyle &&
            css`
                border-color: var(--table-zebra-border-color);
            `}

        ${({ $gap }) =>
            $gap &&
            css`
                border-width: ${$gap}px;
            `}

        @media ${mediaQuery.isPhone} {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            flex: 0 0 auto;
            padding: var(--spacing-8);
        }

        ${({ $isFirstColumnSticky }) =>
            $isFirstColumnSticky &&
            css`
                &:first-child {
                    position: sticky;
                    left: 0;
                }
                width: 31%;
            `}

        .separator {
            color: var(--color-highlight);
            &:before {
                content: '/';
                @media ${mediaQuery.isPhone} {
                    content: '---';
                }
            }
        }
        .secondary-text {
            color: var(--font-color-subtle);
            font-size: 90%;
            margin-top: var(--spacing-8);
        }
        .primary-text {
            color: var(--color-highlight);
        }
    }
`;
