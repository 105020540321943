import React from 'react';
import Wrapper from './styles';
import { useStore } from '../../../../hooks/useStore';
import { stores } from '../../../../stores';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import UiButton from '../../../ui/button/UiButton';
import Svg from '../../../svg/Svg';
import { getRoute } from '../../../../services/router';
import { media } from '../../../../stores/media/media';

export default function SimHeaderBalance() {
    const [wallet] = useStore(stores.wallet);
    const [{ isLargerThanPhone }] = useStore(media);

    return (
        <Wrapper>
            {wallet && <div className="user-balance">{formattedAmountWithCurrency(wallet.balance_uc)}</div>}
            {isLargerThanPhone && (
                <UiButton
                    url={getRoute('purchase-packages')}
                    className="add"
                    size="small"
                    color="primary"
                    icon={<Svg icon="plus-sign" size={1} />}
                />
            )}
        </Wrapper>
    );
}
