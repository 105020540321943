import React from 'react';
import Wrapper from './styles';
import { useStore } from '../../../hooks/useStore';
import { stores } from '../../../stores';
import { ONSITE_MESSAGE_TAB } from '../../../microservices/crm/types';
import { translate } from '../../../services/translate';
import UiButton from '../../ui/button/UiButton';
import { getRoute } from '../../../services/router';
import CrmMessage from '../message/CrmMessage';
import { CRM_MESSAGE_STEP } from '../../../services/onsite-messages';

export default function CrmDropdown() {
    const [messagesByType] = useStore(stores.crm.messagesByType);
    const selectedMessages = messagesByType[ONSITE_MESSAGE_TAB.ALL];

    function renderContent() {
        if (!selectedMessages.messages.length) {
            return <span className="dropdown-content-empty">{translate('no-new-messages', 'crm.dropdown')}</span>;
        }

        return selectedMessages.messages
            .slice(0, CRM_MESSAGE_STEP)
            .map((message) => <CrmMessage key={message.id} message={message} />);
    }

    return (
        <Wrapper>
            <div className="dropdown-content">{renderContent()}</div>
            {Boolean(messagesByType[ONSITE_MESSAGE_TAB.ALL].messages.length) && (
                <div className="dropdown-view-all-button">
                    <UiButton block url={getRoute('inbox')} selected={false}>
                        {translate('view-all', 'crm.dropdown')}
                    </UiButton>
                </div>
            )}
        </Wrapper>
    );
}
