import styled from 'styled-components';
import { mediaQuery } from '../../styles/utils';

export default styled.div`
    position: relative;
    height: 38px;
    cursor: pointer;

    & .progress-level {
        display: block;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 900;
        font-size: 18px;
        text-shadow: 0 0 10px black;
    }

    & .progress-bar {
        & .background {
            fill: var(--color-primary);
            stroke: var(--surface-level-3-bg);
        }

        & .foreground {
            stroke: var(--color-secondary);
            stroke-dasharray: 100;
            stroke-dashoffset: calc(100 - var(--progress));
        }
    }

    & .loyalty-tooltip {
        z-index: 110;
        text-align: center;
        padding: var(--spacing-8);
        display: flex;
        flex-direction: column;
        gap: var(--spacing-8);
        background-color: var(--color-primary);

        @media ${mediaQuery.isPhone} {
            width: 140px;
        }

        & .loyalty-label {
            font-size: var(--font-16);

            @media ${mediaQuery.isPhone} {
                font-size: var(--font-14);
            }
        }

        & .loyalty-points {
            font-size: var(--font-20);
            font-weight: var(--font-weight-bold);

            @media ${mediaQuery.isPhone} {
                font-size: var(--font-14);
            }
        }
    }
`;
