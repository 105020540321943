import React, { useEffect, useState } from 'react';
import { stores } from '../../../stores';
import moment, { Duration } from 'moment';
import { filterStyleProps } from '../../../styles/utils';
import { useStore } from '../../../hooks/useStore';

interface Props {
    className?: string;
    endDate: number | string | Date;
    isLocalized?: boolean;
    isTrimmed?: false;
    simplePattern?: string;
    localizedPattern?: string;
    labelOnFinish?: string;
    currentTimeFunction?: () => void;
    onElapsed?: (args?: unknown) => void;
    children?: ({
        days,
        daysLabel,
        hours,
        hoursLabel,
        minutes,
        minutesLabel,
        seconds,
        secondsLabel,
    }: {
        days: string;
        daysLabel: string;
        hours: string;
        hoursLabel: string;
        minutes: string;
        minutesLabel: string;
        seconds: string;
        secondsLabel: string;
    }) => React.ReactNode | React.ReactNode[];
}

export default function UiCountdown(props: Props) {
    const {
        children,
        endDate,
        isLocalized = false,
        isTrimmed = false,
        simplePattern = 'HH:mm:ss',
        localizedPattern = 'd __ h __ m __ s __',
        labelOnFinish,
        onElapsed = () => {},
        currentTimeFunction,
    } = props;
    const [secondsPassedSinceApplicationStart] = useStore(stores.secondsPassedSinceApplicationStart);
    const [durationUntilEndDate, setDurationUntilEndDate] = useState<Duration>();
    const [parameterizedCountdown, setParameterizedCountdown] = useState<any>();

    useEffect(() => {
        updateCountdown();
    }, [secondsPassedSinceApplicationStart]);

    function updateCountdown() {
        const currentTime = (currentTimeFunction && currentTimeFunction()) || moment();
        const endTime = moment(endDate);

        const durationUntilEndDate = moment.duration(endTime.diff(currentTime));

        if (durationUntilEndDate.asSeconds() < 0 && onElapsed) {
            onElapsed();
            return;
        }

        if (children) {
            setParameterizedCountdown(getParameterizedCountdownFromDuration(durationUntilEndDate));
            return;
        }

        setDurationUntilEndDate(durationUntilEndDate);
    }

    function getParameterizedCountdownFromDuration(durationUntilEndDate: Duration) {
        const localizedDuration = durationUntilEndDate.format(localizedPattern, { trim: false });
        const localizedDurationPieces = localizedDuration.split(' ');

        return {
            days: localizedDurationPieces[0],
            hours: localizedDurationPieces[2],
            minutes: localizedDurationPieces[4],
            seconds: localizedDurationPieces[6],
            daysLabel: localizedDurationPieces[1],
            hoursLabel: localizedDurationPieces[3],
            minutesLabel: localizedDurationPieces[5],
            secondsLabel: localizedDurationPieces[7],
        };
    }

    if ((!durationUntilEndDate || durationUntilEndDate.asSeconds() < 1) && labelOnFinish) {
        return <div {...filterStyleProps(props)}>{labelOnFinish}</div>;
    }

    if (children) {
        return <div {...filterStyleProps(props)}>{parameterizedCountdown && children(parameterizedCountdown)}</div>;
    }

    return (
        <div {...filterStyleProps(props)}>
            {durationUntilEndDate &&
                durationUntilEndDate.format(isLocalized ? localizedPattern : simplePattern, { trim: isTrimmed })}
        </div>
    );
}
