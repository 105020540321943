import {
    initialHorseRacingBetslipPlacingState,
    initialHorseRacingBetslipState,
} from '../services/horse-racing/horse-racing';
import { TrackRace, TodaysTrack } from '@staycool/lvdc-types/track';
import { RaceCarryover } from '@staycool/lvdc-types/race';
import { createStores } from './store/utils';
import { LivestreamDetails, RaceState, TrackRaceDetails } from '../microservices/lvdc/types';

export const horseRacing = createStores({
    betslip: initialHorseRacingBetslipState,
    betslipErrors: [] as any[],
    // "betslipStakeErrors" array will contain errors for both (betslip + normal) stake inputs.
    betslipStakeErrors: [] as any[],
    betslipPlacingState: initialHorseRacingBetslipPlacingState,
    favouriteTrackIds: [] as number[],
    livestreamDetails: { code: null, is_stream_available: false } as LivestreamDetails,
    isBetslipOpen: false,
    isRacebookClosed: false,
    todaysTracks: [] as TodaysTrack[],
    isLoadingRaceData: false,
    isLoadingTrackData: false,
    trackRaceDetails: {
        trackRace: {} as TrackRace,
        entries: [],
        betTypes: [],
        state: {} as RaceState,
        raceList: [],
    } as TrackRaceDetails,
    raceEndedActions: {
        shouldReloadUpcomingMatches: true,
        shouldReloadCarryovers: false,
        shouldReloadTrackRace: false,
    },
    carryovers: [] as RaceCarryover[],
    minutesToPostByTimestamp: new Map<number, number>(),
    predefinedBetStakes: [] as number[],
    minMaxStakes: {
        minStake: 0,
        maxStake: 0,
    },
});
