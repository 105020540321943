import { useEffect } from 'react';
import { getAllCrmMessages, loadUserCrmPreferences } from '../../../services/crm';
import { stores } from '../../../stores';
import { useStore } from '../../../hooks/useStore';

export default function SystemCrm() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);

    useEffect(() => {
        if (isAuthenticated) {
            getAllCrmMessages();
            loadUserCrmPreferences();
        }
    }, [isAuthenticated]);

    return null;
}
