import React from 'react';
import { environment } from '../../../../../../stores/environment/environment';
import Wrapper from './styles';
import { stores } from '../../../../../../stores';
import CoolbetHeaderAnonymous from '../../../anonymous/CoolbetHeaderAnonymous';
import { getHomeRoute } from '../../../../../../services/router';
import { changeLanguage } from '../../../../../../services/language';
import UiNavLink from '../../../../../ui/nav-link/UiNavLink';
import { isFeatureAvailable } from '../../../../../../services/feature';
import UiLanguageSelectMini from '../../../../../ui/language-select/mini/UiLanguageSelectMini';
import CoolbetHeaderAuthenticated from '../../../authenticated/CoolbetHeaderAuthenticated';
import { useStore } from '../../../../../../hooks/useStore';
import { FEATURE } from '../../../../../../services/types';

export default function CoolbetHeaderMobileLayoutDefault() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [language] = useStore(stores.language);
    const [{ CLIENT_NAME }] = useStore(environment);

    return (
        <Wrapper>
            <UiNavLink to={getHomeRoute()}>
                <img src={`/assets/images/logo/${CLIENT_NAME}-compact.svg`} alt={CLIENT_NAME} />
            </UiNavLink>
            <div className="mobile-profile-wrapper">
                {isFeatureAvailable(FEATURE.LANGUAGE_SELECTION) && !isAuthenticated && (
                    <UiLanguageSelectMini currentLanguage={language} shift="center" onValueChange={changeLanguage} />
                )}
                {isAuthenticated ? <CoolbetHeaderAuthenticated /> : <CoolbetHeaderAnonymous />}
            </div>
        </Wrapper>
    );
}
