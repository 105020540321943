import classNames from 'classnames';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useRouter } from '../../services/router';
import Svg from '../svg/Svg';
import Wrapper from './styles';
import { Toast as ToastInterface } from '../../services/toast/types';

interface Props {
    toasts: ToastInterface[];
    closeToast: (toast: ToastInterface) => void;
}

export default function Toast({ closeToast, toasts }: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const [toastTimeoutId, setToastTimeoutId] = useState<NodeJS.Timeout>();
    const [loadingBarAnimation, setLoadingBarAnimation] = useState<Animation>();
    const [toastIdCounter, setToastIdCounter] = useState(0);
    const currentToast: ToastInterface | undefined = toasts[0];

    const { navigateTo } = useRouter();

    const loadingBar = useRef<HTMLDivElement>();

    const effectDuration = 300;

    useEffect(() => {
        displayToast();
    }, [toasts]);

    function displayToast() {
        if (currentToast && !isOpen) {
            currentToast.id = toastIdCounter;
            setToastIdCounter(toastIdCounter + 1);
            setIsOpen(true);
            if (currentToast.lifespan) {
                startTimer();
            }
        }
    }

    function close() {
        if (toastTimeoutId) {
            clearTimeout(toastTimeoutId);
            setToastTimeoutId(undefined);
        }
        setIsOpen(false);
        setTimeout(() => {
            currentToast && closeToast(currentToast);
            displayToast();
        }, effectDuration);
    }

    function startTimer() {
        if (!currentToast) {
            return;
        }
        const { lifespan } = currentToast;
        setToastTimeoutId(setTimeout(close, lifespan));
        startLoadingBar(lifespan);
    }

    function startLoadingBar(lifespan: number | undefined) {
        if (!lifespan || lifespan < 0) {
            lifespan = 2000;
        }
        if (loadingBar.current && loadingBar.current.animate) {
            setLoadingBarAnimation(
                loadingBar.current.animate([{ transform: 'scaleX(0)' }, { transform: 'scaleX(1)' }], {
                    duration: lifespan,
                    fill: 'forwards',
                    easing: 'linear',
                }),
            );
        }
    }

    function stopTimer() {
        clearTimeout(toastTimeoutId);
        stopLoadingBar();
    }

    function stopLoadingBar() {
        if (loadingBarAnimation) {
            loadingBarAnimation.playbackRate = -Math.sqrt(Math.abs(Number(loadingBarAnimation.currentTime) || 0)); // loadingBarAnimation.currentTime is negative sometimes, IDK why
        }
    }

    function onActionClicked(action) {
        if (action.action) {
            action.action(navigateTo);
        }
        close();
    }

    if (!currentToast) {
        return null;
    }

    const { type, size, iconName, iconSubText, title, actions, text } = currentToast;

    return (
        <Wrapper
            $toastType={type || 'secondary'}
            $effectDuration={effectDuration}
            className={classNames({ open: isOpen })}
            onMouseOver={stopTimer}
            onMouseLeave={startTimer}
        >
            <div
                className={classNames('coolbet-toast', size, iconName, {
                    'no-icon': !iconName,
                    'custom-theme': type,
                })}
            >
                <div className="coolbet-toast-icon-container grid-background hide-if-empty">
                    {iconName && (
                        <div className="coolbet-toast-icon">
                            <Svg icon={iconName} />
                        </div>
                    )}
                    {iconSubText && <div className="coolbet-toast-icon-subtext">{iconSubText}</div>}
                </div>

                <div className="coolbet-toast-content-container">
                    <div className={classNames('coolbet-toast-content-text-container', { 'no-title': !title })}>
                        <div className="coolbet-toast-content-text-container-title hide-if-empty">{title}</div>
                        <div
                            className="coolbet-toast-content-text-container-message"
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    </div>

                    <div className="coolbet-toast-content-actions-container hide-if-empty">
                        {actions?.map((action, index) => (
                            <div
                                className="coolbet-toast-content-action-button"
                                onClick={() => onActionClicked(action)}
                                key={index}
                            >
                                {action.name}
                                {action.default && (
                                    <div
                                        className="coolbet-toast-content-action-button-bar"
                                        ref={loadingBar as MutableRefObject<HTMLDivElement>}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
