import React from 'react';
import { isFeatureAvailable } from '../../../../services/feature';
import { isMobileApp } from '../../../../services/mobile-app';
import { translate } from '../../../../services/translate';
import { isExistingUser } from '../../../../services/user';
import { stores } from '../../../../stores';
import UiButton from '../../../ui/button/UiButton';
import { requestLogin } from '../../../../services/auth';
import { FEATURE } from '../../../../services/types';

interface Props {
    block?: boolean;
    size?: 'small' | 'large' | 'default';
}

export default function AuthLoginButton({ block, size }: Props) {
    const isLoginOnRegistrationB2BPage = isFeatureAvailable(FEATURE.UNIFIED_LOGIN_AND_REGISTER);

    if (
        (!isFeatureAvailable(FEATURE.LOGIN) && !isFeatureAvailable(FEATURE.REGISTRATION_B2B)) ||
        (isLoginOnRegistrationB2BPage && !isExistingUser())
    ) {
        return null;
    }

    function onClick() {
        requestLogin();
        stores.modals.isInactivityModalVisible.set(false);
    }

    function getRoute() {
        return isLoginOnRegistrationB2BPage && !isMobileApp() ? 'registration-btob' : undefined;
    }

    return (
        <UiButton
            onClick={onClick}
            route={getRoute()}
            color="primary"
            block={block}
            size={size}
            dataTest="button-login"
        >
            {translate('Login', 'ui.common')}
        </UiButton>
    );
}
