import { rgba } from 'polished';
import styled from 'styled-components';

export default styled.div`
    padding: var(--spacing-16);
    display: flex;
    align-items: center;
    gap: var(--spacing-16);
    cursor: pointer;
    transition: background-color 100ms ease-in-out;

    &:not(:last-child) {
        border-bottom: 1px solid var(--surface-level-2-bg);
    }

    &.crm-message-unread {
        background-color: ${({ theme }) => rgba(theme.colors.primary, 0.05)};
    }

    &.crm-message-active {
        background-color: var(--surface-level-2-bg);
    }

    &:hover {
        background-color: var(--surface-level-2-bg);
    }

    & .crm-mascot {
        position: relative;
        animation-delay: 3000ms;
        height: 32px;
        width: 32px;
        min-width: 32px;
        border-radius: 100%;

        & .crm-mascot-badge {
            position: absolute;
            border-radius: 50%;
            top: 0;
            right: calc(var(--spacing-4) * -1);
            width: 8px;
            height: 8px;
        }

        & .crm-mascot-expired {
            background-color: var(--color-highlight);
        }

        & .crm-mascot-new {
            background-color: var(--color-info);
        }
    }

    & .crm-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;
