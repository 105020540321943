import styled from 'styled-components';

export default styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing-8);

    & .header-button {
        border-radius: 100px;
        padding: 0.5rem;
        .button-icon svg {
            width: 1.125rem;
            height: 1.125rem;
        }
    }
`;
