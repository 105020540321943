import styled from 'styled-components';

type StyledProps = {
    $vertical?: boolean;
    $fullWidth?: boolean;
    $center?: boolean;
    $heightScale: number;
};

export default styled.div<StyledProps>`
    width: ${({ $vertical, $fullWidth }) => ($vertical ? '2px' : $fullWidth ? '100%' : '75px')};
    height: ${({ $vertical, $heightScale }) => ($vertical ? `${75 * $heightScale}px` : '2px')};
    margin: ${({ $center }) => ($center ? '0 auto' : '')};

    .brand-line {
        width: ${({ $vertical }) => ($vertical ? '2px' : '100%')};
        height: ${({ $vertical, $heightScale }) => ($vertical ? `${75 * $heightScale}px` : '2px')};
        display: flex;
        flex-direction: ${({ $vertical }) => ($vertical ? 'column' : '')};
        align-items: center;
        border-radius: 100px;
        overflow: hidden;
        margin-bottom: 0.8rem;
        .green {
            width: ${({ $vertical }) => ($vertical ? '100%' : '20%')};
            height: ${({ $vertical }) => ($vertical ? '20%' : '100%')};
            background-color: #7ee629;
        }
        .blue {
            width: ${({ $vertical }) => ($vertical ? '100%' : '35%')};
            height: ${({ $vertical }) => ($vertical ? '35%' : '100%')};
            background-color: #00bfff;
        }
        .orange {
            width: ${({ $vertical }) => ($vertical ? '100%' : '30%')};
            height: ${({ $vertical }) => ($vertical ? '30%' : '100%')};
            background-color: var(--color-highlight);
        }
        .red {
            width: ${({ $vertical }) => ($vertical ? '100%' : '15%')};
            height: ${({ $vertical }) => ($vertical ? '15%' : '100%')};
            background-color: #ff4a30;
        }
        .brand {
            width: 100%;
            height: 100%;
            background-color: var(--color-highlight);
        }
    }
`;
