import range from 'lodash/range';
import React, { ImgHTMLAttributes } from 'react';
import { useStore } from '../../../hooks/useStore';
import { getRoute } from '../../../services/router';
import { translate } from '../../../services/translate';
import { getTermsAndConditionsRoute } from '../../../services/users/terms-and-conditions';
import { environment } from '../../../stores/environment/environment';
import { media } from '../../../stores/media/media';
import CoolbetFooterPaymentPartners from '../../coolbet/footer/payment-partners/CoolbetFooterPaymentPartners';
import Svg from '../../svg/Svg';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import Wrapper from './styles';

export default function SimFooter() {
    const [{ isPhone }] = useStore(media);
    const [{ IMAGE_BASE_URL }] = useStore(environment);

    const partnersLinks = [
        {
            url: 'http://www.caloundrarsl.com.au/',
            fileName: 'rsl.png',
            alt: 'Caloundra RSL',
        },
        {
            url: 'http://www.diggersservicesclub.com.au/',
            fileName: 'diggers-club.png',
            alt: 'Diggers services club',
        },
        {
            url: 'http://www.currumbinrsl.com.au/',
            fileName: 'currumbinrsl.png',
            alt: 'Currumbin RSL',
        },
        {
            url: 'http://www.greenbankrsl.com.au/',
            fileName: 'greenbank.png',
            alt: 'Greenbank services club',
        },
        {
            url: 'http://rslclubsouthport.com.au/',
            fileName: 'rsl-club-southport.png',
            alt: 'SOPO Southport',
        },
        {
            url: 'https://www.greatrust.com.au/',
            fileName: 'greatrust.png',
            alt: 'Gulf Regional Economic Aboriginal Trust',
        },
    ];

    const links = [
        {
            label: translate('About us', 'ui.about'),
            route: getRoute('sim.about'),
        },
        {
            label: translate('Contact us', 'ui.footer'),
            route: `${getRoute('sim.contact-us')}`,
        },
        {
            label: translate('ui.account.terms---conditions'),
            route: getTermsAndConditionsRoute(),
        },
        {
            label: translate('ui.account.faq'),
            route: getRoute('support.faq'),
        },
        {
            label: translate('Responsible gaming', 'ui.common'),
            route: getRoute('responsible-gaming.set-limits'),
        },
    ];

    function prepareImageProps(src: string): Pick<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'srcSet' | 'loading'> {
        const preparedSrc = `${src}?auto=format`;
        return {
            loading: 'lazy',
            src: preparedSrc,
            srcSet: range(1, 3)
                .map((times) => `${preparedSrc}&dpr=${times} ${times}x`)
                .join(','),
        };
    }

    return (
        <Wrapper className="footer">
            {!isPhone && <CoolbetFooterPaymentPartners />}
            <div className="disclaimer">
                <img
                    className="age-disclaimer"
                    {...prepareImageProps(`${IMAGE_BASE_URL}responsible-gaming/18.png`)}
                    alt="18+"
                />
                <p>{translate('ui.footer.club8-disclaimer')}</p>
                <img {...prepareImageProps(`${IMAGE_BASE_URL}common/gli.avif`)} alt="Certified by GLI icon" />
            </div>
            <nav className="links-row socials">
                <a href="https://www.facebook.com/club8casino">
                    <Svg icon="facebook" size={1.375} />
                </a>
                <a href="https://twitter.com/club8casino">
                    <Svg icon="twitter" size={1.375} />
                </a>
            </nav>
            <nav className="links-row partner-links">
                {partnersLinks.map(({ url, fileName, alt }) => (
                    <a href={url} key={url}>
                        <img {...prepareImageProps(`${IMAGE_BASE_URL}common/${fileName}`)} alt={alt} />
                    </a>
                ))}
            </nav>
            <nav className="links-row internal-links">
                {links.map(({ route, label }) => (
                    <UiNavLink key={route} exact to={route} $openNewTab>
                        {label}
                    </UiNavLink>
                ))}
            </nav>
        </Wrapper>
    );
}
