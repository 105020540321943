import React, { ReactNode } from 'react';
import { useGlobalModal } from '../../../../hooks/useGlobalModal';
import { useStore } from '../../../../hooks/useStore';
import { isFeatureAvailable } from '../../../../services/feature';
import { getRoute, useRouter } from '../../../../services/router';
import { translate } from '../../../../services/translate';
import { FEATURE } from '../../../../services/types';
import { isTestUser } from '../../../../services/user';
import { media } from '../../../../stores/media/media';
import UiButton from '../../../ui/button/UiButton';
import PaymentQuickDepositModal from '../../quick-deposit/modal/PaymentQuickDepositModal';

interface Props {
    icon?: ReactNode;
    className?: string;
}

export default function PaymentDepositButton({ icon, className }: Props) {
    const { navigateTo, pathname } = useRouter();
    const { showModal, hideModal } = useGlobalModal();
    const [{ isPhone }] = useStore(media);

    function handleDepositClick() {
        if (isFeatureAvailable(FEATURE.QUICK_DEPOSIT) && isTestUser() && pathname !== getRoute('deposit')) {
            showModal(PaymentQuickDepositModal, {
                params: {
                    onClose: hideModal,
                },
                onClose: hideModal,
                minWidth: 300,
                maxWidth: 500,
                width: 'auto',
            });
        } else {
            navigateTo(getRoute('deposit'));
        }
    }

    return (
        <UiButton
            color="primary"
            size="small"
            selected={false}
            onClick={handleDepositClick}
            icon={icon}
            className={className}
        >
            {!isPhone && translate('Deposit', 'ui.account')}
        </UiButton>
    );
}
