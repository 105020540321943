import React from 'react';
import Wrapper from './styles';
import UiGroup from '../../ui/group/UiGroup';
import { translate } from '../../../services/translate';
import { getRoute, useRouter } from '../../../services/router';
import { openNewTab } from '../../../services/util';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../services/analytics';
import UiButton from '../../ui/button/UiButton';
import Svg from '../../svg/Svg';
import { getLicence } from '../../../services/licence';
import { Licence } from '@staycool/location';
import { environment } from '../../../stores/environment/environment';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

export default function ResponsibleGamingPanel() {
    const { navigateTo } = useRouter();
    const [{ isPhone }] = useStore(media);
    const [{ IMAGES_HOST }] = useStore(environment);
    const licence = getLicence();
    const imagePath = `${IMAGES_HOST}coolbet/common`;

    const isPanelVisible = [Licence.SWEDEN].includes(licence);
    const buttonProperties = {
        regulatorAddictionTestPage: {
            [Licence.SWEDEN]: 'https://www.stodlinjen.se/#!/spelberoende-test-pgsi',
        },
        regulatorPage: {
            [Licence.SWEDEN]: 'https://www.spelpaus.se/',
        },
        regulatorPageIcon: {
            [Licence.SWEDEN]: 'spelpaus',
        },
        internAddictionTestText: {
            [Licence.SWEDEN]: 'Spelgränser',
        },
        regulatorAddictionTestText: {
            [Licence.SWEDEN]: 'Självtest',
        },
        regulatorPageText: {
            [Licence.SWEDEN]: 'Spelpaus',
        },
    };

    function collectTestPageRedirectsData() {
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.RESPONSIBLE_GAMING_TEST_CLICKED);
        openNewTab(buttonProperties.regulatorAddictionTestPage[licence]);
    }

    if (!isPanelVisible) {
        return null;
    }

    return (
        <Wrapper className="responsible-gaming-panel" reversed={!isPhone}>
            <UiGroup className="rg-panel-buttons" layoutGutterInRem={isPhone ? '0.2rem' : ''}>
                <UiButton
                    onClick={() => navigateTo(getRoute('responsible-gaming.set-limits'))}
                    size="small"
                    icon={
                        <img
                            srcSet={`${imagePath}/spelgranser.svg?w=12&dpr=1 1x,
          ${imagePath}/spelgranser.svg?w=12&dpr=2 2x,
          ${imagePath}/spelgranser.svg?w=12&dpr=3 3x,
          ${imagePath}/spelgranser.svg?w=12&dpr=4 4x`}
                            src={`${imagePath}?w=12&auto=compress`}
                            width="13px"
                            alt="spelgranser"
                        />
                    }
                >
                    {buttonProperties.internAddictionTestText[licence]}
                </UiButton>

                <UiButton
                    onClick={() => openNewTab(buttonProperties.regulatorPage[licence])}
                    size="small"
                    icon={
                        <img
                            srcSet={`${imagePath}/${buttonProperties.regulatorPageIcon[licence]}.svg?w=12&dpr=1 1x,
          ${imagePath}/${buttonProperties.regulatorPageIcon[licence]}.svg?w=12&dpr=2 2x,
          ${imagePath}/${buttonProperties.regulatorPageIcon[licence]}.svg?w=12&dpr=3 3x,
          ${imagePath}/${buttonProperties.regulatorPageIcon[licence]}.svg?w=12&dpr=4 4x`}
                            src={`${imagePath}?w=12&auto=compress`}
                            width="13px"
                            alt={buttonProperties.regulatorPageIcon[licence]}
                        />
                    }
                >
                    {buttonProperties.regulatorPageText[licence]}
                </UiButton>

                {buttonProperties.regulatorAddictionTestPage[licence] && (
                    <UiButton
                        onClick={collectTestPageRedirectsData}
                        size="small"
                        icon={
                            <img
                                srcSet={`${imagePath}/sjalvtest.svg?w=12&dpr=1 1x,
          ${imagePath}/sjalvtest.svg?w=12&dpr=2 2x,
          ${imagePath}/sjalvtest.svg?w=12&dpr=3 3x,
          ${imagePath}/sjalvtest.svg?w=12&dpr=4 4x`}
                                src={`${imagePath}?w=12&auto=compress`}
                                width="13px"
                                alt="sjalvtest"
                            />
                        }
                    >
                        {buttonProperties.regulatorAddictionTestText[licence]}
                    </UiButton>
                )}
            </UiGroup>

            <UiGroup className="clocks-group-wrapper">
                {!isPhone && (
                    <UiButton
                        size="small"
                        onClick={() => navigateTo(getRoute('support'))}
                        icon={<Svg icon="support" size={1} />}
                    >
                        {translate('Help', 'ui.common')}
                    </UiButton>
                )}
            </UiGroup>
        </Wrapper>
    );
}
