import styled from 'styled-components';

export default styled.div`
    display: grid;
    font-size: var(--font-12);

    &.crm-clickable-text {
        cursor: pointer;
    }

    & .crm-title {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: var(--spacing-4);

        & .crm-title-text {
            font-weight: var(--font-weight-medium);
        }

        & .crm-title-time {
            color: var(--font-color-secondary);
            font-size: var(--font-10);
            white-space: nowrap;
        }
    }

    & .crm-description-read {
        color: var(--font-color-secondary);
    }

    & .crm-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;
