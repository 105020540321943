import capitalize from 'lodash/capitalize';
import React, { ReactNode } from 'react';
import Wrapper from './styles';

interface Props {
    values: {
        days: string;
        daysLabel: string;
        hours: string;
        hoursLabel: string;
        minutes: string;
        minutesLabel: string;
        seconds: string;
        secondsLabel: string;
    };
    title?: ReactNode;
}

export default function UiCountdownTimer({ values, title }: Props) {
    const { days, daysLabel, hours, hoursLabel, minutes, minutesLabel, seconds, secondsLabel } = values;
    return (
        <Wrapper>
            {title && <h4>{title}</h4>}
            <div className="timer-values">
                {[
                    [days, daysLabel],
                    [hours, hoursLabel],
                    [minutes, minutesLabel],
                    [seconds, secondsLabel],
                ].map(([value, label]) => (
                    <div key={label} className="timer-section">
                        <span>{value.padStart(2, '0')}</span>
                        <span>{capitalize(label)}</span>
                    </div>
                ))}
            </div>
        </Wrapper>
    );
}
