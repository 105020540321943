import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    row?: boolean;
    flexStart?: boolean;
    nowrap?: boolean;
};

export default styled.div<StyledProps>`
    display: ${({ row }) => (row ? 'flex' : 'block')};
    width: 100%;
    ${({ row }) =>
        row &&
        css<{ flexStart?: boolean; nowrap?: boolean }>`
            align-items: ${({ flexStart }) => (flexStart ? 'flex-start' : 'flex-end')};
            @media (max-width: 480px) {
                flex-flow: ${({ nowrap }) => (nowrap ? 'row nowrap' : 'row wrap')};
            }
            > * {
                flex: 1;
                @media ${mediaQuery.isPhone} {
                    flex-basis: 100%;
                }
            }

            > *:not(:first-child) {
                margin-left: 8px;
                @media ${mediaQuery.isPhone} {
                    margin-left: 0;
                }
            }
        `};
`;
