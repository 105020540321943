import { DEFAULT_KYC_SETTINGS } from '../services/kyc/constants';
import { storageGet } from '../services/storage';
import { createStores } from './store/utils';

export const kyc = createStores({
    kycToken: storageGet<string | undefined>('kycToken'),
    kycTokenExpiry: storageGet<string | undefined>('kycTokenExpiry'),
    settings: DEFAULT_KYC_SETTINGS,
    sourceOfWealthForm: undefined as { fieldId: number; label: string }[] | undefined,
});
