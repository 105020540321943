import { fetchPrematchTree } from '../../microservices/sbgate';
import { logger } from '../logger';
import { getFlatCategories } from './sport-category';

export async function getFlatCategoriesFromPrematchTree(language?: string) {
    if (!language) {
        return [];
    }
    try {
        const tree = await fetchPrematchTree(language);
        return getFlatCategories(tree);
    } catch (error) {
        logger.error('SportsSbgateService', 'getFlatCategoriesFromPrematchTree', error);
        return [];
    }
}
