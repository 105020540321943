export const enum ClosureReason {
    HAMPI = 'HAMPI',
    MINCETUR = 'MINCETUR',
    PID_MISSING = 'PID_MISSING',
    SPELPAUS = 'SPELPAUS',
    SUPPORT = 'SUPPORT',
    TIMEOUT = 'TIMEOUT',
    UNDERAGE = 'UNDERAGE',
}

export type Industry = {
    id: number;
    title: string;
};

export type UserCountry = {
    iso2: string;
    title: string;
};

export type LoginHistory = {
    created: string;
    loginTime: string;
    ipAddress: string;
    device: string;
    os: string;
    browser: string;
    cookie: string;
    ipCountry: string;
    socialLoginService: string;
    appId: string;
    deviceId: string;
    loginSessionId: string;
    message: string;
    reason: string;
};
