import React, { useEffect, useState } from 'react';
import ScriptLoader from '../../../../../script-loader/ScriptLoader';

interface Props {
    onError: () => void;
    onScriptsLoaded: () => void;
}

export default function PaymentDepositProviderTruelayerScripts({ onError, onScriptsLoaded }: Props) {
    const SDK_URL = 'https://cdn.jsdelivr.net/npm/truelayer-embedded-payment-page/dist/truelayer-payment.min.js';
    const [isTruelayerScriptLoaded, setIsTruelayerScriptLoaded] = useState(false);

    useEffect(() => {
        if (isTruelayerScriptLoaded) {
            onScriptsLoaded();
        }
    }, [isTruelayerScriptLoaded]);

    return <ScriptLoader url={SDK_URL} onError={onError} onLoad={() => setIsTruelayerScriptLoaded(true)} />;
}
