import styled from 'styled-components';
import { mediaQuery } from '../../styles/utils';

export default styled.div`
    &.casino-shell {
        width: 100%;
        min-width: 0;
        display: flex;
    }

    .casino-shell-lobby-sidebar-container {
        flex-grow: 0;
        flex-shrink: 0;
        transform: translate3d(0, 0, 0);
        padding-right: ${({ theme }) => theme.layout.gutter};
        padding-bottom: ${({ theme }) => theme.layout.gutter};

        .casino-shell-lobby-sidebar {
            width: 280px;
            position: sticky;
            top: ${({ theme }) => theme.layout.gutter};
        }
    }

    .casino-shell-content-container {
        display: flex;
        gap: var(--spacing-8);
        width: 100%;

        .casino-shell-menu {
            display: flex;
        }

        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            display: block;
            padding: 0;
        }
    }

    .casino-shell-content {
        width: calc(100% - 88px);

        position: relative;
        ${({ theme }) => theme.contentBoxStyle};
        padding: var(--spacing-12);

        @media ${mediaQuery.isPhone} {
            padding: var(--spacing-12) var(--spacing-8);
        }

        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            width: 100%;
        }
    }
`;
