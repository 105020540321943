import styled from 'styled-components';

export default styled.div`
    position: relative;

    header {
        position: relative;
        margin-bottom: var(--spacing-16);

        .level {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-shadow: #000d1a 1px 1px 6px;
            font-size: 6rem;
            * {
                color: inherit;
                font-size: inherit;
                line-height: 6rem;
                display: block;
                margin: 0;
                padding: 0;
                font-weight: bolder;
            }
        }

        .level-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-shadow: #000d1a 1px 1px 6px;
        }
    }
`;
