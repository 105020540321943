import { GetLimitConfiguration } from '@staycool/bets-types/limit-configuration';
import { BetSlipMinimalMarket, FoHotEvent, SportSearchMatch } from '@staycool/sbgate-types';
import { LiveTreeCategoryFo } from '@staycool/sbgate-types/dist/categoryTree/types';
import { PayoutOddsFO } from '@staycool/sports-types';
import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import { BonusBetsResponse } from '../services/bonuses/types';
import { getOrResetBetSlipSettings } from '../services/sports/betslip-settings';
import { initialBetSlipPlacingState, initialBetSlipUserState, MENU_TYPE } from '../services/sports/constants';
import {
    BetslipMode,
    BetSlipPlacingState,
    BetSlipReplayState,
    Campaign,
    CategoryMatch,
    LocalSportsUserSettings,
    MarketTypeCategory,
    MultiBetslipState,
    MultiParlayState,
    Odds,
    OddsByOutcomeIdStore,
    ParlayCard,
    ParlayCardMarket,
    ParlayCardWithMarket,
    PreMatchTree,
    PreMatchTreeChildren,
    SportCategoryWithMatches,
    SportEventTypeObject,
} from '../services/sports/types';
import { storageGet } from '../services/storage';
import { ContestEntry, ContestLegMarket, ContestMarketOutcome } from '../types/components/contests/types';
import { createStores } from './store/utils';
import { LocalStorage } from '../services/local-storage/types';
import { initialMediaStore } from './media/media';

export const sports = createStores({
    isHotCardWithOdds: false,
    topMarketTypesByCategory: {} as Record<number, MarketTypeCategory[]>,
    filterCategoryIds: [] as number[],
    matchesSoon: null as unknown as CategoryMatch[] | null,
    marketTypeGroupById: null,
    matchesLiveBet: null as unknown as SportCategoryWithMatches[],
    sportsById: {},
    category: null as unknown as SportCategoryWithMatches[] | null,
    oddsByOutcomeId: {} as OddsByOutcomeIdStore,
    betSlipMarketIdToOutcomeId: getOrResetBetSlipSettings() as { [marketId: number]: number }, // marketId: outcomeId
    betSlipComboCardMarketIdToOutcomeId: getOrResetBetSlipSettings() as { [marketId: number]: number }, // marketId: outcomeId
    betSlipMatchIdToMarketIds: {} as { [matchId: number]: number[] }, // matchId: [marketIds]
    betSlipUserState: initialBetSlipUserState,
    betSlipPlacingState: initialBetSlipPlacingState as BetSlipPlacingState,
    betslipCollection: [] as MultiBetslipState[],
    isByodOrReplay: false,
    betSlipErrorByMarketId: {},
    marketInfoById: {} as Record<number, BetSlipMinimalMarket>,
    prematchTree: {} as PreMatchTree,
    prematchSelectedMenu: storageGet(LocalStorage.PREMATCH_SELECTED_MENU) || MENU_TYPE.ADVANCED,
    prematchSelectedCategory: {} as PreMatchTree,
    prematchBreadcrumbs: [] as PreMatchTree[],
    flatCategories: null as unknown as PreMatchTreeChildren[],
    liveMatchesCount: 0,
    parlayCards: [] as ParlayCard[],
    liveTree: null as unknown as LiveTreeCategoryFo[],
    liveTreeStreams: {},
    liveTreeSelectedCategoryId: null as unknown as number | undefined,
    liveNow: createStores({
        currentStreamMatchId: undefined as number | undefined,
        isShowingMatchesWithStreamsOnly: false,
        filterIds: [] as number[],
        hasStream: false,
    }),
    hotEvents: null as FoHotEvent[] | null,
    inplayMatchData: {},
    campaigns: {} as { selected?: Campaign; list: Campaign[] },
    bonusBets: {
        free_bet: [],
        free_bet_v2: [],
        risk_free_bet: [],
        multiplier: [],
    } as BonusBetsResponse,
    bonusBetsSelection: {} as Record<string, boolean>,
    selectedBonusId: '',
    selectedUserBonusId: '',
    bookmakerMessages: {},
    userFavorites: { categories: [], matches: [] } as { categories: number[]; matches: number[] },
    selectedFavoriteCategory: null as number | null,
    multiViewCategories: [] as PreMatchTreeChildren[],
    isLivebet: false,
    isNavigationHidden: false,
    isStartingSoonPage: null,
    isBoostedOddsPage: null,
    isSportCampaignPage: false,
    cashoutsDoneTicketIds: [] as string[],
    isSportSideMenuOpen: false,
    isMobileCompactView: storageGet<boolean>(LocalStorage.IS_COMPACT_VIEW, initialMediaStore().isPhone),
    currentlyOpenMatchIds: [] as number[],
    keptBetslipSelections: null as null | { [marketId: number]: number },
    totalStake: [],
    isBottomNavigationVisible: true,
    bettingContextByOutcomeId: {} as { [outcomeId: number]: string },
    marketTypeSelectedByCategoryId: {} as { [categoryId: number]: number },
    heartbeat: {} as Record<string, boolean>,
    userSettings: null as LocalSportsUserSettings | null,
    searchMatchSelected: null as SportSearchMatch | null,
    isSidebetsOpen: false as boolean,
    isTeasersAvailable: false as boolean,
    teaserMainLineErrorMarketIds: [] as number[],
    teaserInvalidMainLineErrorMarketIds: [] as number[],
    teaserStatusDisabledMarketIds: [] as number[],
    teaserPayouts: {} as PayoutOddsFO,
    teaserSelectedPoint: undefined as number | undefined,
    parlayCard: createStores({
        parlayCard: {} as ParlayCardWithMarket,
        marketInfo: {} as Record<number, ParlayCardMarket>,
        betSlipMarketIdToOutcomeIds: {} as Record<number, number[]>,
        stake: 0,
        betSlipPlacingState: initialBetSlipPlacingState as BetSlipPlacingState,
        betSlipErrors: [] as any[],
        oddsByOutcomeId: {} as { [outcomeId: number]: Odds },
        manualAcceptanceStake: 0,
        parlayBetslipCollection: [] as MultiParlayState[],
        betslipPosition: 0,
    }),
    comboCard: createStores({
        comboCards: [] as FoComboCardWithOdds[],
        cardsInBetslip: [] as FoComboCardWithOdds[],
        ineligibleCardIds: [] as number[],
        stakeByCardId: {} as Record<number, number>,
        isLoading: false,
        isComboCardBetslipOpen: false,
    }),
    contestSelections: createStores({
        activeEntry: null as null | ContestEntry,
        entries: [] as ContestEntry[],
        selections: {} as Record<number, { market: ContestLegMarket; selectedOutcome: ContestMarketOutcome }[]>,
        initialSelections: {} as Record<number, { market: ContestLegMarket; selectedOutcome: ContestMarketOutcome }[]>,
    }),
    betSlipPosition: 0,
    qrCode: '',
    betslipMode: BetslipMode.Betslip,
    betbuilderBetslipIdByMarketId: {} as Record<number, string>,
    customBetbuilder: createStores({
        comboBetMarketIdByMatchId: {} as Record<string, number>,
        betbuilderMarketOddsByMarketId: {} as Record<string, number>,
        betbuilderBetSlipMarketIdToOutcomeId: {} as Record<number, number>,
        betslipMarketIdsByMatchId: {} as Record<number, string[]>,
        betbuilderRedundantMarketIdsByMatchId: {} as Record<number, number[] | null>,
        betbuilderErrorsByMatchId: {} as Record<number, string[]>,
        betbuilderMarketInfoById: {} as Record<number, BetSlipMinimalMarket>,
    }),
    betSlipReplayState: {} as BetSlipReplayState,
    sportEventTypes: {} as SportEventTypeObject,
    americanSportCategoryIds: undefined as number[] | undefined,
    limits: null as null | GetLimitConfiguration,
    stakePerBetSetting: true,
});
