import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import moment, { Moment } from 'moment';
import { STORAGE_KEY_TOKEN } from './types';

const RENEWAL_DATE_FORMAT = 'YYYYMMDDHHmmssSSSSSS';

let tokenCreationTime;

export function hasValidToken() {
    return Boolean(getToken() && !isTokenExpired());
}

export function getToken() {
    return getStoreValue(stores.token);
}

export function removeToken(removeQuietly = false) {
    if (!removeQuietly) {
        localStorage.removeItem(STORAGE_KEY_TOKEN);
    }
    stores.token.set(undefined);
    stores.isAuthenticated.set(false);
}

export function setToken(token, requestTime?) {
    tokenCreationTime = requestTime;
    localStorage.setItem(STORAGE_KEY_TOKEN, token);
    stores.token.set(token);
}

export function isTokenExpired() {
    const currentTime = moment();
    const token = getToken();
    if (!token) {
        return true;
    }
    const tokenExpirationTime = getTokenExpirationTime(token);
    return currentTime.isAfter(tokenExpirationTime);
}

export function isTokenRenewalRequired() {
    const token = getToken();
    if (!token) {
        return true;
    }
    const currentTime = moment();
    const tokenRenewalTime = getTokenRenewalTime(token);
    return currentTime.isAfter(tokenRenewalTime) && !isTokenExpired();
}

export function getTokenRenewalTime(token) {
    const { renewal_date } = getDecodedToken(token);
    const tokenRenewalTime = moment(renewal_date, RENEWAL_DATE_FORMAT).utc(true);
    return applyTimeSkew(tokenRenewalTime);
}

function applyTimeSkew(timeMoment: Moment) {
    if (!tokenCreationTime) {
        return timeMoment;
    }
    const token = getToken();
    const { iat } = getDecodedToken(token);
    const timeSkew = tokenCreationTime - iat * 1000;
    return timeMoment.add(timeSkew, 'ms');
}

export function getTokenExpirationTime(token: string) {
    const { exp } = getDecodedToken(token);
    return applyTimeSkew(moment(exp * 1000));
}

function getTokenIssuedTime(token: string) {
    const { iat } = getDecodedToken(token);
    return moment(iat * 1000);
}

export function getTokenDurationTime(token: string) {
    const expirationTime = getTokenExpirationTime(token);
    const issuedTime = getTokenIssuedTime(token);
    return expirationTime.diff(issuedTime, 'minutes');
}

export function getDecodedToken(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(decodeURIComponent(escape(window.atob(base64))));
}
