import styled from 'styled-components';
import { hideScrollBar, mediaQuery } from '../../../styles/utils';

export default styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-8);

    @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
        flex-direction: row;
        width: 100%;
        overflow-x: scroll;
        padding-right: 0;
    }

    .menu-navigation-item {
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            background: none;
            text-align: left;
            align-items: center;
            font-size: var(--font-10);
            font-weight: var(--font-weight-bold);

            svg {
                width: 1rem;
                height: 1rem;
            }
        }
    }

    ${hideScrollBar}
`;
