import React from 'react';
import Wrapper from './styles';
import { OnsiteMessage } from '../../../../../microservices/crm/types';
import { translate } from '../../../../../services/translate';
import CrmMessagePreviewPopupButton from '../button/CrmMessagePreviewPopupButton';
import { useStore } from '../../../../../hooks/useStore';
import { environment } from '../../../../../stores/environment/environment';
import CrmMessagePreviewPopupContent from '../content/CrmMessagePreviewPopupContent';

interface Props {
    message: OnsiteMessage;
    onClose?: () => void;
}

export default function CrmMessagePreviewPopupDefault({ message, onClose }: Props) {
    const [{ IMAGE_BASE_URL }] = useStore(environment);
    const { body, footer } = message;

    return (
        <Wrapper>
            <img
                className="coins"
                width="100%"
                src={`${IMAGE_BASE_URL}cms/promotions/Coin Cashing - END Image for figma 1@2x_1726827977948.png`}
                alt="coins"
            />
            <CrmMessagePreviewPopupContent content={{ body, footer }} />
            <CrmMessagePreviewPopupButton title={translate('Close', 'ui.common')} onClick={onClose} />
        </Wrapper>
    );
}
