import React from 'react';
import Wrapper from './styles';
import Svg from '../../svg/Svg';
import { filterStyleProps } from '../../../styles/utils';

interface Props {
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
    size?: number;
}

export default function UiCloseButton(props: Props) {
    return (
        <Wrapper {...filterStyleProps(props)}>
            <Svg icon="crossmark" />
        </Wrapper>
    );
}
