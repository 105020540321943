import styled from 'styled-components';
import SnippetWithCmsBlocks from '../../snippet-with-cms-blocks/SnippetWithCmsBlocks';
import { mediaQuery } from '../../../styles/utils';

export default styled(SnippetWithCmsBlocks)`
    max-width: 1920px;
    margin: 0 auto;
    padding: var(--spacing-16);

    .description-toggler {
        width: 1240px;
        border: var(--surface-level-3-border);
        background: var(--surface-level-1-bg);
        padding: var(--spacing-24);
        border-radius: var(--ui-radius);
        position: relative;
        margin: 0 auto;
        margin-bottom: var(--spacing-32);

        @media ${mediaQuery.isTablet} {
            width: 100%;
            margin: 0;
        }
        @media ${mediaQuery.isPhone} {
            width: 100%;
            margin: var(--spacing-8) 0;
        }
    }

    .description {
        max-height: 500px;
        opacity: 1;
        overflow: hidden;
        transition: max-height 500ms ease-in-out, opacity 500ms ease-in-out;

        @media ${mediaQuery.isPhone} {
            max-height: 1200px;
        }

        h1,
        h2 {
            font-size: var(--font-20);
            color: #f7f7f8;
            line-height: 20px;
            margin: var(--spacing-16) 0;

            @media ${mediaQuery.isPhone} {
                font-size: var(--font-16);
                line-height: 20px;
            }
        }

        p {
            font-size: var(--font-16);
            line-height: 18px;
            color: #cccccc;
            margin: var(--spacing-8) 0;
            padding: 0;
        }
    }

    .hidden {
        max-height: 0;
    }

    .read-more-icon {
        position: absolute;
        padding: 0.75rem 0.9rem;
        bottom: -65px;
        left: 50%;
        transform: translate(-50%, -50%);
        background: var(--surface-level-1-bg);
        border: var(--surface-level-3-border);
        border-radius: 50px;
    }
    .read-more-icon a#read-more {
        display: inline-block;
        width: var(--font-16);
        height: var(--font-16);
        border: solid 2px #cccccc;
        border-width: 3px 3px 0 0;
        transform: rotate(135deg);
        transition: transform 0.5s ease;
        &.active {
            transform: rotate(-45deg) translate(-15%, 15%);
        }
    }
`;
