import styled from 'styled-components';

export default styled.div`
    display: flex;
    align-items: center;
    background: var(--surface-level-1-bg);
    height: 30px;
    border-radius: 15px;
    padding: 0 var(--spacing-8);
    font-size: var(--font-14);

    .add {
        position: relative;
        border-radius: 50%;
        width: 30px;
        right: -9px;
    }
`;
