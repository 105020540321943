import React from 'react';
import { isFeatureAvailable } from '../../services/feature';
import ZendeskButton from '../zendesk/button/ZendeskButton';
import EdgeTierButton from '../edge-tier/button/EdgeTierButton';
import { FEATURE } from '../../services/types';

export default function ContactUsButton() {
    if (isFeatureAvailable(FEATURE.ZE_CHAT_BOT)) {
        return <ZendeskButton />;
    }

    if (isFeatureAvailable(FEATURE.EDGE_TIER)) {
        return <EdgeTierButton />;
    }

    return null;
}
