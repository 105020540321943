import React from 'react';
import Wrapper from './styles';

interface Props {
    header?: string;
    className?: any;
    children: any;
    style?: any;
    id?: string;
}
export default function UiBox({ className, children, header, id, style }: Props) {
    return (
        <Wrapper className={className} style={style} id={id}>
            {header && <div className="card-header">{header}</div>}
            <div className="card-content">{children}</div>
        </Wrapper>
    );
}
