import React from 'react';
import { isFeatureAvailable } from '../../services/feature';
import { FEATURE } from '../../services/types';
import { EdgeTierContextProvider } from '../edge-tier/EdgeTierContext';
import { LivechatZendeskContextProvider } from './zendesk/LivechatZendeskContext';

export function LivechatContext({ children }) {
    if (isFeatureAvailable(FEATURE.EDGE_TIER)) {
        return <EdgeTierContextProvider>{children}</EdgeTierContextProvider>;
    }
    if (isFeatureAvailable(FEATURE.ZE_CHAT_BOT)) {
        return <LivechatZendeskContextProvider>{children}</LivechatZendeskContextProvider>;
    }
    return children;
}
