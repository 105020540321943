import styled from 'styled-components';

export default styled.div<{ background: string }>`
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    text-align: center;
    height: 100dvh;
    width: 100%;
    color: white;
    background-size: cover;
    background-image: url(${({ background }) => background});
    .desktop-blocked {
        padding: 1rem;
    }
`;
