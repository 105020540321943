import remove from 'lodash/remove';
import uniqBy from 'lodash/uniqBy';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import {
    ADJUSTED_TOWIN_CALC,
    AT_LEAST_TWO_SELECTIONS,
    BONUS_BET_CANNOT_COMBINE_RISK_FREE_BET_AND_NORMAL_ERROR,
    BONUS_BET_MIN_ODDS_ERROR,
    BONUS_BET_MIN_STAKE_ERROR,
    CAMPAIGN_BET_TYPE_ERROR,
    CAMPAIGN_MAX_SELECTIONS_ERROR,
    CAMPAIGN_MIN_ODDS_ERROR,
    CAMPAIGN_MIN_ODD_PER_SELECTION_ERROR,
    CAMPAIGN_MIN_SELECTIONS_ERROR,
    CAMPAIGN_NO_MULTIPLE_SINGLES_ERROR,
    CAMPAIGN_WRONG_STAKE_ERROR,
    COMBO_MARKET_ID,
    COMBO_NOT_ALLOWED_ERROR,
    INCORRECT_AMOUNT_OF_SELECTIONS,
    INPLAY_NOT_ALLOWED,
    INSUFFICIENT_FUNDS_ERROR,
    INVALID_MAIN_LINE,
    INVALID_STAKE_ERROR,
    LOCKED_UNTIL_FUNDED,
    MAX_SELECTIONS_COMBO_ERROR,
    MAX_SELECTIONS_SYSTEM_ERROR,
    MAX_SELECTIONS_TEASER_ERROR,
    MIN_SELECTIONS_TEASER_ERROR,
    NOT_MAIN_LINE,
    ODDS_CHANGED_ERROR,
    ODDS_CLOSED_ERRORS_BACKEND,
    SAME_MARKET_IN_BETSLIP_ERROR,
    SAME_MATCH_IN_BETSLIP_ERROR,
    TEASERS_WRONG_MARKET_TYPE,
    TEASER_CATEGORY_COMBINATION_NOT_ALLOWED,
    TICKET_LIMIT_ERROR,
} from './constants';

export function addBetslipError(marketId, error) {
    stores.sports.betSlipErrorByMarketId.set((state) => {
        state[marketId] = (state[marketId] || []).concat(error);
        state[marketId] = uniqBy(state[marketId], (error: any) => error.code || error);
    });
}

const isSameError = (existingError, error) => (existingError.code || existingError) === (error.code || error);

export function removeBetslipError(marketId, error) {
    stores.sports.betSlipErrorByMarketId.set((state) => {
        remove(state[marketId], (existingError) => isSameError(existingError, error));
    });
}

export function getErrorCode(error) {
    return error.code || error;
}

export function removeAllBetslipErrorsOfCode(errorCodesToRemove: (string | number)[]) {
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    Object.keys(betSlipMarketIdToOutcomeId)
        .concat(String(COMBO_MARKET_ID))
        .forEach((marketId) => errorCodesToRemove.forEach((errorCode) => removeBetslipError(marketId, errorCode)));
}

const FRONTEND_ERRORS = [
    ODDS_CLOSED_ERRORS_BACKEND[0],
    ODDS_CHANGED_ERROR,
    SAME_MATCH_IN_BETSLIP_ERROR,
    SAME_MARKET_IN_BETSLIP_ERROR,
    COMBO_NOT_ALLOWED_ERROR,
    MAX_SELECTIONS_COMBO_ERROR,
    MAX_SELECTIONS_SYSTEM_ERROR,
    MAX_SELECTIONS_TEASER_ERROR,
    MIN_SELECTIONS_TEASER_ERROR,
    TEASERS_WRONG_MARKET_TYPE,
    TEASER_CATEGORY_COMBINATION_NOT_ALLOWED,
    NOT_MAIN_LINE,
    INVALID_MAIN_LINE,
    INCORRECT_AMOUNT_OF_SELECTIONS,
    INPLAY_NOT_ALLOWED,
    AT_LEAST_TWO_SELECTIONS,
    ADJUSTED_TOWIN_CALC,
    INVALID_STAKE_ERROR,
    TICKET_LIMIT_ERROR,
    LOCKED_UNTIL_FUNDED,
    INSUFFICIENT_FUNDS_ERROR,
    BONUS_BET_MIN_ODDS_ERROR,
    BONUS_BET_MIN_STAKE_ERROR,
    BONUS_BET_CANNOT_COMBINE_RISK_FREE_BET_AND_NORMAL_ERROR,
    CAMPAIGN_BET_TYPE_ERROR,
    CAMPAIGN_MAX_SELECTIONS_ERROR,
    CAMPAIGN_MIN_ODDS_ERROR,
    CAMPAIGN_MIN_ODD_PER_SELECTION_ERROR,
    CAMPAIGN_MIN_SELECTIONS_ERROR,
    CAMPAIGN_NO_MULTIPLE_SINGLES_ERROR,
    CAMPAIGN_WRONG_STAKE_ERROR,
];

export function removeAllNonFrontendErrors(exclusionList = []) {
    const keepOnly = [...FRONTEND_ERRORS, ...exclusionList];
    stores.sports.betSlipErrorByMarketId.set((errorsByMarketId) => {
        Object.keys(errorsByMarketId).forEach((marketId) => {
            errorsByMarketId[marketId] = (errorsByMarketId[marketId] || []).filter((error) =>
                keepOnly.includes(getErrorCode(error)),
            );
        });
    });
}
