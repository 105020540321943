import { ObjectValues } from '../ts-utils';

export type CmsBannerMessage = {
    displayPage: ObjectValues<typeof BannerMessageDisplayPage>;
    endDate: Date;
    id: number;
    isHidden?: boolean;
    market: string;
    severityLevel: ObjectValues<typeof BannerMessageSeverityLevel>;
    startDate: Date;
    translationKey: string;
};

export const BannerMessageDisplayPage = {
    deposit: 'deposit',
    header: 'header',
    payout: 'payout',
} as const;

export const BannerMessageSeverityLevel = {
    info: 'info',
    failure: 'failure',
    warning: 'warning',
} as const;

export const CmsSettings = {
    REGISTRATION_PAGE_IMAGE: 'registration-page-image',
    POKER_PAGE_BANNER_IMAGE: 'poker-page-banner-image',
    POKER_PAGE_WELCOME_BANNER: 'poker-page-welcome-banner',
    MARKETING_BADGE_IMAGE: 'marketing-badge-image',
    SPONSORSHIP_BANNER: 'sponsorship-banner',
    LICENSE_BANNER: 'license-banner',
} as const;

export interface SponsorshipBanner {
    imageURL: string;
    imageRedirectLink: string;
}

export type TranslationUpdate = Record<string, string>;

export interface LicenseBannerSetting {
    translationKey: string;
    image?: string;
    screens: ObjectValues<typeof FoScreensForBanners>[];
    imageRedirectLink?: string;
}

export const FoScreensForBanners = {
    MAIN_PAGE: 'main_page',
    RESPONSIBLE_GAMING: 'responsible_gaming',
    SELF_EXCLUSION: 'self_exclusion',
    FAQ: 'faq',
    TERMS_AND_CONDITIONS: 'terms_and_conditions',
    PLAYER_AGREEMENT: 'player_agreement',
    CUSTOMER_CARE: 'customer_care',
} as const;

export interface ArticleResponse {
    body: string;
    category: string;
    created: Date;
    id: string | number;
    mobile: boolean;
    published: boolean;
    seo_desc: Record<string, string>;
    seo_img: Record<string, string>;
    seo_title: Record<string, string>;
    site: string;
    slug: Record<string, string>;
    title: string;
    updated: Date;
    updated_by: string | number;
}

export type Avatar = {
    id: string;
    url: string;
    title: string;
};
