import styled from 'styled-components';
import { lighten } from 'polished';
import { mediaQuery } from '../../../styles/utils';

type StyledProps = {
    $hasMascot: boolean;
    $hasFreeSpinBonus: boolean;
    $isPreviewModal: boolean;
};

export default styled.div<StyledProps>`
    .crm-message {
        width: ${({ $hasFreeSpinBonus, $isPreviewModal }) =>
            $hasFreeSpinBonus && !$isPreviewModal ? '700px' : '320px'};
        max-height: calc(100vh - 100px);
        max-width: ${({ $hasFreeSpinBonus, $isPreviewModal }) =>
            $hasFreeSpinBonus && !$isPreviewModal ? '700px' : '320px'};
        border-radius: 10px;
        background: ${({ theme }) => lighten(0.05, theme.box.background)};
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.35), inset 1px 1px 4px -2px rgba(255, 255, 255, 0.22);
        z-index: 1000;
        left: 50%;
        position: fixed;
        bottom: 1rem;
        margin-left: ${({ $hasFreeSpinBonus, $isPreviewModal }) =>
            $hasFreeSpinBonus && !$isPreviewModal ? '0' : '-175px'};
        visibility: hidden;
        opacity: 0;
        transition: all 500ms cubic-bezier(0.22, 0.61, 0.36, 1);
        transform: translate(0, 100%);
        overflow: auto;

        @media ${mediaQuery.isDesktop} {
            bottom: 100px;
        }

        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            bottom: ${({ theme }) => theme.mobileTabs.height};
            margin: 0 0 1rem 0;
            transform: translate(-50%, 100%);
        }

        &.visible {
            visibility: visible;
            opacity: 1;
            transform: ${({ $hasFreeSpinBonus, $isPreviewModal }) =>
                $hasFreeSpinBonus && !$isPreviewModal ? 'translate(-50%, -50%)' : 'translate(0, 0)'};

            @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                margin: 0 0 1rem 0;
                transform: translate(-50%, 0);
            }
        }

        .close-button {
            z-index: 2;
        }
        .crm-message-content {
            color: var(--font-color-primary);
            border-radius: 5px;
            @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                font-size: 1rem;
            }
            .crm-message-title {
                padding: ${({ $hasMascot }) =>
                    $hasMascot ? '1.5rem 0.5rem 0.2rem 0.5rem;' : ' 0 0.5rem 0.2rem 0.5rem'};
                font-weight: normal;
                font-size: 1.2rem;
                color: var(--font-color-primary);
                display: flex;
                align-items: center;
                @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                    font-size: 1.3rem;
                }
            }

            .crm-message-text {
                overflow: auto;
                padding: 1.4rem;
                font-size: 1rem;
                line-height: 1.3rem;
                border-radius: 5px;
                ol,
                ul {
                    padding: 0 0 0 1.6rem;
                }

                @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                    font-size: 1.2rem;
                    line-height: 1.4rem;
                }
                @media ${mediaQuery.isPhone} {
                    max-height: 400px;
                }
                a {
                    border-bottom: 1px dotted;
                }
            }

            .crm-message-image {
                border-radius: 10px;
            }

            .crm-message-cta {
                margin: 0 0.8rem 0.85rem 0.8rem;
                display: flex;
                align-items: center;
                > a {
                    width: 100%;
                }
                button {
                    flex: 1;
                    height: 55px;
                }
            }
        }
    }
`;
