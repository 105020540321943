import { CmsGeneralSetting } from '../services/sports/types';
import { createStores } from './store/utils';
import { UserAnswers } from '../services/quiz/types';
import { CmsBannerMessage } from '../services/cms/types';

export const cms = createStores({
    bannerMessages: [] as CmsBannerMessage[],
    bannerMessageLastRequest: '',
    currentArticleSlugByLanguage: '',
    modals: {},
    dynamicContent: {},
    menuPage: {},
    activeQuizId: 0,
    activeQuestionId: 0,
    activeQuizIndex: 0,
    activeQuestionNumber: 1,
    userAnswers: {} as UserAnswers,
    activeQuizTab: '',
    isQuizChangedFromFooter: false,
    settings: undefined as CmsGeneralSetting[] | undefined | null,
});
