import React from 'react';
import { translate } from '../../services/translate';
import { getGameImageUrl } from '../../services/casino/games';
import { descriptionTranslations } from '../../services/casino/casino';
import Seo from '../seo/Seo';
import { CasinoGame } from '../../services/casino/types';

export default function CasinoSeo(gameInfo: CasinoGame) {
    if (!gameInfo) {
        return <Seo preRenderDoNotIndex />;
    }

    function getDescription() {
        const descriptions =
            gameInfo.gameType === 'live' ? descriptionTranslations.live : descriptionTranslations.common;

        if (!gameInfo?.type) {
            return descriptionTranslations.common.Slots;
        }

        return descriptions[gameInfo.type]?.() || '';
    }

    return (
        <Seo
            title={translate('title', 'casino.meta', {
                name: gameInfo.name,
            })}
            description={getDescription()}
            image={getGameImageUrl(gameInfo.imageName)}
        />
    );
}
