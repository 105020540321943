import React from 'react';
import { useStore } from '../../hooks/useStore';
import { Campaign } from '../../services/bonuses/types';
import { getPromotionsLanguage, usePromotionArticlePath, usePromotionImagePath } from '../../services/promotion';
import { stores } from '../../stores';
import UiCountdownTimer from '../ui/countdown/timer/UiCountdownTimer';
import UiCountdown from '../ui/countdown/UiCountdown';
import UiNavLink from '../ui/nav-link/UiNavLink';
import UiTextTruncate from '../ui/text-truncate/UiTextTruncate';
import PromotionParticipantsBadge from './participants-badge/PromotionParticipantsBadge';
import Wrapper from './styles';

interface Props {
    promotion: Campaign;
    isMobileLandingPage?: boolean;
    showDescription?: boolean;
    blockClickAction?: boolean;
    openInNewWindow?: boolean;
    onLinkClick?: (event: React.SyntheticEvent) => void;
}

export default function Promotion(props: Props) {
    const { isMobileLandingPage, promotion, showDescription, onLinkClick, blockClickAction, openInNewWindow } = props;
    const [language] = useStore(stores.language);
    const translationLanguage = getPromotionsLanguage(language);
    const path = usePromotionArticlePath(promotion.article?.slug);

    return (
        <Wrapper $isMobileLandingPage={isMobileLandingPage}>
            <UiNavLink
                onClick={(event) => {
                    if (blockClickAction) {
                        event.preventDefault();
                    } else if (onLinkClick) {
                        onLinkClick(event);
                    }
                }}
                $openNewTab={openInNewWindow}
                to={path}
            >
                <div className="promo-card">
                    <img
                        src={usePromotionImagePath(promotion.images, translationLanguage)}
                        alt={promotion.name_external}
                    />
                    {promotion.display.show_countdown_timer && promotion.end_time && (
                        <UiCountdown endDate={promotion.end_time}>
                            {(values) => <UiCountdownTimer values={values} />}
                        </UiCountdown>
                    )}
                    {showDescription && (
                        <div className="promo-description">
                            <UiTextTruncate text={promotion.description[translationLanguage]} />
                            <PromotionParticipantsBadge participants={promotion.participants} />
                        </div>
                    )}
                </div>
                {promotion.terms_and_conditions && promotion.terms_and_conditions[translationLanguage] && (
                    <div
                        className="promo-terms"
                        dangerouslySetInnerHTML={{
                            __html: promotion.terms_and_conditions[translationLanguage],
                        }}
                    />
                )}
            </UiNavLink>
        </Wrapper>
    );
}
