import styled from 'styled-components';

export default styled.div`
    position: relative;

    & .crm-inbox {
        padding: 0;
        background-color: var(--color-body-bg);
        border-radius: var(--spacing-8);
        box-shadow: rgba(0, 0, 0, 0.48) 0px 3px 6px -4px, rgba(0, 0, 0, 0.32) 0px 6px 16px 0px,
            rgba(0, 0, 0, 0.2) 0px 9px 28px 8px;
        z-index: 110;
    }
`;
