import { useEffect } from 'react';
import { useStore } from '../../../hooks/useStore';
import { linkInvitation } from '../../../microservices/refer-a-friend';
import { loadBonusOffers } from '../../../services/bonuses/bonus';
import { getDeviceType } from '../../../services/device';
import { isFeatureAvailable } from '../../../services/feature';
import { ProofOfIdStatus } from '../../../services/kyc/types';
import { storageGet, storageRemove } from '../../../services/storage';
import { FEATURE } from '../../../services/types';
import { getUserFavorites } from '../../../services/user-favorites';
import { stores } from '../../../stores';
import { getStoreValue } from '../../../stores/store/utils';

export default function SystemUser() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [user] = useStore(stores.user);
    const [kycSettings] = useStore(stores.kyc.settings);
    const rafCode = storageGet('raf_code');

    useEffect(() => {
        stores.deviceType.set(getDeviceType());
    }, []);

    useEffect(() => {
        const user = getStoreValue(stores.user);

        if (isAuthenticated && user) {
            if (rafCode) {
                linkInvitation(user.id, rafCode).then(() => storageRemove('raf_code'));
            }
        }

        isFeatureAvailable(FEATURE.USER_FAVORITES) && getUserFavorites();
        isFeatureAvailable(FEATURE.CAMPAIGNS) && loadBonusOffers();
    }, [isAuthenticated]);

    useEffect(() => {
        if (!isAuthenticated || !user || storageGet('isLogoutInProgress')) {
            return;
        }

        if (!user.email || !user.alias || !user.phoneNumber) {
            stores.modals.isEssentialUserInformationInquiryModalOpen.set(true);
        } else if (!user.acceptTermsAndConditions) {
            stores.modals.isTermsAndConditionsAcceptanceModalOpen.set(true);
        } else if (Object.keys(user.consents).length) {
            stores.modals.isPeruTermsAndConditionsAcceptanceModalOpen.set(true);
        } else if (
            (isFeatureAvailable(FEATURE.ID_DOCUMENT_UPLOAD_VERIFICATION) &&
                kycSettings.proofOfId &&
                kycSettings.proofOfId !== ProofOfIdStatus.APPROVED) ||
            !!kycSettings.shouldAskProofOfIdReason
        ) {
            stores.modals.isProofOfIdModalOpen.set(true);
        }
    }, [
        isAuthenticated,
        user?.email,
        user?.alias,
        user?.phoneNumber,
        user?.acceptTermsAndConditions,
        Object.keys(user?.consents || {}).length,
        kycSettings.proofOfId,
        kycSettings.shouldAskProofOfIdReason,
    ]);

    return null;
}
