import React, { createContext, useContext, useEffect, useState } from 'react';
import { useStore } from '../../../hooks/useStore';
import { environment } from '../../../stores/environment/environment';
import { stores } from '../../../stores';
import isEmpty from 'lodash/isEmpty';

const ZendeskContext = createContext<{
    open: () => void;
    isLoaded: boolean;
}>({
    open: () => {},
    isLoaded: false,
});
export function LivechatZendeskContextProvider({ children }: React.PropsWithChildren) {
    const [{ ZENDESK }] = useStore(environment);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [user] = useStore(stores.user);
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [{ receiptById }] = useStore(stores.sports.betSlipPlacingState);

    useEffect(() => {
        if (!window.zE) {
            const script = document.createElement('script');
            script.id = 'ze-snippet';
            script.src = `${ZENDESK?.URL}?key=${ZENDESK?.KEY}`;
            script.onload = function () {
                window.zE(function () {
                    window.zE.hide();
                    window.$zopim?.livechat.window.hide();
                });
                setIsLoaded(true);
            };

            document.body.appendChild(script);
            window.zESettings = {
                webWidget: {
                    offset: {
                        mobile: {
                            horizontal: '15px',
                            vertical: '55px',
                        },
                    },
                    chat: {
                        departments: {
                            enabled: [],
                        },
                    },
                },
            };
        }
    }, []);

    useEffect(() => {
        if (window.zE) {
            window.zE('webWidget:on', 'close', () => {
                window.$zopim?.livechat.window.hide();
            });
        }
    }, [window.zE]);

    useEffect(() => {
        if (window.zE) {
            if (!isAuthenticated) {
                window.zE('webWidget', 'logout');
            }
            window.zE('webWidget', 'identify', {
                name: user?.firstName,
                email: user?.email,
            });
        }
    }, [isAuthenticated, window.zE]);

    useEffect(() => {
        if (!isEmpty(betSlipMarketIdToOutcomeId) || !isEmpty(receiptById)) {
            window.$zopim?.livechat.window.hide();
        }
    }, [betSlipMarketIdToOutcomeId, receiptById]);

    function open() {
        window.zE('webWidget:on', 'open', window.$zopim?.livechat.window.show());
    }

    return <ZendeskContext.Provider value={{ open, isLoaded }}>{children}</ZendeskContext.Provider>;
}

export function useLivechatZendeskContext() {
    return useContext(ZendeskContext);
}
