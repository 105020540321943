import {
    geocomplyClientInitialStatus,
    geocomplyInitiaLastReason,
    geocomplyInitialLicense,
    geocomplyInitialServiceError,
    geocomplyInitialVerification,
} from '../services/geocomply/constants';
import { createStores } from './store/utils';

export const geocomply = createStores({
    client: geocomplyClientInitialStatus,
    isDevModeVisibility: false,
    isLoadingLicense: false,
    isLoadingVerification: false,
    isSilentMode: false,
    lastReason: geocomplyInitiaLastReason,
    license: geocomplyInitialLicense,
    persistLogs: false,
    serviceError: geocomplyInitialServiceError,
    unexpectedError: null,
    verification: geocomplyInitialVerification,
});
