import React from 'react';
import Wrapper from './styles';
import Svg from '../../svg/Svg';
import { stores } from '../../../stores';
import UiButton from '../../ui/button/UiButton';
import { useStore } from '../../../hooks/useStore';
import CrmDropdown from '../dropdown/CrmDropdown';
import { ONSITE_MESSAGE_TAB } from '../../../microservices/crm/types';
import { getRoute, isActiveRoute } from '../../../services/router';
import { Tooltip } from 'react-tooltip';

export default function CrmInboxIcon() {
    const TOOLTIP_NAME = 'crm-inbox';

    const [messagesByType] = useStore(stores.crm.messagesByType);
    const [isInboxOpen, setIsInboxOpen] = useStore(stores.crm.isInboxOpen);

    const allMessages = messagesByType[ONSITE_MESSAGE_TAB.ALL];
    const url = getRoute('inbox');
    const inboxConfig = allMessages.messages.length ? { onClick: () => setIsInboxOpen(!isInboxOpen) } : { url };

    return (
        <Wrapper>
            <div data-tooltip-id={TOOLTIP_NAME} data-tooltip-event="click">
                <UiButton
                    size="small"
                    className="header-button"
                    badge={allMessages.unreadMessagesTotal}
                    icon={<Svg icon="envelope" className="icon" size={1.125} />}
                    selected={isActiveRoute(url, false)}
                    {...inboxConfig}
                />
            </div>
            <Tooltip
                id={TOOLTIP_NAME}
                place="bottom"
                isOpen={isInboxOpen}
                setIsOpen={setIsInboxOpen}
                openOnClick
                clickable
                className="crm-inbox"
                opacity={1}
            >
                <CrmDropdown />
            </Tooltip>
        </Wrapper>
    );
}
