import { Country } from '@staycool/location';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { isB2B } from '../environment';

export function getUserCountry() {
    if (isB2B()) {
        return Country.USA;
    }

    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const user = getStoreValue(stores.user);
    if (isAuthenticated && user) {
        return user.country;
    }
    return getStoreValue(stores.ipCountry);
}

export function isUserFromCountry(country: Country) {
    return getUserCountry() === country;
}

export function isBlurredBlockedProduct() {
    return isUserFromCountry(Country.USA);
}
