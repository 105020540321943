import React from 'react';
import DangerousHtml from '../../../../dangerous-html/DangerousHtml';

interface Props {
    content: {
        header?: string;
        body?: string;
        footer?: string;
    };
}

export default function CrmMessagePreviewPopupContent({ content }: Props) {
    return (
        <>
            {content.header && <DangerousHtml content={content.header} elementType="div" />}
            {content.body && <DangerousHtml content={content.body} elementType="div" />}
            {content.footer && <DangerousHtml content={content.footer} elementType="div" />}
        </>
    );
}
