import { BET_TYPE, BetSlipUserState } from './types';
import { ClientName } from '../utils/types';
import { Currency } from '../wallet/types';

export const ODDS_STATUS = {
    OPEN: 'OPEN',
    SUSPENDED: 'SUSPENDED',
};

export const ODDS_CLOSED_ERROR = 'ODDS_CLOSED';
export const ODDS_CLOSED_ERRORS_BACKEND = [1201, 1202, 1203, 1204, 1205, 1206, 1208, 1210, 1212, 1213, 1105];
export const ODDS_HEARTBEAT_DOWN = 1214;
export const ODDS_CHANGED_ERROR = 1207;
export const ODDS_CHANGED_ERROR_SB_ODDS = 1107;
export const MA_ENABLED_ERROR = 1121;
export const MA_DISABLED_ERROR = 1129;
export const MARKET_SPECIFIC_ERRORS = 1200;
export const SAME_MATCH_IN_BETSLIP_ERROR = 1009;
export const SAME_MARKET_IN_BETSLIP_ERROR = 1018;
export const COMBO_NOT_ALLOWED_ERROR = 1209;
export const INVALID_STAKE_ERROR = 1003;
export const TICKET_LIMIT_ERROR = 1118;
export const LOCKED_UNTIL_FUNDED = 1104;
export const INSUFFICIENT_FUNDS_ERROR = 1113;
export const NOT_ENOUGH_MONEY_LOCKED_BY_BONUS_ERROR = 1114;
export const BET_BUILDER_NOT_ALLOWED_ERROR = 'BET_BUILDER_NOT_ALLOWED_ERROR';
export const LIMIT_EXCEEDED_ERROR = 4001;
export const ACCOUNT_CLOSED_ERROR = 4005;
export const MATCH_EXPECTED_RESULT_TIME_AFTER_DEADLINE_ERROR = 1173;
export const ACCOUNT_CLOSED_ERROR_MESSAGE = 'ACCOUNT CLOSED : Please contact support';
export const TEASER_CATEGORY_COMBINATION_NOT_ALLOWED = 'TEASER_CATEGORY_COMBINATION_NOT_ALLOWED';
export const TEASERS_WRONG_MARKET_TYPE = 1016;
export const NOT_MAIN_LINE = 'NOT_MAIN_LINE';
export const NOT_MAIN_LINE_BACKEND = 1215;
export const INVALID_MAIN_LINE = 1216;
export const PARLAY_CARD_SELECTION_AMOUNT_ERROR = 1020;
export const PARLAY_CARD_BET_TIME_ERROR = 1021;
export const PARLAY_CARD_STATUS_ERROR = 1022;
export const PARLAY_CARD_OUTCOME_STATUS = 1023;
export const PARLAY_CARD_MARKET_STATUS = 1024;
export const PARLAY_CARD_MATCH_STATUS = 1025;
export const PARLAY_CARD_MAX_WIN = 1026;
export const INPLAY_NOT_ALLOWED = 1027;
export const GEOCOMPLY_FAILED_ERROR = 1172;
export const GEOCOMPLY_INVALID_ID_ERROR = 1174;
export const GEOCOMPLY_EXPIRED_ERROR = 1175;
export const GEOCOMPLY_INVALID_ID_ERROR_MESSAGE = 'GEOCOMPLY_INVALID_ID_ERROR';
export const GEOCOMPLY_EXPIRED_ERROR_MESSAGE = 'GEOCOMPLY_EXPIRED_ERROR';
export const GEOCOMPLY_FAILED_ERROR_MESSAGE = 'PLAYER_GEOCOMPLY_FAILURE';
export const LOGGED_IN_TIME_DURATION_EXCEEDED_ERROR = 3007;
export const LOGGED_IN_TIME_DURATION_EXCEEDED_MESSAGE = 'LOGGED_IN_TIME_DURATION_EXCEEDED';
export const PARLAY_CARD_NOT_AVAILABLE = 'PARLAY_CARD_NOT_AVAILABLE';
export const PARLAY_CARD_SELECTION_NOT_AVAILABLE = 'PARLAY_CARD_SELECTION_NOT_AVAILABLE';
export const PARLAY_CARD_SELECTION_LINE_CHANGED = 'PARLAY_CARD_SELECTION_LINE_CHANGED';
export const INCORRECT_AMOUNT_OF_SELECTIONS = 'INCORRECT_AMOUNT_OF_SELECTIONS';
export const MAX_SELECTIONS_COMBO_ERROR = 'MAX_SELECTIONS_COMBO';
export const MAX_SELECTIONS_SYSTEM_ERROR = 'MAX_SELECTIONS_SYSTEM';
export const MAX_SELECTIONS_COMBO_CARD_ERROR = 'MAX_SELECTIONS_COMBO_CARD_ERROR';
export const MAX_SELECTIONS_TEASER_ERROR = 'MAX_SELECTIONS_TEASER';
export const MIN_SELECTIONS_TEASER_ERROR = 'MIN_SELECTIONS_TEASER';
export const AT_LEAST_TWO_SELECTIONS = 'AT_LEAST_TWO_SELECTIONS';
export const ADJUSTED_TOWIN_CALC = 'ADJUSTED_TOWIN_CALC';
export const CASHOUT_NOT_ELIGIBLE_ERROR = 1151;
export const DUPLICATE_TICKET_ERROR = 1109;
export const WRONG_PRODUCT_ERROR = 'WRONG_PRODUCT_ERROR';
export const WRONG_MARKET_TYPE_GROUP = 'WRONG_MARKET_TYPE_GROUP';
export const WRONG_MATCH_RESULT_TIME = 'WRONG_MATCH_RESULT_TIME';
export const WRONG_BET_TYPE = 'WRONG_BET_TYPE';
export const NOT_ENOUGH_SELECTIONS = 'NOT_ENOUGH_SELECTIONS';
export const TOO_MANY_SELECTIONS = 'TOO_MANY_SELECTIONS';
export const WRONG_MATCH = 'WRONG_MATCH';
export const BETSLIP_MAX_LENGTH = 10;
export const COMBO_CARD_BETSLIP_MIN_MAX_STAKE = 'COMBO_CARD_BETSLIP_MIN_MAX_STAKE';
export const COMBO_CARD_NOT_AVAILABLE = 'COMBO_CARD_NOT_AVAILABLE';
export const COMBO_CARD_NOT_CASHOUT_ELIGIBLE = 'this-betslip-is-not-cashout-eligible';
export const SELECTION_NOT_BETBUILDER_ELIGIBLE = 'SELECTION_NOT_BETBUILDER_ELIGIBLE';
export const BETBUILDER_SELECTION_IS_REDUNDANT = 'BETBUILDER_SELECTION_IS_REDUNDANT';
export const BETBUILDER_SELECTIONS_SET_IS_NOT_COMBINABLE = 'BETBUILDER_SELECTIONS_SET_IS_NOT_COMBINABLE';

// eslint-disable-next-line import/no-unused-modules
export const CAMPAIGN_PRODUCT = {
    BOTH: 'BOTH',
    PREMATCH: 'PREMATCH',
    LIVEBET: 'LIVEBET',
};
export const CAMPAIGN_SPORT_REQUIREMENTS_BET_TYPE = {
    SINGLE: 'SINGLE',
    COMBO: 'COMBO',
    BETBUILDER: 'BETBUILDER',
    // TODO: When 'BOTH' is deprecated as a bet type in a campaign's sport requirements, remove this
    BOTH: 'BOTH',
};
export const CAMPAIGN_MIN_ODDS_ERROR = 'CAMPAIGN_MIN_ODDS';
export const CAMPAIGN_MIN_ODD_PER_SELECTION_ERROR = 'CAMPAIGN_MIN_ODD_PER_SELECTION';
export const CAMPAIGN_MAX_BET_PER_PERIOD_ERROR = 'CAMPAIGN_MAX_BET_PER_PERIOD';
export const CAMPAIGN_WRONG_STAKE_ERROR = 'CAMPAIGN_WRONG_STAKE';
export const CAMPAIGN_BET_TYPE_ERROR = 'CAMPAIGN_BET_TYPE_ERROR';
export const CAMPAIGN_MIN_SELECTIONS_ERROR = 'CAMPAIGN_MIN_SELECTIONS';
export const CAMPAIGN_MAX_SELECTIONS_ERROR = 'CAMPAIGN_MAX_SELECTIONS';
export const CAMPAIGN_NO_MULTIPLE_SINGLES_ERROR = 'CAMPAIGN_NO_MULTIPLE_SINGLES';
export const FIRST_DAY_BETSTAKE_AMOUNT = 'FIRST_DAY_BETSTAKE_AMOUNT';
export const BETSTAKE_NOT_EQUAL_PREV_DAY_MAXWIN = 'BETSTAKE_NOT_EQUAL_PREV_DAY_MAXWIN';
export const BETSTAKE_NOT_EQUAL_PREV_DAY_SATE = 'BETSTAKE_NOT_EQUAL_PREV_DAY_SATE';
export const BET_MIN_ODDS = 'BET_MIN_ODDS';
export const MORE_THAN_ONE_BET = 'MORE_THAN_ONE_BET';
export const MULTIPLE_BET_IN_THE_PERIOD = 'MULTIPLE_BET_IN_THE_PERIOD';
export const MIN_STAKE_REQUIREMENT = 'MIN_STAKE_REQUIREMENT';
export const WRONG_SEGMENT = 'WRONG_SEGMENT';
export const NO_CAMPAIGN = 'NO_CAMPAIGN';
export const BET_PERIOD_SHOULD_BE_DAY = 'BET_PERIOD_SHOULD_BE_DAY';
export const INVALID_TIME_FORMAT = 'INVALID_TIME_FORMAT';
export const DUPLICATE_REQUEST = 'DUPLICATE_REQUEST';
export const USER_CURRENCY_NOT_AVAILABLE_IN_CAMPAIGN = 'USER_CURRENCY_NOT_AVAILABLE_IN_CAMPAIGN';

const TEASERS_SELECTION_NOT_AVAILABLE = 'TEASERS_SELECTION_NOT_AVAILABLE';
const TEASERS_NOT_MAIN_LINE = 'TEASERS_NOT_MAIN_LINE';

export const ON_DEMAND_BET_ERROR_MESSAGE = {
    [NOT_MAIN_LINE]: {
        [BET_TYPE.TEASER]: TEASERS_NOT_MAIN_LINE,
        [BET_TYPE.PARLAY_CARD]: PARLAY_CARD_SELECTION_NOT_AVAILABLE,
    },
    [INVALID_MAIN_LINE]: {
        [BET_TYPE.TEASER]: TEASERS_SELECTION_NOT_AVAILABLE,
        [BET_TYPE.PARLAY_CARD]: PARLAY_CARD_SELECTION_NOT_AVAILABLE,
    },
};

export const maxComboSelectionsByClient = {
    [ClientName.STATION]: 12,
    default: 15,
};

export const initialBetSlipUserState = Object.freeze({
    betType: BET_TYPE.SINGLE,
    stakeByMarketId: Object.freeze({}),
    MAStakeByMarketId: Object.freeze({}),
    systemStakes: Object.freeze({}),
    parentSystemStakes: Object.freeze({ withSingles: '', noSingles: '' }),
    MASystemStakes: Object.freeze({}),
    copiedFrom: null,
    userBetTypeSelection: undefined,
    betslipCode: undefined,
}) as BetSlipUserState;

export const systemBetTypeBySystemKey = {
    1: 'SINGLE',
    2: 'DOUBLE',
    3: 'TREBLE',
    4: 'FOURFOLD',
    5: 'FIVEFOLD',
    6: 'SIXFOLD',
    7: 'SEVENFOLD',
    8: 'EIGHTFOLD',
    9: 'NINEFOLD',
    10: 'TENFOLD',
};
export const MATCH_STATUS = {
    OPEN: 'OPEN',
    LIVE: 'LIVE',
    OFFTHEBOARD: 'OFFTHEBOARD',
};
export const MATCH_TYPE = {
    BETBUILDER: 'BETBUILDER',
    LIVEBET: 'LIVEBET',
    OUTRIGHT: 'OUTRIGHT',
    NORMAL: null,
} as const;
export const initialBetSlipPlacingState = {
    receiptById: {},
    isLoading: false,
    needsConfirm: false,
    isConfirmed: false,
    needsConfirmDuplicate: false,
};
export const COMBO_MARKET_ID = null;
export const MULTI_SINGLE_MARKET_ID = 'multi_single';
export const SPORT_ID = {
    FOOTBALL: 1,
    ICEHOCKEY: 2,
    BASKETBALL: 3,
    TENNIS: 5,
    VOLLEYBALL: 6,
    AMERICAN_FOOTBALL: 9,
    BASEBALL: 11,
    DARTS: 15,
    SNOOKER: 23,
    BADMINTON: 24,
    BEACH_VOLLEYBALL: 25,
    TABLE_TENNIS: 32,
    ESPORTS: 27,
    CRICKET: 100,
};

export const BG_SETS_SPORTS = [SPORT_ID.VOLLEYBALL, SPORT_ID.BEACH_VOLLEYBALL];

export const BG_GAME_SPORTS = [SPORT_ID.BADMINTON, SPORT_ID.TABLE_TENNIS];

export const QUICK_STAKES = {
    [Currency.EUR]: [1, 5, 10, 50, 100],
    [Currency.CLP]: [1000, 5000, 10000],
    [Currency.MXN]: [20, 100, 200, 500],
    [Currency.NOK]: [10, 50, 100, 500],
    [Currency.SEK]: [10, 50, 100, 500, 1000],
    [Currency.USD]: [1, 5, 10, 50, 100],
    [Currency.CAD]: [1, 5, 10, 50, 100],
    [Currency.DKK]: [1, 5, 10, 50, 100],
    [Currency.PEN]: [10, 20, 100],
    [Currency.PYG]: [8000, 40000, 80000, 400000, 800000],
    [Currency.USDT]: [1, 5, 10, 50, 100],
};

export const QUICK_STATION_STAKES = { [Currency.USD]: [5, 20, 50, 100] };
export const QUICK_WYNNBET_STAKES = { [Currency.USD]: [50, 100, 250, 500] };

export const MENU_TYPE = {
    ADVANCED: 'advanced',
    SIMPLE: 'simple',
};

export const TIME_FILTER_LIVE = 'Live';
export const STREAM_FILTER_LIVE = 'Streaming';
export const TIME_FILTER_FALLBACK = '24';

export const BETSLIP_SETTINGS_TTL = 30 * 60 * 1000;

export const BONUS_BET_MIN_ODDS_ERROR = 'BONUS_BET_MIN_ODDS_ERROR';
export const BONUS_BET_MIN_STAKE_ERROR = 'BONUS_BET_MIN_STAKE_ERROR';
export const BONUS_BET_CANNOT_COMBINE_RISK_FREE_BET_AND_NORMAL_ERROR = 'CANNOT_COMBINE_RISK_FREE_BET_AND_NORMAL';
export const RISK_FREE_BET_STAKE_LIMIT_EXCEEDED_ERROR = 'RISK_FREE_BET_STAKE_LIMIT_EXCEEDED_ERROR';

export const PAGE_SIZE = 6;

export const betbuilderCultureByLanguage = {
    en: 'en-GB',
    us: 'en-GB', // TODO: change to en-US when sportcast has applied US translations for us
    ca: 'en-GB',
    on: 'en-GB',
    eu: 'en-GB',
    sv: 'en-GB',
    fi: 'fi-FI',
    et: 'et-EE',
    ru: 'ru-RU',
    is: 'is-IS',
    cl: 'es-ES',
    pe: 'es-ES',
    es: 'es-ES',
    py: 'es-ES',
    ec: 'es-ES',
    mx: 'es-ES',
};

export const sportPrefix = 'ui.sportsbook';
