import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    display: flex;
    gap: var(--spacing-32);
    align-items: center;

    .header-button {
        border-radius: 50%;
        width: 38px;
        height: 38px;
    }

    @media ${mediaQuery.isSmallerThanLaptop} {
        gap: var(--spacing-8);
    }
`;
