import React, { ReactNode } from 'react';
import Wrapper from './styles';

interface Props {
    className?: string;
    children: ReactNode;
}

export default function UiSubHeading({ className, children }: Props) {
    return <Wrapper className={className}>{children}</Wrapper>;
}
