import React from 'react';
import Wrapper from './styles';
import UiFormLabel from '../../../ui/form/label/UiFormLabel';
import UiAlert from '../../../ui/alert/UiAlert';
import { FieldError, RegisterOptions, useFormContext } from 'react-hook-form';
import { translate } from '../../../../services/translate';
import { filterStyleProps } from '../../../../styles/utils';
import classNames from 'classnames';

interface Props {
    error?: FieldError | undefined;
    name: string;
    options: { label: string; value: any }[];
    bottomSpacing?: string;
    isDisabled?: boolean;
    label?: string;
    placeholder?: string;
    required?: boolean;
    className?: string;
}
export default function Ui2FormSelect({
    error,
    isDisabled,
    name,
    label,
    options,
    placeholder,
    required = false,
    className,
    ...rest
}: Props) {
    const { register, watch } = useFormContext();
    const value = watch(name);

    const registerOptions: RegisterOptions = {
        required: { value: required, message: translate('Oops! You forgot this field', 'ui.registration') },
    };

    return (
        <Wrapper {...filterStyleProps(rest)} className={classNames(className, { error })}>
            {label && <UiFormLabel>{label}</UiFormLabel>}
            <div className="select-container">
                <select
                    {...register(name, registerOptions)}
                    disabled={isDisabled}
                    className={classNames({ ['select-placeholder']: !value })}
                >
                    <option hidden disabled selected value="">
                        {placeholder}
                    </option>
                    {options.map((option, index) => (
                        <option key={`${option.label}-${index}`} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            {error && (
                <UiAlert className="input-error" failure>
                    {error.message}
                </UiAlert>
            )}
        </Wrapper>
    );
}
