import { Country } from '@staycool/location';
import { Language } from '../services/language/types';
import { Currency } from '../services/wallet/types';
import { createStores } from './store/utils';

export const registration = createStores({
    errors: undefined as Record<string, { error: string }> | undefined,
    form: {
        acceptTermsAndConditions: false,
        address: '',
        address2: '',
        alias: '',
        birthDate: undefined as { day: number; month: number; year: number } | undefined,
        city: '',
        country: Country.ESTONIA,
        currency: Currency.EUR,
        email: '',
        eVerificationMethod: undefined as string | undefined,
        fathersName: '',
        firstName: '',
        gender: '',
        hasAcceptedAgeAndPlayingOwnBehalf: false,
        language: undefined as unknown as Language,
        lastName: '',
        loqateLookupAddressId: undefined,
        mothersName: '',
        nationality: '',
        password: '',
        personalId: '',
        personalIdType: 'dni',
        phoneNumber: '',
        phonePrefix: '+372',
        province: '',
        singleFamilyName: '',
        sessionId: undefined as string | undefined,
        socialMediaRegistrationToken: undefined as string | undefined,
        subscribeDirectMail: undefined,
        subscribeMailList: undefined,
        subscribePhoneCalls: undefined,
        subscribeSms: undefined,
        temporaryToken: undefined,
        zip: '',
        registrationPromoCode: undefined,
    },
    allowedCurrenciesByCountryCode: {} as Record<Country, Currency[]>,
    countries: [] as { code: Country; currency: Currency; name: string; phonePrefix: string }[],
    phoneCodeByCountryCode: {} as Record<Country, string>,
});
