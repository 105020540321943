import { getServiceUrl, httpGet, httpPost, httpPut } from '../services/api';
import { logger } from '../services/logger';
import { OnsiteMessageStatus, OnsiteMessageWithCampaign, UserCrmPreferences } from './crm/types';

const getUrl = (url) => getServiceUrl('crm', url);

export async function loadCrmMessages() {
    const url = getUrl('v2/onsite-message/list');
    return httpGet<OnsiteMessageWithCampaign[]>(url);
}
export async function loadCrmMessageById(messageId) {
    try {
        const url = getUrl(`onsite-message/${messageId}`);
        return httpGet<OnsiteMessageWithCampaign>(url);
    } catch (error) {
        logger.error('CrmMicroservice', 'loadCrmMessageById', error);
    }
}

export async function confirmMessageDelivery(messageId) {
    try {
        const url = getUrl(`onsite-message/${messageId}/confirm`);
        await httpGet<void>(url);
    } catch (error) {
        logger.error('CrmMicroservice', 'confirmMessageDelivery', error);
    }
}

export async function getUserSubscriptionPreferences(): Promise<UserCrmPreferences | undefined> {
    const url = getUrl(`user-preferences/my`);
    return httpGet<UserCrmPreferences | undefined>(url);
}

export async function insertUserSubscriptionPreferences(
    body: Omit<UserCrmPreferences, 'user_id'>,
): Promise<UserCrmPreferences | undefined> {
    const payload = {
        subscribe_direct_mail: body.subscribeDirectMail,
        subscribe_phone_calls: body.subscribePhoneCalls,
        subscribe_sms: body.subscribeSms,
        subscribe_mail_list: body.subscribeMailList,
    };
    const url = getUrl(`user-preferences`);
    return httpPost<UserCrmPreferences | undefined>(url, payload);
}

export async function updateUserSubscriptionPreferences(
    body: Omit<UserCrmPreferences, 'user_id'>,
): Promise<UserCrmPreferences | undefined> {
    const payload = {
        subscribe_direct_mail: body.subscribeDirectMail,
        subscribe_phone_calls: body.subscribePhoneCalls,
        subscribe_sms: body.subscribeSms,
        subscribe_mail_list: body.subscribeMailList,
    };
    const url = getUrl(`user-preferences`);
    return httpPut<UserCrmPreferences | undefined>(url, payload);
}

export async function sendOnSiteMessageStatus(body: { userMessageId: number; status: OnsiteMessageStatus }) {
    const url = getUrl(`v2/onsite-message/status`);
    await httpPost<void>(url, body);
}
