import React, { useEffect } from 'react';
import { translate } from '../../../services/translate';
import { stores } from '../../../stores';
import { logout } from '../../../services/auth';
import AuthLoginButton from '../login/button/AuthLoginButton';
import UiGroup from '../../ui/group/UiGroup';
import { getRoute, isActiveRoute } from '../../../services/router';
import { captureUserActivity } from '../../../services/user';
import UiButton from '../../ui/button/UiButton';
import Svg from '../../svg/Svg';
import UiModal from '../../ui/modal/UiModal';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';
import { renewToken } from '../../../services/api';

export default function AuthInactivityModal() {
    const [isTwoMinuteWarningModalVisible, setIsTwoMinuteWarningModalVisible] = useStore(
        stores.modals.isTwoMinuteWarningModalVisible,
    );
    const [isInactivityModalVisible, setIsInactivityModalVisible] = useStore(stores.modals.isInactivityModalVisible);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const isInactivityModalDisabled = isActiveRoute(getRoute('poker'), false) && !isActiveRoute(getRoute('poker'));
    const [{ isPhone }] = useStore(media);

    useEffect(() => {
        if (isAuthenticated) {
            setIsInactivityModalVisible(false);
            setIsTwoMinuteWarningModalVisible(false);
        }
    }, [isAuthenticated]);

    function triggerLogout() {
        logout();
        setIsTwoMinuteWarningModalVisible(false);
    }

    function onClose() {
        setIsInactivityModalVisible(false);
    }

    async function handleConfirmationAccepted() {
        captureUserActivity();
        await renewToken();
        setIsTwoMinuteWarningModalVisible(false);
    }

    if (isInactivityModalDisabled) {
        return null;
    }

    return (
        <>
            <UiModal
                open={isInactivityModalVisible}
                onClose={onClose}
                className="new-modal"
                title={translate('You were logged out due to inactivity.', 'ui.common')}
                icon={<Svg icon="logout" size={3} />}
                mode={isPhone ? 'drawer' : 'default'}
                maxWidth={300}
                minWidth={300}
            >
                <AuthLoginButton block />
            </UiModal>

            <UiModal
                open={isTwoMinuteWarningModalVisible}
                onClose={triggerLogout}
                className="new-modal"
                title={translate('We will log you out in 2 minutes!', 'ui.common')}
                icon={<Svg icon="stop-clock" size={3} />}
                mode={isPhone ? 'drawer' : 'default'}
                maxWidth={300}
                minWidth={300}
            >
                <UiGroup vertical>
                    <UiButton block color="primary" onClick={handleConfirmationAccepted}>
                        {translate('Stay on site!', 'ui.common')}
                    </UiButton>
                    <UiButton block onClick={triggerLogout}>
                        {translate('Logout', 'ui.header')}
                    </UiButton>
                </UiGroup>
            </UiModal>
        </>
    );
}
