import React, { ReactNode } from 'react';
import { FieldValues, FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import classNames from 'classnames';

type Props<T extends FieldValues> = {
    children: ReactNode;
    className?: string;
    onChange?: (event?) => void;
    onSubmit: SubmitHandler<T>;
    useFormMethods: UseFormReturn<T>;
};

export default function Ui2Form<T extends Record<string, any>>({
    children,
    className,
    onSubmit,
    onChange,
    useFormMethods,
}: Props<T>) {
    return (
        <div className={classNames(className)}>
            <FormProvider {...useFormMethods}>
                <form
                    onSubmit={useFormMethods.handleSubmit(onSubmit)}
                    onChange={onChange}
                    autoComplete="off"
                    noValidate
                >
                    {children}
                </form>
            </FormProvider>
        </div>
    );
}
