import styled, { css } from 'styled-components';
import { mediaQuery } from '../../styles/utils';

type StyledProps = {
    $isMobileLandingPage?: boolean;
};

export default styled.div<StyledProps>`
    position: relative;
    .promo-card {
        ${({ $isMobileLandingPage }) =>
            !$isMobileLandingPage &&
            css`
                @media ${mediaQuery.isPhone} {
                    margin: ${({ theme }) => theme.layout.gutterHalf};
                    max-width: 225px;
                    min-width: 225px;
                }
            `};

        &:hover {
            transform: translateY(-10px);
            color: var(--font-color-primary);
        }
        border-radius: 10px;
        box-shadow: var(--ui-shadow);
        overflow: hidden;
        background: var(--surface-level-2-bg);
        color: var(--font-color-secondary);
        transition: transform 200ms ease-in-out, color 200ms ease-in-out;
        cursor: pointer;
        & > *:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
    img {
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        display: block;
    }
    .promo-terms {
        padding: ${({ theme }) => theme.layout.gutter};
        color: var(--font-color-subtle);
        font-size: 0.75rem;
    }
    .promo-description {
        padding: 0 2rem;
        font-size: 1rem;
        line-height: 1.2rem;
        max-height: 150px;
        min-height: 150px;
        @media ${mediaQuery.isPhone} {
            padding: 0 1rem;
        }
        a {
            color: #00bfff;
            border-bottom: 1px dotted;
            text-decoration: none;
        }
    }
`;
