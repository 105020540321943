import { css } from 'styled-components';

export const breakpoints = {
    phoneMax: 800,
    phoneMin: 380,
    phoneMedium: 600,
    tabletMin: 800,
    tabletMedium: 1000,
    tabletMax: 1200,
    laptopMin: 1440,
    desktopMin: 1440,
    desktopSmall: 1600,
    desktopMedium: 1800,
    desktop4K: 3800,
    desktop8k: 7600,
};

export const mediaQuery = {
    isPhone: `(max-width: ${breakpoints.phoneMax}px)`,
    isTablet: `(max-width: ${breakpoints.tabletMax}px) and (min-width: ${breakpoints.phoneMax + 1}px)`,
    isLaptop: `(max-width: ${breakpoints.desktopMin}px) and (min-width: ${breakpoints.tabletMax + 1}px)`,
    isDesktop: `(min-width: ${breakpoints.desktopMin + 1}px)`,
    isDesktopMedium: `(max-width: ${breakpoints.desktopMedium}px) and (min-width: ${breakpoints.desktopSmall + 1}px)`,
    isLaptopMin: `(max-width: ${breakpoints.laptopMin}px) and (min-width: ${breakpoints.tabletMax + 1}px)`,
    isTabletMax: `(max-width: ${breakpoints.tabletMax}px) and (min-width: ${breakpoints.tabletMin + 1}px)`,
    isTabletMediumLarge: `(max-width: ${breakpoints.tabletMax}px) and (min-width: ${breakpoints.tabletMedium + 1}px) `,
    isTabletMediumSmall: `(max-width: ${breakpoints.tabletMedium}px) and (min-width: ${breakpoints.tabletMin + 1}px) `,
    isTabletMin: `(max-width: ${breakpoints.tabletMin}px) and (min-width: ${breakpoints.phoneMedium + 1}px)`,
    isPhoneMedium: `(max-width: ${breakpoints.phoneMedium}px) and (min-width: ${breakpoints.phoneMin + 1}px)`,
    isPhoneMin: `(max-width: ${breakpoints.phoneMin}px)`,
    isDesktopSmall: `(max-width: ${breakpoints.desktopSmall}px) and (min-width: ${breakpoints.laptopMin + 1}px)`,
    isSmallerThanLaptop: `(max-width: ${breakpoints.tabletMax}px)`,
    is4kDesktop: `(min-width: ${breakpoints.desktop4K}px) and (max-width: ${breakpoints.desktop8k}px)`,
    is8kDesktop: `(min-width: ${breakpoints.desktop8k}px)`,
    isLargerThanPhone: `(min-width: ${breakpoints.phoneMax}px)`,
    isLargerThanTablet: `(min-width: ${breakpoints.tabletMax}px)`,
    isMobileLandscapeMode: `(orientation: landscape) and (max-height: ${breakpoints.phoneMax}px)`,
    isSmallPhone: '(max-width: 359px)',
};

export type MediaTypes = keyof typeof mediaQuery;

const globalEventHandlers = [
    'onAbort',
    'onAnimationEnd',
    'onAnimationIteration',
    'onAuxClick',
    'onBlur',
    'onCancel',
    'onCanPlay',
    'onCanPlayThrough',
    'onChange',
    'onClick',
    'onClose',
    'onContextMenu',
    'onDoubleClick',
    'onDurationChange',
    'onEnded',
    'onError',
    'onFocus',
    'onGotPointerCapture',
    'onInput',
    'onInvalid',
    'onKeyDown',
    'onKeyPress',
    'onKeyUp',
    'onLoad',
    'onLoadedData',
    'onLoadedMetadata',
    'onLoadStart',
    'onLostPointerCapture',
    'onMouseDown',
    'onMouseEnter',
    'onMouseLeave',
    'onMouseMove',
    'onMouseOut',
    'onMouseOver',
    'onMouseUp',
    'onPause',
    'onPlay',
    'onPointerCancel',
    'onPointerDown',
    'onPointerEnter',
    'onPointerLeave',
    'onPointerMove',
    'onPointerOut',
    'onPointerOver',
    'onPointerUp',
    'onReset',
    'onScroll',
    'onSelect',
    'onSubmit',
    'onTouchCancel',
    'onTouchStart',
    'onTransitionEnd',
    'onWheel',
];

export function filterStyleProps(props) {
    const onEventRegex = /^on[A-Z].*$/;
    const styleProps = {};

    for (const propName of Object.keys(props)) {
        const isInexistentNativeEventHandler = propName.match(onEventRegex) && !globalEventHandlers.includes(propName);
        const isInvalidDomProp = ['forwardedRef', 'isLoading'].includes(propName);

        if (isInexistentNativeEventHandler || isInvalidDomProp) {
            // eslint-disable-next-line no-continue
            continue;
        }

        styleProps[propName] = props[propName];
    }

    return styleProps;
}

export const hideScrollBar = css`
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const casinoCategoryGamesGrid = css`
    margin-top: var(--spacing-12);
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;

    @media ${mediaQuery.isDesktop} {
        grid-template-columns: repeat(9, 1fr);
    }

    @media ${mediaQuery.isDesktopSmall} {
        grid-template-columns: repeat(8, 1fr);
    }

    @media ${mediaQuery.isLaptopMin} {
        grid-template-columns: repeat(7, 1fr);
    }

    @media ${mediaQuery.isTabletMax} {
        grid-template-columns: repeat(6, 1fr);
    }

    @media ${mediaQuery.isTabletMediumSmall} {
        grid-template-columns: repeat(5, 1fr);
    }
    @media ${mediaQuery.isTabletMin}, ${mediaQuery.isPhoneMedium} {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 580px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 450px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const buttonSizes = {
    default: {
        css: css`
            height: 2.5rem; // lock size when loading state
            padding: var(--button-size-default);
            @media ${mediaQuery.isPhone} {
                font-size: 0.85rem;
                min-height: 50px;
            }
            .button-icon svg {
                width: 1.1rem;
                height: 1.1rem;
            }
        `,
    },
    tiny: {
        css: css`
            padding: var(--button-size-tiny);
            font-size: var(--button-font-size-tiny);
            letter-spacing: 0.01em;
            @media ${mediaQuery.isPhone} {
                font-size: 0.55rem;
            }
            .button-icon svg {
                width: 0.5rem;
                height: 0.5rem;
            }
        `,
    },
    small: {
        css: css`
            padding: var(--button-size-small);
            font-size: var(--button-font-size-small);
            letter-spacing: 0.03em;
            @media ${mediaQuery.isPhone} {
                font-size: 0.675rem;
            }
            .button-icon svg {
                width: 0.75rem;
                height: 0.75rem;
            }
        `,
    },
    large: {
        css: css`
            padding: var(--button-size-large);
            font-size: var(--button-font-size-large);
            @media ${mediaQuery.isPhone} {
                font-size: 0.95rem;
            }
            .button-icon svg {
                width: 2.25rem;
                height: 2.25rem;
            }
        `,
    },
};
