import * as classnames from 'classnames';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../../hooks/useStore';
import { getLimitsByType } from '../../../microservices/responsible-gaming';
import { getSessionStartTime } from '../../../microservices/users';
import { getActiveCurrency } from '../../../services/currency';
import { isFeatureAvailable } from '../../../services/feature';
import { useClock } from '../../../services/hooks';
import { logger } from '../../../services/logger';
import { LIMIT_TYPE } from '../../../services/responsible-gaming/types';
import { translate } from '../../../services/translate';
import { FEATURE } from '../../../services/types';
import { stores } from '../../../stores';
import Wrapper from './styles';

interface Props {
    className?: string;
    isGameTopBar?: boolean;
}

export default function ResponsibleGamingTimers(props: Props) {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [sessionStartTime, setSessionStartTime] = useStore(stores.session.startTime);
    const [sessionEndTime, setSessionEndTime] = useStore(stores.session.endTime);
    const [sessionLimitPeriodEndTime, setSessionLimitPeriodEndTime] = useStore(stores.session.limitPeriodEndTime);

    const [formattedCurrentTime, setFormattedCurrentTime] = useState(moment().format('HH:mm'));
    const [formattedSessionDuration, setFormattedSessionDuration] = useState('00:00');
    const [formattedDurationUntilSessionExpiry, setFormattedDurationUntilSessionExpiry] = useState('');

    const [secondsSinceClockStart, { pauseClock, resumeClock, resetClock }] = useClock();

    useEffect(() => {
        if (isAuthenticated) {
            setupPanelClocks();
        }
        return () => cleanupPanelClocks();
    }, [isAuthenticated]);

    useEffect(() => {
        updatePanelClocks();
    }, [sessionStartTime, sessionEndTime, secondsSinceClockStart]);

    function setupPanelClocks() {
        if (!sessionStartTime) {
            loadSessionStartTime();
        }
        if (sessionEndTime === undefined) {
            loadSessionDurationLimits();
        }
        resumeClock();
    }

    function cleanupPanelClocks() {
        pauseClock();
        resetClock();
    }

    async function loadSessionStartTime() {
        try {
            const { sessionStart } = await getSessionStartTime();
            setSessionStartTime(new Date(sessionStart));
        } catch (error) {
            logger.error('ResponsibleGamingTimers', 'loadSessionStartTime', error);
        }
    }

    function getLoginDurationLimitWithLeastRemainingAmount(
        loginDurationLimitsByPeriod: Record<string, { current_limit: any; next_limit: any }> = {},
    ) {
        const loginDurationLimits = Object.values(loginDurationLimitsByPeriod);

        return loginDurationLimits.length > 0
            ? orderBy(
                  loginDurationLimits,
                  (loginDurationLimit) => moment().add(loginDurationLimit.current_limit.remaining_amount, 'minutes'),
                  'asc',
              )[0]
            : {};
    }

    async function loadSessionDurationLimits() {
        if (!isFeatureAvailable(FEATURE.RESPONSIBLE_GAMING_LOGIN_DURATION_LIMIT)) {
            return;
        }
        setSessionEndTime(undefined);
        setSessionLimitPeriodEndTime(undefined);

        try {
            const { current_limit } = getLoginDurationLimitWithLeastRemainingAmount(
                await getLimitsByType(LIMIT_TYPE.LOGIN_DURATION, getActiveCurrency()),
            ) as { current_limit: any; next_limit: any };

            if (current_limit?.remaining_amount) {
                setSessionEndTime(moment().add(current_limit.remaining_amount, 'minutes').toDate());
            } else {
                setSessionEndTime(null);
            }

            if (current_limit?.end_date) {
                setSessionLimitPeriodEndTime(moment(current_limit.end_date).toDate());
            }
        } catch (error) {
            logger.error('ResponsibleGamingTimers', 'loadSessionDurationLimits', error);
        }
    }

    function updatePanelClocks() {
        updateCurrentTime();
        updateSessionDuration();
        updateDurationUntilSessionExpiry();
    }

    function updateCurrentTime() {
        const currentTime = moment();
        setFormattedCurrentTime(currentTime.format('HH:mm'));
    }

    function updateSessionDuration() {
        if (sessionStartTime) {
            const currentTime = moment();
            const sessionDuration = moment.duration(currentTime.diff(moment(sessionStartTime)));
            setFormattedSessionDuration(sessionDuration.format('hh:mm', { trim: false }));
        }
    }

    function updateDurationUntilSessionExpiry() {
        if (!sessionEndTime) {
            return;
        }
        const currentTime = moment();
        const durationUntilSessionExpiry = moment.duration(moment(sessionEndTime).diff(currentTime));
        const isCountdownCompleted = durationUntilSessionExpiry.asSeconds() <= 0;

        if (isCountdownCompleted) {
            setFormattedDurationUntilSessionExpiry('00:00');
        } else {
            const isLessThanMinuteLeft = durationUntilSessionExpiry.asSeconds() < 60;
            const durationFormat = isLessThanMinuteLeft ? 'mm:ss' : 'hh:mm';

            setFormattedDurationUntilSessionExpiry(durationUntilSessionExpiry.format(durationFormat, { trim: false }));
        }

        if (sessionLimitPeriodEndTime && moment(sessionLimitPeriodEndTime).isBefore(currentTime)) {
            loadSessionDurationLimits();
        }
    }

    function getSessionEndClass() {
        if (!sessionEndTime) {
            return;
        }
        const minutesLeft = moment(sessionEndTime).diff(moment(), 'minutes');

        return classnames('session-expiry-countdown', {
            'five-minutes-left': minutesLeft < 5,
            'ten-minutes-left': minutesLeft >= 5 && minutesLeft < 10,
        });
    }
    return (
        <Wrapper className={props.className} $isGameTopBar={props.isGameTopBar}>
            <div className="clocks-group">
                <div className="current-time" title={translate('Current time', 'ui.common')}>
                    {formattedCurrentTime}
                </div>

                {formattedSessionDuration && (
                    <div className="session-duration" title={translate('Session duration', 'ui.common')}>
                        {formattedSessionDuration}
                    </div>
                )}

                {formattedDurationUntilSessionExpiry && (
                    <div
                        key={getSessionEndClass()} // key triggers shakeClock animation from 10 -> 5 min
                        className={getSessionEndClass()}
                        title={translate('Duration until session expiry', 'ui.common')}
                    >
                        {formattedDurationUntilSessionExpiry}
                    </div>
                )}
            </div>
        </Wrapper>
    );
}
