import { useEffect } from 'react';
import { useStorage } from '../../../services/storage';
import { closeWindow } from '../../../services/poker';
import { poker } from '../../../stores/poker/poker';

export default function SystemPoker() {
    const [isClosePopup, setIsClosePopup] = useStorage(poker.isClosePopup, 'closePopup');

    useEffect(() => {
        if (isClosePopup) {
            window.close();
            closeWindow(); // trying to close popup in poker download client
        }

        return () => setIsClosePopup(false);
    }, [isClosePopup]);

    return null;
}
