import { Country } from '@staycool/location';
import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { logger } from '../services/logger';
import { Jackpot, JackpotRecentWin } from '../types/casino-thrilltech-jackpot/types';
import { Currency } from '../services/wallet/types';

const getUrl = (url) => getServiceUrl('casino-thrilltech-jackpots', url);

export async function getAvailableJackpots(payload: { country: Country; currency: Currency }) {
    try {
        const url = getUrl('fo/jackpots');
        return await httpGet<Jackpot[]>(url, payload);
    } catch (error) {
        logger.error('CasinoThrilltechJackpotsMicroservice', 'getAvailableJackpots', error);
        return [];
    }
}

export async function getUserAvailableJackpots(payload: { country: Country; currency: Currency }) {
    try {
        const url = getUrl('fo/jackpots/user');
        return await httpGet<Jackpot[]>(url, payload);
    } catch (error) {
        logger.error('CasinoThrilltechJackpotsMicroservice', 'getUserAvailableJackpots', error);
        return [];
    }
}

export async function getRecentWins(payload?: { jackpotId: number; country: Country; currency: Currency }) {
    try {
        const url = getUrl('fo/jackpots/wins');
        return await httpPost<JackpotRecentWin[]>(url, payload);
    } catch (error) {
        logger.error('CasinoThrilltechJackpotsMicroservice', 'getRecentWins', error);
        return [];
    }
}

export async function optIn(payload: { jackpotId: number; contributionValue?: number; userCurrency: Currency }) {
    try {
        const url = getUrl('fo/jackpot/opt-in');
        return await httpPost(url, payload);
    } catch (error) {
        logger.error('CasinoThrilltechJackpotsMicroservice', 'optIn', error);
        throw error;
    }
}

export async function optOut(jackpotId: number) {
    try {
        const url = getUrl('fo/jackpot/opt-out');
        return await httpPost(url, { jackpotId });
    } catch (error) {
        logger.error('CasinoThrilltechJackpotsMicroservice', 'optOut', error);
        throw error;
    }
}
