import mapValues from 'lodash/mapValues';
import { LANGUAGE, LOCALE } from './types';

export const localeOfLanguage = {
    [LANGUAGE.CANADIAN]: LOCALE.CA,
    [LANGUAGE.CHILEAN]: LOCALE.CL,
    [LANGUAGE.ENGLISH]: LOCALE.EN,
    [LANGUAGE.ESTONIAN]: LOCALE.EE,
    [LANGUAGE.FINNISH]: LOCALE.FI,
    [LANGUAGE.ICELANDIC]: LOCALE.IS,
    [LANGUAGE.EUROPEAN]: LOCALE.EU,
    [LANGUAGE.PERUVIAN]: LOCALE.PE,
    [LANGUAGE.RUSSIAN]: LOCALE.RU,
    [LANGUAGE.SPANISH]: LOCALE.ES,
    [LANGUAGE.SWEDISH]: LOCALE.SE,
    [LANGUAGE.ECUADORIAN]: LOCALE.EC,
    [LANGUAGE.MEXICAN]: LOCALE.MX,
    [LANGUAGE.US]: LOCALE.US,
};

// MomentJS uses locales for i18n. Norwegian locale starts with "nb_NO". To update MomentJS locale translations
// you reference it with the first part of locale (in Norwegian case "nb"). That's why we need this dictionary.
export const momentJsLanguageByApplicationLanguage = mapValues(localeOfLanguage, (locale) => locale.split('_')[0]);
