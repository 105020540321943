import { getPinnedSubproviders, pinSubprovider, removePinnedSubprovider } from '../../microservices/casino-preferences';
import { toast } from 'react-toastify';
import { translate } from '../translate';
import { getStoreValue } from '../../stores/store/utils';
import { casino } from '../../stores/casino';
import { CasinoCategorySubprovider } from './types';

export interface CasinoFilterProperties {
    studios: number[];
    types: number[];
    rtps: number[];
    maxWinMultipliers: number[];
    volatilities: string[];
    features: number[];
    minBets: number[];
    operatingLanguages: number[];
    jackpots: string[];
    gameTypes?: number[];
    themes?: number[];
    releaseDateLast30Days: string[];
    releaseDateCurrentYear: string[];
}

export interface CasinoFilter {
    id?: string;
    name: string;
    filter: CasinoFilterProperties;
}

export const placeholderCasinoFilter: CasinoFilter = {
    id: '',
    name: '',
    filter: {
        features: [],
        maxWinMultipliers: [],
        rtps: [],
        studios: [],
        types: [],
        volatilities: [],
        minBets: [],
        operatingLanguages: [],
        gameTypes: [],
        themes: [],
        jackpots: [],
        releaseDateCurrentYear: [],
        releaseDateLast30Days: [],
    },
};

export const liveFiltersStudios = (isProd) => ({
    PRAGMATIC: 68,
    ONAIR: 61,
    EVOLUTION: 24,
    EZUGI: 25,
    AUTHENTICGAMING: 151,
    STAKELOGIC_LIVE: isProd ? 112 : 110,
    BOMBAY_LIVE: isProd ? 105 : 149,
});

export async function toggleSubproviderPin(subproviderId: number) {
    const subproviders = getStoreValue(casino.categorySubproviders);

    const subprovider = subproviders.find(({ id }) => id === subproviderId);
    if (!subprovider) {
        return;
    }

    if (subprovider.isPinned) {
        await removePinnedSubprovider(subprovider.id);
    } else {
        await pinSubprovider(subprovider.id);
    }

    const subproviderIndex = subproviders.indexOf(subprovider);

    if (subproviderIndex === -1) {
        return;
    }

    const currentSubprovider = subproviders[subproviderIndex];
    const updatedSubprovider = { ...currentSubprovider, isPinned: !currentSubprovider.isPinned };

    const updatedSubproviders = [
        updatedSubprovider,
        ...subproviders.slice(0, subproviderIndex),
        ...subproviders.slice(subproviderIndex + 1),
    ];

    casino.categorySubproviders.set(updatedSubproviders);

    toast.success(
        translate(subprovider.isPinned ? `Unpinned ${subprovider.name}` : `Pinned ${subprovider.name}`, 'ui.casino'),
        {
            autoClose: 1000,
        },
    );
}

export async function sortPinnedSubproviders(categorySubproviders: CasinoCategorySubprovider[]) {
    const subproviders = await getPinnedSubproviders();
    const uniqueSubproviderIds = new Set(subproviders);

    const nonPinnedSubproviders = categorySubproviders
        .filter((subprovider) => !uniqueSubproviderIds.has(subprovider.id))
        .map((provider) => ({ ...provider, isPinned: false }));

    const sortedPinnedSubproviders = categorySubproviders
        .filter((subprovider) => uniqueSubproviderIds.has(subprovider.id))
        .sort((a, b) => {
            return Array.from(uniqueSubproviderIds).indexOf(a.id) - Array.from(uniqueSubproviderIds).indexOf(b.id);
        })
        .map((provider) => ({ ...provider, isPinned: true }));

    const combinedSubproviders = [...sortedPinnedSubproviders, ...nonPinnedSubproviders];

    casino.categorySubproviders.set(combinedSubproviders);

    return combinedSubproviders;
}
