import React, { ReactNode } from 'react';
import Wrapper from './styles';
import { filterStyleProps } from '../../../../styles/utils';

interface Props {
    children: ReactNode;
    noWrap?: boolean;
    className?: string;
}

export default function UiFormLabel({ children, noWrap, ...rest }: Props) {
    return (
        <Wrapper $noWrap={noWrap} {...filterStyleProps({ ...rest })}>
            {children}
        </Wrapper>
    );
}
