import { Buffer } from 'buffer';
import camelCase from 'lodash/camelCase';
import { environment } from '../stores/environment/environment';
import { getStoreValue } from '../stores/store/utils';
import { logger } from './logger';

export function preventEvent(event) {
    event.preventDefault();
    event.stopPropagation();
}

export function delay(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function convertArrayToObject(arrayToConvert, keyField) {
    return arrayToConvert.reduce((object, item) => ({ ...object, [item[keyField]]: item }), {});
}

export function openNewTab(url) {
    const newTabReference = window.open(url, '_blank');
    if (newTabReference) {
        newTabReference.opener = null; // Explanation: https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
    }
}

export function encodeUriBase64(text) {
    return encodeURIComponent(window.btoa(text));
}

export function decodeUriBase64(text) {
    return window.atob(decodeURIComponent(text));
}

export function isProduction() {
    const { ENVIRONMENT } = getStoreValue(environment);
    return ENVIRONMENT === 'prod';
}

export function camelCaseKeys(target) {
    if (Array.isArray(target)) {
        return target.map((element) => camelCaseKeys(element));
    }
    if (typeof target === 'object' && target !== null) {
        const formattedObject = {};
        Object.keys(target).forEach((key) => {
            formattedObject[camelCase(key)] = camelCaseKeys(target[key]);
        });
        return formattedObject;
    }
    return target;
}

export function scrollToTop() {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
}

export function scrollToTopOfElement(element: HTMLElement, offset?: number) {
    if (!element?.tagName) {
        return;
    }

    const DELAY_TIME_FOR_OPEN_SCREEN_KEYBOARD = 350;
    const DEFAULT_OFFSET_FROM_TOP = 12;

    const elementPositionTop = element.getBoundingClientRect().top;
    const positionForScroll = window.pageYOffset + elementPositionTop - (offset ? offset : DEFAULT_OFFSET_FROM_TOP);

    setTimeout(() => {
        window.scroll({
            top: positionForScroll,
            left: 0,
            behavior: 'smooth',
        });
    }, DELAY_TIME_FOR_OPEN_SCREEN_KEYBOARD);
}

export function getDocumentHost() {
    return window.location.hostname.replace('www.', '');
}

export const toBase64 = (input) => Buffer.from(input, 'binary').toString('base64');

export function getUniqCombinations<T>(arr: T[], k: number): T[][] {
    const result: T[][] = [];

    function combine(startIndex: number, combination: T[]) {
        if (combination.length === k) {
            result.push(combination);
            return;
        }

        for (let i = startIndex; i < arr.length; i++) {
            combine(i + 1, [...combination, arr[i]]);
        }
    }

    combine(0, []);

    return result;
}

export const retryAsync = async (
    fn: () => any,
    {
        retryTimes = 20,
        delayFn = (r: number) => Math.max(r * 200, 10_000),
        handleError,
    }: { retryTimes?: number; delayFn?: (r: number) => number; handleError?: (error?: Error) => any } = {},
) => {
    let retry = 0;
    let error: Error | null = null;
    while (retry <= retryTimes) {
        try {
            return await fn();
        } catch (e: any) {
            logger.error('UtilService', 'combine', e);
            error = e;
            retry++;
            await delay(delayFn(retry));
        }
    }
    if (handleError) {
        handleError(error as Error);
    } else {
        throw error;
    }
};

export function getTimeLeftInShortFormat(days: number, hours: number, minutes: number, seconds: number) {
    if (days > 0) {
        return `${days}d ${hours}h`;
    } else if (hours > 0) {
        return `${hours}h ${minutes}m`;
    } else {
        return `${minutes}m ${seconds}s`;
    }
}
