import styled from 'styled-components';

export default styled.div`
    --menu-bg: var(--surface-level-2-bg);
    --menu-border: var(--color-secondary);
    --menu-item-color: white;
    --menu-item-active-color: yellow;

    z-index: 10;

    position: fixed;
    display: flex;
    bottom: 0;
    width: 100%;

    .menu {
        &-center,
        &-left,
        &-right {
            height: 74px;
        }

        &-left,
        &-right {
            display: flex;
            position: relative;
            bottom: -1px;
            flex: 1 0 0;
            background: var(--menu-bg);

            &:before {
                z-index: -1;
                content: '';
                position: absolute;
                top: -1px;
                width: 100%;
                height: 40px;
                border-top: 1px solid var(--menu-border);
                background: var(--menu-bg);
            }
        }

        &-left {
            &:before {
                transform: rotate(2deg);
                transform-origin: top right;
            }
        }

        &-right {
            &:before {
                transform: rotate(-2deg);
                transform-origin: top left;
            }
        }

        &-center {
            position: relative;
            width: 80px;
            flex: 80px 0 0;
            background: var(--menu-bg);
            mask: url('/assets/images/sim/sim-mobile-menu-bg.svg');
            mask-position: center bottom;
            mask-repeat: no-repeat;

            &:after {
                content: '';
                position: absolute;
                background: var(--menu-border);
                width: 100%;
                height: 74px;
                mask: url('/assets/images/sim/sim-mobile-menu-border.svg');
                mask-position: center -6px;
                mask-repeat: no-repeat;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: var(--spacing-4);
            z-index: 1;
            flex: 1 0 0;
            text-align: center;
            height: 100%;
            font-size: 12px;
            font-weight: var(--font-weight-bold);
            text-transform: capitalize;
            color: var(--menu-item-color);

            &.active {
                color: yellow;
            }
        }

        &-main-item {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 23px;
            width: 64px;
            height: 64px;
            border: 1px solid var(--color-primary);
            background: var(--button-primary-bg);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: var(--spacing-4);
            color: var(--button-primary-text-color);
            font-size: 12px;
            font-weight: var(--font-weight-bold);
            text-transform: capitalize;
        }
    }
`;
