import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../../../hooks/useStore';
import { loadKycUserSettings } from '../../../../microservices/kyc';
import { useTimeout } from '../../../../services/hooks';
import { isMobileApp } from '../../../../services/mobile-app';
import { useSharedStore } from '../../../../services/persisted-shared-storage';
import { getRoute, useRouter } from '../../../../services/router';
import { stores } from '../../../../stores';
import asyncComponent from '../../../async-component/AsyncComponent';

const AccountVerificationKycModal = asyncComponent(
    () => import('./modal/AccountVerificationKycModal' /* webpackChunkName: 'AccountVerificationKycModal'*/),
);

export default function AccountVerificationKyc() {
    const { navigateTo } = useRouter();
    const [kycToken, setKycToken] = useSharedStore('kycToken', stores.kyc.kycToken);
    const [kycTokenExpiry, setKycTokenExpiry] = useSharedStore('kycTokenExpiry', stores.kyc.kycTokenExpiry);
    const [, setIsLoginModalOpen] = useStore(stores.modals.isLoginModalOpen);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        load();
    }, [kycToken]);

    const kycTokenExpiryTimeout = kycTokenExpiry
        ? moment.utc(kycTokenExpiry).diff(moment.utc(), 'milliseconds')
        : undefined;

    useTimeout(
        () => {
            closeKycModal();
            navigateToLogin();
        },
        kycTokenExpiryTimeout,
        [kycTokenExpiryTimeout],
    );

    async function load() {
        if (!kycToken) {
            return;
        }
        await loadKycUserSettings();
        setIsLoading(false);
    }

    function closeKycModal() {
        setKycToken(undefined);
        setKycTokenExpiry(undefined);
    }

    function navigateToLogin() {
        if (isMobileApp()) {
            setIsLoginModalOpen(true);
        } else {
            navigateTo(getRoute('login'));
        }
    }

    if (isLoading) {
        return null;
    }

    return <AccountVerificationKycModal isOpen={!!kycToken} onClose={closeKycModal} />;
}
