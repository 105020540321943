import React, { PropsWithChildren } from 'react';
import Wrapper from './styles';
import MainLayout from '../main/MainLayout';
import AccountMenu from '../../components/account/menu/AccountMenu';
import { media } from '../../stores/media/media';
import { useStore } from '../../hooks/useStore';

export default function AccountLayout({ children }: PropsWithChildren) {
    const [{ isDesktop, isLaptop }] = useStore(media);

    return (
        <MainLayout>
            <Wrapper>
                {(isDesktop || isLaptop) && (
                    <div className="account-menu">
                        <AccountMenu />
                    </div>
                )}
                <div className="account-content">{children}</div>
            </Wrapper>
        </MainLayout>
    );
}
