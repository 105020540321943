import styled, { css } from 'styled-components';

type StyledProps = {
    $size?: number;
    $rotate?: number;
    $width?: number;
    $color?: string;
};

export default styled.svg<StyledProps>`
    display: block;
    aspect-ratio: 1;
    height: ${({ $size }) => ($size ? `${$size}rem` : '')};
    width: ${({ $size }) => ($size ? `${$size}rem` : '100%')}; //fix for ios
    transform: rotate(${({ $rotate }) => ($rotate ? $rotate : '')}deg);
    color: ${({ $color }) => ($color ? `${$color}` : '')};
    ${({ $width }) =>
        $width &&
        css`
            height: initial;
            width: ${$width}rem;
        `}
`;
