import React, { ComponentPropsWithoutRef } from 'react';
import { useStore } from '../../../hooks/useStore';
import { environment } from '../../../stores/environment/environment';
import { filterStyleProps } from '../../../styles/utils';
import Wrapper from './styles';

type Props = ComponentPropsWithoutRef<typeof Wrapper>;

export default function UiAvatar({ src, ...props }: Props) {
    const [{ IMAGES_HOST }] = useStore(environment);
    src = `${IMAGES_HOST}${src}`;

    return <Wrapper src={src} {...filterStyleProps(props)} />;
}
