import React from 'react';
import { isFeatureAvailable } from '../../../../services/feature';
import { isMobileApp, NativeMessageEventType, sendNativeEvent } from '../../../../services/mobile-app';
import { translate } from '../../../../services/translate';
import { isExistingUser } from '../../../../services/user';
import UiButton from '../../../ui/button/UiButton';
import { requestLogin } from '../../../../services/auth';
import { FEATURE } from '../../../../services/types';

interface Props {
    block?: boolean;
    openInNewTab?: boolean;
    size?: 'small' | 'large' | 'default';
}

export default function AuthRegisterButton({ block = false, openInNewTab = false, size }: Props) {
    const isRegistrationB2BFeatureAvailable = isFeatureAvailable(FEATURE.REGISTRATION_B2B);

    if (
        isExistingUser() ||
        !(isFeatureAvailable(FEATURE.REGISTRATION) || isFeatureAvailable(FEATURE.REGISTRATION_B2B))
    ) {
        return null;
    }

    function onClick() {
        if (isFeatureAvailable(FEATURE.UNIFIED_LOGIN_AND_REGISTER)) {
            requestLogin();
        } else if (isRegistrationB2BFeatureAvailable) {
            sendNativeEvent({ type: NativeMessageEventType.REGISTER });
        }
    }

    function getRoute() {
        if (isFeatureAvailable(FEATURE.UNIFIED_LOGIN_AND_REGISTER) && isMobileApp()) {
            return;
        }
        return isRegistrationB2BFeatureAvailable ? 'registration-btob' : 'registration';
    }

    return (
        <UiButton
            onClick={onClick}
            route={getRoute()}
            color="primary"
            block={block}
            size={size}
            dataTest="button-register"
            openNewTab={openInNewTab}
        >
            {translate('Register', 'ui.common')}
        </UiButton>
    );
}
