import { logger } from '../services/logger';
import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { CasinoRace, LeaderboardQueryParams, LeaderboardRow, RaceHistorySortingKey } from '../services/casino/types';
import { ObjectValues } from '../services/ts-utils';

const getUrl = (url) => getServiceUrl('casino-race', url);

export function getMyCasinoRaces() {
    const url = getUrl('fo/race/active/me');
    return httpGet<CasinoRace[]>(url);
}

export function getPublicCasinoRaces() {
    const url = getUrl('fo/race/public');
    return httpGet<CasinoRace[]>(url);
}

export async function joinRace(raceId: number) {
    try {
        return await httpPost(getUrl(`api/registration/join-race/${raceId}`));
    } catch (error) {
        logger.error('CasinoRaceMicroservice', 'joinRace', error); // Failed to join the race
    }
}

export async function getSimGamingLeaderboard(queryParams: LeaderboardQueryParams) {
    return httpGet<LeaderboardRow[]>(getUrl(`fo/race/sim-gaming-leaderboard`), queryParams);
}

export async function getCasinoRaceHistory(sortingKey: ObjectValues<typeof RaceHistorySortingKey>) {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const historyUrl = 'fo/race/history/last-10';
    const url = {
        last_10: isAuthenticated ? `${historyUrl}/me` : `${historyUrl}/public`,
        only_mine: `fo/race/history/onlymine`,
    };
    return httpGet<{ races: any[]; total?: number }>(getUrl(url[sortingKey]));
}

export async function disableMinBetReminder(raceId: number) {
    try {
        return await httpPost(getUrl(`api/game/disable-min-bet-reminder/${raceId}`), {});
    } catch (error) {
        logger.error('CasinoRaceMicroservice', 'disableMinBetReminder', error);
    }
}

export async function resetPoints(raceId: number) {
    try {
        return await httpPost<any>(getUrl(`api/game/reset-points/${raceId}`));
    } catch (error) {
        logger.error('CasinoRaceMicroservice', 'resetPoints', error);
        return error;
    }
}
