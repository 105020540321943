import React, { useEffect } from 'react';
import { stores } from '../../../stores';
import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from '../../../microservices/pusher';
import { getDelayedEventsByAuthenticatedUser, reportDelayedEvents } from '../../../microservices/analytics';
import { trackGoogleTagManagerEvent } from '../../../services/analytics';
import { useStore } from '../../../hooks/useStore';
import { DelayedEvent } from '../../../microservices/analytics/types';

export default function SystemDelayedEvents() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [user] = useStore(stores.user);

    useEffect(() => {
        if (isAuthenticated && user) {
            handleDepositDelayedEvents();
        }
    }, [isAuthenticated]);

    useSocketSubscribeUnsubscribe('private', {
        params: {
            service: 'analytics',
            channel: 'delayed-events',
        },
        guardFunction: () => isAuthenticated,
        watchParams: [isAuthenticated],
    });

    useSocketTopicEvents(
        `delayed-events-created-${user?.id}`,
        () => {
            handleDepositDelayedEvents();
        },
        [user?.id],
    );

    async function handleDepositDelayedEvents() {
        const delayedEvents: DelayedEvent[] = await getDelayedEventsByAuthenticatedUser();
        const delayedEventsIds = delayedEvents?.map((delayedEvent) => {
            trackGoogleTagManagerEvent(delayedEvent.event, {
                ...delayedEvent.variables,
                userId: delayedEvent.user_id,
            });
            return delayedEvent.id;
        });
        await reportDelayedEvents(delayedEventsIds);
    }

    return <></>;
}
