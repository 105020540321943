import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import filter from 'lodash/filter';
import { getRoute, isActiveRoute } from './router';
import { Toast } from './toast/types';

let toastIdCounter = 0;

export function showToast(toast: Toast): void {
    if (isActiveRoute(`${getRoute('poker')}/:rest`, false)) {
        return;
    }
    toast.id = toastIdCounter++;
    const toasts = getStoreValue(stores.toasts);

    if (toasts.length < 2 || toast.isImportant) {
        stores.toasts.set([...toasts, toast]);
    }
}

export function closeToast(toast: Toast): void {
    const toasts = getStoreValue(stores.toasts);
    stores.toasts.set(filter(toasts, (currentToast) => currentToast.id !== toast.id));
}

export function closeToastsOnLogout(): void {
    const toasts = getStoreValue(stores.toasts);
    if (toasts.length > 1) {
        stores.toasts.set(filter(toasts, { closeOnLogout: false }));
    }
}
