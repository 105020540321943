import styled, { css } from 'styled-components';

import { lighten } from 'polished';

type StyledProps = {
    $isUserFromFinlandAndLoggedOutAndCampaign: boolean;
};
export default styled.div<StyledProps>`
    position: relative;

    .cms-content {
        ${({ $isUserFromFinlandAndLoggedOutAndCampaign }) =>
            $isUserFromFinlandAndLoggedOutAndCampaign &&
            css`
                filter: blur(10px);
                z-index: 1;
            `};
    }

    .logged-out-finnish-users-message {
        position: absolute;
        z-index: 2;
        top: 50vh;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .support-styles {
        p {
            text-align: justify;
        }
        .support-link {
            text-decoration: none;
            color: #ff8d00;
        }
        .support-heading {
            text-decoration: none;
            color: #ff8d00;
        }
        .support-anchor {
            padding-top: 90px;
            text-decoration: none;
            color: #ff8d00;
        }
    }

    button {
        outline: none;
    }

    ul,
    ol {
        padding: 0;
    }

    table {
        border: 1px solid #424242;
        tr {
            th {
                color: #ff8d02;
                background: #1b1b1b;
            }
            td {
                border-bottom: 1px solid #424242;
            }
            &:hover {
                background: #252424;
            }
        }
    }

    .marketing-terms-and-conditions {
        margin-top: 1rem;
        padding: 2rem;
        border-radius: 10px;
        background-color: var(--surface-level-1-bg);
        color: var(--font-color-secondary);
    }

    .marketing-terms-and-conditions-content {
        max-width: 900px;
        margin: 0 auto;
        padding: 1rem 2rem 1rem 3rem;
        border-radius: 10px;
        background-color: ${({ theme }) => lighten(0.05, theme.box.background)};

        h1 {
            font-size: 1.5em;
            margin: 0.67em 0;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: var(--color-highlight);
        }
        ol li {
            list-style-type: decimal;
            padding: 0.5rem;
            line-height: 1.3rem;
        }
    }

    @media (max-width: 768px) {
        .marketing-terms-and-conditions {
            padding: 0;
        }
    }

    @media screen and (max-width: 1365px) {
        .overlay-modal {
            width: 450px;
        }
    }

    @media screen and (max-width: 480px) {
        .overlay-modal {
            width: 98%;
            margin: 0 1%;
            left: 0;
            transform: translateX(0);
            top: 0;
            height: 85vh;
        }
        .overlay-modal-body {
            height: 90%;
        }
    }

    .terms-button {
        position: absolute;
        top: 0;
        right: 0;
    }
    .overlay-modal {
        transition: transform 200ms ease-in;
        position: absolute;
        z-index: 1001;
        padding: 20px;
        border-radius: 3px;
        background: #2f2f2f;
        width: 600px;
        max-width: 95%;
        max-height: 70vh;
        transform: translateX(-50%);
        left: 50%;
        top: 20px;
        text-align: left;
    }
    .overlay-modal-body {
        overflow: auto;
        height: 60vh;
        padding: 0 10px 0 0;
    }
    .overlay-modal {
        h1 {
            font-size: 18px;
            text-transform: uppercase;
            color: #fff;
            font-weight: bold;
            margin: 0 0 20px;
            padding: 0 40px 0 0;
        }
        ul {
            margin: 20px;
        }
        li {
            list-style: inherit;
            padding: 0 0 10px 0;
        }
        p,
        ul li {
            color: #f1f1f1;
        }
        em {
            color: #ff8d02;
            font-style: normal;
            &.underline {
                background: none;
                border-bottom: 1px dotted #ff8d02;
            }
        }
    }

    .loader-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50vh;
    }
`;
