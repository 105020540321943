import { produce } from 'immer';
import { stateSubscriber, Store, storeType } from './types';

// eslint-disable-next-line @typescript-eslint/ban-types
export function createStores<T extends Object>(object: T) {
    return {
        ...Object.keys(object).reduce((stores, key) => {
            stores[key] = isStore(object[key]) ? object[key] : createGlobalStore(object[key], key);
            return stores;
        }, {} as { [K in keyof T]: storeType<T[K]> }),
        _store: true,
    };
}

export function createGlobalStore<T>(initialState: T, storeName: string) {
    const subscriptions = new Set<stateSubscriber<T>>();

    const store = {
        _store: true,
    } as Store<T>;

    store.state = initialState;

    store.set = (updateState: T | ((draft: T) => void)) => {
        const newState = updateState instanceof Function ? produce(store.state, updateState) : updateState;
        store.state = newState;
        if (window.traceStore && window.traceStore === store) {
            console.trace(storeName, newState);
        }
        if (window.watchStore && window.watchStore === store) {
            console.info(storeName, newState);
        }
        subscriptions.forEach((setState) => setState(newState));
    };
    store.reset = () => store.set(initialState);

    store.subscribe = (setState) => subscriptions.add(setState);
    store.unsubscribe = (setState) => subscriptions.delete(setState);
    store.unsubscribeAll = () => subscriptions.clear();
    return store;
}

function isStore(item: any): item is Store {
    return Boolean(item && item._store);
}

export function getStoreValue<T>(store: Store<T>) {
    return store.state;
}
