import React from 'react';
import Wrapper from './styles';
import classNames from 'classnames';
import { filterStyleProps } from '../../../styles/utils';

interface Props {
    vertical?: boolean;
    expand?: boolean;
    reversed?: boolean;
    verticallyCentered?: boolean;
    horizontallyCentered?: boolean;
    layoutGutterInRem?: string;
    shouldOverflow?: boolean;
    className?: string;
    children?: any;
    style?: any;
    onClick?: any;
    horizontal?: boolean;
}

export default function UiGroup({
    shouldOverflow,
    children,
    className,
    expand,
    reversed,
    vertical,
    verticallyCentered,
    horizontallyCentered,
    layoutGutterInRem,
    ...rest
}: Props) {
    return (
        <Wrapper
            {...filterStyleProps({ shouldOverflow, ...rest })}
            className={classNames('UiGroup', className)}
            $layoutGutterInRem={layoutGutterInRem}
            $expand={expand}
            $reversed={reversed}
            $shouldOverflow={shouldOverflow}
            $vertical={vertical}
            $verticallyCentered={verticallyCentered}
            $horizontallyCentered={horizontallyCentered}
        >
            {shouldOverflow ? <div className="UiGroup-items">{children}</div> : children}
        </Wrapper>
    );
}
