import { useEffect, useState } from 'react';
import { logger } from '../../../services/logger';
import { getFortuneWheelTime } from '../../../microservices/fortune-wheel';

export function useFortuneWheel() {
    const [timeToSpin, setTimeToSpin] = useState<Date | undefined>();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const elapsedTime = await getFortuneWheelTime();
                setTimeToSpin(elapsedTime);
            } catch (error) {
                logger.error('useFortuneWheel', 'fetchData', error);
                setTimeToSpin(undefined);
            }
        };

        fetchData();
    }, []);

    return {
        timeToSpin,
    };
}
