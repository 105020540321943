import { useEffect, useState } from 'react';
import { useTimeout } from '../../../services/hooks';
import moment from 'moment';
import { stores } from '../../../stores';
import { logout, setTokenAndAuthenticate } from '../../../services/auth';
import throttle from 'lodash/throttle';
import isNumber from 'lodash/isNumber';
import { useSharedStore } from '../../../services/persisted-shared-storage';
import { random } from 'lodash/number';
import {
    getToken,
    getTokenDurationTime,
    getTokenExpirationTime,
    getTokenRenewalTime,
    setToken,
} from '../../../services/token';
import { parse } from 'query-string';
import { useStore } from '../../../hooks/useStore';
import { renewToken } from '../../../services/api';
import { STORAGE_KEY_TOKEN } from '../../../services/types';

export default function SystemSession() {
    const [token] = useStore(stores.token);
    const [wallet] = useStore(stores.wallet);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [logoutViaStoreDate] = useStore(stores.logoutViaStoreDate);
    const [, setSessionStartTime] = useStore(stores.session.startTime);
    const [millisecondsUntilSessionWarning, setMillisecondsUntilSessionWarning] = useState<number | undefined>();
    const [millisecondsUntilTokenRenew, setMillisecondsUntilTokenRenew] = useState<number | undefined>();
    const [millisecondsUntilLogOut, setMillisecondsUntilLogOut] = useState<number | undefined>();

    const [lastActivityTime, setLastActivityTime] = useSharedStore<string | undefined>(
        'lastActivityTime',
        stores.lastActivityTime,
    );
    const [isTwoMinuteWarningModalVisible, setIsTwoMinuteWarningModalVisible] = useSharedStore(
        'isTwoMinuteWarningModalVisible',
        stores.modals.isTwoMinuteWarningModalVisible,
    );
    const [, setIsInactivityModalVisible] = useSharedStore(
        'isInactivityModalVisible',
        stores.modals.isInactivityModalVisible,
    );

    const { overrideSessionWarning, overrideSessionLogout } = parse(window.location.search);
    const overrideSessionWarningValue = Number.parseInt(overrideSessionWarning as string);
    const overrideSessionLogoutValue = Number.parseInt(overrideSessionLogout as string);

    useEffect(() => {
        if (token) {
            const randomMilliseconds = random(0, 5 * 60 * 1000);
            setMillisecondsUntilTokenRenew(
                moment
                    .duration(getTokenExpirationTime(token).diff(moment()))
                    .subtract(10, 'minutes')
                    .add(randomMilliseconds, 'milliseconds')
                    .as('milliseconds'),
            );
        } else {
            setMillisecondsUntilTokenRenew(undefined);
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            if (isAuthenticated) {
                const defaultLogoutTimeSeconds = getTokenDurationTime(token) * 60;
                const defaultSessionWarningTimeSeconds = (getTokenDurationTime(token) - 2) * 60;
                setMillisecondsUntilSessionWarning(
                    (overrideSessionWarningValue || defaultSessionWarningTimeSeconds) * 1000,
                );
                setMillisecondsUntilLogOut((overrideSessionLogoutValue || defaultLogoutTimeSeconds) * 1000);
                setLastActivityTime(moment().toISOString());
            } else {
                setMillisecondsUntilSessionWarning(undefined);
                setMillisecondsUntilLogOut(undefined);
                setLastActivityTime(undefined);
            }
        }
    }, [isAuthenticated]);

    useTimeout(
        () => {
            renewToken();
        },
        millisecondsUntilTokenRenew,
        [millisecondsUntilTokenRenew, token],
    );

    useTimeout(
        () => {
            setIsTwoMinuteWarningModalVisible(true);
        },
        millisecondsUntilSessionWarning,
        [millisecondsUntilSessionWarning, lastActivityTime],
    );

    useTimeout(
        () => {
            setIsTwoMinuteWarningModalVisible(false);
            setIsInactivityModalVisible(true);
            logout();
        },
        millisecondsUntilLogOut,
        [millisecondsUntilLogOut, lastActivityTime],
    );

    useEffect(() => {
        if (wallet && isNumber(wallet.balance_uc)) {
            setLastActivityTime(moment().toISOString());
        }
    }, [wallet]);

    useEffect(() => {
        if (!isAuthenticated || isTwoMinuteWarningModalVisible) {
            return;
        }

        const throttledEventHandler = throttle(
            () => {
                setLastActivityTime(moment().toISOString());
            },
            3000,
            { leading: true, trailing: true },
        );

        const useCapturingPhase = true;
        const eventsToTrackActivityBy = ['keydown', 'click', 'scroll', 'mousemove', 'touchstart'];

        for (const event of eventsToTrackActivityBy) {
            window.addEventListener(event, throttledEventHandler, useCapturingPhase);
        }

        return () => {
            for (const event of eventsToTrackActivityBy) {
                window.removeEventListener(event, throttledEventHandler, useCapturingPhase);
            }
        };
    }, [isAuthenticated, isTwoMinuteWarningModalVisible]);

    useEffect(() => {
        window.addEventListener('storage', handleGlobalTokenChange, false);
        return () => {
            window.removeEventListener('storage', handleGlobalTokenChange, false);
        };
    }, []);

    useEffect(() => {
        if (logoutViaStoreDate) {
            logout();
        }
    }, [logoutViaStoreDate]);

    useEffect(() => {
        if (!isAuthenticated) {
            setSessionStartTime(undefined);
        }
    }, [isAuthenticated]);

    function handleGlobalTokenChange(event) {
        if (event.key !== STORAGE_KEY_TOKEN || event.oldValue === event.newValue) {
            return;
        }

        const token = event.newValue;

        if (!token) {
            logout(undefined, true);
            return;
        }

        if (getToken()) {
            const currentTokenRenewalTime = getTokenRenewalTime(getToken());
            const newTokenRenewalTime = getTokenRenewalTime(token);

            if (newTokenRenewalTime.isAfter(currentTokenRenewalTime)) {
                setToken(token);
            }
        } else {
            setTokenAndAuthenticate(token);
        }
    }

    return null;
}
