import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.header`
    background: var(--color-header-bg);
    padding: 0.8rem;

    @media ${mediaQuery.isSmallerThanLaptop} {
        padding: var(--spacing-4) var(--spacing-8);
    }

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1920px;
        margin: 0 auto;
        @media ${mediaQuery.isSmallerThanLaptop} {
            .header-logo {
                margin: 0;
            }
            .header-logo img {
                height: 20px;
                width: 122px;
            }
        }
    }

    .header-logo {
        margin: 0 0.8rem;
        img {
            width: var(--header-logo-width);
        }
    }

    .header-products-nav {
        display: flex;
        align-items: center;
        flex-grow: 1;

        margin-left: 0.8rem;
        > *:not(:last-child) {
            margin-right: 2rem;
        }
        a > div {
            color: var(--header-color);
            &.active,
            &:hover {
                color: var(--header-color-active);
                svg {
                    color: var(--header-color-highlight);
                }
            }
        }
    }

    .ui-language-select-mini-text {
        color: var(--header-color);
    }

    .header-user-controls {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: var(--spacing-12);
        @media ${mediaQuery.isSmallerThanLaptop} {
            span[type='button'],
            button {
                min-height: auto;
            }
        }
    }
`;
