import { useEffect } from 'react';
import { logger } from '../../../services/logger';
import { stores } from '../../../stores';
import { onBalanceUpdate } from '../../../microservices/pusher';
import { getStoreValue } from '../../../stores/store/utils';
import { NativeMessageEventType, sendNativeEvent } from '../../../services/mobile-app';

export default function SystemWallet() {
    useEffect(() => {
        onBalanceUpdate.subscribe((update: { user: string; balance: number; currency: string }) => {
            logger.dev('SystemWallet', 'onBalanceUpdate', update);

            const selectedWallet = getStoreValue(stores.wallet);
            const wallets = getStoreValue(stores.wallets);
            if (!selectedWallet) {
                return;
            }
            wallets.forEach((wallet) => {
                if (wallet.currency === update.currency) {
                    wallet.balance_uc = update.balance;
                }
            });
            if (selectedWallet.currency === update.currency) {
                stores.wallet.set({ ...selectedWallet, balance_uc: update.balance });
                sendNativeEvent({
                    type: NativeMessageEventType.BALANCE_UPDATE,
                    currency: selectedWallet.currency,
                    balance: update.balance,
                });
            }
            stores.wallets.set(wallets);
        });
    }, []);

    return null;
}
