import styled, { css } from 'styled-components';

const toggleTrackLength = 48;
const toggleThumbSize = 1.5;

type StyledProps = {
    $justify?: string;
    $nowrap?: boolean;
    $direction?: string;
    $reverse?: boolean;
};

function direction($direction, $reverse) {
    let postFix = '';
    if ($reverse) {
        postFix = '-reverse';
    }

    return `${$direction}${postFix}`;
}

function justify($justify?: string) {
    switch ($justify) {
        case 'center':
            return 'center';
        case 'right':
            return 'right';
        case 'left':
            return 'left';
        case 'space':
        default:
            return 'space-between';
    }
}

export default styled.div<StyledProps>`
    display: flex;
    align-items: center;
    justify-content: ${({ $justify }) => justify($justify)};
    flex-direction: ${({ $direction = 'row', $reverse = false }) => direction($direction, $reverse)};
    padding: 0.4rem 0;
    gap: var(--spacing-8);

    ${({ $nowrap }) =>
        $nowrap &&
        css`
            flex-direction: column;
        `};
    .pseudo-toggle-container {
        position: relative;
        display: inline-block;
        height: 1.5rem;
        margin: 0 0 0 0.8rem;
        min-width: ${toggleTrackLength}px;
        text-transform: none;
        letter-spacing: initial;
    }

    input {
        appearance: none;
        height: 0;
        opacity: 0;
        width: 0;

        &:disabled + .pseudo-toggle {
            cursor: not-allowed;
            opacity: 0.5;
        }

        &:checked + .pseudo-toggle {
            border: var(--form-input-toggle-border-active);
            background-color: var(--form-input-toggle-bg-active);
        }

        &:checked + .pseudo-toggle:after {
            background-color: var(--form-input-toggle-knob-bg-active);
            border: var(--form-input-toggle-bg-active);
            transform: translate(calc(100% + 2px), 0);
        }
    }
    label {
        padding: ${({ $justify }) => ($justify === 'center' ? '0 var(--spacing-8) 0 0' : '0 !important')};
        margin: 0 !important;
        font-size: 0.75rem;
    }

    .pseudo-toggle {
        background-color: var(--form-input-toggle-bg);
        border: var(--form-input-toggle-border);
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50px;

        &:after {
            background-color: var(--form-input-toggle-knob-bg);
            border: var(--form-input-toggle-border);
            border-radius: 50%;
            content: '';
            display: block;
            height: calc(${toggleThumbSize}rem - 2px);
            width: calc(${toggleThumbSize}rem - 2px);
            transform: translate(0, 0);
        }
    }
`;
