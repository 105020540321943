import React from 'react';
import Wrapper from './styles';

interface Props {
    badge?: number;
    className?: string;
}

export default function UiBadge({ badge, className }: Props) {
    if (!badge) {
        return null;
    }

    return <Wrapper className={className}>{badge}</Wrapper>;
}
