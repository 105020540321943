import React from 'react';
import { stores } from '../../../../stores';
import { translate } from '../../../../services/translate';
import UiButton from '../../../ui/button/UiButton';
import { isFeatureAvailable } from '../../../../services/feature';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';
import { FEATURE } from '../../../../services/types';

interface Props {
    className?: string;
}

export default function AuthPayAndPlayButton({ className }: Props) {
    const [isPayAndPlayModalOpen, setIsPayAndPlayModalOpen] = useStore(stores.modals.isPayAndPlayModalOpen);
    const [isPayAndPlayEnabled] = useStore(stores.isPayAndPlayEnabled);
    const [{ isPhone, isTablet }] = useStore(media);
    const isMobileDevice = isPhone || isTablet;

    function toggleIsPayAndPlayModalOpen() {
        stores.modals.isInactivityModalVisible.set(false);
        setIsPayAndPlayModalOpen(!isPayAndPlayModalOpen);
    }

    if (!isPayAndPlayEnabled || !isFeatureAvailable(FEATURE.PAY_AND_PLAY)) {
        return null;
    }

    return (
        <UiButton
            onClick={toggleIsPayAndPlayModalOpen}
            data-test="deposit-and-play"
            color="primary"
            className={className}
            size={isMobileDevice ? 'small' : 'default'}
        >
            {translate('Deposit & Play', 'ui.common')}
        </UiButton>
    );
}
