import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    background-color: var(--surface-level-1-bg);
    border: var(--surface-level-1-border);
    border-radius: var(--ui-radius);
    padding: var(--spacing-24);
    max-width: 450px;
    margin: 0 auto;
    @media ${mediaQuery.isPhone} {
        border: none;
        overflow: auto;
    }
    .sim-balance-info {
        background: #fff;
        color: var(--surface-level-1-bg);
        font-size: 0.75rem;
        padding: 0.375rem;
        height: 3.25rem;
        border-radius: calc(3.25rem / 2);
        
        .current-balance {
            font-weight: 700;
            font-size: 1.5rem;
            padding: 0 0.375rem;
        }

        label {
            display: block;
            text-align: center;
        }
    }
    .brand-logo {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        text-transform: uppercase;
        font-size: var(--font-10);
    }
    .account-menu-branding {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-16);
        margin-bottom: var(--spacing-16);
        display: none;
        @media ${mediaQuery.isPhone} {
            display: flex;
        }
        }
    }
    .account-info {
        display: flex;
        justify-content: center;
        gap: var(--spacing-4);
        width: 100%;
        padding: var(--spacing-12) 0;
    }

    .info-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: var(--spacing-4);
    
        color: var(--font-color-primary);
        text-align: center;
        cursor: pointer;
        .info-box-content {
            font-size: 1.5rem;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
            text-transform: uppercase;
            &.balance {
                color: var(--color-highlight);
            }
        }
    }

    .account-links-primary {
        display: flex;
        justify-content: center;
        gap: var(--spacing-8);
        padding: var(--spacing-16) var(--spacing-12);
        margin-top: var(--spacing-8);
    }
    .account-links {
        display: flex;
        flex-direction: column;
        padding: var(--spacing-24) var(--spacing-12);
        border-top: 1px dashed var(--surface-level-2-line-color);
        border-bottom: 1px dashed var(--surface-level-2-line-color);
        gap: 20px;
    }
    .auth-buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: var(--spacing-8);
    }
    .bonus-code-entry {
        width: 100%;
    }
`;
