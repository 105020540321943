import React, { useState } from 'react';
import UiDotsLoader from '../ui/dots-loader/UiDotsLoader';
import { useTimeout } from '../../services/hooks';

interface Props {
    className?: string;
    timeout?: number;
}
export default function Loader(props: Props) {
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);
    const { timeout = 200, ...rest } = props;

    useTimeout(() => {
        setIsLoaderVisible(true);
    }, timeout);

    if (!isLoaderVisible) {
        return null;
    }

    return <UiDotsLoader {...rest} />;
}
