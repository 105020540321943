import React, { useEffect } from 'react';
import Wrapper from './styles';

interface Props {
    snippetKey: string;
}

export default function SeoDescription({ snippetKey }: Props) {
    function readMoreButtonListener(event) {
        if (event.target.id == 'read-more') {
            event.preventDefault();
            event.target.parentElement?.nextElementSibling.classList.toggle('hidden');
            event.target.classList.toggle('active');
        }
    }

    useEffect(() => {
        document.addEventListener('click', readMoreButtonListener);

        return () => document.removeEventListener('click', readMoreButtonListener);
    }, []);

    return <Wrapper snippetKey={snippetKey} />;
}
