import React from 'react';
import Wrapper from './styles';
import { filterStyleProps } from '../../../styles/utils';
import { LinkProps } from 'react-router-dom';

interface Props extends React.PropsWithChildren, React.HTMLAttributes<HTMLLinkElement> {
    to?: string;
    exact?: boolean;
    $openNewTab?: boolean;
}

export default function UiNavLink({ $openNewTab, children, ...rest }: Props) {
    const linkProps: LinkProps = {};

    if ($openNewTab) {
        linkProps.target = '_blank';
    }

    return (
        <Wrapper {...filterStyleProps(rest)} {...linkProps}>
            {children}
        </Wrapper>
    );
}
