import React from 'react';
import UiButton from '../../../../ui/button/UiButton';

interface Props {
    title: string;
    onClick?: () => void;
}

export default function CrmMessagePreviewPopupButton({ title, onClick }: Props) {
    return (
        <UiButton color="primary" onClick={onClick} block>
            {title}
        </UiButton>
    );
}
