import { LocalStorage } from '../local-storage/types';
import { storageGet, storageSet } from '../storage';
import { MissingTranslationReport } from './types';

let translations = [];
let isReportingMissingTranslations = false;
let isMissingTranslationReportedByUniqueKey =
    storageGet<Record<string, boolean>>(LocalStorage.REPORTED_MISSING_TRANSLATIONS) || {};
let missingTranslationReports: MissingTranslationReport[] = [];

export const getTranslationHelpers = () => {
    function setTranslations(update) {
        translations = update;
    }
    function setIsReportingMissingTranslations(newValue: boolean) {
        isReportingMissingTranslations = newValue;
    }
    function setIsMissingTranslationReportedByUniqueKey(value: Record<string, boolean>) {
        isMissingTranslationReportedByUniqueKey = value;
        storageSet(LocalStorage.REPORTED_MISSING_TRANSLATIONS, isMissingTranslationReportedByUniqueKey);
    }
    function addMissingTranslationReport(value: MissingTranslationReport) {
        missingTranslationReports.push(value);
    }
    function setMissingTranslationReports(value: MissingTranslationReport[]) {
        missingTranslationReports = value;
    }

    return {
        addMissingTranslationReport,
        translations,
        setTranslations,
        isReportingMissingTranslations,
        setIsReportingMissingTranslations,
        isMissingTranslationReportedByUniqueKey,
        setIsMissingTranslationReportedByUniqueKey,
        missingTranslationReports,
        setMissingTranslationReports,
    };
};
