import styled from 'styled-components';

export default styled.div`
    position: relative;
    cursor: pointer;

    .wheel {
        width: 32px;
        height: 32px;
        background: url('/assets/images/sim/wheel.svg');
        transition: all 500ms ease;
        transform-origin: 50% 50%;

        &-text {
            position: absolute;
            bottom: 0;
            border: 1px solid var(--color-primary);
            border-radius: 10px;
            background: white;
            color: black;
            padding: 0 var(--spacing-4);
            font-size: var(--font-10);
            font-weight: var(--font-weight-medium);
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &:hover {
        .wheel {
            transform: rotate(360deg);
        }
    }
`;
