import styled, { css } from 'styled-components';
import { hideScrollBar } from '../../../styles/utils';

type StyledProps = {
    $layoutGutterInRem?: string;
    $expand?: boolean;
    $reversed?: boolean;
    $shouldOverflow?: boolean;
    $vertical?: boolean;
    $verticallyCentered?: boolean;
    $horizontallyCentered?: boolean;
};

const itemsGutter = (props) => props.$layoutGutterInRem || 'var(--spacing-4)';
const flexConfiguration = (props) => (props.$expand ? '1 1 auto' : '');
const flexFlowRow = (props) => (props.$reversed ? 'row-reverse nowrap' : 'row nowrap');
const flexFlowColumn = (props) => (props.$reversed ? 'column-reverse nowrap' : 'column nowrap');

const horizontal = (props: StyledProps) =>
    !props.$vertical &&
    !props.$shouldOverflow &&
    css`
        flex-flow: ${flexFlowRow};

        > * {
            flex: ${flexConfiguration};

            &:not(:last-child) {
                margin-${props.$reversed ? 'left' : 'right'}: ${itemsGutter};
            }
        }
    `;

const horizontalOverflow = (props: StyledProps) =>
    !props.$vertical &&
    props.$shouldOverflow &&
    css`
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        .UiGroup-items {
            display: flex;
            flex-flow: ${flexFlowRow};

            > * {
                flex: ${flexConfiguration};

                &:not(:last-child) {
                    margin-${props.$reversed ? 'left' : 'right'}: ${itemsGutter};
                }
            }
        }
    `;

const vertical = (props: StyledProps) =>
    props.$vertical &&
    !props.$shouldOverflow &&
    css`
        flex-flow: ${flexFlowColumn};

        > * {
            flex: ${flexConfiguration};

            &:not(:last-child) {
                margin-${props.$reversed ? 'top' : 'bottom'}: ${itemsGutter};
            }
        }
    `;

const verticalOverflow = (props: StyledProps) =>
    props.$vertical &&
    props.$shouldOverflow &&
    css`
        overflow: auto;

        .UiGroup-items {
            display: flex;
            flex-flow: ${flexFlowColumn};

            * {
                flex: ${flexConfiguration};

                &:not(:last-child) {
                    margin-bottom: ${itemsGutter};
                }
            }
        }
    `;

const verticallyCenteredColumn = (props: StyledProps) =>
    props.$verticallyCentered &&
    props.$vertical &&
    css`
        justify-content: center;
    `;

const verticallyCenteredRow = (props: StyledProps) =>
    props.$verticallyCentered &&
    !props.$vertical &&
    css`
        align-items: center;
    `;

const horizontallyCenteredColumn = (props: StyledProps) =>
    props.$horizontallyCentered &&
    props.$vertical &&
    css`
        align-items: center;
    `;

const horizontallyCenteredRow = (props) =>
    props.horizontallyCentered &&
    !props.vertical &&
    css`
        justify-content: center;
    `;

export default styled.div<StyledProps>`
    display: flex;

    ${hideScrollBar};

    ${horizontal};
    ${horizontalOverflow};
    ${vertical};
    ${verticalOverflow};

    ${verticallyCenteredColumn};
    ${verticallyCenteredRow};
    ${horizontallyCenteredColumn};
    ${horizontallyCenteredRow};
`;
