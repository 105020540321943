import { mediaQuery } from '../styles/utils';
import { getStoreValue } from '../stores/store/utils';
import { initialMediaStore, media } from '../stores/media/media';
import { useEffect } from 'react';
import { UAParser } from 'ua-parser-js';

export function useMediaQueryHooks() {
    useEffect(() => {
        for (const key of Object.keys(mediaQuery)) {
            window.matchMedia(mediaQuery[key]).addListener(() => {
                media.set(initialMediaStore());
            });
        }
    }, []);
}

export function isMobile() {
    const uaParser = new UAParser();

    return (
        (getStoreValue(media).isPhone || getStoreValue(media).isTablet) &&
        ['MOBILE', 'TABLET'].includes((uaParser.getDevice().type || 'DESKTOP').toUpperCase())
    );
}

export function isOldBrowser() {
    const { userAgent } = window.navigator;
    const hasMsie = userAgent.includes('MSIE ');
    const hasTrident = Boolean(userAgent.match(/Trident.*rv:11\./));

    return hasMsie || hasTrident;
}

export function getWidthToFitInContainer(selector: string) {
    const element = document.querySelector<HTMLElement>(selector)?.getBoundingClientRect();

    if (element?.width) {
        return element.width;
    }

    return 0;
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
