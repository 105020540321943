import React from 'react';
import { useStore } from '../../../../hooks/useStore';
import { isB2B } from '../../../../services/environment';
import { isFeatureAvailable } from '../../../../services/feature';
import { getRoute } from '../../../../services/router';
import { translate } from '../../../../services/translate';
import { FEATURE } from '../../../../services/types';
import { stores } from '../../../../stores';
import { media } from '../../../../stores/media/media';
import CrmInboxIcon from '../../../crm/inbox-icon/CrmInboxIcon';
import LoyaltyMeter from '../../../loyalty-meter/LoyaltyMeter';
import PaymentDepositButton from '../../../payment/deposit/button/PaymentDepositButton';
import ResponsibleGamingTimers from '../../../responsible-gaming/timers/ResponsibleGamingTimers';
import Svg from '../../../svg/Svg';
import UiButton from '../../../ui/button/UiButton';
import CoolbetHeaderAuthenticatedMenu from './menu/CoolbetHeaderAuthenticatedMenu';
import Wrapper from './styles';

export default function CoolbetHeaderAuthenticated() {
    const [{ isPhone }] = useStore(media);
    const [dynamicPromotionsCount] = useStore(stores.dynamicPromotionsCount);
    const isShowingClock =
        isFeatureAvailable(FEATURE.SESSION_DURATION) ||
        isFeatureAvailable(FEATURE.RESPONSIBLE_GAMING_LOGIN_DURATION_LIMIT);

    return (
        <Wrapper>
            {isShowingClock && <ResponsibleGamingTimers className="clock" />}

            {isFeatureAvailable(FEATURE.INBOX) && <CrmInboxIcon />}

            {!isB2B() && isPhone && (
                <UiButton
                    size="small"
                    className="header-button"
                    url={getRoute('promotions.list')}
                    badge={dynamicPromotionsCount}
                    icon={<Svg icon="bonus-promotions" />}
                />
            )}

            {isFeatureAvailable(FEATURE.LOYALTY_PROGRAM) && <LoyaltyMeter />}

            <CoolbetHeaderAuthenticatedMenu />

            {isFeatureAvailable(FEATURE.DEPOSIT) && (
                <PaymentDepositButton icon={<Svg icon="plus-sign" className="icon" size={1.125} />} />
            )}

            {isFeatureAvailable(FEATURE.PAYMENT_PACKAGES) && (
                <UiButton
                    color="primary"
                    size="small"
                    selected={false}
                    url={getRoute('purchase-packages')}
                    icon={<Svg icon="plus-sign" className="icon" size={1.125} />}
                >
                    {!isPhone && translate('Purchase packages', 'ui.payment-packages')}
                </UiButton>
            )}
        </Wrapper>
    );
}
