import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import stackTrace from 'stack-trace';
import { getTranslationHelpers } from './translation/helpers';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { LANGUAGE } from './language/types';
import { logger } from './logger';
import { MissingTranslationReport, TranslateKey } from './translation/types';

export function translate(
    key: TranslateKey,
    context?: string,
    replacements?: any[] | Record<string, string | number | Date>,
) {
    if (!key) {
        logger.error('TranslateService', 'translate', `Translation key missing ${context}`);
        return '';
    }
    let fallback = '';
    let text = '';

    if (isString(key)) {
        key = key.trim();
        fallback = key;
    } else if (Array.isArray(key)) {
        fallback = key[1];
        key = key[0];
    } else {
        return '';
    }
    const { translations } = getTranslationHelpers();

    try {
        if (context) {
            key = kebabCaseify(key);
            key = `${context}.${key}`;
        }

        key = key.slice(0, 126); // Max translation key length in l10n service is 127 characters
        text = translations[key];

        if (!text && text !== '') {
            // TODO temporary workaround because "stack-trace" doesn't work properly with FF
            try {
                reportMissingTranslation(key, fallback);
            } catch (error) {
                logger.error('TranslateService', 'translate', `Error translating ${key} ${context}`);
                logger.error('TranslateService', 'translate', error);
            }
        }

        const textOrFallback = text || fallback;

        if (textOrFallback && replacements) {
            text = interpolate(textOrFallback, replacements);
        }
    } catch (error) {
        logger.error('TranslateService', 'translate', `Error translating ${key} ${context}`);
        logger.error('TranslateService', 'translate', error);
    }

    return text || fallback || '???';
}

export function interpolate(text: string, replacements) {
    /*
        EXAMPLES of "interpolate" usages

        Old format:
        text = 'My name is %1, I am %2 years old and I work at %3';
        replacements = ['CoolBear', 2, 'Coolbet'];

        New format:
        text = 'My name is {{ name }}, I am {{ age }} years old and I work at {{ workplace }}';
        replacements = {
            name: 'CoolBear',
            age: 2,
            workplace: 'Coolbet',
        };

        Note:
        The new format matches interpolations within the string in any of the following form:
        '{{ name }}', '{{name }}', '{{ name}}', '{{name}}'
    */

    if (Array.isArray(replacements)) {
        replacements.forEach((replacementValue, replacementIndex) => {
            text = text.replace(`%${replacementIndex + 1}`, replacementValue);
        });
    }

    if (isPlainObject(replacements)) {
        const reg = /{{\s*([^ ]+)\s*}}/g;
        text = text.replace(reg, (match, p1) => get(replacements, p1, '') as string);
    }

    return text;
}

function kebabCaseify(text: string) {
    return text.toLowerCase().replace(/[ .,!?&^%$#'"/<>()+@]/gi, '-');
}

function reportMissingTranslation(key: string, fallback: string) {
    const {
        addMissingTranslationReport,
        isMissingTranslationReportedByUniqueKey,
        setIsMissingTranslationReportedByUniqueKey,
    } = getTranslationHelpers();
    const isSportsBookCategoryKey = key.startsWith('sb.category') && key.endsWith('slug');
    const isSeoKey = key.startsWith('seo.') && (!fallback || key === fallback);
    const isSportsMarketConcreteKey = key.startsWith('sb.market-concrete');
    const isCbOutcomeKey = key.startsWith('cb.outcome');
    const isSbOutcomeKey = key.startsWith('sb.outcome');
    const isSbMarketTypeKey = key.startsWith('sb.market-type.');
    const isSbLivebetKey = key.startsWith('ui.sportsbook.lb.');
    const isCasinoGameKey = key.startsWith('casino.game.');

    if (
        !key ||
        isSportsBookCategoryKey ||
        isSeoKey ||
        isSportsMarketConcreteKey ||
        isCbOutcomeKey ||
        isSbOutcomeKey ||
        isSbMarketTypeKey ||
        isSbLivebetKey ||
        isCasinoGameKey ||
        key.includes('undefined')
    ) {
        return;
    }

    const trace = stackTrace.get();
    key = key.substr(0, 126);

    const translateIndex: number = trace.findIndex((callSite) => callSite.getFunctionName() === 'translate');
    const location = trace[translateIndex + 1].getFunctionName();

    const missingTranslationReport: MissingTranslationReport = {
        key,
        path: window.location.pathname,
        lang: getStoreValue(stores.language) || LANGUAGE.ENGLISH,
        location: String(location) || '',
    };

    if (fallback && key !== fallback) {
        missingTranslationReport.fallback = fallback;
    }

    const uniqueReportKey = `${missingTranslationReport.lang}${missingTranslationReport.key}${missingTranslationReport.ng_url}`;

    if (!isMissingTranslationReportedByUniqueKey[uniqueReportKey]) {
        setIsMissingTranslationReportedByUniqueKey({
            ...isMissingTranslationReportedByUniqueKey,
            [uniqueReportKey]: true,
        });
        addMissingTranslationReport(missingTranslationReport);
    }
}
