import moment from 'moment';
import { geocomply } from '../../stores/geocomply';
import { logger } from '../logger';
import { geocomplyInitialLicense, geocomplyInitialServiceError } from './constants';
import { GeocomplyLogMessage, LOG_LEVEL } from './types';

export async function attemptWithRetry<T>(
    retryErrorCodes: Array<number>,
    knownErrorCodes: Array<number>,
    promiseFactory: (attemptIndex: number, totalAttempts: number) => Promise<T>,
    totalAttempts = 20,
    retryDelayMs = 2000,
) {
    let currentError;
    let attemptsLeft = totalAttempts;

    while (attemptsLeft > 0) {
        const attemptIndex = totalAttempts - attemptsLeft + 1;

        try {
            return await promiseFactory(attemptIndex, totalAttempts);
        } catch (error: any) {
            if (!knownErrorCodes.includes(parseInt(error.code))) {
                geocomply.unexpectedError.set(error);
                throw error;
            }

            const isLastAttempt = attemptIndex === totalAttempts;

            if (!retryErrorCodes.includes(error.code) || isLastAttempt) {
                geocomply.client.set((geocomplyClient) => {
                    geocomplyClient.clientError = { code: error.code, message: error.message };
                });

                throw error;
            }

            currentError = error;
            await new Promise((resolve) => setTimeout(resolve, retryDelayMs));
            attemptsLeft--;
        }
    }

    throw currentError;
}

export function geocomplyClientErrorHandler(errorCode, errorMessage) {
    logger.error('GeocomplyHelpersService', 'geocomplyClientErrorHandler', `${errorCode} | ${errorMessage}`);
    logMessage(`${errorCode} | ${errorMessage}`, 'ERROR');

    geocomply.client.set((geocomplyClient) => {
        geocomplyClient.clientError = null;
        geocomplyClient.clientVersion = null;
        geocomplyClient.isConnected = false;
        geocomplyClient.isConnecting = false;
        geocomplyClient.isGeolocating = false;
    });

    if (errorCode === 608) {
        geocomply.serviceError.set(geocomplyInitialServiceError);
        geocomply.license.set(geocomplyInitialLicense);
    }
}

export function logMessage(message: string, level: LOG_LEVEL) {
    return geocomply.client.set((geocomplyClient) => {
        const newLogMessage: GeocomplyLogMessage = {
            time: moment().toISOString(),
            message,
            level,
        };

        geocomplyClient.logs = [...geocomplyClient.logs, newLogMessage];
    });
}

export const startDurationTimer = () => {
    const unixStartTime = new Date().getTime();

    const getHumanizedDuration = (durationInMs: number): string => {
        if (durationInMs < 1000) {
            return `${durationInMs}ms`;
        }

        if (durationInMs < 60000) {
            const seconds = Math.floor(durationInMs / 1000);
            const milliseconds = durationInMs - seconds * 1000;

            return `${seconds}sec ${milliseconds}ms`;
        }

        const minutes = Math.floor(durationInMs / 60000);
        const seconds = (durationInMs - minutes * 60000) / 1000;

        return `${minutes}min  ${seconds}sec`;
    };

    return () => {
        const durationInMs = new Date().getTime() - unixStartTime;

        return {
            milliseconds: durationInMs,
            humanized: getHumanizedDuration(durationInMs),
        };
    };
};
