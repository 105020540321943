import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { stores } from '../../../../stores';
import { media } from '../../../../stores/media/media';
import { getStoreValue } from '../../../../stores/store/utils';
import { changeLanguage } from '../../../../services/language';
import UiLanguageSelectMini from '../../../ui/language-select/mini/UiLanguageSelectMini';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    size?: number;
}

export default function CoolbetHeaderLanguages(
    props: Props & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) {
    const [language] = useStore(stores.language);
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const [{ isPhone }] = useStore(media);

    return (
        <UiLanguageSelectMini
            {...props}
            currentLanguage={language}
            shift={isPhone ? 'left' : isAuthenticated ? 'center' : 'left'}
            onValueChange={changeLanguage}
        />
    );
}
