import React from 'react';
import { filterStyleProps } from '../../../../styles/utils';
import Wrapper from './styles';

interface Props extends React.ComponentPropsWithoutRef<typeof Wrapper> {
    expand?: boolean;
}

export default function UiFormGroup({ children, ...rest }: Props) {
    return <Wrapper {...filterStyleProps(rest)}>{children}</Wrapper>;
}
