import React from 'react';
import Wrapper from './styles';
import UiFormLabel from '../form/label/UiFormLabel';
import { UseFormRegisterReturn } from 'react-hook-form';

interface Props extends React.HTMLProps<HTMLInputElement> {
    label?: string;
    justify?: 'spaced' | 'center' | 'left' | 'right';
    direction?: 'row' | 'column';
    reverse?: boolean;
    nowrap?: boolean;
    dataTest?: string;
    registerReturn?: UseFormRegisterReturn<string>;
    className?: string;
}

export function UiToggle({
    label,
    reverse,
    direction,
    justify,
    dataTest,
    registerReturn,
    nowrap,
    className,
    ...args
}: Props) {
    return (
        <Wrapper $reverse={reverse} $direction={direction} $justify={justify} $nowrap={nowrap} className={className}>
            {label && <UiFormLabel>{label}</UiFormLabel>}
            <label className="pseudo-toggle-container">
                <input {...args} {...registerReturn} type="checkbox" data-test={dataTest} />
                <span className="pseudo-toggle" />
            </label>
        </Wrapper>
    );
}
