import keyBy from 'lodash/keyBy';
import { codex } from '../../microservices/cms';
import { marketing } from '../../stores/marketing';
import { logger } from '../logger';
import { CategoryMatch } from '../sports/types';
import { getUserCountry } from '../users/country';
import { OddPromotion, PromotionTypes } from '../cms-promotion';
import { loadOddsForFoLineQueue } from '../sports/odds-line';

// eslint-disable-next-line import/no-unused-modules
export async function populateMatchPromotionsByMatches(matches: CategoryMatch[]) {
    if (!matches.length) {
        return;
    }
    const matchPromotions = await loadMatchPromotions(matches);
    if (!matchPromotions) {
        return;
    }
    const newPromotions = matchPromotions
        .map((promotion) => {
            const match = matches.find((match) => match.id === promotion?.match_id);
            return {
                ...promotion,
                sport_id: match?.sport,
                match_betting_end: match?.betting_end,
            };
        })
        .filter(Boolean);
    const newPromotionsByMatchId = keyBy(newPromotions, (x) => x.match_id);
    marketing.matchPromotionsByMatchIds.set((state) => ({ ...state, ...newPromotionsByMatchId }));
    newPromotions
        .filter((promotion): promotion is OddPromotion => promotion.type === 'odd')
        .map(({ market }) => loadOddsForFoLineQueue([[market.id]]));
}

async function loadMatchPromotions(matches: CategoryMatch[]) {
    const matchIds = matches.flatMap((match) => match.id);
    const country = getUserCountry();
    try {
        return await codex(matchIds, country);
    } catch (error) {
        logger.error('CmsPromotionService', 'loadMatchPromotions', error);
        return matches;
    }
}

export function getPromotionAnalyticsInfo(promotion: PromotionTypes) {
    return {
        sports: promotion.sport_id,
        country: getUserCountry(),
        type: promotion.type,
        name: promotion.name,
    };
}
