import React from 'react';
import asyncComponent from '../../../async-component/AsyncComponent';

const PaymentQuickDeposit = asyncComponent(
    () => import('../PaymentQuickDeposit' /* webpackChunkName: 'PaymentQuickDeposit'*/),
    { isSilent: true },
);

export default function PaymentQuickDepositModal(props) {
    return <PaymentQuickDeposit {...props} />;
}
