import 'core-js/actual/set-immediate';
import 'core-js/actual/array/is-array';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Router } from 'react-router-dom';
import BootstrapPage from './pages/bootstrap/BootstrapPage';
import Redirects from './components/redirects/Redirects';
import 'animate.css/animate.min.css';
import { createBrowserHistory } from 'history';

console.info(`[FO] version: ${process.env.REACT_APP_VERSION || 'development'}`);
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const history = createBrowserHistory();
const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
    <Router basename="/" history={history}>
        <div data-version={process.env.REACT_APP_VERSION || 'development'}>
            <Redirects />
            <BootstrapPage />
        </div>
    </Router>,
);
