import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import Loader from '../loader/Loader';
import FourOFour from '../four-o-four/FourOFour';
import { getHeaderElementsFromHtml } from '../../services/cms';
import { stores } from '../../stores';
import { logger } from '../../services/logger';
import { useHeaderElementsByContent, useTimeout } from '../../services/hooks';
import { getUserCountry } from '../../services/users/country';
import { Country } from '@staycool/location';
import UiBox from '../ui/box/UiBox';
import { translate } from '../../services/translate';
import CmsDomToReact from './dom-to-react/CmsDomToReact';
import { getArticleBySlug, getArticleForPreviewBySlug } from '../../microservices/cms';
import { loadCasinoJackpots } from '../../microservices/casino';
import ScriptLoader from '../script-loader/ScriptLoader';
import { useStore } from '../../hooks/useStore';
import AuthLoginModal from '../auth/login/modal/AuthLoginModal';

interface Props {
    pageSlug: string;
    onPageLoaded?: (page) => void;
    isPreviewRequest?: boolean;
}

export default function Cms({ onPageLoaded = () => {}, pageSlug, isPreviewRequest }: Props) {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [language] = useStore(stores.language);

    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState<any>();
    const [isJqueryLoaded, setIsJqueryLoaded] = useState(false);
    const [headerElements, setHeaderElements] = useState<any[]>([]);
    const [isLoadingFailed, setIsLoadingFailed] = useState(false);
    const isUserFromFinlandAndLoggedOutAndCampaign =
        getUserCountry() === Country.FINLAND && !isAuthenticated && page?.category === 'campaign';

    useEffect(() => {
        initializePage();
        return resetComponent;
    }, [pageSlug, isAuthenticated]);

    useEffect(() => {
        if (page && isJqueryLoaded && !isLoading) {
            setHeaderElements(getHeaderElementsFromHtml(page?.body));
            onPageLoaded(page);
        }
    }, [page, isJqueryLoaded, isLoading]);
    useHeaderElementsByContent(headerElements);

    useEffect(() => {
        if (isUserFromFinlandAndLoggedOutAndCampaign) {
            stores.modals.isLoginModalOpen.set(true);
        }
    }, [isUserFromFinlandAndLoggedOutAndCampaign]);

    async function initializePage() {
        try {
            const cmsPage = isPreviewRequest
                ? await getArticleForPreviewBySlug(pageSlug, language)
                : await getArticleBySlug(pageSlug, language);
            loadCasinoJackpots();
            setPage(cmsPage);
        } catch (error) {
            logger.error('Cms', 'initializePage', error);
            setIsLoadingFailed(true);
        }
    }

    function resetComponent() {
        setPage(undefined);
        setIsLoadingFailed(false);
    }

    useTimeout(() => {
        setIsLoading(false);
    }, 600);

    const shouldDisplayLoader = (!isLoadingFailed && !(page && isJqueryLoaded)) || isLoading;
    const shouldDisplayContent = !isLoadingFailed && page && isJqueryLoaded && !isLoading;
    const shouldDisplayFourOFour = isLoadingFailed && !isLoading;

    return (
        <Wrapper $isUserFromFinlandAndLoggedOutAndCampaign={isUserFromFinlandAndLoggedOutAndCampaign}>
            {isUserFromFinlandAndLoggedOutAndCampaign && (
                <UiBox className="logged-out-finnish-users-message">
                    <div>{translate('ui.common.login-to-see-offers')}</div>
                </UiBox>
            )}

            <div className="cms-content">
                <ScriptLoader onLoad={() => setIsJqueryLoaded(true)} url="/lib/jquery-2.2.4.min.js" />
                {!isAuthenticated && <AuthLoginModal />}
                {shouldDisplayLoader && (
                    <div className="loader-container">
                        <Loader />
                    </div>
                )}
                {shouldDisplayContent && <CmsDomToReact html={page.body} />}
                {shouldDisplayFourOFour && <FourOFour />}
            </div>
        </Wrapper>
    );
}
