import React, { useEffect } from 'react';
import { isMobileApp } from '../../../../services/mobile-app';
import { getRoute, useRouter } from '../../../../services/router';
import { stores } from '../../../../stores';
import { isFeatureAvailable } from '../../../../services/feature';
import { useStore } from '../../../../hooks/useStore';
import { FEATURE } from '../../../../services/types';
import asyncComponent from '../../../async-component/AsyncComponent';

const AuthLoginModalContent = asyncComponent(
    () => import('./content/AuthLoginModalContent' /* webpackChunkName: 'AuthLoginModalContent'*/),
    { isSilent: true },
);

export default function AuthLoginModal() {
    const [, setAppealId] = useStore(stores.appeal.id);
    const [isLoginModalOpen, setIsLoginModalOpen] = useStore(stores.modals.isLoginModalOpen);
    const { navigateTo } = useRouter();

    function onClose() {
        setAppealId('');
        setIsLoginModalOpen(false);
    }

    useEffect(() => {
        if (isLoginModalOpen) {
            if (isFeatureAvailable(FEATURE.UNIFIED_LOGIN_AND_REGISTER) && !isMobileApp()) {
                onClose();
                navigateTo(getRoute('registration-btob'));
            }
        }
    }, [isLoginModalOpen]);

    if (isMobileApp() || isFeatureAvailable(FEATURE.UNIFIED_LOGIN_AND_REGISTER)) {
        return null;
    }

    return <AuthLoginModalContent onClose={onClose} />;
}
