import { getStoreValue } from '../stores/store/utils';
import { stringify } from 'query-string';
import { environment } from '../stores/environment/environment';

interface GetCmsImageUrlType {
    fileName: string;
    params?: object;
    postfix?: string;
}

export function getCmsImageUrl({ fileName, params = {}, postfix }: GetCmsImageUrlType) {
    const parsedFileName = fileName?.replace(/https:\/\/www\.coolbet\.com/g, '');

    if (parsedFileName.startsWith('http') || parsedFileName.startsWith('/c/assets')) {
        return parsedFileName;
    }

    const imageHost = getStoreValue(environment).CMS_IMAGES;
    const image = getImageUrl(`${imageHost}${parsedFileName}`, params);

    return `${image}${postfix}`;
}

function getImageUrl(rootPath, params = {}) {
    let dpr = window.devicePixelRatio;
    if (dpr > 2) {
        dpr = 2;
    }
    const queryParams = stringify({ ...params, dpr });
    return `${rootPath}?${queryParams}`;
}
