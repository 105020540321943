import styled, { css } from 'styled-components';

type StyledProps = {
    size?: number;
};

export default styled.div<StyledProps>`
    ${({ size }) =>
        css`
            img {
                display: block;
                height: ${size}px;
            }
        `}
`;
