import styled from 'styled-components';

export default styled.div`
    position: relative;

    flex: 1;
    color: var(--mobile-nav-tab-text-color);
    > * {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        height: var(--mobile-nav-height);

        text-decoration: none;
        font-size: 0.75rem;
        color: var(--mobile-nav-tab-text-color);

        &.active {
            background: var(--mobile-nav-tab-bg-active);
            color: var(--mobile-nav-tab-text-color-active);
            border-top: 1px solid var(--color-highlight);
        }
    }

    label {
        margin-top: 0.5rem;
        max-width: 97%;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
    }

    .badge {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: -0.85rem;

        transform: translate(80%, 0);
        height: 1.3rem;
        width: 1.3rem;

        background-color: var(--mobile-nav-tab-badge-bg);
        border-radius: 100px;
        border: var(--mobile-nav-tab-badge-border);

        color: var(--mobile-nav-tab-badge-text-color);
        font-size: 0.6rem;
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering};
    }
`;
