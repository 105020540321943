import React from 'react';
import Wrapper from './styles';
import { getClient } from '../../services/environment';
import { GlobalStyles } from '../../theme/global';
import MobileWebCheckBanner from '../mobile-web-check-banner/MobileWebCheckBanner';
import { environment } from '../../stores/environment/environment';
import { useStore } from '../../hooks/useStore';

export default function DesktopBlocked() {
    const [{ IMAGES_HOST }] = useStore(environment);
    const client = getClient();

    return (
        <Wrapper background={`${IMAGES_HOST}${client}/desktop-blocked.jpg`}>
            <GlobalStyles />
            <div className="desktop-blocked">
                <div className="app-not-accessible-description">
                    <MobileWebCheckBanner forceShow />
                </div>
            </div>
        </Wrapper>
    );
}
