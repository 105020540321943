import { useEffect } from 'react';
import {
    initGoogleTagManager,
    TAG_MANAGER_EVENT,
    trackFacebookPixelEvent,
    trackGoogleTagManagerEvent,
    updateGoogleTagManager,
} from '../../../services/analytics';
import { logger } from '../../../services/logger';
import { useRouter } from '../../../services/router';
import { storageGet, storageRemove } from '../../../services/storage';
import { stores } from '../../../stores';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

export default function SystemAnalytics() {
    const { success, pending } = useRouter().queryParams;
    const [user] = useStore(stores.user);
    const message = success || pending;
    const [{ isPhone }] = useStore(media);
    const [applicationType] = useStore(stores.applicationType);

    useEffect(() => {
        const isPokerClient = applicationType === 'poker-download';
        if (!isPokerClient) {
            initGoogleTagManager();
        }

        if (isPhone) {
            trackMobileUserMatchViewSettings();
        }
        trackMatchOfTheDayView();
        sendSuccessfulDeposit(message);
    }, []);

    useEffect(() => {
        updateGoogleTagManager({
            userId: user && user.id,
            country: user && user.country,
            visitorLoginState: user ? 'logged-in' : 'logged-out',
        });

        if (window.FS && user) {
            window.FS('setIdentity', {
                uid: user.id,
            });
        }
    }, [user]);

    function sendSuccessfulDeposit(message) {
        const regexes = [/^your[- ]deposit[- ]is[- ]pending/i, /^your[- ]deposit[- ]was[- ]successful/i];
        if (regexes.some((regex) => String(message).match(regex))) {
            const deposit = storageGet<{ amount: number; currency: string }>('payment.deposit');
            if (!deposit) {
                return;
            }
            const { amount, currency } = deposit;
            if (!amount || !currency) {
                return;
            }
            try {
                trackFacebookPixelEvent('Purchase', { value: amount, currency });
                storageRemove('payment.deposit');
            } catch (error) {
                logger.error('SystemAnalytics', 'sendSuccessfulDeposit', error);
            }
        }
    }

    function trackMobileUserMatchViewSettings() {
        const isCompactView = storageGet('isCompactView');
        if (isCompactView) {
            trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.SPORT_MATCH_IN_COMPACT_VIEW);
        } else {
            trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.SPORT_MATCH_IN_EXPANDED_VIEW);
        }
    }

    function trackMatchOfTheDayView() {
        const isExpandView = storageGet('matchOfTheDayVisible');
        if (isExpandView) {
            trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.SPORT_MOTD_IN_EXPANDED_VIEW);
        } else {
            trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.SPORT_MOTD_IN_COMPACT_VIEW);
        }
    }
    return null;
}
