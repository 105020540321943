import styled from 'styled-components';
import UiBox from '../../box/UiBox';
import { mediaQuery } from '../../../../styles/utils';

export default styled(UiBox)`
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    background-color: transparent;

    & .blurred-message {
        position: absolute;
        z-index: 4;
        top: 100px;
        left: 50%;
        transform: translate(-50%, -50%);

        @media ${mediaQuery.isTablet} {
            top: 75px;
        }

        @media ${mediaQuery.isPhone} {
            top: 75px;
        }
    }
`;
