import moment from 'moment';
import { getTranslationHelpers } from './helpers';
import { LANGUAGE, Language } from '../language/types';
import { translate } from '../translate';
import { getFoTranslations, getIncrementalTranslationUpdates } from '../../microservices/cms';
import { logger } from '../logger';
import { localeOfLanguage, momentJsLanguageByApplicationLanguage } from '../language/constants';
import { storageSet } from '../storage';
import { LocalStorage } from '../local-storage/types';
import merge from 'lodash/merge';

export async function setLanguage(language: Language) {
    const { setTranslations } = getTranslationHelpers();
    const locales = {
        ca: 'en-CA',
        us: 'en-US',
        en: 'en-GB',
        cl: 'es-CL',
        ec: 'es-EC',
        mx: 'es-MX',
        pe: 'es-PE',
        et: 'et',
        ru: 'ru',
        fi: 'fi-FI',
        sv: 'sv-SE',
        is: 'is',
    };
    let fallbackLanguage;
    switch (language) {
        case LANGUAGE.PERUVIAN:
        case LANGUAGE.ECUADORIAN:
        case LANGUAGE.MEXICAN:
            fallbackLanguage = LANGUAGE.DEFAULT_LATAM;
            break;
        default:
            break;
    }

    document?.querySelector('html')?.setAttribute('lang', locales[language] || locales['en']);
    const response = await loadFoTranslations(language, fallbackLanguage);
    const update = await mergeIncrementalTranslationUpdate(
        language,
        fallbackLanguage,
        response.lastUpdated,
        response.translationsByKey,
    );
    setTranslations(update);
    storageSet(LocalStorage.LANGUAGE, language);

    moment.locale(localeOfLanguage[language]); // Changes moment.js global language

    moment.updateLocale(momentJsLanguageByApplicationLanguage[language], {
        durationLabelsStandard: {
            s: translate('second', 'ui.duration'),
            ss: translate('seconds', 'ui.duration'),
            m: translate('minute', 'ui.duration'),
            mm: translate('minutes', 'ui.duration'),
            h: translate('hour', 'ui.duration'),
            hh: translate('hours', 'ui.duration'),
            d: translate('day', 'ui.duration'),
            dd: translate('days', 'ui.duration'),
        } as any,
    });
}

async function loadFoTranslations(language: Language, fallbackLanguage: Language) {
    try {
        return await getFoTranslations(language, fallbackLanguage);
    } catch (error) {
        logger.error('TranslationLanguageSwitchService', 'loadFoTranslations', error);
        return { translationsByKey: {}, lastUpdated: null };
    }
}

async function mergeIncrementalTranslationUpdate(
    language: Language,
    fallbackLanguage: Language,
    lastUpdated: string | null,
    translations,
) {
    const lastUpdatedUnixTime = new Date(lastUpdated as string).getTime();

    try {
        if (lastUpdatedUnixTime) {
            const incrementalTranslationUpdates = await getIncrementalTranslationUpdates(
                language,
                fallbackLanguage,
                lastUpdatedUnixTime.toString(),
            );
            return merge({}, translations, incrementalTranslationUpdates);
        }
        return translations;
    } catch (error) {
        logger.error('TranslationLanguageSwitchService', 'mergeIncrementalTranslationUpdate', 'error');
        return translations;
    }
}
