import styled from 'styled-components';

export default styled.div`
    text-align: center;

    h4 {
        color: #fff;
        font-size: 0.625rem;
        margin: 0 0 0.25rem;
        font-weight: 900;
    }

    .timer-values,
    .timer-section {
        display: flex;
    }

    .timer-values {
        gap: 0.25rem;
        max-width: 12rem;
        margin: 0 auto;
    }

    .timer-section {
        flex-direction: column;
        border-radius: 0.25rem;
        border: 1px solid #fff;
        flex: 1;
        align-items: center;
        padding: 0.375rem 0;
        font-weight: 700;

        :first-child {
            color: #fff;
            font-size: 1rem;
            gap: 0.125rem;

            & + span {
                font-size: 0.5625rem;
                opacity: 0.65;
            }
        }
    }
`;
