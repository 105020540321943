import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { getCoolbetTokenFromPoker, initGame } from '../microservices/poker-playtech-gameslink';
import { getFromConfigByLicenseCountry } from './casino/casino';
import { storageGet, storageRemove, storageSet } from './storage';
import { removeToken } from './token';
import { setTokenAndAuthenticate } from './auth';
import { getOS, OPERATING_SYSTEM } from './device';
import { getLicence } from './licence';
import { toast } from 'react-toastify';
import { translate } from './translate';
import { logger } from './logger';
import { Country, Licence } from '@staycool/location';
import { environment } from '../stores/environment/environment';
import { STORAGE_KEY_TOKEN } from './types';
import { isUserFromCountry } from './users/country';
import { AppType } from './mobile-app/types';
import { ObjectValues } from './ts-utils';

export enum POKER_CLIENT_TYPE {
    HTML5 = 'HTML5',
    DOWNLOAD = 'DOWNLOAD',
    ANDROID = 'ANDROID',
    IOS = 'IOS',
    MAC_DOWNLOAD = 'MAC_DOWNLOAD',
}

export function getClientType(): POKER_CLIENT_TYPE {
    if (getOS() === OPERATING_SYSTEM.WINDOWS_DESKTOP) {
        return POKER_CLIENT_TYPE.DOWNLOAD;
    }
    if (getOS() === OPERATING_SYSTEM.APPLE_MOBILE) {
        return POKER_CLIENT_TYPE.IOS;
    }
    if (getOS() === OPERATING_SYSTEM.APPLE_DESKTOP) {
        return POKER_CLIENT_TYPE.MAC_DOWNLOAD;
    }
    return POKER_CLIENT_TYPE.ANDROID;
}

export async function openPokerWeb(appType: AppType, tournamentCode?: number): Promise<void> {
    if (!getStoreValue(stores.isAuthenticated)) {
        stores.modals.isLoginModalOpen.set(true);
        return;
    }

    const windowReference = window.open();

    try {
        const game = await initGame({ appType, isWebClient: true, tournamentCode });
        if (game?.gameLaunchUrl && windowReference) {
            windowReference.location = game.gameLaunchUrl;
        } else {
            toast.warn(translate('Poker launch failed, please contact customer support', 'ui.poker'));
        }
    } catch (error) {
        logger.error('PokerService', 'openPokerWeb', error);
        toast.error(translate('Poker launch failed, please contact customer support', 'ui.poker'));
    }
}

const APPLE_STORE_LINKS = {
    // TODO: better solution
    [Licence.ESTONIA]: 'itms-apps://apps.apple.com/us/app/coolbet-pokker-eesti/id1515446404?ls=1',
    [Licence.MALTA]: 'itms-apps://apps.apple.com/us/app/coolbet-poker/id1515450841?ls=1',
    [Licence.SWEDEN]: 'itms-apps://apps.apple.com/us/app/coolbet-poker-sverige/id1515453486?ls=1',
};

const MAC_APP_STORE_LINKS = {
    [Licence.ESTONIA]: 'https://apps.apple.com/us/app/coolbet-ee-poker/id1562125530',
    [Licence.MALTA]: 'https://apps.apple.com/us/app/coolbet-com-poker/id1562125156',
    [Licence.SWEDEN]: 'https://apps.apple.com/us/app/coolbet-se-poker/id1562125961',
};

export function openAppleAppStoreLink() {
    window.location.href = APPLE_STORE_LINKS[getLicence()];
}

export function startPokerClientDownload() {
    window.location.href = getFromConfigByLicenseCountry(
        getStoreValue(environment).POKER_PLAYTECH?.WINDOWS_DOWNLOAD_CLIENT,
    );
}

export function startPokerAndroidAppDownload() {
    window.location.href = getFromConfigByLicenseCountry(getStoreValue(environment).POKER_PLAYTECH?.ANDROID_APP);
}

export function startPokerMacClientDownload() {
    if (isUserFromCountry(Country.NORWAY) || isUserFromCountry(Country.CANADA)) {
        const link = getStoreValue(environment).POKER_PLAYTECH?.MAC_DOWNLOAD_CLIENT?.MALTA;
        if (link) {
            window.location.href = link;
            return;
        }
    }
    window.open(MAC_APP_STORE_LINKS[getLicence()]);
}

export function closeWindow() {
    window.location.assign('htcmd:close');
}

export async function tryCoolbetLoginFromPoker(): Promise<void> {
    const { loginSessionId, token } = getPokerSessionCredentials();
    if (loginSessionId && token) {
        try {
            removeToken();
            const coolbetToken = await getCoolbetTokenFromPoker(loginSessionId, token);
            await setTokenAndAuthenticate(coolbetToken);
        } catch (e) {
            cleanPokerSessionCredentials();
        }
    }
}

enum PokerCredentialKeys {
    POKER_LOGIN_SESSION_ID = 'pokerLoginSessionId',
    POKER_TOKEN = 'pokerToken',
}

export function setPokerSessionCredentials(loginSessionId: string): void {
    storageSet(PokerCredentialKeys.POKER_LOGIN_SESSION_ID, loginSessionId);
    storageSet(PokerCredentialKeys.POKER_TOKEN, storageGet(STORAGE_KEY_TOKEN));
}

function getPokerSessionCredentials(): PokerSessionCredentials {
    return {
        loginSessionId: storageGet(PokerCredentialKeys.POKER_LOGIN_SESSION_ID),
        token: storageGet(PokerCredentialKeys.POKER_TOKEN),
    };
}

function cleanPokerSessionCredentials(): void {
    storageRemove(PokerCredentialKeys.POKER_LOGIN_SESSION_ID);
    storageRemove(PokerCredentialKeys.POKER_TOKEN);
}

interface PokerSessionCredentials {
    loginSessionId: string;
    token: string;
}

export function getPokerDownloadLink(): (() => void) | undefined {
    if (getOS() === OPERATING_SYSTEM.LINUX) {
        return;
    }

    if (getOS() === OPERATING_SYSTEM.APPLE_DESKTOP) {
        return startPokerMacClientDownload;
    }
    if (getOS() === OPERATING_SYSTEM.WINDOWS_DESKTOP) {
        return startPokerClientDownload;
    }
    if (getOS() === OPERATING_SYSTEM.APPLE_MOBILE) {
        return openAppleAppStoreLink;
    }
    return startPokerAndroidAppDownload;
}

export const POKER_ARTICLE_SLUG = {
    CASHIER: 'cashier',
    ANNUAL_REPORT: 'annual-report',
    AUTH: 'auth',
    AUTH_EE: 'auth-ee',
    AUTH_MT: 'auth-mt',
    AUTH_SE: 'auth-se',
    AUTH_MX: 'auth-mx',
    AUTH_PE: 'auth-pe',
    SET_LIMITS: 'set-limits',
} as const;

export type PokerArticleSlug = ObjectValues<typeof POKER_ARTICLE_SLUG>;
