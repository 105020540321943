import { LoyaltyProgress } from '../microservices/loyalty/types';
import { createGlobalStore } from './store/utils';

const initialStore: LoyaltyProgress = {
    level: {
        level: 0,
        percent: 0,
        currentLevelXp: 0,
        xpToNextLevel: 0,
    },
    tier: '',
    id: '',
};

export const loyaltyStore = createGlobalStore({ ...initialStore }, 'loyaltyStore');
