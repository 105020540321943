import styled from 'styled-components';
import { ClientName } from '../../../services/utils/types';

export default styled.header`
    background: var(--color-header-bg);
    box-shadow: var(--ui-shadow);
    padding: 0 0.8rem;

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: ${({ theme }) => theme.header.height};
        max-width: 1920px;
        margin: 0 auto;
    }

    .header-logo {
        margin: 0 0.8rem;
        img {
            width: var(--header-logo-width);
        }
    }

    .header-products-nav {
        display: flex;
        align-items: center;
        flex-grow: 1;

        margin-left: 0.8rem;
        > *:not(:last-child) {
            margin-right: 2rem;
        }
        a > * {
            color: var(--header-color);
            &.active,
            &:hover {
                color: var(--header-color-active);
                svg {
                    color: var(--header-color-highlight);
                }
            }
        }
    }

    .ui-language-select-mini-text {
        color: var(--header-color);
    }

    .header-user-controls {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: var(--spacing-12);
    }

    &.header-${ClientName.IVC} {
        .header-logo img {
            height: 3.375rem;
        }
        .menu-item {
            color: #d3e3f0;
            &.active {
                color: #89f1fa;
            }
        }
    }
`;
