import styled from 'styled-components';

export default styled.div`
    padding: 2rem;
    background-color: var(--color-body-bg);

    .coolbet-footer-legal-logos-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        max-width: ${({ theme }) => theme.layout.maxWidth};
        margin: 0 auto;
        img:not([alt='EMTA']) {
            height: 40px;
        }
    }

    .coolbet-footer-legal-logos-logo {
        max-height: 40px;
        filter: grayscale(100%) contrast(50%);
        transition: filter 0.2s;

        &.license-logo {
            will-change: transform;
        }

        &:hover,
        &.colored {
            filter: brightness(1.2);
        }
    }

    .coolbet-footer-legal-logos-logo-link {
        display: block;
        margin: 0 10px;
    }
`;
