import React from 'react';
import Wrapper from './styles';
import { useFortuneWheel } from '../../../../hooks/sim/fortune-wheel';
import UiCountdown from '../../../ui/countdown/UiCountdown';
import { getRoute, useRouter } from '../../../../services/router';
import { useStore } from '../../../../hooks/useStore';
import { environment } from '../../../../stores/environment/environment';
import { translate } from '../../../../services/translate';

export default function SimHeaderWheelOfFortune() {
    const { timeToSpin } = useFortuneWheel();
    const { navigateTo } = useRouter();
    const [{ THUNDERBITE_TOKEN }] = useStore(environment);

    return THUNDERBITE_TOKEN ? (
        <Wrapper onClick={() => navigateTo(getRoute('sim.fortune-wheel'))}>
            <div className="wheel" />
            <div className="wheel-text">
                {timeToSpin && <UiCountdown endDate={timeToSpin} labelOnFinish={translate('collect', 'ui.sim')} />}
            </div>
        </Wrapper>
    ) : null;
}
