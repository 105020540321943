import React from 'react';
import Wrapper from './styles';
import { useStore } from '../../../hooks/useStore';
import { stores } from '../../../stores';
import { isFeatureAvailable } from '../../../services/feature';
import { FEATURE } from '../../../services/types';
import { environment } from '../../../stores/environment/environment';
import { getRoute, isActiveRoute } from '../../../services/router';
import CoolbetHeaderWarning from '../../coolbet/header/warning/CoolbetHeaderWarning';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import UiButton from '../../ui/button/UiButton';
import Svg from '../../svg/Svg';
import classNames from 'classnames';
import { translate } from '../../../services/translate';
import { NativeMessageEventType, sendNativeEvent } from '../../../services/mobile-app';
import { requestLogin } from '../../../services/auth';
import SimHeaderAuthenticated from './authenticated/SimHeaderAuthenticated';

interface HeaderLink {
    active?: boolean;
    badge?: number;
    available: boolean;
    icon: string;
    isSupportedScreen?: boolean;
    label: string;
    route: string;
}

interface Props {
    links?: HeaderLink[];
}

export default function SimHeader({ links = [] }: Props) {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const isTwoToneTheme = !isFeatureAvailable(FEATURE.THEME_SINGLE_TONE);
    const [{ CLIENT_NAME }] = useStore(environment);

    return (
        <Wrapper className={`header-${CLIENT_NAME}`}>
            <CoolbetHeaderWarning />

            <div className="header-container">
                <UiNavLink to={getRoute('sim')} className="header-logo">
                    <img src={`/assets/images/logo/${CLIENT_NAME}.svg`} alt={CLIENT_NAME} />
                </UiNavLink>

                <div className="header-products-nav">
                    {links.map((link) => (
                        <UiButton
                            type="tab"
                            key={link.route}
                            url={link.route}
                            size="default"
                            selected={link.active || isActiveRoute(link.route, false)}
                            icon={<Svg icon={link.icon} />}
                            isTwoToneTheme={isTwoToneTheme}
                            badge={link.badge}
                            className={classNames('menu-item', {
                                active: link.active || isActiveRoute(link.route, false),
                            })}
                        >
                            {link.label}
                        </UiButton>
                    ))}
                </div>

                <div className="header-user-controls">
                    {isAuthenticated ? (
                        <SimHeaderAuthenticated />
                    ) : (
                        <>
                            <UiButton
                                onClick={() => sendNativeEvent({ type: NativeMessageEventType.REGISTER })}
                                url={getRoute('registration')}
                                color="primary"
                                dataTest="button-register"
                            >
                                {translate('Register', 'ui.common')}
                            </UiButton>
                            <UiButton
                                onClick={() => {
                                    requestLogin();
                                    stores.modals.isInactivityModalVisible.set(false);
                                }}
                                color="primary"
                                dataTest="button-login"
                            >
                                {translate('Login', 'ui.common')}
                            </UiButton>
                        </>
                    )}
                </div>
            </div>
        </Wrapper>
    );
}
