import { LocalStorage } from '../local-storage/types';
import { storageGet } from '../storage';
import { BETSLIP_SETTINGS_TTL } from './constants';

export function getOrResetBetSlipSettings() {
    const betSlipSettings: { updatedAt: number; betSlipMarketIdToOutcomeId: Record<number, number> } =
        storageGet(LocalStorage.BETSLIP_SETTINGS) || {};
    if (betSlipSettings.updatedAt) {
        const duration = new Date().getTime() - betSlipSettings.updatedAt;
        if (duration > BETSLIP_SETTINGS_TTL) {
            betSlipSettings.betSlipMarketIdToOutcomeId = {};
        }
    }
    return betSlipSettings.betSlipMarketIdToOutcomeId || {};
}
