import { RouterHistory } from '@sentry/react/types/reactrouter';
import { Country, Licence } from '@staycool/location';
import memoize from 'lodash/memoize';
import { parse, stringify } from 'query-string';
import { loadPrematchTree } from '../microservices/sbgate';
import { updateProfile } from '../microservices/users';
import routes from '../routes.json';
import { stores } from '../stores';
import { casino } from '../stores/casino';
import { environment } from '../stores/environment/environment';
import { getStoreValue } from '../stores/store/utils';
import { getClient, isOfficeIp } from './environment';
import { localeOfLanguage } from './language/constants';
import { FANTASY_LANGUAGE, LANGUAGE, LOCALE, Language, REAL_LANGUAGE } from './language/types';
import { getLicence } from './licence';
import { NativeMessageEventType, sendNativeEvent } from './mobile-app';
import { getLastActiveProductRoute, getRoute, isActiveRoute } from './router';
import { betbuilderCultureByLanguage } from './sports/constants';
import { getFlatCategoriesFromPrematchTree } from './sports/sbgate';
import { storageGet } from './storage';
import { setLanguage } from './translation/language-switch';
import { ObjectValues } from './ts-utils';
import { isTestUser } from './user';
import { getUserCountry } from './users/country';
import { ClientName } from './utils/types';

export interface LanguageSelection {
    label: string;
    value: Language;
    locale: LOCALE;
    experimental?: ObjectValues<typeof ClientName>[];
    clients?: ObjectValues<typeof ClientName>[];
    licences: ObjectValues<typeof Licence>[];
}

export const FANTASY_TO_REAL_LANGUAGE: { [key in FANTASY_LANGUAGE]: REAL_LANGUAGE } = {
    [LANGUAGE.CHILEAN]: LANGUAGE.SPANISH,
    [LANGUAGE.PERUVIAN]: LANGUAGE.SPANISH,
    [LANGUAGE.CANADIAN]: LANGUAGE.ENGLISH,
    [LANGUAGE.ECUADORIAN]: LANGUAGE.SPANISH,
    [LANGUAGE.MEXICAN]: LANGUAGE.SPANISH,
    [LANGUAGE.US]: LANGUAGE.ENGLISH,
    [LANGUAGE.EUROPEAN]: LANGUAGE.ENGLISH,
};

const STORAGE_LANGUAGE_KEY = 'language';

export const languageByCountry = {
    [Country.CANADA]: LANGUAGE.CANADIAN,
    [Country.CHILE]: LANGUAGE.CHILEAN,
    [Country.ESTONIA]: LANGUAGE.ESTONIAN,
    [Country.FINLAND]: LANGUAGE.FINNISH,
    [Country.ICELAND]: LANGUAGE.ICELANDIC,
    [Country.PERU]: LANGUAGE.PERUVIAN,
    [Country.NORWAY]: LANGUAGE.EUROPEAN,
    [Country.SWEDEN]: LANGUAGE.SWEDISH,
    [Country.ECUADOR]: LANGUAGE.ECUADORIAN,
    [Country.MEXICO]: LANGUAGE.MEXICAN,
    [Country.USA]: LANGUAGE.US,
};

const B2C_FANTASY_LANGUAGES = [
    FANTASY_LANGUAGE.CANADIAN,
    FANTASY_LANGUAGE.CHILEAN,
    FANTASY_LANGUAGE.ECUADORIAN,
    FANTASY_LANGUAGE.MEXICAN,
    FANTASY_LANGUAGE.PERUVIAN,
];

const languages: LanguageSelection[] = [
    {
        label: 'English',
        value: LANGUAGE.ENGLISH,
        locale: LOCALE.EN,
        licences: [
            Licence.ESTONIA,
            Licence.MALTA,
            Licence.MEXICO,
            Licence.SIMULATED_GAMING,
            Licence.SWEDEN,
            Licence.PERU,
        ],
    },
    {
        label: 'Canadian English',
        value: LANGUAGE.CANADIAN,
        locale: LOCALE.CA,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'Svenska',
        value: LANGUAGE.SWEDISH,
        locale: LOCALE.SE,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'Suomi',
        value: LANGUAGE.FINNISH,
        locale: LOCALE.FI,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'Eesti',
        value: LANGUAGE.ESTONIAN,
        locale: LOCALE.EE,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'Русский',
        value: LANGUAGE.RUSSIAN,
        locale: LOCALE.RU,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'Íslenska',
        value: LANGUAGE.ICELANDIC,
        locale: LOCALE.IS,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'Chilean Spanish',
        value: LANGUAGE.CHILEAN,
        locale: LOCALE.CL,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'Peruvian Spanish',
        value: LANGUAGE.PERUVIAN,
        locale: LOCALE.PE,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN, Licence.PERU],
    },
    {
        label: 'Ecuadorian Spanish',
        value: LANGUAGE.ECUADORIAN,
        locale: LOCALE.EC,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'Mexican Spanish',
        value: LANGUAGE.MEXICAN,
        locale: LOCALE.MX,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
    {
        label: 'English US',
        value: LANGUAGE.US,
        locale: LOCALE.US,
        licences: [Licence.USA_ARKANSAS, Licence.USA_NEVADA, Licence.USA_MICHIGAN, Licence.USA_MISSISSIPPI],
    },
    {
        label: 'Español',
        value: LANGUAGE.SPANISH,
        locale: LOCALE.ES,
        experimental: [],
        clients: [ClientName.STATION, ClientName.WYNNBET_NEVADA],
        licences: [
            Licence.ESTONIA,
            Licence.MALTA,
            Licence.MEXICO,
            Licence.SWEDEN,
            Licence.USA_NEVADA,
            Licence.USA_MISSISSIPPI,
        ],
    },
    {
        label: 'European En',
        value: LANGUAGE.EUROPEAN,
        locale: LOCALE.EU,
        licences: [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN],
    },
];

export const languagesByKey = languages.reduce((languagesByKey, language) => {
    // eslint-disable-next-line no-param-reassign
    languagesByKey[language.value] = language;
    return languagesByKey;
}, {});

async function translateUrlToLanguage({
    pathname,
    targetLanguage,
    languageFromUrl,
}: {
    pathname: string;
    targetLanguage: Language;
    languageFromUrl?: string;
}) {
    const [currentLanguage, ...urlSegments] = decodeURI(pathname).split('/').slice(1);
    let routeKeyPrefix = '';
    let routeKey;

    for (const urlSegment of urlSegments) {
        routeKey = findUrlSegmentKeyAndTranslatedValue({
            urlSegment,
            currentLanguage,
            routeKeyPrefix,
        });
        if (!routeKey) {
            break;
        }
        routeKeyPrefix = routeKey;
    }

    routeKey = routeKey || routeKeyPrefix;

    if (routeKey === 'sport') {
        const categorySlug = urlSegments.slice(1).join('/');
        const route = await getTranslatedSportsbookRoute(targetLanguage, categorySlug, languageFromUrl);
        if (route) {
            return route;
        }
    }

    if (routeKey.includes('casino-v-2')) {
        const key = routeKey.replace('casino-v-2', 'casino');
        const categorySlug = urlSegments.slice(routeKey.split('.').length).join('/');
        const categoriesBySlug = getStoreValue(casino.categoriesBySlug);

        const currentCategory = categoriesBySlug[categorySlug];
        return `${getRoute(key, targetLanguage)}/${currentCategory?.id || categorySlug}`;
    }

    const currentArticleSlug = getStoreValue(stores.cms.currentArticleSlugByLanguage); // TODO: doesn't work during initial page load
    if (currentArticleSlug) {
        const translatedSlug = currentArticleSlug[targetLanguage] || currentArticleSlug[currentLanguage];
        if (routeKey) {
            return `${getRoute(routeKey, targetLanguage)}/${translatedSlug}`;
        }
        return `/${targetLanguage}/${translatedSlug}`;
    }

    const activeArticle: any = getStoreValue(stores.blog.activeArticle); // TODO: doesn't work during initial page load
    const activeArticleSlug = activeArticle && activeArticle.slug;
    if (activeArticleSlug) {
        const translatedSlug = activeArticleSlug[targetLanguage] || activeArticleSlug[currentLanguage];
        if (routeKey) {
            return `${getRoute(routeKey, targetLanguage)}/${translatedSlug}`;
        }
        return `/${targetLanguage}/${getRoute('blog', targetLanguage)}/${translatedSlug}`;
    }

    const untranslatedUrlSegments = urlSegments.slice(routeKey.split('.').length).join('/');

    if (routeKey) {
        const basePath = getRoute(routeKey, targetLanguage);
        return untranslatedUrlSegments ? `${basePath}/${untranslatedUrlSegments}` : basePath;
    }

    return getLastActiveProductRoute(targetLanguage);
}

async function getTranslatedSportsbookRoute(targetLanguage: string, categorySlug: string, urlLanguage?: string) {
    const oldCategories =
        getStoreValue(stores.sports.flatCategories) || (await getFlatCategoriesFromPrematchTree(urlLanguage));
    if (!oldCategories) {
        return getRoute('sport', targetLanguage);
    }
    const category = oldCategories.filter((category) => category.fullSlug === categorySlug)[0];
    if (category?.id) {
        const response = await loadPrematchTree(targetLanguage);
        const slug = response?.flatCategories.filter((flatCategory) => flatCategory.id === category.id)[0]?.fullSlug;
        return `${getRoute('sport', targetLanguage)}/${slug || ''}`;
    }
}

export async function changeLanguage(language: Language) {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    if (isAuthenticated) {
        await updateProfile({ language });
    }
    await updateSiteLanguage(language);
}

export async function updateSiteLanguage(language: Language) {
    const { pathname } = window.location;
    const urlParams = window.location.search || '';

    const translatedUrl = await translateUrlToLanguage({ pathname, targetLanguage: language });

    const applicationType = getStoreValue(stores.applicationType);
    if (applicationType !== 'poker-download') {
        window.location.href = translatedUrl + urlParams;
    }
}

function findUrlSegmentKeyAndTranslatedValue({ urlSegment, currentLanguage, routeKeyPrefix }) {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const depth = routeKeyPrefix ? routeKeyPrefix.split('.').length + 1 : 1;
    return Object.keys(routes)
        .filter((key) => key.split('.').length === depth)
        .filter((key) => key.startsWith(routeKeyPrefix || ''))
        .find((routeKey) => routes[routeKey][currentLanguage] === urlSegment);
}

export async function initLanguage(history: RouterHistory) {
    const chosenLanguage = findLanguage();
    stores.language.set(chosenLanguage);

    await setLanguage(chosenLanguage);

    const [languageFromUrl] = decodeURI(window.location.pathname).split('/').slice(1);
    if (chosenLanguage !== languageFromUrl) {
        await translateUrl(history, chosenLanguage, languageFromUrl);
    }

    sendNativeEvent({ type: NativeMessageEventType.PAGE_LOADED, language: chosenLanguage });
}

async function translateUrl(history, language: Language, languageFromUrl: string) {
    const params = parse(window.location.search);
    delete params.lang;
    const pathName = window.location.pathname.replace('/--/', `/${LANGUAGE.ENGLISH}/`);
    const translatedPath = await translateUrlToLanguage({
        pathname: pathName,
        targetLanguage: language,
        languageFromUrl,
    });
    history.replace(`${translatedPath}?${stringify(params)}`);
}

function findLanguage() {
    const clientNameFromEnv = getStoreValue(environment).CLIENT_NAME;
    const languageSelection = getInitialLanguage();
    const licence = getLicence();
    const isLanguageAllowed =
        isOfficeIp() ||
        (languageSelection.licences.includes(licence) &&
            (!languageSelection.clients || languageSelection.clients.includes(clientNameFromEnv)));
    let chosenLanguage = isLanguageAllowed ? languageSelection.value : LANGUAGE.ENGLISH;
    const isPokerSubPage =
        isActiveRoute(getRoute('poker', LANGUAGE.ENGLISH), false) &&
        !isActiveRoute(getRoute('poker', LANGUAGE.ENGLISH));
    if (isPokerSubPage) {
        chosenLanguage = FANTASY_TO_REAL_LANGUAGE[chosenLanguage] || chosenLanguage;
    }
    stores.language.set(chosenLanguage);
    return chosenLanguage;
}

function getInitialLanguage(): LanguageSelection {
    return (
        getUserLanguage() ||
        getLanguageFromUrl() ||
        getLastUsedLanguage() ||
        getIpCountryLanguage() ||
        getNavigatorLanguage() ||
        getDefaultLanguage()
    );
}

function getUserLanguage() {
    const user = getStoreValue(stores.user);
    return getAvailableLanguagesMemoized().find((language) => language.value === user?.language);
}

function getLastUsedLanguage() {
    const activeLanguageValue = storageGet(STORAGE_LANGUAGE_KEY);
    return getAvailableLanguagesMemoized().find((language) => language.value === activeLanguageValue);
}

function getIpCountryLanguage() {
    const ipCountry = getStoreValue(stores.ipCountry);
    return getAvailableLanguagesMemoized().find((language) => language.value === languageByCountry[ipCountry]);
}

function getNavigatorLanguage() {
    const locale = tryGetLocaleFromString(navigator.language);
    return getAvailableLanguagesMemoized().find((language) => language.locale === locale);
}

function tryGetLocaleFromString(languageString: string) {
    for (const language of Object.keys(localeOfLanguage)) {
        if (languageString.includes(language)) {
            return localeOfLanguage[language] as LOCALE;
        }
    }
    return null;
}

function getDefaultLanguage() {
    return getAvailableLanguagesMemoized()[0];
}

function getLanguageFromUrl() {
    return getLanguageFromQueryString() || getLanguageFromUrlPath();
}

function getLanguageFromQueryString() {
    const queryParams = parse(window.location.search) as { lang: string };
    return languages.find(({ value }) => value === (queryParams.lang && queryParams.lang.toLowerCase()));
}

export function getLanguageFromUrlPath() {
    const language = window.location.pathname.split('/')[1];
    return languages.find(({ value }) => value === language);
}

export function getActiveLocale() {
    return localeOfLanguage[getStoreValue(stores.language)];
}

export function getBetbuilderCulture(language: Language) {
    return betbuilderCultureByLanguage[language] || betbuilderCultureByLanguage['en'];
}

export const getAvailableLanguagesMemoized = memoize(getAvailableLanguages);

function getAvailableLanguages() {
    const userCountry = getUserCountry();
    const client = getClient();
    const licence = getLicence();

    const isRealUser = !(isTestUser() || isOfficeIp());

    return languages.filter((language) => {
        if (!language.licences.includes(licence)) {
            return false;
        } else if (language.experimental?.includes(client) && isRealUser) {
            return false;
        } else if (language.clients && !language.clients.includes(client)) {
            return false;
        } else if (
            isRealUser &&
            B2C_FANTASY_LANGUAGES.includes(language.value as FANTASY_LANGUAGE) &&
            userCountry !== language.value.toUpperCase()
        ) {
            return false;
        } else if (userCountry !== Country.NORWAY && language.value === LANGUAGE.EUROPEAN) {
            return false;
        } else if (userCountry === Country.NORWAY && language.value === LANGUAGE.ENGLISH) {
            return false;
        }
        return true;
    });
}
