export enum KycDocumentType {
    ADDRESS = 'address',
    ID = 'id',
    PAYMENT = 'payment',
    INCOME = 'income',
    ONE_TIME = 'one-time',
}

export enum EVerifiedStatus {
    NOT_CHECKED = 'not_checked',
    REQUESTED = 'requested',
    FAILED = 'failed',
    VERIFIED = 'verified',
    PENDING = 'pending',
}

export enum KycStatus {
    WAITING = 'waiting',
    APPROVED = 'approved',
    NONE = 'none',
    SECURITY = 'security',
}

export enum SourceOfIncomeStatus {
    NOT_CHECKED = 'not_checked',
    DECLINED = 'declined',
    APPROVED = 'approved',
    REQUESTED = 'requested',
    PENDING = 'pending',
}

export enum ProofOfPaymentStatus {
    NOT_CHECKED = 'not_checked',
    DECLINED = 'declined',
    APPROVED = 'approved',
    PENDING = 'pending',
    REQUESTED = 'requested',
}

export enum ProofOfIdStatus {
    NOT_CHECKED = 'not_checked',
    APPROVED = 'approved',
    DECLINED = 'declined',
    PENDING = 'pending',
    REQUESTED = 'requested',
}

export enum ProofOfAddressStatus {
    NOT_CHECKED = 'not_checked',
    DECLINED = 'declined',
    APPROVED = 'approved',
    PENDING = 'pending',
    FAILED = 'failed',
    REQUESTED = 'requested',
}

type HasReachedKycUploadLimit = { [key in KycDocumentType]: boolean };
type KycUploadLimits = { [key in KycDocumentType]: number };

export type KycSettings = {
    hasReachedLimits: HasReachedKycUploadLimit;
    isClosed: boolean;
    isEVerificationEnabled: boolean;
    isKycBannerEnabled: boolean;
    isKycCaptureEnabled: boolean;
    isKycDocsEnabled: boolean;
    isIdenfyEnabled: boolean;
    kycAddressDeclineReason: string;
    kycIdDeclineReason: string;
    kycIncomeDeclineReason: string;
    kycPaymentDeclineReason: string;
    kycStatus: string;
    limits: KycUploadLimits;
    proofOfAddress: string;
    proofOfId: string;
    proofOfPaymentStatus: string;
    shouldAskProofOfIdReason?: 'id-expired' | 'id-required';
    sourceOfIncomeStatus: string;
    oneTimeDocumentRequestId?: string;
    oneTimeDocumentRequestType?: string;
};

export enum ProofOfPaymentVerificationOption {
    BANK_SCREENSHOT = 'bank-screenshot',
    BANK_STATEMENT = 'bank-statement',
    CARD_FRONT_AND_BACK = 'card-front-and-back',
    CARD_LOST = 'card-lost',
    CARD_NO_NAME_OR_NUMBER = 'card-no-name-or-number',
    CRYPTO_WALLET_SCREENSHOT = 'crypto-wallet-screenshot',
    E_WALLET_SCREENSHOT = 'e-wallet-screenshot',
}

export interface RequestedDocument {
    amount?: number;
    backSideDeclineReason?: string;
    currency?: string;
    date?: string;
    declineReason?: string;
    documentType: 'E_WALLET' | 'BANK_ACCOUNT' | 'CREDIT_CARD' | 'CRYPTO_WALLET' | 'DEPOSIT';
    expirationDate?: string;
    frontSideDeclineReason?: string;
    id: string;
    number?: string;
    previouslySelectedOption?: ProofOfPaymentVerificationOption;
    selectedOption?: ProofOfPaymentVerificationOption;
    status: 'Pending' | 'Requested' | 'Declined';
    type: string;
}

export type GbgParams = {
    backendUrl: string;
    journeyDefinitionId: string;
    token: string;
};
