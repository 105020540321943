import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

const selectHeight = '2.5rem';

export default styled.div`
    .select-container {
        position: relative;
        &:after {
            content: '\u25BE'; //NOTE: ▾ (U+025BE)
            position: absolute;
            right: 0;
            top: 14px;

            height: 1rem;
            width: 1rem;

            font-size: 0.8rem;
        }
    }

    .dropdown {
        appearance: none;
        background-color: var(--form-input-bg);
        border: var(--form-input-border);
        border-radius: var(--ui-radius);
        cursor: pointer;
        color: var(--form-input-text);
        height: ${selectHeight};
        padding: 0 1.5rem 0 1.2rem;
        width: 100%;
        margin-bottom: 0.8rem;
        @media ${mediaQuery.isPhone} {
            height: 3rem;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }

        option {
            color: var(--form-input-text);
        }
        &[disabled] {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    &.input-sm {
        max-width: 70px;
    }

    &.input-md {
        max-width: 80px;
    }

    .stake-currency {
        position: absolute;
        top: 36%;
        transform: translate(0, -50%);
        left: var(--spacing-8);
        font-size: var(--font-14);
    }
`;
