import { getServiceUrl, httpPost } from '../services/api';
import { BONUS_STATUS } from '../services/bonuses/types';
import { logger } from '../services/logger';

const getUrl = (url) => getServiceUrl('casino-bonuses', url);

interface ClaimFreeSpinsRequest {
    bonusId: string;
    bonusCode: string;
    freeSpinsGameId: number;
}

export async function claimFreeSpins(params: ClaimFreeSpinsRequest): Promise<{ gameId?: number; message: string }> {
    try {
        const url = getUrl('fo/free-spin/claim');
        return await httpPost(url, params);
    } catch (error) {
        logger.error('CasinoBonusesMicroservice', 'claimFreeSpins', error);
        const message = error?.['message'] || BONUS_STATUS.SUBMISSION_FAILED;
        return { message };
    }
}
