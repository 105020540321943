import React, { ReactNode } from 'react';
import Wrapper from './styles';
import Svg from '../../../svg/Svg';
import UiNavLink from '../../nav-link/UiNavLink';
import classNames from 'classnames';

interface Props {
    label?: string;
    icon: string | ReactNode;
    to?: string;
    badge?: ReactNode;
    onClick?: (...args: unknown[]) => void;
    badgeClassName?: string;
    className?: string;
    iconClassName?: string;
}

export default function UiBottomNavigationTab({
    icon,
    label,
    to,
    badge,
    onClick,
    badgeClassName,
    className,
    iconClassName,
}: Props) {
    const content = (
        <>
            {icon &&
                (typeof icon === 'string' ? (
                    <Svg icon={icon} className={classNames(className, iconClassName)} size={1.25} />
                ) : (
                    icon
                ))}
            <label className={className}>{label}</label>
            {Boolean(badge) && <label className={classNames('badge', badgeClassName)}>{badge}</label>}
        </>
    );

    return <Wrapper>{to ? <UiNavLink to={to}>{content}</UiNavLink> : <div onClick={onClick}>{content}</div>}</Wrapper>;
}
