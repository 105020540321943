import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    .bonus-entry {
        @media ${mediaQuery.isLargerThanPhone} {
            max-width: 226px;
        }
        .bonus-code-entry {
            display: flex;
            align-items: flex-start;

            width: 100%;
            @media ${mediaQuery.isPhone} {
                max-width: inherit;
            }
            &.is-modal {
                max-width: inherit;
            }
            > button {
                margin-left: var(--spacing-8);
            }
        }
        .bonus-code-input {
            width: 100%;
            input {
                margin-bottom: var(--spacing-8);
            }
        }
        .bonus-code-link {
            padding: var(--spacing-8) 0;
            color: var(--font-link-color);
            cursor: pointer;
            @media ${mediaQuery.isPhone} {
                text-align: center;
            }
        }
    }
`;
