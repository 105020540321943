import React from 'react';
import Wrapper from './styles';
import { isFeatureAvailable } from '../../../../services/feature';
import { FEATURE } from '../../../../services/types';
import LoyaltyMeter from '../../../loyalty-meter/LoyaltyMeter';
import SimHeaderWheelOfFortune from '../wheel-of-fortune/SimHeaderWheelOfFortune';
import SimHeaderBalance from '../balance/SimHeaderBalance';
import SimHeaderUser from '../user/SimHeaderUser';
import CrmInboxIcon from '../../../crm/inbox-icon/CrmInboxIcon';
import { useStore } from '../../../../hooks/useStore';
import { media } from '../../../../stores/media/media';

export default function SimHeaderAuthenticated() {
    const [{ isPhone }] = useStore(media);

    return (
        <Wrapper>
            <SimHeaderBalance />
            <SimHeaderWheelOfFortune />
            {isFeatureAvailable(FEATURE.LOYALTY_PROGRAM) && <LoyaltyMeter />}
            {isFeatureAvailable(FEATURE.INBOX) && !isPhone && <CrmInboxIcon />}
            <SimHeaderUser />
        </Wrapper>
    );
}
