import React from 'react';
import Wrapper from './styles';
import { OnsiteMessage } from '../../../../../microservices/crm/types';
import CrmMessagePreviewPopupButton from '../button/CrmMessagePreviewPopupButton';
import { translate } from '../../../../../services/translate';
import { useStore } from '../../../../../hooks/useStore';
import { environment } from '../../../../../stores/environment/environment';
import CrmMessagePreviewPopupContent from '../content/CrmMessagePreviewPopupContent';
import DangerousHtml from '../../../../dangerous-html/DangerousHtml';

interface Props {
    message: OnsiteMessage;
    onClose?: () => void;
}

export default function CrmMessagePreviewPopupLevelUp({ message, onClose }: Props) {
    const [{ IMAGE_BASE_URL }] = useStore(environment);
    const { header, body, footer } = message;

    return (
        <Wrapper>
            <header>
                <img src={`${IMAGE_BASE_URL}cms/promotions/star_1726829246124.png`} alt="" />
                {header && <DangerousHtml className="level" elementType="div" content={header || ''} />}
            </header>
            <h1 className="level-title">Level up</h1>
            <CrmMessagePreviewPopupContent content={{ body, footer }} />
            <CrmMessagePreviewPopupButton title={translate('Claim', 'ui.common')} onClick={onClose} />
        </Wrapper>
    );
}
