import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-20);
    padding: var(--spacing-20);
    justify-content: center;

    .free-spin-bonus-item {
        background-color: var(--surface-level-2-bg);
        border-radius: var(--ui-radius);
        overflow: hidden;
        box-shadow: var(--ui-shadow);
        text-align: center;
        padding: var(--spacing-16);
        width: 200px;
    }

    .free-spin-bonus-item:last-child {
        flex-grow: 0;
        align-self: center;
    }
`;
