import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { loadOddsForFoLine } from '../../microservices/sb-odds';

let marketIdsFOLine: number[][] = [];

export function loadOddsForFoLineQueue(marketIdsPerMarketType: number[][]) {
    marketIdsFOLine.push(...marketIdsPerMarketType);
    return loadOddsForFoLineDebounced(marketIdsFOLine);
}

const loadOddsForFoLineDebounced = AwesomeDebouncePromise(
    (marketIds) => {
        marketIdsFOLine = [];
        return loadOddsForFoLine(marketIds);
    },
    10,
    { onlyResolvesLast: false },
);
