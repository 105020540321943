import { getStoreValue } from '../../stores/store/utils';
import { stores } from '../../stores';
import { getPaybackAvailableProgram } from '../../microservices/payback/casino-payback';
import { logger } from '../logger';
import { isFeatureAvailable } from '../feature';
import { FEATURE } from '../types';
import { casinoPaybackStore } from '../../stores/casino-payback';

export async function loadCasinoPaybackProgram() {
    try {
        const isAuthenticated = getStoreValue(stores.isAuthenticated);
        let casinoPayback = getStoreValue(casinoPaybackStore.casinoPayback);

        if (isAuthenticated && isFeatureAvailable(FEATURE.CASINO_PAYBACK)) {
            if (casinoPayback && new Date(casinoPayback.expiresAt) < new Date()) {
                casinoPayback = null;
            } else {
                casinoPayback = await getPaybackAvailableProgram();
            }
            casinoPaybackStore.casinoPayback.set(casinoPayback);
        }
    } catch (error) {
        logger.error('CasinoCasinoPaybackService', 'loadCasinoPaybackProgram', error);
    }
}
