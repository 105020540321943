import styled from 'styled-components';

const inputStatus = (props) => {
    const { success, failure, warning, theme } = props;

    if (success) {
        return theme.alerts.successColor;
    }
    if (failure) {
        return theme.alerts.failureColor;
    }
    if (warning) {
        return theme.alerts.warningColor;
    }
    return '';
};

export default styled.div`
    textarea {
        width: 100%;
        color: var(--form-input-text);
        margin: 0 0 0.8rem 0;
        font-size: 0.875rem;
        border: var(--form-input-border);
        background-color: var(--form-input-bg);
        box-shadow: var(--ui-shadow);
        border-radius: var(--ui-radius);
        border-bottom-color: ${inputStatus};
        resize: none;
        padding: ${({ theme }) => theme.layout.gutter};
        outline: 0;

        &:focus,
        &:active {
            background-color: var(--form-input-bg);
            border-color: var(--form-input-border);
        }
    }
`;
