import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import CasinoSeo from '../../components/casino-seo/CasinoSeo';
import { useStore } from '../../hooks/useStore';
import { loadCasinoGameForSeoById } from '../../microservices/casino';
import { useRouter } from '../../services/router';
import { stores } from '../../stores';
import { casino } from '../../stores/casino';
import { getStoreValue } from '../../stores/store/utils';
import MainLayout from '../main/MainLayout';
import Wrapper from './styles';
import useCasinoInit from '../../hooks/useCasinoInit';
import { isWithLicence } from '../../services/licence';
import { Licence } from '@staycool/location';
interface Props {
    children: JSX.Element;
}

export default function CasinoGameLayout({ children }: Props) {
    useCasinoInit({ isLobby: false });
    const [isCasinoInitialized] = useStore<boolean>(casino.isInitialized);
    const [isRgPanelVisible] = useStore(stores.responsibleGaming.isResponsibleGamingPanelVisible);
    const [isFullScreen] = useStore(casino.isFullScreen);
    const [seo, setSeo] = useState<any>();
    const { gameSlug, providerId, type } = useRouter().params;

    useEffect(() => {
        if (isCasinoInitialized) {
            const fetchMetaData = async () => {
                const gameId = gameSlug?.split('-').pop();
                if (gameId) {
                    const gameInfo =
                        getStoreValue(casino.gamesById)[gameId] || (await loadCasinoGameForSeoById(gameId));

                    const seoMetaData = CasinoSeo(gameInfo);
                    setSeo(seoMetaData);
                }
            };

            fetchMetaData();
        }
    }, [gameSlug, isCasinoInitialized, providerId, type]);

    return (
        <Wrapper
            $isMaltaPanelVisible={isWithLicence(Licence.MALTA)}
            className={classNames({ 'casino-game-layout-semi-full-screen': isFullScreen && isRgPanelVisible })}
        >
            {seo}
            <MainLayout>{children}</MainLayout>
        </Wrapper>
    );
}
