import React from 'react';
import CasinoV2Menu from '../../components/casino-v-2/menu/CasinoV2Menu';
import Seo from '../../components/seo/Seo';
import useCasinoInit from '../../hooks/useCasinoInit';
import { useStore } from '../../hooks/useStore';
import { translate } from '../../services/translate';
import { casino } from '../../stores/casino';
import { media } from '../../stores/media/media';
import MainLayout from '../main/MainLayout';
import Wrapper from './styles';
import { isBlurredBlockedProduct } from '../../services/users/country';
import UiProductClosed from '../../components/ui/product-closed/UiProductClosed';
import { PRODUCT } from '../../types/common';
import UiProductClosedBlurMessage from '../../components/ui/product-closed/blur-message/UiProductClosedBlurMessage';

export default function CasinoLayout({ children }: React.PropsWithChildren) {
    const [isBlockedCountry] = useStore(casino.isCasinoBlocked);

    useCasinoInit({ isLobby: true });

    const [casinoInitialized] = useStore(casino.isInitialized);
    const [{ isSmallerThanLaptop }] = useStore(media);

    return (
        <>
            <Seo title={translate('seo.casino.title')} description={translate('seo.casino.desc')} />
            <MainLayout>
                {casinoInitialized && (
                    <>
                        <Wrapper className="casino-shell">
                            <div className="casino-shell-content-container">
                                {!isSmallerThanLaptop && (
                                    <div className="casino-shell-menu">
                                        <CasinoV2Menu />
                                    </div>
                                )}
                                {isBlockedCountry && !isBlurredBlockedProduct() ? (
                                    <UiProductClosed product={PRODUCT.CASINO} />
                                ) : (
                                    <div className="casino-shell-content">
                                        <div className="casino-shell-games-container">{children}</div>
                                    </div>
                                )}
                            </div>
                        </Wrapper>
                        {isBlockedCountry && isBlurredBlockedProduct() && <UiProductClosedBlurMessage />}
                    </>
                )}
            </MainLayout>
        </>
    );
}
