import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getRoute } from '../../services/router';
import redirectsConfig from '../../redirects.json';
import { LANGUAGE } from '../../services/language/types';

export default function Redirects() {
    const redirects = Object.values(redirectsConfig).flatMap(({ from, to }) => {
        if (typeof from === 'string') {
            return { from: `/${from}`, to: getRoute(to, LANGUAGE.US) };
        }
        return Object.keys(from).map((language) => ({
            from: `/${language}/${from[language]}`,
            to: getRoute(to, language),
        }));
    });

    return (
        <>
            {redirects.map((redirect) => (
                <Route
                    key={redirect.from}
                    path={redirect.from}
                    render={() => <Redirect to={`${redirect.to}${window.location.search}`} />}
                />
            ))}
        </>
    );
}
