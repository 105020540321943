import { getServiceUrl, httpGet, httpPost, httpPostFile } from '../services/api';
import { getKycTokenHeaders } from '../services/kyc/header';
import { GbgParams, KycDocumentType, KycSettings, RequestedDocument } from '../services/kyc/types';
import { logger } from '../services/logger';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';

const getUrl = (url) => getServiceUrl('kyc', url);

export async function uploadFiles(
    files: File[],
    uploadType: KycDocumentType,
    extraData?: Record<string, string | Blob>,
) {
    const kycToken = getStoreValue(stores.kyc.kycToken);
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const url = isAuthenticated ? getUrl('upload') : getUrl('anon_upload');
    const formData = new FormData();
    files.forEach((file) => formData.append('file', file));
    formData.append('type', uploadType);
    if (!isAuthenticated && kycToken) {
        formData.append('hash', kycToken);
    }
    if (extraData) {
        Object.entries(extraData).forEach(([key, value]) => formData.append(key, value));
    }
    return httpPostFile(url, formData);
}

export async function loadKycUserSettings() {
    const headers = getKycTokenHeaders();
    const url = getUrl('user-settings');
    try {
        const result = await httpGet<KycSettings>(url, undefined, { headers });
        stores.kyc.settings.set(result);
    } catch (error) {
        logger.error('KycMicroservice', 'loadKycUserSettings', error);
    }
}

export function getRequestedPaymentDocuments() {
    const headers = getKycTokenHeaders();
    return httpGet<RequestedDocument[]>(getUrl('requested-documents/payment'), undefined, { headers });
}

export async function loadGbgParams() {
    const headers = getKycTokenHeaders();
    const url = getUrl('docs/capture');
    return await httpGet<GbgParams>(url, undefined, { headers });
}

export function verifyMyId() {
    const url = getUrl('verify-my-id');
    return httpPost<void>(url);
}

export function getIdenfyAuthToken() {
    const headers = getKycTokenHeaders();
    const url = getUrl('idenfy');
    return httpGet<string>(url, undefined, { headers });
}
