import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.footer`
    padding: 0 1rem 2rem;
    font-size: var(--font-14);

    @media ${mediaQuery.isPhone} {
        padding-bottom: calc(2rem + var(--mobile-nav-height));
    }

    &,
    .links-row {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    flex-direction: column;
    gap: 1rem;

    & > * {
        max-width: 1920px;
    }

    .disclaimer {
        padding-top: 1rem;
        text-align: center;

        .age-disclaimer {
            height: 2.5rem;
            cursor: pointer;
        }

        .warning {
            font-weight: 700;
        }
    }

    .links-row {
        gap: 0.75rem;

        &.partner-links img {
            height: 1.875rem;
        }

        &.internal-links {
            gap: 1.25rem;
            flex-wrap: wrap;

            & > a {
                text-transform: uppercase;
                font-weight: 700;
            }
        }
    }

    .age-disclaimer,
    .partner-links img {
        opacity: 0.75;
        width: auto;
        transition: opacity ease-in-out 200ms;

        &:hover {
            opacity: 1;
        }
    }

    .internal-links a,
    .socials svg,
    .warning {
        color: #e7c86c;
        transition: color ease-in-out 200ms;
    }

    .internal-links a,
    .socials svg {
        &:hover {
            color: #ffffff;
        }
    }
`;
