import { loadCasinoJackpots } from '../../microservices/casino';
import { casino } from '../../stores/casino';
import { getStoreValue } from '../../stores/store/utils';
import { formattedAmountWithCurrency, getActiveCurrency } from '../currency';
import { CasinoGame, CasinoJackpot } from './types';

export async function setCasinoJackpots() {
    await loadCasinoJackpots();

    const games = getStoreValue<CasinoGame[]>(casino.filteredGames);
    const jackpots = getStoreValue<{ [key: string]: CasinoJackpot }>(casino.jackpots);
    const activeCurrency = getActiveCurrency();

    casino.jackpotsDisplayByGameId.set(
        games.reduce((jackpotsDisplayByGameId, game) => {
            const gameJackpotDisplay =
                game.jackpotName && jackpots[game.jackpotName] && jackpots[game.jackpotName][activeCurrency];
            if (gameJackpotDisplay && gameJackpotDisplay > 0) {
                jackpotsDisplayByGameId[game.id] = formattedAmountWithCurrency(gameJackpotDisplay);
            }
            return jackpotsDisplayByGameId;
        }, {}),
    );
}

export function hasCasinoJackpotDisplay(game: CasinoGame) {
    return Boolean(getStoreValue(casino.jackpotsDisplayByGameId)[game.id]);
}
