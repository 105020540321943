import { fetchIpDetails } from '../microservices/ip';
import { logger } from './logger';
import { stores } from '../stores';
import { isFeatureAvailable } from './feature';
import { FEATURE } from './types';
import { Country } from '@staycool/location';

export async function loadIpDetails(ip: string) {
    if (!isFeatureAvailable(FEATURE.IP_CHECK)) {
        stores.ipCountry.set(Country.USA);
        return;
    }
    try {
        const { countryCode } = await fetchIpDetails(ip);
        stores.ipCountry.set(countryCode || 'EN');
    } catch (error) {
        logger.error('IpService', 'loadIpDetails', error);
    }
}
