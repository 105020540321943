import React from 'react';
import { OnsiteMessage } from '../../../../../microservices/crm/types';
import CrmMessagePreviewPopupContent from '../content/CrmMessagePreviewPopupContent';
import CrmMessagePreviewPopupButton from '../button/CrmMessagePreviewPopupButton';
import { translate } from '../../../../../services/translate';
import { useStore } from '../../../../../hooks/useStore';
import { environment } from '../../../../../stores/environment/environment';

interface Props {
    message: OnsiteMessage;
    onClose?: () => void;
}

export default function CrmMessagePreviewPopupDailyLogin({ message, onClose }: Props) {
    const [{ IMAGE_BASE_URL }] = useStore(environment);
    const { header, body, footer } = message;

    return (
        <>
            <header>
                <img src={`${IMAGE_BASE_URL}cms/promotions/C8_Coin_Pack_10.png`} alt="" />
            </header>
            <CrmMessagePreviewPopupContent content={{ header, body, footer }} />
            <CrmMessagePreviewPopupButton title={translate('Claim', 'ui.common')} onClick={onClose} />
        </>
    );
}
