import React from 'react';
import Wrapper from './styles';
import { translate } from '../../../../services/translate';

export default function UiProductClosedBlurMessage() {
    return (
        <Wrapper>
            <div className="blurred-message">{translate('unable-to-serve-customers')}</div>
        </Wrapper>
    );
}
