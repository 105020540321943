import styled from 'styled-components';
import { darken } from 'polished';

export default styled.div`
    padding: 2.5rem 0;
    background: var(--footer-bg);

    .coolbet-footer-navigation-container {
        display: flex;
        justify-content: space-evenly;
        max-width: ${({ theme }) => theme.layout.maxWidth};
        margin: 0 auto;

        @media (max-width: 1200px) {
            flex-wrap: wrap;
        }
    }

    .coolbet-footer-navigation-title {
        font-size: 0.85rem;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1px;
        padding: 0 1rem 1rem 1rem;
    }

    .coolbet-footer-navigation-menu-element {
        display: block;
        text-transform: capitalize;
        font-size: 0.85rem;
        padding: 0.5rem 1rem;
        margin: 0 0 0.8rem 0;
        color: var(--footer-color);
        transition: color 0.2s, background-color 0.2s;
        border-radius: 5px;
        cursor: pointer;
        &:last-of-type {
            margin-bottom: 0;
        }

        &:hover {
            color: var(--font-color-secondary);
            background-color: ${({ theme }) => darken(0.06, theme.surfacesLevel1.background)}};
        }
    }
`;
