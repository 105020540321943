import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CmsPage from '../../pages/cms/CmsPage';
import NavigatorRender from './render/NavigatorRender';
import MainLayout from '../../layouts/main/MainLayout';

interface Props {
    routes: any[];
    noRedirect?: boolean;
    checkForCmsContent?: boolean;
    root?: boolean;
}

export default function Navigator({ routes, noRedirect, checkForCmsContent, root }: Props) {
    const validRoutes = routes.filter(Boolean);
    let redirectTo = validRoutes[0] && validRoutes[0].route;

    const queryParams = window.location.search || '';
    redirectTo = `${redirectTo}${queryParams}`;

    return (
        <Switch>
            {validRoutes.map((route) => (
                <Route
                    exact={route.exact}
                    key={route.key || route.route}
                    path={route.route}
                    render={(props) => <NavigatorRender {...route} {...props} root={root} />}
                />
            ))}

            {checkForCmsContent && (
                <Route
                    render={(props) => (
                        <MainLayout>
                            <CmsPage {...props} />
                        </MainLayout>
                    )}
                />
            )}

            {redirectTo && !noRedirect && <Redirect to={redirectTo} />}
        </Switch>
    );
}
