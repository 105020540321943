import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { ClosureReason } from './types';

export function getHasGamblingRestriction() {
    const user = getStoreValue(stores.user);
    if (!user) {
        return false;
    }

    const isSportsbookRestricted =
        isRestrictiveCloseReason(user.sportsbookClosedReason) || isRestrictiveCloseReason(user.racebookClosedReason);
    const isCasinoRestricted =
        isRestrictiveCloseReason(user.casinoClosedReason) || isRestrictiveCloseReason(user.pokerClosedReason);

    return isSportsbookRestricted && isCasinoRestricted;
}

function isRestrictiveCloseReason(reason?: ClosureReason) {
    if (!reason) {
        return false;
    }
    const gamblingRestrictions = [
        ClosureReason.SPELPAUS,
        ClosureReason.HAMPI,
        ClosureReason.PID_MISSING,
        ClosureReason.MINCETUR,
    ];
    return gamblingRestrictions.includes(reason);
}
