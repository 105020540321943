import { useEffect, useLayoutEffect } from 'react';
import { useStore } from '../../../hooks/useStore';
import { getCasinoFilter } from '../../../services/casino/filter';
import { LocalStorage } from '../../../services/local-storage/types';
import { storageSet } from '../../../services/storage';
import { stores } from '../../../stores';
import { casino } from '../../../stores/casino';
import { media } from '../../../stores/media/media';

export default function SystemCasino() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [{ isDesktop, isLaptop }] = useStore(media);
    const [isPaybackBoosterCompactView] = useStore(casino.isPaybackBoosterCompactView);

    const isDesktopActual = isDesktop || isLaptop;

    function handleCasinoReloading(isAuthenticated) {
        casino.playForReal.set(isAuthenticated);
        casino.shouldReload.set(true);
    }

    useLayoutEffect(() => {
        handleCasinoReloading(isAuthenticated);
    }, [isAuthenticated, isDesktopActual]);

    useLayoutEffect(() => {
        casino.filter.set(getCasinoFilter());
    }, [isAuthenticated]);

    useEffect(() => {
        storageSet(LocalStorage.CASINO_BOOSTER_IS_COMPACT, isPaybackBoosterCompactView);
    }, [isPaybackBoosterCompactView]);

    return null;
}
