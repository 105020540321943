import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    display: flex;
    align-items: center;
    width: 450px;
    margin: 0 auto;
    min-height: calc(100vh - 70px);
    padding: 0 2rem;
    text-align: center;
    @media ${mediaQuery.isPhone} {
        max-width: 100%;
    }
    h1 {
        font-size: 3rem;
        margin: 0;
        @media ${mediaQuery.isPhone} {
            text-align: center;
            font-size: 2rem;
        }
    }
    p {
        font-size: 1rem;
        padding: 2rem;
    }
    a {
        border-bottom: 1px dotted;
    }
    .logo-icon {
        display: flex;
        justify-content: center;
        margin: var(--spacing-12) auto;
    }
`;
