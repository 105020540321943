import React from 'react';
import Wrapper from './styles';
import { OnsiteMessageWithCampaign } from '../../../microservices/crm/types';
import { openMessageByUser } from '../../../services/crm';
import classNames from 'classnames';
import { getRoute, useRouter } from '../../../services/router';
import { scrollToTop } from '../../../services/util';
import { isExpiredMessage, isUnreadMessage } from '../../../services/onsite-messages';
import CrmMessageInfo from './info/CrmMessageInfo';

interface Props {
    message: OnsiteMessageWithCampaign;
    isActive?: boolean;
}

export default function CrmMessage({ message, isActive }: Props) {
    const { navigateTo } = useRouter();
    const isUnread = isUnreadMessage(message);
    const isExpired = isExpiredMessage(message);

    async function handleMessageClick() {
        await openMessageByUser(message);
        navigateTo(getRoute('inbox'));
        scrollToTop();
    }

    return (
        <Wrapper
            className={classNames({ ['crm-message-unread']: isUnread, ['crm-message-active']: isActive })}
            onClick={handleMessageClick}
        >
            <div className="crm-mascot">
                <img src={message.mascotAvatar ?? '/assets/images/general/mascot-bear.png'} alt="mascot-avatar" />
                <div
                    className={classNames('crm-mascot-badge', {
                        ['crm-mascot-new']: isUnread,
                        ['crm-mascot-expired']: isExpired,
                    })}
                />
            </div>
            <CrmMessageInfo message={message} />
        </Wrapper>
    );
}
