import styled from 'styled-components';

type StyledProps = {
    $failure?: boolean;
    $success?: boolean;
    $warning?: boolean;
    $info?: boolean;
    $center?: boolean;
};

export default styled.div<StyledProps>`
    background-color: ${({ $failure, $success, $warning }) =>
        $failure
            ? 'var(--alert-error-bg)'
            : $success
            ? 'var(--alert-success-bg)'
            : $warning
            ? 'var(--alert-warning-bg)'
            : 'var(--alert-info-bg)'};
    border: 1px solid
        ${({ $failure, $success, $warning }) =>
            $failure
                ? ' var(--color-error)'
                : $success
                ? ' var(--color-success)'
                : $warning
                ? ' var(--color-warning)'
                : ' var(--color-info)'};
    border-radius: var(--ui-radius);

    padding: 0.4rem 0.8rem;
    margin-bottom: var(--spacing-8);

    font-size: 0.8rem;
    color: ${({ $failure, $success, $warning }) =>
        $failure
            ? 'var(--color-error)'
            : $success
            ? 'var(--color-success)'
            : $warning
            ? 'var(--color-warning)'
            : 'var(--color-info)'};
    text-align: ${({ $center }) => ($center ? 'center' : 'left')};
`;
