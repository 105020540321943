import React from 'react';
import { translate } from '../../../services/translate';
import UiButton from '../../ui/button/UiButton';
import { useEdgeTierContext } from '../../../contexts/edge-tier/EdgeTierContext';

export default function EdgeTierButton() {
    const { open, isLoaded } = useEdgeTierContext();

    return (
        <UiButton isLoading={!isLoaded} color="primary" onClick={open} size="small">
            {translate('Open Live Chat', 'ui.common')}
        </UiButton>
    );
}
