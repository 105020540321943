import React from 'react';
import AuthProfileValidatorTermsAndConditionsAcceptanceModal from '../../../auth/profile-validator/terms-and-conditions-acceptance-modal/AuthProfileValidatorTermsAndConditionsAcceptanceModal';
import { useStore } from '../../../../hooks/useStore';
import { stores } from '../../../../stores';
import { isFeatureAvailable } from '../../../../services/feature';
import { FEATURE } from '../../../../services/types';

export default function SimAuthProfileValidator() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);

    if (!isFeatureAvailable(FEATURE.READ_AND_AGREE_TERMS) || !isAuthenticated) {
        return null;
    }

    return <AuthProfileValidatorTermsAndConditionsAcceptanceModal />;
}
