import React, { useEffect } from 'react';
import { translate } from '../../../services/translate';
import UiButton from '../../ui/button/UiButton';
import { useStore } from '../../../hooks/useStore';
import { environment } from '../../../stores/environment/environment';
import { useLivechatZendeskContext } from '../../../contexts/livechat/zendesk/LivechatZendeskContext';

export default function ZendeskButton() {
    const [{ ZENDESK }] = useStore(environment);
    const { open, isLoaded } = useLivechatZendeskContext();

    useEffect(() => {
        return () => window.$zopim?.livechat.window.hide();
    }, []);

    function isChatOffline() {
        if (!ZENDESK?.BUSINESS_HOUR_TIMEZONE) {
            return false;
        }
        const now = new Date();
        const localHour =
            new Intl.DateTimeFormat('en-US', {
                timeZone: ZENDESK?.BUSINESS_HOUR_TIMEZONE,
                hour: 'numeric',
                hourCycle: 'h23',
            })
                .formatToParts(now)
                .find((part) => part.type === 'hour')?.value ?? '0';
        const currentHour = Number(localHour);
        const hours = {
            start: Number(ZENDESK?.BUSINESS_HOUR_START),
            end: Number(ZENDESK?.BUSINESS_HOUR_END),
        };
        if (hours.start > hours.end) {
            return currentHour >= hours.end && currentHour < hours.start;
        }
        return currentHour < hours.start || currentHour >= hours.end;
    }

    if (isChatOffline()) {
        return null;
    }

    return (
        <UiButton isLoading={!isLoaded} color="primary" onClick={open} size="small">
            {translate('Open Live Chat', 'ui.common')}
        </UiButton>
    );
}
