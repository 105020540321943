import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from '../../microservices/pusher';
import { isTestUser } from '../user';
import { stores } from '../../stores';

export const useSportsMaintenanceSocketUpdates = () => {
    useSocketSubscribeUnsubscribe('public', {
        params: {
            service: 'sbgate',
            channel: 'maintenance',
        },
        resubscribeOnReconnect: true,
    });

    useSocketTopicEvents('sports-maintenance', (message) => {
        decideSportsbookMaintenance(message);
    });
};

export function decideSportsbookMaintenance(response) {
    const { product, endDate, maintenanceMode } = response;

    let maintenanceState = {
        endDate,
        liveBet: false,
        preMatch: false,
        betslip: false,
    };

    if (maintenanceMode === 'off' || (maintenanceMode === 'test' && isTestUser())) {
        stores.maintenance.sportsbookMaintenanceState.set(maintenanceState);
        return;
    }

    if (product === 'both') {
        maintenanceState = { ...maintenanceState, liveBet: true, preMatch: true };
    } else {
        maintenanceState = { ...maintenanceState, [product]: true };
    }

    stores.maintenance.sportsbookMaintenanceState.set(maintenanceState);
}
