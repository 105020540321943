import moment from 'moment';
import { getServiceUrl, httpGet } from '../services/api';
import {
    CasinoDateFilter,
    CasinoHighestWinsPayload,
    HighestDailyWins,
    RecentlyPlayed,
    UserActivity,
    UserHighestWin,
    UserStatistics,
    WinnerTicker,
} from '../services/casino/types';
import { logger } from '../services/logger';
import { casino } from '../stores/casino';

const getUrl = (url) => getServiceUrl('casino-statistics', url);

export async function getRecentlyPlayedGames() {
    const url = getUrl('fo/recently-played');
    return httpGet<RecentlyPlayed[]>(url);
}

export async function loadUserStatistics(dateFilter: CasinoDateFilter): Promise<void> {
    const properFilter = {
        period: dateFilter.period,
        startDate: (dateFilter.startDate as any) ? moment(dateFilter.startDate as any).format('YYYY-MM-DD') : undefined,
        endDate: (dateFilter.endDate as any) ? moment(dateFilter.endDate as any).format('YYYY-MM-DD') : undefined,
    };
    try {
        const url = getUrl('fo/statistics');
        const userStatistics: UserStatistics = await httpGet(url, properFilter);

        casino.userStatistics.set(userStatistics);
    } catch (error) {
        logger.error('CasinoStatisticsMicroservice', 'loadUserStatistics', error);
    }
}

export async function loadUserActivity(dateFilter: CasinoDateFilter): Promise<void> {
    const properFilter = {
        period: dateFilter.period,
        startDate: (dateFilter.startDate as any) ? moment(dateFilter.startDate as any).format('YYYY-MM-DD') : undefined,
        endDate: (dateFilter.endDate as any) ? moment(dateFilter.endDate as any).format('YYYY-MM-DD') : undefined,
    };
    try {
        const url = getUrl('fo/activity');
        const userActivity: UserActivity = await httpGet(url, properFilter);

        casino.userActivity.set(userActivity);
    } catch (error) {
        logger.error('CasinoStatisticsMicroservice', 'loadUserActivity', error);
    }
}

export async function loadUserBiggestWins(dateFilter: CasinoDateFilter) {
    const properFilter = {
        period: dateFilter.period,
        startDate: (dateFilter.startDate as any) ? moment(dateFilter.startDate as any).format('YYYY-MM-DD') : undefined,
        endDate: (dateFilter.endDate as any) ? moment(dateFilter.endDate as any).format('YYYY-MM-DD') : undefined,
    };
    try {
        const url = getUrl('fo/biggest-wins');
        const userBiggestWins: UserHighestWin[] = (await httpGet(url, properFilter)) || [];
        casino.highestWins.set(userBiggestWins);
    } catch (error) {
        logger.error('CasinoStatisticsMicroservice', 'loadUserBiggestWins', error);
    }
}

export async function loadCasinoHighestWins(payload: CasinoHighestWinsPayload) {
    try {
        const url = getUrl('fo/highest-wins');
        return httpGet<HighestDailyWins[]>(url, payload);
    } catch (error) {
        logger.error('CasinoStatisticsMicroservice', 'loadCasinoHighestWins', error);
        return [];
    }
}

export async function getLatestWinnerTickerUpdate(): Promise<WinnerTicker> {
    const url = getUrl('fo/last-ticker-win');
    return httpGet<WinnerTicker>(url);
}
