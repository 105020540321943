import { Market } from '@staycool/location';
import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { PromotionTypes } from '../services/cms-promotion';
import { ArticleResponse, Avatar, CmsBannerMessage, TranslationUpdate } from '../services/cms/types';
import { Language } from '../services/language/types';
import { CmsGeneralSetting } from '../services/sports/types';

const getUrl = (url) => getServiceUrl('cms', url);

export async function getBannerMessages(market: Market) {
    const url = getUrl('banner-message/user');
    return httpGet<CmsBannerMessage[]>(url, { market });
}

export async function getFoTranslations(language: string, fallbackLanguage: string) {
    const url = getUrl(`translations/fo/${language}`);
    return httpGet<{ translationsByKey: Record<string, string>; lastUpdated: string }>(url, {
        fallbackLanguage,
        version: process.env.REACT_APP_VERSION || 'development',
    });
}

export function getIncrementalTranslationUpdates(
    language: Language,
    fallbackLanguage: Language,
    lastUpdatedUnixTime: string,
) {
    const url = getUrl(`translations/fo/incremental/${language}/${lastUpdatedUnixTime}`);
    return httpGet<TranslationUpdate>(url, {
        fallbackLanguage,
        version: process.env.REACT_APP_VERSION || 'development',
    });
}

export function reportMissingTranslations(missingTranslationReports) {
    const url = getUrl('translations/missing/report');
    return httpPost(url, missingTranslationReports);
}

export async function getCmsSettings() {
    const url = getUrl('settings');
    return await httpGet<CmsGeneralSetting[]>(url);
}

export async function codex(matchIds: number[], country: string) {
    const url = getUrl(`match-promotion`);
    return httpGet<PromotionTypes[]>(url, { country, matchIds: matchIds.join(',') });
}

export async function getArticleBySlug(pageSlug: string, language: Language) {
    const url = getUrl(`articles/public/by-slug/${pageSlug}/${language}`);
    return httpGet<ArticleResponse>(url);
}

export async function getArticleForPreviewBySlug(pageSlug: string, language: Language) {
    const url = getUrl(`articles/preview/${pageSlug}/${language}`);
    return httpGet<ArticleResponse>(url);
}

export function getArticleById(id: string | number) {
    const url = getUrl(`articles/public/${id}`);
    return httpGet<ArticleResponse>(url);
}

export function checkIsUserEligibleForContentBySegment(segmentId: string) {
    const url = getUrl(`articles/user-segment-check/${segmentId}`);
    return httpGet<{ isUserInSegment: boolean }>(url);
}

export async function getAvatars() {
    const url = getUrl('avatars');
    return httpGet<Avatar[]>(url);
}
