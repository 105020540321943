import React, { useState, useEffect, ReactNode } from 'react';
import Wrapper from './styles';
import { stores } from '../../../../stores';
import PaymentProviderNotAvailable from '../../provider-not-available/PaymentProviderNotAvailable';
import UiAppLoader from '../../../ui/app-loader/UiAppLoader';
import UiBox from '../../../ui/box/UiBox';
import { logger } from '../../../../services/logger';
import { getRoute, useRouter } from '../../../../services/router';
import { translate } from '../../../../services/translate';
import { depositWithPnp } from '../../../../microservices/payments';
import { PROVIDERS } from '../../../../services/payments/types';
import PaymentDepositPayAndPlayProvidersTrustly from './providers/trustly/PaymentDepositPayAndPlayProvidersTrustly';
import { Country } from '@staycool/location';
import { isMobileApp } from '../../../../services/mobile-app';
import UiDotsLoader from '../../../ui/dots-loader/UiDotsLoader';
import { useStore } from '../../../../hooks/useStore';
import PaymentDepositPayAndPlayProvidersTruelayer from './providers/truelayer/PaymentDepositPayAndPlayProvidersTruelayer';

interface Props {
    country: Country;
    currency: string;
    amount: string;
    bonusCode: string;
    provider: PROVIDERS;
    onClose: () => void;
}

export default function PaymentDepositPayAndPlay({ country, currency, amount, bonusCode, provider, onClose }: Props) {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [isPayAndPlayEnabled] = useStore(stores.isPayAndPlayEnabled);
    const [language] = useStore(stores.language);
    const [response, setResponse] = useState<Record<string, string>>({});
    const { navigateTo } = useRouter();

    const pnpComponentByProvider: Partial<Record<PROVIDERS, ReactNode>> = {
        [PROVIDERS.TRUELAYER]: (
            <PaymentDepositPayAndPlayProvidersTruelayer response={response} setIsError={setIsError} />
        ),
        [PROVIDERS.TRUSTLY]: <PaymentDepositPayAndPlayProvidersTrustly depositUrl={response.url} />,
    };

    useEffect(() => {
        if (!isPayAndPlayEnabled) {
            navigateTo(`${getRoute('welcome')}?error=${translate('Provider not available.', 'ui.payments')}`);
            return;
        }
        initPayAndPlayDeposit();
    }, []);

    async function initPayAndPlayDeposit() {
        const isRedirectProvider = !(provider in pnpComponentByProvider);
        setIsLoading(true);
        try {
            const response = await depositWithPnp({
                currency,
                amount: parseFloat(amount),
                country,
                bonusCode,
                language,
            });

            if (isRedirectProvider) {
                window.location.href = response.url;
            }
            setResponse(response);
        } catch (error) {
            logger.error('PaymentDepositPayAndPlay', 'initPayAndPlayDeposit', error);
            setIsError(true);
        }
        setIsLoading(false);
    }

    if (isError) {
        return (
            <Wrapper>
                <UiBox>
                    <div className="container">
                        <PaymentProviderNotAvailable onBack={onClose} />
                    </div>
                </UiBox>
            </Wrapper>
        );
    }

    if (isLoading) {
        return isMobileApp() ? <UiAppLoader /> : <UiDotsLoader />;
    }

    return <Wrapper>{pnpComponentByProvider[provider]}</Wrapper>;
}
