import React, { useCallback, useState } from 'react';
import UiModal from '../ui/modal/UiModal';
import { GlobalModalContextProvider, ShowModalHandlerOptions } from '../../contexts/global-modal/GlobalModalContext';
import { media } from '../../stores/media/media';
import { useStore } from '../../hooks/useStore';
import { ModalState } from '../../types/components/global-modal/types';
import classNames from 'classnames';
import omit from 'lodash/omit';

export default function GlobalModal({ children }: React.PropsWithChildren) {
    const [store, setStore] = useState<ModalState>({} as ModalState);
    const [{ isPhone }] = useStore(media);
    const { Component, params, mode, className, open } = store;
    const [stack, setStack] = useState<ModalState[]>([]);

    const showModal = useCallback(
        function (component: React.ElementType, options: ShowModalHandlerOptions = {}) {
            setStore({
                ...store,
                ...options,
                Component: component,
                open: true,
            });

            setStack([...stack, { ...options, Component: component, open: true }]);
        },
        [stack],
    );

    const hideModal = useCallback(
        function () {
            const previousState = stack[stack.length - 1];
            setStack([...stack.slice(-1)]);

            if (previousState) {
                setStore({
                    ...store,
                    ...previousState,
                });
            } else {
                setStore({
                    ...store,
                    open: false,
                });

                setTimeout(() => {
                    setStore({} as ModalState);
                }, 500);
            }
        },
        [stack],
    );

    function renderComponent() {
        if (!Component) {
            return null;
        }

        return <Component {...params} />;
    }

    return (
        <GlobalModalContextProvider value={{ store, showModal, hideModal }}>
            <UiModal
                open={Boolean(open)}
                className={className !== null && classNames('new-modal', className)}
                mode={mode ? mode : isPhone ? 'drawer' : 'default'}
                {...omit(store, ['Component', 'params', 'mode', 'className', 'open'])}
            >
                {renderComponent()}
            </UiModal>
            {children}
        </GlobalModalContextProvider>
    );
}
