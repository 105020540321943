import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from '../../../hooks/useStore';
import {
    getCasinoCategoryName,
    getCasinoCategoryRoute,
    getCasinoCategorySubprovidersRoute,
} from '../../../services/casino/categories';
import {
    CasinoCategory,
    CasinoCategoryCode,
    CasinoCategoryTypes,
    CasinoCustomCategoryIds,
    MenuItem,
    MenuState,
} from '../../../services/casino/types';
import { isFeatureAvailable } from '../../../services/feature';
import { openCasinoLobby } from '../../../services/ios-app';
import { AppType } from '../../../services/mobile-app/types';
import { getRoute, isActiveRoute } from '../../../services/router';
import { translate } from '../../../services/translate';
import { FEATURE } from '../../../services/types';
import { isTestUser } from '../../../services/user';
import { stores } from '../../../stores';
import { casino } from '../../../stores/casino';
import { media } from '../../../stores/media/media';
import Svg from '../../svg/Svg';
import UiButton from '../../ui/button/UiButton';
import Wrapper from './styles';

interface Props {
    items?: { [key in MenuState]: MenuItem[] };
}

export default function CasinoV2Menu({ items }: Props) {
    const [currentMenuState, setCurrentMenuState] = useState<MenuState>(MenuState.CASINO);
    const [favoriteGameIds] = useStore(casino.userPreferences.favoriteGameIds);
    const [menu, setMenu] = useState<{ [key in MenuState]: MenuItem[] }>();
    const [categories] = useStore<CasinoCategory[]>(casino.categories);
    const [races] = useStore(stores.casinoRace.active.races);
    const [appType] = useStore(stores.appType);
    const location = useLocation();
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [{ isSmallerThanLaptop }] = useStore(media);
    const isCasinoRaceAvailable = isFeatureAvailable(FEATURE.CASINO_RACE) && !isEmpty(races);
    const wrapperRef = useRef<HTMLDivElement>(null);

    function getIconNameByCategoryName(categoryName: string) {
        return categoryName.toLowerCase().replace(/([ &])/g, '-');
    }

    const categoryTypesToExcludeFromMenu = [
        CasinoCategoryCode.FEATURED_GAMES,
        CasinoCategoryCode.FAVORITE_GAMES,
        CasinoCategoryCode.RECENTLY_PLAYED,
        CasinoCategoryCode.RACE,
        CasinoCategoryCode.CASINO_PAYBACK,
        CasinoCategoryCode.ALL,
        CasinoCategoryCode.LIVE_CASINO,
        CasinoCategoryCode.POPULAR_GAMES,
        CasinoCategoryCode.REAL_DEALER,
    ];

    const defaultMenuItems: { [key in MenuState]: MenuItem[] } = {
        [MenuState.CASINO]: [
            {
                title: translate('casino.nav.casino-lobby'),
                route: getRoute('casino.slots.lobby'),
                icon: 'casino-lobby',
                isPublic: true,
                isAvailable: true,
            },
            {
                title: translate('Providers', 'casino.filter'),
                route: getCasinoCategorySubprovidersRoute(CasinoCategoryTypes.CASINO),
                icon: 'providers',
                isPublic: true,
                isAvailable: true,
            },
            {
                title: getCasinoCategoryName(CasinoCustomCategoryIds.FAVORITE_GAMES),
                route: `${getRoute('casino.slots')}/${translate(
                    `casino.category.${CasinoCustomCategoryIds.FAVORITE_GAMES}.slug`,
                )}`,
                icon: 'star',
                isPublic: true,
                isAvailable: isAuthenticated && !!favoriteGameIds.length,
            },
            {
                title: translate('casino.nav.casino-race'),
                route: getRoute('casino.race.schedule'),
                icon: 'casino-race',
                isPublic: isAuthenticated,
                isAvailable: isCasinoRaceAvailable,
            },
            ...categories
                .filter(
                    (category) =>
                        category.type === CasinoCategoryTypes.CASINO &&
                        !categoryTypesToExcludeFromMenu.includes(category.code),
                )
                .map((category) => ({
                    route: getCasinoCategoryRoute(category),
                    title: getCasinoCategoryName(category.id),
                    icon: getIconNameByCategoryName(category.name),
                    isPublic: true,
                    isAvailable: true,
                })),
            {
                title: translate('casino.nav.my-casino'),
                route: getRoute('casino.my-casino'),
                icon: 'user',
                isPublic: isTestUser(),
                isAvailable: true,
            },
        ],
        [MenuState.LIVE]: [
            {
                title: translate('casino.nav.casino-live-lobby'),
                route: getRoute('casino.live.lobby'),
                icon: 'casino-lobby',
                isPublic: true,
                isAvailable: true,
            },
            {
                title: translate('Providers', 'casino.filter'),
                route: getCasinoCategorySubprovidersRoute(CasinoCategoryTypes.LIVE),
                icon: 'providers',
                isPublic: true,
                isAvailable: true,
            },
            {
                title: getCasinoCategoryName(CasinoCustomCategoryIds.FAVORITE_GAMES),
                route: `${getRoute('casino.live')}/${translate(
                    `casino.category.${CasinoCustomCategoryIds.FAVORITE_GAMES}.slug`,
                )}`,
                icon: 'star',
                isPublic: true,
                isAvailable: isAuthenticated && !!favoriteGameIds.length,
            },
            ...categories
                .filter(
                    (category) =>
                        category.type === CasinoCategoryTypes.LIVE &&
                        !categoryTypesToExcludeFromMenu.includes(category.code),
                )
                .map((category) => ({
                    route: getCasinoCategoryRoute(category),
                    title: getCasinoCategoryName(category.id),
                    icon: getIconNameByCategoryName(category.name),
                    isPublic: true,
                    isAvailable: true,
                })),

            {
                title: translate('casino.nav.my-casino'),
                route: getRoute('casino.my-casino'),
                icon: 'user',
                isPublic: isTestUser(),
                isAvailable: isFeatureAvailable(FEATURE.MY_CASINO),
            },
        ],
    };

    const menuItems = items || defaultMenuItems;

    useEffect(() => {
        Object.entries(menuItems).forEach(([state, menuItems]) => {
            const currentMenuItem = menuItems.find((menuItem) => isActiveRoute(menuItem.route));
            if (currentMenuItem) {
                const targetRoute = getRoute('casino.my-casino');
                setCurrentMenuState(
                    targetRoute === currentMenuItem.route
                        ? currentMenuState === 'casino'
                            ? MenuState.CASINO
                            : MenuState.LIVE
                        : (state as MenuState),
                );
            }
        });
    }, [location]);

    useEffect(() => {
        if (wrapperRef.current) {
            for (let i = 0; i < wrapperRef.current?.children.length; i++) {
                if (wrapperRef.current?.children[i].classList.contains('active')) {
                    wrapperRef.current?.children[i].scrollIntoView({
                        inline: 'center',
                        block: 'end',
                    });
                }
            }
        }
    }, [wrapperRef.current]);

    useEffect(() => {
        const filteredLobbyMenuItems = menuItems[MenuState.CASINO].filter(
            (menuItem) => menuItem.isAvailable && menuItem.isPublic,
        );
        const filteredLiveMenuItems = menuItems[MenuState.LIVE].filter(
            (menuItem) => menuItem.isAvailable && menuItem.isPublic,
        );
        const filteredMenu = {
            [MenuState.CASINO]: filteredLobbyMenuItems,
            [MenuState.LIVE]: filteredLiveMenuItems,
        };
        setMenu(filteredMenu);
    }, [isAuthenticated, favoriteGameIds, races, items]);

    async function handleLiveLobbyClickOnIOS() {
        await openCasinoLobby();
    }

    return (
        <Wrapper ref={wrapperRef}>
            {menu &&
                menu[currentMenuState].map((menuItem) => (
                    <UiButton
                        className={classnames({ active: isActiveRoute(menuItem.route, false) }, 'menu-navigation-item')}
                        key={`${currentMenuState}_${menuItem.title}_${menuItem.route}`}
                        url={menuItem.route}
                        type={isSmallerThanLaptop ? 'button' : 'card'}
                        icon={<Svg icon={menuItem.icon} size={1.4} />}
                        size="small"
                        {...(isSmallerThanLaptop && { iconPosition: 'left' })}
                        selected={isActiveRoute(menuItem.route, false)}
                        onClick={(event) => {
                            if (appType === AppType.IOS && menuItem.icon === 'casino-live') {
                                event.preventDefault();
                                event.stopPropagation();
                                handleLiveLobbyClickOnIOS();
                            }
                        }}
                    >
                        {menuItem.title}
                    </UiButton>
                ))}
        </Wrapper>
    );
}
