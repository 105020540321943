import {
    GEOCOMPLY_REASON,
    GeoComplyMobileHint,
    GeocomplyClientError,
    GeocomplyGeopacketErrors,
    GeocomplyGeoverificationStatusStore,
    GeocomplyLogMessage,
} from './types';

export const geocomplyClientInitialStatus: GeocomplyClientInitialStatusStore = {
    logs: [],
    hint: null,
    clientError: null,
    libraryVersion: null,
    clientVersion: null,
    isConnected: false,
    isConnecting: false,
    isAttemptingConnection: false,
    isGeolocating: false,
    isAttemptingGeolocation: false,
    isLibraryLoaded: false,
};

export const geocomplyInitiaLastReason: GEOCOMPLY_REASON | null = null;
export const geocomplyInitialLicense: GeocomplyLicenseStore = { license: null, expiresAt: null };
export const geocomplyInitialServiceError: GeocomplyServiceError = null;
export const geocomplyInitialVerification: GeocomplyGeoverificationStatusStore = {
    deviceUuid: null,
    isGeoVerified: null,
    expiresAt: null,
    retryAt: null,
    ipAddress: null,
};

interface GeocomplyLicenseStore {
    license: string | null;
    expiresAt: string | null;
}

interface GeocomplyClientInitialStatusStore {
    logs: Array<GeocomplyLogMessage>;
    clientError: GeocomplyClientError | null;
    hint: GeoComplyMobileHint | null;
    libraryVersion: string | null;
    clientVersion: string | null;
    isConnected: boolean;
    isConnecting: boolean;
    isAttemptingConnection: boolean;
    isGeolocating: boolean;
    isAttemptingGeolocation: boolean;
    isLibraryLoaded: boolean;
}

type GeocomplyServiceError = {
    code: number;
    name: string;
    troubleshooter: GeocomplyGeopacketErrors;
} | null;
