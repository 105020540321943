import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    $noWrap?: boolean;
};

export default styled.label<StyledProps>`
    display: block;
    font-size: 12px;
    color: var(--font-color-primary);
    margin: 0 0 0.5rem 0;
    padding: 0 0.4rem;
    @media ${mediaQuery.isPhone} {
        font-size: 0.85rem;
    }
    ${({ $noWrap }) => $noWrap && 'white-space: nowrap;'}
`;
