import React from 'react';
import Wrapper from './styles';
import UiFormGroup from '../../../ui/form/group/UiFormGroup';
import UiFormLabel from '../../../ui/form/label/UiFormLabel';
import UiAlert from '../../../ui/alert/UiAlert';
import { FieldError, RegisterOptions, useFormContext } from 'react-hook-form';
import { translate } from '../../../../services/translate';
import { filterStyleProps } from '../../../../styles/utils';

type RadioOption = {
    value: string;
    label: string;
    editOptions?: boolean | JSX.Element;
    defaultChecked?: boolean;
    disabled?: boolean;
    dataTest?: string;
};

interface Props extends React.HTMLProps<HTMLInputElement> {
    name: string;
    options: RadioOption[];
    bottomSpacing?: string;
    radioNowrap?: boolean;
    radioRow?: boolean;
    label?: string;
    error?: FieldError;
    dataTest?: string;
}

export default function Ui2FormRadio({
    name,
    options,
    label,
    error,
    radioRow,
    dataTest,
    radioNowrap = false,
    required = false,
    ...args
}: Props) {
    const { register } = useFormContext();

    const registerOptions: RegisterOptions = {
        required: { value: required, message: translate('Oops! You forgot this field', 'ui.registration') },
    };

    return (
        <Wrapper {...filterStyleProps(args)}>
            <UiFormGroup row={radioRow} nowrap={radioNowrap}>
                {label && <UiFormLabel>{label}</UiFormLabel>}

                {options.map((option) => (
                    <label key={option.value}>
                        <input
                            {...args}
                            {...register(name, registerOptions)}
                            type="radio"
                            value={option.value}
                            defaultChecked={option.defaultChecked}
                            disabled={option.disabled}
                            data-test={dataTest}
                        />

                        <span className="pseudo-radio" />
                        <span className="pseudo-label">{option.label}</span>
                        {option.editOptions}
                    </label>
                ))}
            </UiFormGroup>
            {error && (
                <UiAlert className="input-error" failure>
                    {error.message}
                </UiAlert>
            )}
        </Wrapper>
    );
}
