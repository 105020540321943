import { useEffect } from 'react';
import { NativeMessageEventType, isMobileApp, sendNativeEvent } from '../../../services/mobile-app';
import { getRoute, useRouter } from '../../../services/router';
import { storageGet, storageSet } from '../../../services/storage';
import { stores } from '../../../stores';
import { getStoreValue } from '../../../stores/store/utils';

export default function SystemRouteListener() {
    const pathname = useRouter().pathname;

    function isCasinoGameRoute(route: string | undefined): boolean {
        return route?.startsWith(getRoute('casino-game')) || false;
    }

    useEffect(() => {
        const activeRoute = pathname;
        const lastActiveRoute = storageGet<string | undefined>('lastActiveRoute');

        const reloadRequired = getStoreValue(stores.apiVersion.reloadRequired);
        if (lastActiveRoute && activeRoute !== lastActiveRoute && reloadRequired) {
            window.location.reload();
        }

        if (isMobileApp() && isCasinoGameRoute(lastActiveRoute) && !isCasinoGameRoute(activeRoute)) {
            sendNativeEvent({ type: NativeMessageEventType.GAME_CLOSED });
        }

        storageSet('lastActiveRoute', activeRoute);
    }, [pathname]);

    return null;
}
