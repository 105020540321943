import { Country } from '@staycool/location';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../hooks/useStore';
import { updateTrackingIDs } from '../../../services/analytics';
import { validatePublicBonusCode } from '../../../services/bonuses/bonus';
import { isFeatureAvailable } from '../../../services/feature';
import { PROVIDERS } from '../../../services/payments/types';
import { getRoute } from '../../../services/router';
import { storageGet } from '../../../services/storage';
import { translate } from '../../../services/translate';
import { FEATURE } from '../../../services/types';
import { Currency } from '../../../services/wallet/types';
import { stores } from '../../../stores';
import DangerousHtml from '../../dangerous-html/DangerousHtml';
import PaymentDepositProviderPayAndPlay from '../../payment/deposit/pay-and-play/PaymentDepositPayAndPlay';
import Ui2Form from '../../ui-2/form/Ui2Form';
import Ui2FormSelect from '../../ui-2/form/select/Ui2FormSelect';
import Ui2FormTextInput from '../../ui-2/form/text-input/Ui2FormTextInput';
import UiAlert from '../../ui/alert/UiAlert';
import UiButton from '../../ui/button/UiButton';
import UiFormGroup from '../../ui/form/group/UiFormGroup';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import Wrapper from './styles';

interface Props {
    onClose: () => void;
    setShouldDisableBackdropClick: (value: boolean) => void;
}

interface FormValues {
    currency: string;
    depositAmount: string;
    bonusCode: string;
}

export default function AuthPayAndPlay({ onClose, setShouldDisableBackdropClick }: Props) {
    const [currencyOptions, setCurrencyOptions] = useState<Currency[]>([]);
    const [isRegisteredUser, setIsRegisteredUser] = useState(false);
    const [shouldOpenPnpDeposit, setShouldOpenPnpDeposit] = useState(false);
    const [isBonusCodeValid, setIsBonusCodeValid] = useState(false);
    const [pnpDepositParams, setPnpDepositParams] = useState<FormValues>({
        currency: '',
        depositAmount: '',
        bonusCode: '',
    });
    const [payAndPlaySettings] = useStore(stores.payAndPlaySettings);
    const form = useForm<FormValues>({
        mode: 'onSubmit',
    });
    const { errors } = form.formState;
    const selectedCurrency = form.watch('currency');

    useEffect(() => {
        setIsRegisteredUser(storageGet('userAlias'));
        updateTrackingIDs();
    }, []);

    useEffect(() => {
        if (payAndPlaySettings) {
            const options = Object.keys(payAndPlaySettings.minAmount) as Currency[];
            const [firstOption] = options;
            setCurrencyOptions(options);
            form.setValue('currency', firstOption);
        }
    }, [payAndPlaySettings]);

    function confirmDeposit({ currency, depositAmount, bonusCode }: FormValues) {
        const formattedAmount = Number(depositAmount).toFixed(2);
        setPnpDepositParams({ currency, depositAmount: formattedAmount, bonusCode });
        setShouldOpenPnpDeposit(true);
        setShouldDisableBackdropClick(true);
    }

    function validateDepositAmount(amount?: number) {
        const [minAmount, maxAmount] = (['minAmount', 'maxAmount'] as const).map(
            (field) => payAndPlaySettings?.[field][selectedCurrency] || 0,
        );

        if (!amount) {
            return translate('Oops! Seems you forgot to enter amount', 'ui.account');
        } else if (amount < minAmount) {
            return translate('Minimum deposit amount is {{ amount }} {{ currency }}.', 'ui.account', {
                amount: minAmount,
                currency: selectedCurrency,
            });
        } else if (amount > maxAmount) {
            return translate('Maximum deposit amount is {{ amount }} {{ currency }}.', 'ui.account', {
                amount: maxAmount,
                currency: selectedCurrency,
            });
        }
        return true;
    }

    async function validateBonusCode(bonusCode: string) {
        if (!bonusCode) {
            return true;
        }
        setIsBonusCodeValid(false);
        const { isError, message, isSuccess } = await validatePublicBonusCode(bonusCode);
        if (isError) {
            return message;
        }
        if (isSuccess) {
            setIsBonusCodeValid(true);
        }
        return true;
    }

    return (
        <Wrapper>
            {shouldOpenPnpDeposit ? (
                <PaymentDepositProviderPayAndPlay
                    provider={payAndPlaySettings?.provider as PROVIDERS}
                    country={payAndPlaySettings?.country as Country}
                    currency={pnpDepositParams.currency}
                    amount={pnpDepositParams.depositAmount}
                    bonusCode={pnpDepositParams.bonusCode}
                    onClose={onClose}
                />
            ) : (
                <Ui2Form onSubmit={confirmDeposit} useFormMethods={form}>
                    <UiFormGroup row nowrap className="amount-row">
                        <Ui2FormTextInput
                            name="depositAmount"
                            type="number"
                            data-test="pay-and-play-amount"
                            placeholder={translate('Amount', 'ui.account')}
                            validator={{
                                validate: {
                                    depositAmount: validateDepositAmount,
                                },
                            }}
                        />
                        <Ui2FormSelect
                            name="currency"
                            data-test="pay-and-play-currency"
                            placeholder={translate('Amount', 'ui.account')}
                            error={form.formState.errors.currency}
                            options={currencyOptions.map((cur) => ({ label: cur, value: cur }))}
                        />
                    </UiFormGroup>
                    {payAndPlaySettings?.isBonusAllowed && (
                        <>
                            <Ui2FormTextInput
                                required={false}
                                name="bonusCode"
                                label={translate('Wait, I have a bonus code', 'ui.bonus')}
                                placeholder={translate('Bonus code', 'ui.account')}
                                validator={{
                                    validate: {
                                        bonusCode: validateBonusCode,
                                    },
                                }}
                            />
                            {[errors.bonusCode, errors.depositAmount].map((error, idx) =>
                                error ? (
                                    <UiAlert failure key={idx}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: error.message as string,
                                            }}
                                        />
                                    </UiAlert>
                                ) : null,
                            )}
                            {isBonusCodeValid && (
                                <UiAlert success>{translate('Bonus code is valid', 'ui.bonus')}</UiAlert>
                            )}
                        </>
                    )}
                    <DangerousHtml
                        className="pay-and-play-terms"
                        elementType="p"
                        content={translate('agree to terms', 'ui.pay-and-play')}
                    />
                    <UiButton
                        type="button"
                        data-test="pay-and-play-deposit"
                        color="primary"
                        size="large"
                        isFormSubmitButton
                        block
                    >
                        {translate('Deposit & Play', 'ui.pay-and-play')}
                    </UiButton>

                    {!isRegisteredUser && isFeatureAvailable(FEATURE.REGISTRATION) && (
                        <p className="alternative-option-link">
                            {translate('For more deposit options use our', 'ui.pay-and-play')}
                            <br />
                            <UiNavLink onClick={onClose} to={`${getRoute('registration')}`}>
                                {translate('regular signup form', 'ui.pay-and-play')}
                            </UiNavLink>
                        </p>
                    )}
                    {isRegisteredUser && (
                        <p className="alternative-option-link">
                            {translate('To login without deposit use our', 'ui.pay-and-play')}
                            <br />
                            <UiNavLink onClick={onClose} to={`${getRoute('login')}`}>
                                {translate('regular login form', 'ui.pay-and-play')}
                            </UiNavLink>
                        </p>
                    )}
                    <DangerousHtml
                        className="alternative-option-link"
                        elementType="p"
                        content={translate('more info', 'ui.pay-and-play')}
                    />
                </Ui2Form>
            )}
        </Wrapper>
    );
}
