import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    bottomSpacing?: string;
};

export default styled.div<StyledProps>`
    margin-bottom: ${({ bottomSpacing }) => bottomSpacing || 'var(--spacing-12)'};
    .select-container {
        position: relative;
        &:after {
            pointer-events: none;
            content: '\u25BE'; //NOTE: ▾ (U+025BE)
            position: absolute;
            right: 0;
            top: 12px;

            height: 1rem;
            width: 1rem;

            font-size: 0.8rem;
        }

        & .select-placeholder {
            color: var(--font-color-subtle);
        }
    }

    &.error select {
        border-color: var(--color-error);
    }

    select {
        appearance: none;
        background-color: var(--form-input-bg);
        border: var(--form-input-border);
        border-radius: var(--ui-radius);
        cursor: pointer;
        color: var(--form-input-text);
        height: 2.5rem;
        padding: 0 1.5rem 0 0.75rem;
        width: 100%;
        @media ${mediaQuery.isPhone} {
            height: 3rem;
        }

        :focus {
            outline: none;
            box-shadow: none;
        }

        option {
            color: var(--form-input-text);
        }
        &[disabled] {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    &.input-sm {
        max-width: 70px;
    }

    &.input-md {
        max-width: 80px;
    }

    .stake-currency {
        position: absolute;
        top: 36%;
        transform: translate(0, -50%);
        left: var(--spacing-8);
        font-size: var(--font-14);
    }
    .input-error {
        margin-top: var(--spacing-4);
    }
`;
