import React from 'react';
import Wrapper from './styles';
import { OnsiteMessageWithCampaign } from '../../../../microservices/crm/types';
import { useTimeAgo } from '../../../../hooks/useTimeAgo';
import DangerousHtml from '../../../dangerous-html/DangerousHtml';
import classNames from 'classnames';
import { isUnreadMessage } from '../../../../services/onsite-messages';

interface Props {
    message: OnsiteMessageWithCampaign;
    onMessageClick?: () => Promise<void>;
}

export default function CrmMessageInfo({ message, onMessageClick }: Props) {
    const isUnread = isUnreadMessage(message);
    const timeDifference = useTimeAgo({ date: message.createdAt });

    const description = message?.description
        ? message.description.replace(/<(?!\/?span\b)[^>]*>/gm, ' ')
        : message?.mascotMessage;

    return (
        <Wrapper onClick={onMessageClick} className={classNames({ ['crm-clickable-text']: onMessageClick })}>
            <div className="crm-title">
                <span className="crm-title-text crm-ellipsis">{message.title}</span>
                {!onMessageClick && <span className="crm-title-time">&#8729; {timeDifference}</span>}
            </div>
            {description && (
                <DangerousHtml
                    content={description}
                    className={classNames('crm-ellipsis', { ['crm-description-read']: !isUnread && onMessageClick })}
                />
            )}
        </Wrapper>
    );
}
