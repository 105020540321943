import React from 'react';
import Wrapper from './styles';
import { filterStyleProps } from '../../../styles/utils';

interface Props extends React.PropsWithChildren, React.HTMLAttributes<HTMLDivElement> {
    options?: {
        isFirstColumnSticky?: boolean;
        zebraStyle?: boolean;
        gap?: number;
    };
}

export default function UiTable({ options, children, ...rest }: Props) {
    const { isFirstColumnSticky, zebraStyle, gap } = options ?? {};

    return (
        <Wrapper
            $isFirstColumnSticky={isFirstColumnSticky}
            $zebraStyle={zebraStyle}
            $gap={gap}
            {...filterStyleProps(rest)}
        >
            {children}
        </Wrapper>
    );
}
