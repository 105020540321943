export const SHOW_SECTION = {
    ROUND_PREDICTIONS: 'ROUND_PREDICTIONS',
    LEADERBOARDS: 'LEADERBOARDS',
};
export const SHOW_ROUND_INFO = {
    ROUND: 'ROUND',
    TOURNAMENT: 'TOURNAMENT',
};
export const ROUND_STATUS = {
    ACTIVE: 'ACTIVE',
    FINISHED: 'FINISHED',
};
