import { getTranslationHelpers } from '../../../services/translation/helpers';
import { reportMissingTranslations } from '../../../microservices/cms';
import { useInterval } from '../../../services/hooks';
import { logger } from '../../../services/logger';

export default function SystemMissingTranslations() {
    useInterval(() => {
        sendMissingTranslationsReport();
    }, 30 * 1000);

    async function sendMissingTranslationsReport() {
        const {
            missingTranslationReports,
            setMissingTranslationReports,
            isReportingMissingTranslations,
            setIsReportingMissingTranslations,
        } = getTranslationHelpers();
        if (!missingTranslationReports.length || isReportingMissingTranslations) {
            return;
        }

        setIsReportingMissingTranslations(true);
        const missingTranslationReportsToSend = [...missingTranslationReports];
        setMissingTranslationReports([]);

        try {
            await reportMissingTranslations(missingTranslationReportsToSend);
        } catch (error) {
            logger.error('SystemMissingTranslations', 'sendMissingTranslationsReport', error);
            setMissingTranslationReports([...missingTranslationReports, ...missingTranslationReportsToSend]);
        }

        setIsReportingMissingTranslations(false);
    }

    return null;
}
