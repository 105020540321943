import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Wrapper from './styles';
import UiFormGroup from '../../ui/form/group/UiFormGroup';
import { stores } from '../../../stores';
import { translate } from '../../../services/translate';
import { delay } from '../../../services/util';
import { tryBonusCode } from '../../../services/bonuses/bonus';
import UiAlert from '../../ui/alert/UiAlert';
import { getRoute, useRouter } from '../../../services/router';
import { validators } from '../../../services/validators';
import UiButton from '../../ui/button/UiButton';
import DangerousHtml from '../../dangerous-html/DangerousHtml';
import { filterStyleProps } from '../../../styles/utils';
import { useStore } from '../../../hooks/useStore';
import { isSIM } from '../../../services/environment';
import UiTextInput from '../../ui/text-input/UiTextInput';
import debounce from 'lodash/debounce';

interface Props {
    depositAmount?: number;
    isShowingLinkInitially?: boolean;
    selectedProvider?: string;
    onBonusClaimed?: () => void;
    isModal?: boolean;
    fromQueryParams?: boolean;
    bonusCode: string;
    onBonusCodeChange: (code: string) => void;
    onBonusCodeValidChange?: (isValid: boolean) => void;
}

export default function PromotionBonusEntry({
    depositAmount,
    selectedProvider,
    isShowingLinkInitially = false,
    isModal = false,
    onBonusClaimed = () => {},
    bonusCode,
    onBonusCodeChange,
    onBonusCodeValidChange,
    ...rest
}: Props) {
    const { navigateTo } = useRouter();
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [isShowingLink, setIsShowingLink] = useState(Boolean(isShowingLinkInitially) && !bonusCode);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitButtonVisible, setIsSubmitButtonVisible] = useState(Boolean(bonusCode));
    const [isDepositCallToActionActive, setIsDepositCallToActionActive] = useState(false);
    const [bonusResponse, setBonusResponse] = useState<{
        bonusValidationMessage: any;
        status: any;
        isSuccess: boolean;
        isError: boolean;
        message: string;
        rawResponse: any;
    } | null>(null);
    const [validateResponse, setValidateResponse] = useState<{ error?: string; success?: string } | null>();

    const bonusCodeValidator = useMemo(
        () =>
            Boolean(selectedProvider)
                ? validators.bonusCodeDeposit(depositAmount, selectedProvider)
                : validators.bonusCode,
        [depositAmount, selectedProvider],
    );
    const debouncedHandleValidateBonusCode = useCallback(debounce(handleValidateBonusCode, 500), []);

    useEffect(() => {
        bonusCode && handleValidateBonusCode(bonusCode);
    }, []);

    async function trySubmitBonusCode() {
        setIsSubmitting(true);
        setValidateResponse(null);
        const response = await tryBonusCode(bonusCode, Boolean(depositAmount) && Boolean(selectedProvider));
        setBonusResponse(response);
        setIsSubmitting(false);

        if (response.isSuccess) {
            if (response.status === 'NEED_DEPOSIT') {
                setIsDepositCallToActionActive(true);
            } else {
                await delay(1000);
                reset();
                onBonusClaimed();
                navigateTo(getRoute('promotions.bonuses'));
            }
        }
    }

    function reset() {
        setValidateResponse(null);
        setBonusResponse(null);
        onBonusCodeChange('');
        stores.bonuses.prefilledBonusCode.set(null);
        setIsDepositCallToActionActive(false);
    }

    function handleGoToDepositPage() {
        reset();
        navigateTo(`${getRoute(isSIM() ? 'purchase-packages' : 'deposit')}?bonus=${bonusCode}`);
    }

    async function handleValidateBonusCode(bonusCode: string) {
        const response = await bonusCodeValidator(bonusCode);

        setValidateResponse(response);
    }

    function handleBonusCodeChange(event: ChangeEvent<HTMLInputElement>) {
        onBonusCodeChange(event.target.value);
        debouncedHandleValidateBonusCode(event.target.value);
    }

    function renderMessage(success?: string, error?: string) {
        return (
            <UiFormGroup row>
                {success && (
                    <UiAlert success>
                        <DangerousHtml content={success} />
                    </UiAlert>
                )}
                {error && (
                    <UiAlert failure>
                        <DangerousHtml content={error} />
                    </UiAlert>
                )}
            </UiFormGroup>
        );
    }

    if (bonusResponse && isDepositCallToActionActive) {
        return (
            <Wrapper {...filterStyleProps({ ...rest })}>
                <div className="bonus-entry">
                    <UiAlert success>
                        <DangerousHtml content={bonusResponse.message} />
                    </UiAlert>

                    {!isShowingLinkInitially && (
                        <UiButton color="primary" onClick={handleGoToDepositPage}>
                            {translate('Go to deposit', 'ui.bonus')}
                        </UiButton>
                    )}
                </div>
            </Wrapper>
        );
    }

    return (
        <Wrapper {...filterStyleProps({ ...rest })}>
            <div className="bonus-entry">
                {isAuthenticated && isShowingLink && (
                    <p className="bonus-code-link" onClick={() => setIsShowingLink(false)}>
                        {translate('Have a bonus code?', 'ui.account')}
                    </p>
                )}
                {isAuthenticated && !isShowingLink && (
                    <div className={classNames('bonus-code-entry', { 'is-modal': isModal })}>
                        <UiTextInput
                            value={bonusCode}
                            onChange={handleBonusCodeChange}
                            className="bonus-code-input"
                            placeholder={translate('Bonus code', 'ui.account')}
                            disabled={isSubmitting}
                            onFocus={() => setIsSubmitButtonVisible(true)}
                        />
                        {isSubmitButtonVisible && (
                            <UiButton
                                type="button"
                                color="primary"
                                isLoading={isSubmitting}
                                disabled={!validateResponse?.success}
                                onClick={trySubmitBonusCode}
                            >
                                {translate('Submit', 'ui.bonus')}
                            </UiButton>
                        )}
                    </div>
                )}
                {bonusCode && validateResponse && renderMessage(validateResponse.success, validateResponse.error)}
                {bonusResponse &&
                    renderMessage(
                        bonusResponse?.isSuccess ? bonusResponse.message : undefined,
                        bonusResponse?.isError ? bonusResponse.message : undefined,
                    )}
            </div>
        </Wrapper>
    );
}
