import { createGlobalStore } from '../store/utils';
import { Environment } from './types';
import { ClientName } from '../../services/utils/types';
import { Licence, Market } from '@staycool/location';
import { ObjectValues } from '../../services/ts-utils';

const initialStore = {
    QUALIFIED_NAME: '',
    AVAILABLE_LICENSES: [] as Licence[],
    AVAILABLE_MARKETS: [] as Market[],
    SENTRY_DSN: '',
    CASINO_GAMES_PICS: '',
    CMS_IMAGES: '',
    IMAGES_HOST: '',
    IMAGE_BASE_URL: '',
    DIGITAL_SPORTS: '',
    ENVIRONMENT: '' as 'dev' | 'stage' | 'qa' | 'prod',
    LOQATE_API_KEY: '',
    ROOT_URL: '',
    CLIENT_NAME: '' as ObjectValues<typeof ClientName>,
    THEME: '',
    LVDC_ASSOCIATION_MAPPING: {} as Record<string, number>,
};

export const environment = createGlobalStore({ ...initialStore } as Environment, 'environment');

window.environment = environment;
