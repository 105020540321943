import { Country } from '@staycool/location';
import { getServiceUrl, httpGet, httpPatch, httpPost, httpPut } from '../services/api';
import { User, UserQueryParameters } from '../services/types';
import { LoginHistory, UserCountry } from '../services/users/types';
import { AnnualReport } from '../stores/responsible-gaming/types';

const getUrl = (url) => getServiceUrl('users', url);

export function getMe(query?: UserQueryParameters) {
    const url = getUrl('me');
    return httpGet<User>(url, query);
}

export function appealAccountClosure(id: string, form: { question: string; answer: string }[]) {
    const url = getUrl('appeal');
    return httpPost<void>(url, { id, form });
}

export async function getGeneratedUserData(country: Country) {
    return httpGet<{
        alias: string;
        phonePrefix: string;
        phoneNumber: string;
        password: string;
        email: string;
        personalId: string;
        gender: string;
        firstName: string;
        lastName: string;
        fathersName?: string;
        mothersName?: string;
        address: string;
        zip: string;
        city: string;
        province?: string;
        birthDate: Record<string, string>;
    }>(getUrl('fake-user'), { country });
}

export function getAccountReopenForm(id: string) {
    const url = getUrl('appeal/form');
    return httpGet<{ questions: string[] }>(url, { id });
}

export function postZignsecBankVerification(bankIdentifier: string) {
    const url = getUrl(`user-bank-verification/?targetUrl=${window.location.href}&bankIdentifier=${bankIdentifier}`);
    return httpPost<{ redirectUrl: string }>(url);
}

export function saveChileanPersonalId(personalId: string) {
    return httpPut(getUrl('required-fields/chile/'), { personalId });
}

export async function getSessionStartTime() {
    const url = getUrl('user-login-history/session-start');
    return httpGet<{ sessionStart: string }>(url);
}

export async function getSourceOfWealthForm() {
    return httpGet<{ fields: { fieldId: number; label: string }[] }>(getUrl('source-of-wealth'));
}

export async function saveSourceOfWealthForm(data: { fieldId: number; label: string; value: string }[]) {
    return httpPost<void>(getUrl('source-of-wealth'), data);
}

export async function saveLoqateAddress(addressLookupId: string) {
    return httpPut<{
        id: string;
        country: string;
        province: string;
        zip: string;
        city: string;
        address: string;
    }>(getUrl('me/loqate'), { addressLookupId });
}

export async function validateEmail(email: string) {
    return httpPost<{ isDisposableEmail: boolean }>(getUrl('validators/public/email'), { email });
}

export async function validateName(data: {
    externalFirstOrFullName: string;
    externalLastName?: string;
    shouldCloseAccountOnMismatch?: boolean;
}) {
    return httpPost<{ areNamesSimilar: boolean; comment: string }>(getUrl('validators/name'), data);
}

export function validateAlias(alias?: string) {
    return httpPost<{ result: boolean }>(getUrl('validators/public/alias'), {
        alias,
    });
}

export function validatePhoneNumber(country: Country, phonePrefix: string, phoneNumber: string) {
    return httpPost<{ result: boolean }>(getUrl('validators/public/phoneNumber'), {
        country,
        phoneNumber,
        phonePrefix,
    });
}

export async function getCountries() {
    return httpGet<UserCountry[]>(getUrl('countries'));
}

export function getAnnualReport(): Promise<AnnualReport> {
    return httpGet(getUrl('get-annual-report/'));
}

export function getAccessHistory() {
    return httpGet<LoginHistory[]>(getUrl('user-login-history'));
}

export function acceptTermsAndConditions() {
    return httpPost<{ message: string }>(getUrl('terms-and-conditions/accept/'));
}

export function acceptPeruTermsAndConditions(consent: {
    POLAR_KYC_MIGRATION?: boolean;
    JUEGOS_TERMS_AND_CONDITIONS_CONFIRMATION?: boolean;
    JUEGOS_PRIVACY_CONFIRMATION?: boolean;
    PEP_CONFIRMATION?: boolean;
}) {
    return httpPost<void>(getUrl('consent'), consent);
}

export function getUserProductStatus(userId: string) {
    return httpGet<UserProductStatus>(getUrl(`/user-product-status/${userId}`));
}

export async function updateProfile(userProfile: Partial<User>) {
    const url = getUrl('me/');
    await httpPatch<User>(url, userProfile);
}

interface UserProductStatus {
    userId: string;
    racebookClosed: boolean;
    createdAt: Date;
    updatedAt: Date;
}
