// TODO: move back to betslip once test cyclic dependency issues are fixed

import round from 'lodash/round';
import { environment } from '../../stores/environment/environment';
import { payoutRoundUp } from '@staycool/cb-round';
import { getStoreValue } from '../../stores/store/utils';

export function getFixedStake(stake: number | string) {
    if (
        !stake ||
        isNaN(Number(stake)) ||
        /[a-zA-Z]/.test(String(stake)) ||
        Number(stake) > Number.MAX_SAFE_INTEGER ||
        Number(stake) < Number.MIN_SAFE_INTEGER
    ) {
        return '0';
    }

    stake = typeof stake === 'number' ? stake.toString() : stake.replace(',', '.');

    const [integerPart, fractionalPart] = stake.split('.');
    const trimmedFractional = (fractionalPart || '').slice(0, 2);

    return fractionalPart || stake.endsWith('.')
        ? `${parseInt(integerPart, 10)}.${trimmedFractional}`
        : `${parseInt(integerPart, 10)}`;
}

export const payoutRound = (value: number) => {
    const { FEATURE_ENABLED_PAYOUT_ROUND_UP } = getStoreValue(environment);
    if (FEATURE_ENABLED_PAYOUT_ROUND_UP === 'true') {
        return payoutRoundUp(value);
    }
    return round(value, 2);
};
