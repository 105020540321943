import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.footer`
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-16);
    background: var(--footer-bg);
    @media ${mediaQuery.isPhone} {
        padding-bottom: calc(var(--mobile-nav-height) + var(--betslip-toggle-height));
    }
    &.hidden {
        display: none;
    }
`;
