import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { translate } from '../../../../services/translate';
import { useInterval } from '../../../../services/hooks';
import moment from 'moment';
import { toast } from 'react-toastify';
import { prolongSessionReminder } from '../../../../microservices/responsible-gaming';
import UiGroup from '../../../ui/group/UiGroup';
import { stores } from '../../../../stores';
import { logger } from '../../../../services/logger';
import { getLastActiveProductRoute, getRoute, isActiveRoute, useRouter } from '../../../../services/router';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import values from 'lodash/values';
import round from 'lodash/round';
import { logoutByStopSession } from '../../../../services/responsible-gaming';
import UiButton from '../../../ui/button/UiButton';
import UiNavLink from '../../../ui/nav-link/UiNavLink';
import classNames from 'classnames';
import Svg from '../../../svg/Svg';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { PRODUCT } from '../../../../types/common';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

export default function ResponsibleGamingAlertSessionReminder() {
    const { navigateTo } = useRouter();
    const [unApprovedSessionReminder] = useStore(stores.responsibleGaming.unApprovedSessionReminder);
    const [isUnapprovedSessionReminderOpen, setIsUnapprovedSessionReminderOpen] = useStore(
        stores.modals.isUnapprovedSessionReminderOpen,
    );
    const [durationElapsedFromSessionStart, setDurationElapsedFromSessionStart] = useState('');
    const [totalWalletBalanceChangeAmount, setTotalWalletBalanceChangeAmount] = useState(0);
    const [isProlongingReminder, setIsProlongingReminder] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [{ isPhone }] = useStore(media);

    const [applicationType] = useStore(stores.applicationType);

    useEffect(() => {
        initializeTransactionsStatistics();
        updateSessionDuration();
    }, [unApprovedSessionReminder]);

    useInterval(() => {
        updateSessionDuration();
    }, 1000);

    function updateSessionDuration() {
        if (!unApprovedSessionReminder || applicationType === 'poker-download') {
            return;
        }

        const { sessionStart } = unApprovedSessionReminder;

        if (sessionStart) {
            const currentTime = moment().utc();
            const sessionStartTime = moment(sessionStart);
            const durationFromSessionStart = moment.duration(currentTime.diff(sessionStartTime));
            setDurationElapsedFromSessionStart(durationFromSessionStart.format('hh:mm:ss', { trim: false }));
        }
    }

    function initializeTransactionsStatistics() {
        if (!unApprovedSessionReminder || applicationType === 'poker-download') {
            return;
        }

        const { wallet_data } = unApprovedSessionReminder;

        if (wallet_data) {
            setTotalWalletBalanceChangeAmount(
                wallet_data.reduce(
                    (totalWalletBalanceChangeAmount, walletTransaction) =>
                        totalWalletBalanceChangeAmount + walletTransaction.amount_uc,
                    0,
                ),
            );
        }
    }

    function getWalletTransactionsByProduct() {
        const initialProductTransactions = {
            [PRODUCT.SPORTSBOOK]: {
                amount: 0,
                product: PRODUCT.SPORTSBOOK,
            },

            [PRODUCT.CASINO]: {
                amount: 0,
                product: PRODUCT.CASINO,
            },
            [PRODUCT.POKER]: {
                amount: 0,
                product: PRODUCT.POKER,
            },
            [PRODUCT.VIRTUAL_SPORTS]: {
                amount: 0,
                product: PRODUCT.VIRTUAL_SPORTS,
            },
        };

        const currentProductTransactions = mapValues(
            keyBy(unApprovedSessionReminder?.wallet_data, (transaction) => transaction.product.toLowerCase()),

            (transaction) => ({ amount: transaction.amount_uc, product: transaction.product }),
        );

        return values(merge(initialProductTransactions, currentProductTransactions));
    }

    async function prolongReminder() {
        setIsUnapprovedSessionReminderOpen(false);
        setIsProlongingReminder(true);

        try {
            await prolongSessionReminder(unApprovedSessionReminder);
        } catch (error) {
            logger.error('ResponsibleGamingAlertSessionReminder', 'prolongReminder', error);
            toast.error(translate('Failed to prolong reminder!', 'ui.responsible-gaming'));
        }

        setIsProlongingReminder(false);
    }

    async function stopSession() {
        setIsUnapprovedSessionReminderOpen(false);
        setIsLoggingOut(true);
        await logoutByStopSession();
        if (isActiveRoute(getRoute('casino-game'), false)) {
            navigateTo(getLastActiveProductRoute());
        }
        setIsLoggingOut(false);
    }

    if (!unApprovedSessionReminder) {
        return null;
    }

    return (
        <Wrapper
            open={isUnapprovedSessionReminderOpen}
            title={translate('Session Reminder', 'ui.common')}
            icon={<Svg icon="rg-session-reminder" size={4} />}
            maxWidth={300}
            minWidth={300}
            className="new-modal"
            mode={isPhone ? 'drawer' : undefined}
        >
            <UiGroup vertical>
                {durationElapsedFromSessionStart && (
                    <p className="session-time">
                        {translate('Session time', 'ui.common')}: &nbsp;
                        <em>{durationElapsedFromSessionStart}</em>
                    </p>
                )}

                {getWalletTransactionsByProduct().map((walletTransaction) => (
                    <div key={walletTransaction.product} className="one-product">
                        {translate(walletTransaction.product, 'ui.common')}
                        <em
                            className={classNames({
                                positive: round(walletTransaction.amount, 2) > 0,
                                negative: round(walletTransaction.amount, 2) < 0,
                            })}
                        >
                            {formattedAmountWithCurrency(walletTransaction.amount)}
                        </em>
                    </div>
                ))}

                <div className="all-products-total">
                    {translate('Total', 'ui.common')}
                    <span
                        className={classNames('total-amount', {
                            positive: round(totalWalletBalanceChangeAmount, 2) > 0,
                            negative: round(totalWalletBalanceChangeAmount, 2) < 0,
                        })}
                    >
                        {formattedAmountWithCurrency(totalWalletBalanceChangeAmount)}
                    </span>
                </div>
            </UiGroup>

            <div className="buttons-group">
                <UiButton color="warning" onClick={() => stopSession()} isLoading={isLoggingOut} block>
                    {translate('Stop and Get Rest', 'ui.reminders')}
                </UiButton>

                <UiButton color="primary" onClick={() => prolongReminder()} isLoading={isProlongingReminder} block>
                    {translate('Continue Playing', 'ui.reminders')}
                </UiButton>
            </div>

            <p>
                {translate('You can set new deposit limits or manage existing ones on', 'ui.responsible-gaming')}{' '}
                <UiNavLink to={getRoute('responsible-gaming.set-limits')} onClick={prolongReminder}>
                    {translate('Set Limits View', 'ui.responsible-gaming')}
                </UiNavLink>
                .
            </p>
            <p>
                {translate('You can find all responsible gaming tools and information on', 'ui.responsible-gaming')}{' '}
                <UiNavLink to={getRoute('responsible-gaming')} onClick={prolongReminder}>
                    {translate('Responsible Gaming View', 'ui.responsible-gaming')}
                </UiNavLink>
                .
            </p>
        </Wrapper>
    );
}
