import styled from 'styled-components';

export default styled.div`
    display: flex;
    align-items: center;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;

    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);

    background-color: var(--mobile-nav-bg);
    border-top: var(--mobile-nav-border);

    min-height: var(--mobile-nav-height);
    animation-duration: 100ms !important;

    .mobile-tabs {
        display: flex;
        align-items: center;
        width: 100%;
        > * {
            flex: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        > *:not(:last-child) {
            margin-right: 0.2rem;
        }
    }
    .hidden {
        display: none;
    }
`;
