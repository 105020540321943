import React, { useState } from 'react';
import Wrapper from './styles';
import { getAvailableLanguagesMemoized, languagesByKey, LanguageSelection } from '../../../../services/language';
import classNames from 'classnames';
import { isFeatureAvailable } from '../../../../services/feature';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';
import { FEATURE } from '../../../../services/types';
import { Language } from '../../../../services/language/types';

interface Props {
    className?: string;
    currentLanguage: string;
    onValueChange: (language: Language) => void;
    position?: 'top' | 'bottom';
    shift?: 'center' | 'right' | 'left';
}

export default function UiLanguageSelectMini({
    className,
    onValueChange = () => {},
    shift = 'center',
    position = 'bottom',
    currentLanguage = 'en',
}: Props) {
    const [isBoardOpened, setIsBoardOpened] = useState(false);
    const [{ isDesktop, isLaptop }] = useStore(media);
    const languageKey = languagesByKey[currentLanguage].locale.slice(-2).toLowerCase();

    function openDropdown() {
        setIsBoardOpened(true);
    }

    function closeDropDown() {
        setIsBoardOpened(false);
    }

    function getLanguages() {
        return getAvailableLanguagesMemoized().filter((language) => currentLanguage !== language.value);
    }

    function handleClick(event: React.MouseEvent, language: LanguageSelection) {
        event.stopPropagation();
        onValueChange(language.value);
    }

    return (
        <Wrapper
            className={classNames(className, 'ui-language-select-mini-wrapper')}
            onMouseEnter={isDesktop || isLaptop ? openDropdown : undefined}
            onMouseLeave={closeDropDown}
            onClick={!(isDesktop || isLaptop) ? () => setIsBoardOpened(!isBoardOpened) : undefined}
            $position={position}
            $shift={shift}
            data-test="dropdown-languages"
        >
            {isFeatureAvailable(FEATURE.LANGUAGE_FLAG) && (
                <img className="flag" width="25px" src={`/assets/images/flags/1x1/${languageKey}.svg`} alt="" />
            )}
            <div className="ui-language-select-mini-text">{languagesByKey[currentLanguage].value}</div>

            <div className={classNames('ui-language-select-mini-board-container-overflow', { opened: isBoardOpened })}>
                {getLanguages().length > 0 && (
                    <div className="ui-language-select-mini-board-container">
                        <div className="ui-language-select-mini-board-container-arrow" />
                        <div
                            className={`${
                                isFeatureAvailable(FEATURE.LANGUAGE_FLAG)
                                    ? 'ui-language-select-mini-board'
                                    : 'ui-language-select-mini-board ui-language-select-mini-board-column'
                            }`}
                        >
                            {getLanguages().map((language) => (
                                <div className="ui-language-select-mini-board-language-container" key={language.value}>
                                    <div className="ui-language-select-mini-board-language">
                                        {isFeatureAvailable(FEATURE.LANGUAGE_FLAG) ? (
                                            <div
                                                className="flag-container"
                                                onClick={(event) => handleClick(event, language)}
                                                data-test={language.value}
                                            >
                                                <img
                                                    className="flag"
                                                    width="25px"
                                                    loading="lazy"
                                                    src={`/assets/images/flags/1x1/${language.locale
                                                        .slice(-2)
                                                        .toLowerCase()}.svg`}
                                                    alt=""
                                                />
                                                <div className="ui-language-select-mini-board-language-text">
                                                    <span>{language.value}</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                className="ui-language-select-mini-board-language-text"
                                                onClick={(event) => handleClick(event, language)}
                                            >
                                                {language.value.toUpperCase()}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </Wrapper>
    );
}
