import React, { createContext, useContext, useEffect, useState } from 'react';
import ScriptLoader from '../../components/script-loader/ScriptLoader';
import { isFeatureAvailable } from '../../services/feature';
import { FEATURE } from '../../services/types';
import { useStore } from '../../hooks/useStore';
import { stores } from '../../stores';
import { environment } from '../../stores/environment/environment';
import { getRoute, isActiveRoute, useRouter } from '../../services/router';
import { casino } from '../../stores/casino';
import { media } from '../../stores/media/media';
import { mediaQuery } from '../../styles/utils';
import { createGlobalStyle } from 'styled-components';

type ChatPosition = 'left' | 'right';
export interface EdgeTierChat {
    open: () => void;
    isAvailable: (callback: (error: unknown, data: { isAvailable: boolean }) => void) => void;
    setSetupId: (chatId: string, callback?: (error: unknown) => void) => void;
    storeVariable: (variableId: number, value: unknown, callback?: (error: unknown) => void) => void;
}

const GlobalStyle = createGlobalStyle<{ bottomOffset: number }>`
    @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
        iframe#edge-tier-chat:not(.edge-tier-chat-open)[class^='edge-tier-chat'] {
            transform: scale(0.7);
            transform-origin: right;
        }
    }
    
    iframe#edge-tier-chat {
        bottom: ${({ bottomOffset }) => bottomOffset}px;
        z-index: 1199 !important; // modal has z-index: 1200
    }
`;

const EdgeTierContext = createContext<{
    open: () => void;
    hide: () => void;
    show: () => void;
    isLoaded: boolean;
}>({
    open: () => {},
    hide: () => {},
    show: () => {},
    isLoaded: false,
});
export function EdgeTierContextProvider({ children }: React.PropsWithChildren) {
    const { pathname } = useRouter();
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [isChatInitialized, setIsChatInitialized] = useState(false);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [user] = useStore(stores.user);
    const [isFilterModalOpen] = useStore(casino.isFilterModalOpen);
    const [isSportSideMenuOpen] = useStore(stores.sports.isSportSideMenuOpen);
    const isRightSidebarOpen = isFilterModalOpen || isSportSideMenuOpen;
    const [isBetslipOpen] = useStore(stores.isBetslipOpen);
    const [{ isPhone }] = useStore(media);

    const VARIABLES_IDS = {
        USER_ID: 1,
        USER_NAME: 2,
        USER_SURNAME: 3,
        USER_EMAIL: 4,
    };

    const [{ EDGE_TIER }] = useStore(environment);
    const { URL, LOGGED_IN_CHAT_ID, LOGGED_OUT_CHAT_ID } = EDGE_TIER || {};

    const loggedInChatId = LOGGED_IN_CHAT_ID || '1';
    const loggedOutChatId = LOGGED_OUT_CHAT_ID || '1';

    function renderMobileStyles() {
        if (!isPhone) {
            return null;
        }

        const leagueBarOffside = isActiveRoute(`${getRoute('league')}/:id`) ? 50 : 0;
        const bottomOffside = isFeatureAvailable(FEATURE.BETSLIP_V2) ? 180 : 80;

        return <GlobalStyle bottomOffset={bottomOffside + leagueBarOffside} />;
    }

    useEffect(() => {
        if (isScriptLoaded) {
            window.EdgeTierChat.isAvailable((error, data) => {
                if (data.isAvailable) {
                    setIsChatInitialized(true);
                    updateVisibility();
                    if (isAuthenticated) {
                        window.EdgeTierChat.setSetupId(loggedInChatId);
                        window.EdgeTierChat.storeVariable(VARIABLES_IDS.USER_ID, user?.id);
                        window.EdgeTierChat.storeVariable(VARIABLES_IDS.USER_NAME, user?.firstName);
                        window.EdgeTierChat.storeVariable(VARIABLES_IDS.USER_SURNAME, user?.lastName);
                        window.EdgeTierChat.storeVariable(VARIABLES_IDS.USER_EMAIL, user?.email);
                    } else {
                        window.EdgeTierChat.setSetupId(loggedOutChatId);
                        window.EdgeTierChat.storeVariable(VARIABLES_IDS.USER_ID, null);
                        window.EdgeTierChat.storeVariable(VARIABLES_IDS.USER_NAME, null);
                        window.EdgeTierChat.storeVariable(VARIABLES_IDS.USER_SURNAME, null);
                        window.EdgeTierChat.storeVariable(VARIABLES_IDS.USER_EMAIL, null);
                    }
                }
            });
        }
    }, [isAuthenticated, isScriptLoaded]);

    useEffect(() => {
        if (isRightSidebarOpen) {
            setPosition('left');
        } else {
            setPosition('right');
        }
    }, [isRightSidebarOpen]);

    useEffect(updateVisibility, [pathname, isBetslipOpen]);

    function updateVisibility() {
        const isHiddenRoute =
            isActiveRoute(`${getRoute('poker')}/:rest`, true) ||
            isActiveRoute(`${getRoute('casino-game')}/:gameSlug`, true) ||
            isActiveRoute(getRoute('share'), false) ||
            isActiveRoute(getRoute('match-promotion-preview'), false);

        if (isHiddenRoute || isBetslipOpen) {
            hide();
        } else {
            show();
        }
    }

    function getIframe() {
        return document.getElementById('edge-tier-chat');
    }

    function hide() {
        const iframe = getIframe();
        if (iframe) {
            iframe.style.display = 'none';
        }
    }

    function show() {
        const iframe = getIframe();
        if (iframe) {
            iframe.style.display = 'block';
        }
    }

    function setPosition(position: ChatPosition) {
        const iframe = getIframe();

        if (iframe) {
            switch (position) {
                case 'left':
                    iframe.style.right = 'auto';
                    iframe.style.left = '10px';
                    break;
                case 'right':
                default:
                    iframe.style.right = '10px';
                    iframe.style.left = 'auto';
                    break;
            }
        }
    }

    function open() {
        window.EdgeTierChat?.open();
        show();
    }

    return (
        <EdgeTierContext.Provider value={{ open, isLoaded: isChatInitialized, hide, show }}>
            {URL && (
                <ScriptLoader
                    url={URL}
                    onLoad={() => setIsScriptLoaded(true)}
                    attributes={{ 'data-id': `${isAuthenticated ? loggedInChatId : loggedOutChatId}` }}
                />
            )}
            {renderMobileStyles()}
            {children}
        </EdgeTierContext.Provider>
    );
}

export function useEdgeTierContext() {
    return useContext(EdgeTierContext);
}
