import React, { PropsWithChildren, useEffect } from 'react';
import AccountDataForClosedAccount from '../../components/account/data/for-closed-account/AccountDataForClosedAccount';
import AccountVerificationKyc from '../../components/account/verification/kyc/AccountVerificationKyc';
import asyncComponent from '../../components/async-component/AsyncComponent';
import AuthInactivityModal from '../../components/auth/inactivity-modal/AuthInactivityModal';
import AuthLoginModal from '../../components/auth/login/modal/AuthLoginModal';
import AuthMultipleSessionModal from '../../components/auth/multiple-session-modal/AuthMultipleSessionModal';
import AuthPayAndPlayModal from '../../components/auth/pay-and-play/modal/AuthPayAndPlayModal';
import AuthProfileValidator from '../../components/auth/profile-validator/AuthProfileValidator';
import BannerMessage from '../../components/banner-message/BannerMessage';
import ByodHeader from '../../components/byod/header/ByodHeader';
import CoolbetFooter from '../../components/coolbet/footer/CoolbetFooter';
import CoolbetHeader from '../../components/coolbet/header/CoolbetHeader';
import CoolbetHeaderMobile from '../../components/coolbet/header/mobile/CoolbetHeaderMobile';
import CrmMessagePreview from '../../components/crm/message-preview/CrmMessagePreview';
import MobileWebCheckBanner from '../../components/mobile-web-check-banner/MobileWebCheckBanner';
import ResponsibleGamingPanel from '../../components/responsible-gaming/panel/ResponsibleGamingPanel';
import ResponsibleGaming from '../../components/responsible-gaming/ResponsibleGaming';
import ScrollToTop from '../../components/scroll-to-top/ScrollToTop';
import SeoFooter from '../../components/seo/footer/SeoFooter';
import Toast from '../../components/toast/Toast';
import UiBottomNavigation from '../../components/ui/bottom-navigation/UiBottomNavigation';
import { useStore } from '../../hooks/useStore';
import { isBYOD, isSIM } from '../../services/environment';
import { isFeatureAvailable } from '../../services/feature';
import { checkSourceType } from '../../services/mobile-app';
import { storageGet, storageSet } from '../../services/storage';
import { closeToast, closeToastsOnLogout } from '../../services/toast';
import { FEATURE } from '../../services/types';
import { stores } from '../../stores';
import { media } from '../../stores/media/media';
import SimLayout from '../sim/SimLayout';
import Wrapper from './styles';

const GeocomplyFeedback = asyncComponent(() => import('../../components/geocomply/feedback/GeocomplyFeedback'), {
    isSilent: true,
});

// TODO: there should be also main layout for SIM
export default function MainLayout({ children }: PropsWithChildren) {
    const [{ isDesktop, isLaptop, isTablet, isPhone }] = useStore(media);
    const [applicationType] = useStore(stores.applicationType);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [toasts] = useStore(stores.toasts);

    const forceHideMobileNav = window.location.search.includes('hideMobileNav') || storageGet<string>('hideMobileNav');

    if (forceHideMobileNav) {
        storageSet('hideMobileNav', true);
    }

    const displayWebHeader = isFeatureAvailable(FEATURE.HEADER) && checkSourceType() !== 'IOS' && !forceHideMobileNav;

    useEffect(() => {
        if (!isAuthenticated && toasts.length) {
            closeToastsOnLogout();
        }
    }, [isAuthenticated]);

    if (isSIM()) {
        return <SimLayout>{children}</SimLayout>;
    }

    return (
        <Wrapper className="main" id="main-layout">
            <ResponsibleGamingPanel />
            {isBYOD() ? (
                <ByodHeader />
            ) : (
                displayWebHeader && (
                    <div className="header">
                        {(isDesktop || isLaptop) && <CoolbetHeader />}
                        {!isDesktop && !isLaptop && isFeatureAvailable(FEATURE.HEADER_MOBILE) && (
                            <CoolbetHeaderMobile />
                        )}
                    </div>
                )
            )}

            <BannerMessage />
            <ScrollToTop>
                <div id="main-container" className="main-container">
                    {isFeatureAvailable(FEATURE.MOBILE_WEB_APP_INSTALL) && <MobileWebCheckBanner />}
                    <ResponsibleGaming>
                        {children}
                        <SeoFooter />
                    </ResponsibleGaming>
                    <CrmMessagePreview />
                </div>
            </ScrollToTop>
            {applicationType !== 'poker-download' && (isPhone || isTablet) && !forceHideMobileNav && (
                <div className="main-phone-navigation">
                    <UiBottomNavigation />
                </div>
            )}
            {isFeatureAvailable(FEATURE.LOGIN) && <AuthLoginModal />}
            {isFeatureAvailable(FEATURE.GEOCOMPLY) && <GeocomplyFeedback />}
            <AuthPayAndPlayModal />
            {isFeatureAvailable(FEATURE.LOGIN) && <AuthInactivityModal />}
            {isFeatureAvailable(FEATURE.RESPONSIBLE_GAMING) && <AuthMultipleSessionModal />}
            <AuthProfileValidator />
            <AccountVerificationKyc />
            <AccountDataForClosedAccount />
            <Toast toasts={toasts} closeToast={closeToast} />
            {isFeatureAvailable(FEATURE.FOOTER) && <CoolbetFooter />}
        </Wrapper>
    );
}
