import styled from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    position: relative;
    cursor: pointer;
    svg {
        margin: 0;
    }
    .user-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--spacing-12);

        background-color: var(--surface-level-2-bg);
        border: var(--surface-level-2-border);
        border-radius: 100px;
        box-shadow: var(--ui-shadow);

        width: 120px;
        max-width: 120px;
        height: 38px;
        padding: var(--spacing-4) var(--spacing-12);
        font-size: var(--font-12);
        font-weight: var(--font-weight-medium);
        @media ${mediaQuery.isPhone} {
            width: auto;
            max-width: 115px;
        }
        svg {
            color: var(--font-color-subtle);
        }
        .user-info {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .user-name {
            color: var(--font-color-secondary);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .user-balance {
            color: var(--color-secondary);
        }
    }

    &:hover {
        .account-button-dropdown-container {
            z-index: 500;
            visibility: visible;
            transition: visibility 0s, z-index 0s;
        }

        .account-button-dropdown {
            transform: translateY(0);
        }
    }

    .account-button-dropdown-container {
        position: absolute;
        top: calc(100% - 6px);
        right: -14px;
        min-width: 150px;
        overflow: hidden;
        padding: 5px 6px;
        visibility: hidden;
        z-index: -1;
        transition: visibility 0.3s step-end, z-index 0s 0.3s step-end;
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            display: none;
        }
    }

    .account-button-dropdown {
        width: 100%;
        margin-top: var(--spacing-4);
        background-color: var(--surface-level-2-bg);
        border: var(--surface-level-2-border);
        border-radius: var(--ui-radius);

        text-align: left;
        font-size: 12px;

        transition: background-color 0.2s, color 0.2s, transform 0.3s;
        transform: translateY(-100%);
        box-shadow: var(--ui-shadow);
        overflow: hidden;

        .button {
            text-decoration: none;
            display: flex;
            color: var(--font-color-primary);
            align-items: center;
            justify-content: left;
            cursor: pointer;
            width: 100%;
            padding: 0.7rem;
            margin: 0;
            &:hover {
                background: var(--surface-level-1-bg);
            }
            .icon {
                width: 1rem;
                fill: white;
            }
            span {
                margin-left: 0.8rem;
            }
        }
    }
`;
