import styled from 'styled-components';
import { hideScrollBar } from '../../../styles/utils';

export default styled.div`
    width: 346px;

    & .dropdown-content {
        height: 260px;
        border-radius: var(--spacing-8);
        z-index: 1;

        position: relative;
        overflow: auto;
        ${hideScrollBar};

        & .dropdown-content-empty {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: var(--font-color-secondary);
        }
    }

    & .dropdown-view-all-button {
        padding: var(--spacing-8) var(--spacing-16);
        border-top: 1px solid var(--surface-level-2-bg);
    }
`;
