import { getServiceUrl, httpDelete, httpGet, httpPost, httpPut } from '../services/api';
import { CasinoFilter } from '../services/casino/casino-preferences';
import { logger } from '../services/logger';
import { casino } from '../stores/casino';
import { toast } from 'react-toastify';
import { translate } from '../services/translate';

const getUrl = (url) => getServiceUrl('casino-preferences', url);

export async function loadFilters(): Promise<void> {
    try {
        const url = getUrl('fo/filters');
        const result = await httpGet<CasinoFilter[]>(url);
        casino.userPreferences.filters.set(result || []);
    } catch (error) {
        logger.error('CasinoPreferencesMicroservice', 'loadFilters', error);
    }
}

export async function loadFilterById(filterId: string): Promise<CasinoFilter | undefined> {
    try {
        const url = getUrl(`fo/filters/${filterId}`);
        const filter = await httpGet<CasinoFilter>(url);
        if (filter?.id) {
            casino.originalFilter.set(filter);
            return filter;
        }
    } catch (error) {
        logger.error('CasinoPreferencesMicroservice', 'loadFilterById', error);
    }
}

export async function addFilter(filter: CasinoFilter): Promise<CasinoFilter | undefined> {
    try {
        const url = getUrl('fo/filters');
        return await httpPost(url, filter);
    } catch (error) {
        logger.error('CasinoPreferencesMicroservice', 'addFilter', error);
        throw error;
    }
}

export async function updateFilter(filterId: string, filter: CasinoFilter): Promise<CasinoFilter | undefined> {
    try {
        const url = getUrl(`fo/filters/${filterId}`);
        return await httpPut(url, filter);
    } catch (error) {
        logger.error('CasinoPreferencesMicroservice', 'updateFilter', error);
        throw error;
    }
}

export async function deleteFilter(filterId: string): Promise<CasinoFilter | undefined> {
    try {
        const url = getUrl(`fo/filters/${filterId}`);
        return await httpDelete(url);
    } catch (error) {
        logger.error('CasinoPreferencesMicroservice', 'deleteFilter', error);
    }
}

export async function getFavoriteGames() {
    try {
        const url = getUrl('fo/favorite-games');
        return (await httpGet<number[]>(url)) || [];
    } catch (error) {
        logger.error('CasinoPreferencesMicroservice', 'getFavoriteGames', error);
        throw error;
    }
}

export async function addGameToFavorites(gameGroupId: number) {
    try {
        const url = getUrl('fo/favorite-games');
        return await httpPost(url, { gameGroupId });
    } catch (error) {
        logger.error('CasinoPreferencesMicroservice', 'addGameToFavorites', error);
        throw error;
    }
}

export async function removeGameFromFavorites(id: number) {
    try {
        const url = getUrl(`fo/favorite-games/${id}`);
        return await httpDelete(url);
    } catch (error) {
        logger.error('CasinoPreferencesMicroservice', 'removeGameFromFavorites', error);
        throw error;
    }
}

export async function getPinnedSubproviders() {
    try {
        const url = getUrl('fo/subprovider/pinned');
        return (await httpGet<number[]>(url)) || [];
    } catch (error) {
        logger.error('CasinoPreferencesMicroservice', 'getPinnedSubproviders', error);
        toast.error(translate('Something went wrong', 'ui.common'));
    }
}

export async function pinSubprovider(subproviderId: number) {
    try {
        const url = getUrl('fo/subprovider/pinned');
        return await httpPost(url, { subproviderId });
    } catch (error) {
        logger.error('CasinoPreferencesMicroservice', 'pinSubprovider', error);
        toast.error(translate('Something went wrong', 'ui.common'));
    }
}

export async function removePinnedSubprovider(subproviderId: number) {
    try {
        const url = getUrl(`fo/subprovider/pinned/${subproviderId}`);
        return await httpDelete(url);
    } catch (error) {
        logger.error('CasinoPreferencesMicroservice', 'removePinnedSubprovider', error);
        toast.error(translate('Something went wrong', 'ui.common'));
    }
}
