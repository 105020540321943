import { Country } from '@staycool/location';
import { Currency } from '../wallet/types';

export type AuthenticationResponse = {
    alias: string;
    askSmsVerification: boolean;
    closed: boolean;
    country: Country;
    displayName: string;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    phoneNumber: string;
    phonePrefix: string;
    registrationToken: string;
    token: string;
    verified: boolean;
};

export interface BankIdAuthenticationSessionResponse {
    id: string;
    autoStartToken: string;
    qrCodeLink: string;
    status: BankIdAuthenticationSessionStatus;
}

export enum BankIdAuthenticationSessionStatus {
    FAILED = 'Failed',
    FINISHED = 'Finished',
    PENDING = 'Pending',
}

export enum LoginMethod {
    APPLE = 'apple',
    BANK_ID = 'bank-id',
    BANNER_PAY_AND_PLAY = 'banner-pay-and-play',
    BO_LOGIN = 'bo-login',
    EMAIL = 'email',
    FACEBOOK = 'facebook',
    FINNISH_BANK_ID = 'Finnish Bank ID',
    FINNISH_MOBILE_ID = 'Finnish Mobile ID',
    GOOGLE = 'google',
    PAY_AND_PLAY = 'pay-and-play',
    SMART_ID = 'smart-id',
    TOKEN = 'TOKEN',
}

export enum RegistrationFlow {
    EMAIL = 'email',
    BANK_ID = 'bank-id',
    FINNISH_TRUST_NETWORK = 'Finnish Trust Network',
    PAY_AND_PLAY = 'pay-and-play',
    SMART_ID = 'SmartID',
}

export enum SmartIdSessionResult {
    DOCUMENT_UNUSABLE = 'DOCUMENT_UNUSABLE',
    OK = 'OK',
    REQUIRED_INTERACTION_NOT_SUPPORTED_BY_APP = 'REQUIRED_INTERACTION_NOT_SUPPORTED_BY_APP',
    TIMEOUT = 'TIMEOUT',
    USER_REFUSED = 'USER_REFUSED',
    USER_REFUSED_CERT_CHOICE = 'USER_REFUSED_CERT_CHOICE',
    USER_REFUSED_CONFIRMATIONMESSAGE = 'USER_REFUSED_CONFIRMATIONMESSAGE',
    USER_REFUSED_CONFIRMATIONMESSAGE_WITH_VC_CHOICE = 'USER_REFUSED_CONFIRMATIONMESSAGE_WITH_VC_CHOICE',
    USER_REFUSED_DISPLAYTEXTANDPIN = 'USER_REFUSED_DISPLAYTEXTANDPIN',
    USER_REFUSED_VC_CHOICE = 'USER_REFUSED_VC_CHOICE',
    WRONG_VC = 'WRONG_VC',
}

export enum SmartIdSessionState {
    COMPLETE = 'COMPLETE',
}

export type RegistrationFields = {
    acceptTermsAndConditions: boolean;
    address: string;
    alias: string;
    birthDate: { day: number; month: number; year: number } | undefined;
    city: string;
    country: Country;
    currency: Currency;
    email: string;
    eVerificationMethod?: string;
    fathersName?: string;
    firstName: string;
    gender?: string;
    hasAcceptedAgeAndPlayingOwnBehalf: boolean;
    language: string;
    lastName: string;
    mothersName?: string;
    nationality?: string;
    password: string;
    personalId?: string;
    personalIdType?: string;
    phoneNumber: string;
    province?: string;
    sessionId?: string;
    socialMediaRegistrationToken?: string;
    subscribeDirectMail?: boolean;
    subscribeMailList?: boolean;
    subscribePhoneCalls?: boolean;
    subscribeSms?: boolean;
    zip: string;
    loqateLookupAddressId?: string;
};
