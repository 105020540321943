import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    text-align: left;
    .amount-row {
        align-items: flex-start;
        margin-bottom: 0.375rem;
        & > div {
            &:first-child {
                flex: 1;
            }
            &:last-child {
                flex: unset;
                width: 5rem;
            }
        }

        @media ${mediaQuery.isPhone} {
            gap: var(--spacing-8);
        }
    }

    form label {
        padding: 0;
    }

    .alternative-option-link {
        text-align: center;
        margin-top: 1rem;
    }

    .pay-and-play-terms {
        margin-top: 1rem;
    }

    .bonus-code-input {
        input {
            margin-bottom: 0;
        }
    }
`;
