import React, { useEffect } from 'react';
import UiBox from '../../../ui/box/UiBox';
import { translate } from '../../../../services/translate';
import moment from 'moment';
import { logout } from '../../../../services/auth';
import UiCloseButton from '../../../ui/close-button/UiCloseButton';
import { useStore } from '../../../../hooks/useStore';
import { responsibleGaming } from '../../../../stores/responsible-gaming/responsible-gaming';
import Wrapper from './styles';

export default function ResponsibleGamingAlertLoginDurationLimitExceeded() {
    const [loginDurationLimitEndDate, setLoginDurationLimitEndDate] = useStore(
        responsibleGaming.loginDurationLimitEndDate,
    );

    useEffect(() => {
        if (loginDurationLimitEndDate) {
            logout();
        }
    }, [loginDurationLimitEndDate]);

    function handleClose() {
        setLoginDurationLimitEndDate(null);
    }

    if (!loginDurationLimitEndDate) {
        return null;
    }

    return (
        <Wrapper open onClose={handleClose}>
            <UiBox>
                <UiCloseButton onClick={handleClose} className="close-button" />

                {translate('Login duration has been exceeded', 'ui.responsible-gaming', {
                    durationLimitExpiryTime: moment(loginDurationLimitEndDate).format('DD.MM.YYYY HH:mm'),
                })}
            </UiBox>
        </Wrapper>
    );
}
