import { kyc } from '../../stores/kyc';
import { getStoreValue } from '../../stores/store/utils';

export function getKycTokenHeaders() {
    const headers = {};
    const kycToken = getStoreValue(kyc.kycToken);
    if (kycToken) {
        headers['kyc-token'] = kycToken;
    }
    return headers;
}
