import React from 'react';
import Wrapper from './styles';
import Svg from '../../svg/Svg';
import { requestLogin } from '../../../services/auth';
import { stores } from '../../../stores';
import { useStore } from '../../../hooks/useStore';
import { getRoute, useRouter } from '../../../services/router';
import { translate } from '../../../services/translate';
import UiNavLink from '../../ui/nav-link/UiNavLink';

export default function SimMobileMenu() {
    const { navigateTo } = useRouter();
    const [isAuthenticated] = useStore(stores.isAuthenticated);

    function handleOnClickLogin() {
        requestLogin();
        stores.modals.isInactivityModalVisible.set(false);
    }

    return (
        <Wrapper>
            <div className="menu-left">
                <UiNavLink to={`${getRoute('sim.vip')}`} exact className="menu-item">
                    <Svg icon="live-vip" size={2} />
                    <span>{translate('casino.category.19.name')}</span>
                </UiNavLink>
                <UiNavLink to={getRoute('sim.games')} className="menu-item">
                    <Svg icon="casino-lobby" size={2} />
                    <span>lobby</span>
                </UiNavLink>
            </div>
            <div className="menu-center" />
            <div className="menu-right">
                <UiNavLink to={getRoute('sim.leaderboard')} className="menu-item">
                    <Svg icon="poker-trophy" size={2} />
                    <span>{translate('Leaderboard', 'ui.header')}</span>
                </UiNavLink>
                <UiNavLink to={getRoute('promotions')} className="menu-item">
                    <Svg icon="promotions" size={2} />
                    <span>{translate('Promotions', 'ui.header')}</span>
                </UiNavLink>
            </div>
            <div
                className="menu-main-item"
                onClick={
                    isAuthenticated
                        ? () => {
                              navigateTo(getRoute('purchase-packages'));
                          }
                        : handleOnClickLogin
                }
            >
                {isAuthenticated ? (
                    <>
                        <Svg icon="cash" size={2} />
                        <span>{translate('shop', 'ui.sim.shop')}</span>
                    </>
                ) : (
                    <>
                        <Svg icon="profile" size={2} />
                        <span>{translate('join', 'ui.sim.join')}</span>
                    </>
                )}
            </div>
        </Wrapper>
    );
}
