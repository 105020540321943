import { getServiceUrl, httpPost } from '../services/api';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { openNewTab } from '../services/util';

const getUrl = (url) => getServiceUrl('liberation', url);

export function downloadData(downloadToken) {
    const authToken = getStoreValue(stores.token);
    const url = getUrl(`download/${downloadToken}?token=${authToken}`);
    openNewTab(url);
}

export function downloadDataForClosedUser(downloadToken) {
    const url = getUrl(`download/closedUser/${downloadToken}`);
    openNewTab(url);
}

export function sendDownloadVerification() {
    const url = getUrl('request-data-download/');
    return httpPost<{ pin_id: string }>(url, { language: getStoreValue(stores.language) });
}
