import React, { useState } from 'react';
import Wrapper from './styles';
import CrmMessagePreviewPopupLevelUp from './level-up/CrmMessagePreviewPopupLevelUp';
import UiModal from '../../../ui/modal/UiModal';
import { OnsiteMessage } from '../../../../microservices/crm/types';
import CrmMessagePreviewPopupDefault from './default/CrmMessagePreviewPopupDefault';
import CrmMessagePreviewPopupDailyLogin from './daily-login/CrmMessagePreviewPopupDailyLogin';
import { crm } from '../../../../stores/crm';
import UiBox from '../../../ui/box/UiBox';
import UiCloseButton from '../../../ui/close-button/UiCloseButton';

interface Props {
    message: OnsiteMessage;
}

export default function CrmMessagePreviewPopup({ message }: Props) {
    const themeComponents = {
        default: CrmMessagePreviewPopupDefault,
        'level-up': CrmMessagePreviewPopupLevelUp,
        'daily-login-reward': CrmMessagePreviewPopupDailyLogin,
    };

    const ThemeComponent = themeComponents[message.onsiteMessageTheme ? message.onsiteMessageTheme : 'default'];
    const [isOpen, setIsOpen] = useState(true);

    function handleClosePopup() {
        crm.onsite.set(undefined);
        setIsOpen(false);
    }

    return (
        <UiModal open={isOpen} mode="default">
            <Wrapper>
                <UiBox>
                    <UiCloseButton onClick={handleClosePopup} />
                    <ThemeComponent message={message} onClose={handleClosePopup} />
                </UiBox>
            </Wrapper>
        </UiModal>
    );
}
