import styled, { css } from 'styled-components';

type StyledProps = {
    $isPokerLobbyPage?: boolean;
};

export default styled.div<StyledProps>`
    ${({ $isPokerLobbyPage }) =>
        !$isPokerLobbyPage &&
        css`
            .footer,
            .header,
            .responsible-gaming-panel {
                display: none;
            }

            #main-layout > .main-container {
                padding: unset;
            }
        `}

    .footer {
        margin-top: 0;
    }

    #main-layout > .main-container {
        padding: 0;
        margin: 0 auto;
    }
`;
