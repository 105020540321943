import { useEffect } from 'react';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { stores } from '../stores';
import { getCmsSettings } from '../microservices/cms';
import { logger } from './logger';
import { useStore } from '../hooks/useStore';
const headerElements = ['script', 'link'];

export const useCmsSettings = () => {
    const [settings, setSettings] = useStore(stores.cms.settings);
    useEffect(() => {
        if (settings === null) {
            return;
        }
        if (!settings) {
            setSettings(null);
            (async () => {
                try {
                    setSettings(await getCmsSettings());
                } catch (e) {
                    logger.error('CmsService', 'useCmsSettings', e);
                }
            })();
        }
    }, []);
    return settings;
};

export function getHeaderElementsFromHtml(htmlContent) {
    const domElements: any[] = [];

    const parseOptions = {
        replace({ name, attribs = {}, children }) {
            if (!headerElements.includes(name)) {
                return undefined;
            }

            if (name === 'script') {
                children?.forEach(({ data }) => {
                    const element = document.createElement('script');
                    element.text = data as string;
                    element.type = 'text/javascript';
                    element.charset = 'utf-8';
                    domElements.push(element);
                });
            } else if (name === 'link') {
                const element = document.createElement('link');
                Object.keys(attribs).forEach((attribute) => {
                    element[attribute] = attribs[attribute];
                });
                domElements.push(element);
            }
        },
    };

    parse(htmlContent, parseOptions as HTMLReactParserOptions);
    return domElements;
}

export function hideCookiebot() {
    window.addEventListener('CookiebotOnDialogInit', () => {
        if (!Cookiebot.consented) {
            Cookiebot.submitCustomConsent(!1, !1, !1);
            window.addEventListener('CookiebotOnConsentReady', () => {
                Cookiebot.deleteConsentCookie();
            });
        }
    });
}
declare let Cookiebot: any;
