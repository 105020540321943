import { getServiceUrl, httpGet } from '../services/api';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { logger } from '../services/logger';
import { Market } from '@staycool/location';
import { BlogPost } from '../services/cms-blog';
import { Language } from '../services/language/types';

const getUrl = (url) => getServiceUrl('l10n', url);

export async function getSnippet(key: string, overrideLanguage?: Language) {
    const language = overrideLanguage || getStoreValue(stores.language);
    const url = getUrl(`snippets/by_lang/${language}/${key}`);
    const response = await httpGet<{ body: any }>(url);
    return response.body;
}

export async function getBlogArticle(blogSlug) {
    const language = getStoreValue(stores.language);
    const url = getUrl(`posts/by_slug/Coolbet/${language}/${blogSlug}`);
    return httpGet<any>(url);
}

export async function getNewPostsAmount() {
    const language = getStoreValue(stores.language);
    const fromDate = getStoreValue(stores.blog.blogVisitDate);
    const url = getUrl(`posts/count_from_timestamp/${language}?timestamp=${fromDate}`);
    try {
        const response = await httpGet<any>(url);
        stores.blog.newBlogPostsCount.set(response.count);
    } catch (error) {
        logger.error('L10nMicroservice', 'getNewPostsAmount', error);
    }
}

export async function loadBlogCategories() {
    const language = getStoreValue(stores.language);
    const categories = getStoreValue(stores.blog.categories);

    if (categories.length) {
        return;
    }

    const url = getUrl(`posts/category_count/Coolbet/${language}`);

    try {
        const response = await httpGet<any>(url);
        stores.blog.categories.set(response);
    } catch (error) {
        logger.error('L10nMicroservice', 'loadBlogCategories', error);
    }
}

export async function loadArticlesByParams({
    category,
    limit,
    offset,
    excludeArticleId,
    market,
}: {
    category: string;
    limit: number;
    offset?: number;
    excludeArticleId?: string;
    market?: Market;
}) {
    const language = getStoreValue(stores.language);
    let articles = [] as BlogPost[];

    try {
        const queryParameters = { category, limit, offset, excludeArticleId, market };
        const url = getUrl(`posts/by_params/${language}`);
        articles = await httpGet<BlogPost[]>(url, queryParameters);
    } catch (error) {
        logger.error('L10nMicroservice', 'loadArticlesByParams', error);
    }

    return articles;
}

export async function getBlogPreview(draftId) {
    const language = getStoreValue(stores.language);
    const url = getUrl(`drafts/${language}/${draftId}/`);
    return httpGet<any>(url);
}
