import { useEffect } from 'react';
import { stores } from '../../../stores';
import {
    initializeResponsibleGaming,
    processReminder,
    resetResponsibleGamingTimeoutStatuses,
} from '../../../services/responsible-gaming';
import { isWithLicence } from '../../../services/licence';
import { Licence } from '@staycool/location';
import { useStore } from '../../../hooks/useStore';
import { useSubscription } from '../../../services/subscription';
import { onResponsibleGamingSessionReminder } from '../../../microservices/pusher';

export default function SystemResponsibleGaming() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [user] = useStore(stores.user);
    const [, setSessionEndTime] = useStore(stores.session.endTime);
    const [, setSessionLimitPeriodEndTime] = useStore(stores.session.limitPeriodEndTime);

    useEffect(() => {
        if (isAuthenticated) {
            initializeResponsibleGaming(isAuthenticated);
        } else {
            resetResponsibleGamingTimeoutStatuses();
            setSessionEndTime(undefined);
            setSessionLimitPeriodEndTime(undefined);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        const isResponsibleGamingPanelVisible = Boolean(user && isWithLicence(Licence.SWEDEN));

        stores.responsibleGaming.isResponsibleGamingPanelVisible.set(isResponsibleGamingPanelVisible);
    }, [user, isAuthenticated]);

    useSubscription(onResponsibleGamingSessionReminder, processReminder);

    return null;
}
