import invert from 'lodash/invert';
import some from 'lodash/some';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { getErrorCode } from './betslip-errors';
import { COMBO_MARKET_ID, MA_DISABLED_ERROR, MA_ENABLED_ERROR } from './constants';
import { getFixedStake } from './betslip-formatting';
import { getMinStake } from './limits';
import { BET_TYPE } from './types';
import { isOpenbetComboBetslip } from '../bet-builder';

export function hasBetslipManualAcceptanceError() {
    const MANUAL_ACCEPTANCE_ERRORS = [MA_DISABLED_ERROR, MA_ENABLED_ERROR];
    const betSlipErrorByMarketId = getStoreValue(stores.sports.betSlipErrorByMarketId);
    const genericErrors = betSlipErrorByMarketId[String(COMBO_MARKET_ID)];
    return some(genericErrors, (error) => MANUAL_ACCEPTANCE_ERRORS.includes(getErrorCode(error)));
}

export function handleIfManualAcceptance(manualAcceptanceError, betType) {
    if (![MA_DISABLED_ERROR, MA_ENABLED_ERROR].includes(manualAcceptanceError.code)) {
        return;
    }
    const userState = getStoreValue(stores.sports.betSlipUserState);
    const comboCardUserState = getStoreValue(stores.sports.comboCard.stakeByCardId);
    const outcomeIdByMarketId = isOpenbetComboBetslip(userState.betType)
        ? getStoreValue(stores.sports.customBetbuilder.betbuilderBetSlipMarketIdToOutcomeId)
        : getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const minStake = getMinStake();
    const { stakeByMarketId, systemStakes } = userState;

    let betslipUserStateUpdate;
    if (betType === BET_TYPE.COMBO_CARD) {
        const betslipComboCardUserStateUpdate = calculateComboCardManualAcceptanceStakes(
            manualAcceptanceError,
            comboCardUserState,
        );
        stores.sports.comboCard.stakeByCardId.set((state) => ({
            ...state,
            ...betslipComboCardUserStateUpdate,
        }));
        return;
    }
    if (betType === BET_TYPE.SYSTEM) {
        betslipUserStateUpdate = calculateManualAcceptanceStakesSystemBet(
            manualAcceptanceError,
            systemStakes,
            minStake,
        );
    } else {
        betslipUserStateUpdate = calculateManualAcceptanceStakes(
            manualAcceptanceError,
            outcomeIdByMarketId,
            stakeByMarketId,
            minStake,
            betType === BET_TYPE.TEASER,
        );
        if (betType === BET_TYPE.BETBUILDER) {
            delete betslipUserStateUpdate?.stakeByMarketId?.null;
        }
    }
    stores.sports.betSlipUserState.set((state) => ({
        ...state,
        ...betslipUserStateUpdate,
    }));
}

function calculateManualAcceptanceStakes(
    manualAcceptanceError,
    outcomeIdByMarketId,
    stakeByMarketId,
    minStake,
    isTeaser = false,
) {
    const MAStakeByMarketId = {};
    const outcomeIds: Array<string | null> = isTeaser ? [] : Object.keys(manualAcceptanceError.meta);
    const marketIdByOutcomeId = invert(outcomeIdByMarketId);
    outcomeIds.concat([null]).forEach((outcomeId) => {
        let maxStakePercentage;
        if (outcomeId === null) {
            maxStakePercentage = Math.min(...Object.values(manualAcceptanceError.meta as number[]));
        } else {
            maxStakePercentage = manualAcceptanceError.meta[outcomeId];
        }
        const marketId = marketIdByOutcomeId[String(outcomeId)] || null;
        let allowedStake = Math.floor(maxStakePercentage * (stakeByMarketId[String(marketId)] || 0));
        allowedStake = allowedStake < minStake ? 0 : allowedStake;
        if (manualAcceptanceError.code === MA_ENABLED_ERROR) {
            MAStakeByMarketId[String(marketId)] = getFixedStake(stakeByMarketId[String(marketId)] - allowedStake || 0);
        }

        stakeByMarketId = { ...stakeByMarketId, [String(marketId)]: `${allowedStake}` };
    });
    return { MAStakeByMarketId, stakeByMarketId };
}

function calculateComboCardManualAcceptanceStakes(manualAcceptanceError, comboCardUserState) {
    const comboCardId = Number(Object.keys(manualAcceptanceError.meta)[0]);
    const maxStakePercentage = Number(Object.values(manualAcceptanceError.meta)[0]);
    const allowedStake = Math.floor(maxStakePercentage * (comboCardUserState[String(comboCardId)] || 0));

    return { [`${comboCardId}`]: getFixedStake(allowedStake) };
}

function calculateManualAcceptanceStakesSystemBet(manualAcceptanceError, systemStakes, minStake) {
    const maxStakePercentage = Math.min(...Object.values(manualAcceptanceError.meta as number[]));
    const MASystemStakes = {};
    Object.keys(systemStakes).forEach((systemKey) => {
        let allowedStake = Math.floor(maxStakePercentage * systemStakes[systemKey]);
        allowedStake = allowedStake < minStake ? 0 : allowedStake;
        if (manualAcceptanceError.code === MA_ENABLED_ERROR) {
            MASystemStakes[systemKey] = getFixedStake(systemStakes[systemKey] - allowedStake || 0);
        }
        systemStakes = { ...systemStakes, [systemKey]: allowedStake };
    });
    return {
        MASystemStakes,
        systemStakes,
        disableSystemIndividualUpdate: true,
    };
}

export function isMAErrorForParlayCard() {
    const betSlipErrors = getStoreValue(stores.sports.parlayCard.betSlipErrors);
    const MANUAL_ACCEPTANCE_ERRORS = [MA_DISABLED_ERROR, MA_ENABLED_ERROR];
    return betSlipErrors.some((error) => MANUAL_ACCEPTANCE_ERRORS.includes(error.code || error));
}

export function isMAStakeAllowedForParlayCard() {
    const betSlipErrors = getStoreValue(stores.sports.parlayCard.betSlipErrors);
    return betSlipErrors.map((error) => error.code || error).includes(MA_ENABLED_ERROR);
}
