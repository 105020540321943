import { loadCasinoDisabledProviders } from '../../microservices/casino';
import { casino } from '../../stores/casino';

const updateInterval = 1000 * 60 * 5;
const maintenanceState: { nextUpdate?: Date; timeoutUpdate?: number } = {
    nextUpdate: undefined,
    timeoutUpdate: undefined,
};

export async function updateCasinoMaintenance() {
    const maintenanceList = await loadCasinoDisabledProviders();
    const now = new Date();
    const disabledProviders = {};

    maintenanceList.forEach((maintenance) => {
        const maintenanceStartDate = new Date(maintenance.startDate);
        const maintenanceEndDate = new Date(maintenance.endDate);

        if (maintenanceStartDate <= now && maintenanceEndDate > now) {
            if (disabledProviders[maintenance.providerId]) {
                if (maintenance.endDate > disabledProviders[maintenance.providerId].endDate) {
                    disabledProviders[maintenance.providerId] = maintenance;
                }
            } else {
                disabledProviders[maintenance.providerId] = maintenance;
            }
        }

        [maintenanceStartDate, maintenanceEndDate].forEach((updateTime) => {
            if (updateTime > now && (!maintenanceState.nextUpdate || updateTime < maintenanceState.nextUpdate)) {
                setNextUpdateTime(updateTime);
            }
        });
    });

    if (
        !maintenanceState.nextUpdate ||
        maintenanceState.nextUpdate.getTime() > now.getTime() + updateInterval ||
        maintenanceState.nextUpdate < now
    ) {
        setNextUpdateTime(new Date(now.getTime() + updateInterval));
    }

    casino.maintenance.set(disabledProviders);
}

function setNextUpdateTime(updateTime: Date) {
    maintenanceState.nextUpdate = updateTime;
    clearTimeout(maintenanceState.timeoutUpdate);
    maintenanceState.timeoutUpdate = window.setTimeout(() => {
        updateCasinoMaintenance();
    }, updateTime.getTime() - Date.now());
}
