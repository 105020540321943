import React, { ReactNode } from 'react';
import Wrapper from './styles';
import { filterStyleProps } from '../../../styles/utils';

interface Props {
    center?: boolean;
    className?: string;
    children?: ReactNode;
    dangerouslySetInnerHTML?: { __html: string };
    failure?: boolean;
    info?: boolean;
    id?: string;
    success?: boolean;
    warning?: boolean;
}

export default function UiAlert({ children, failure, success, warning, info, center, ...rest }: Props) {
    return (
        <Wrapper
            $failure={failure}
            $success={success}
            $warning={warning}
            $info={info}
            $center={center}
            {...filterStyleProps(rest)}
        >
            {children}
        </Wrapper>
    );
}
