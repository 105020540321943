import React, { useMemo } from 'react';
import Wrapper from './styles';
import Svg from '../../../svg/Svg';
import { Country } from '@staycool/location';
import { getUserCountry } from '../../../../services/users/country';
import { stores } from '../../../../stores';
import { useStore } from '../../../../hooks/useStore';

export default function CoolbetFooterLinks() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const userCountry = getUserCountry() as Country;

    const socialMedia = [
        {
            icon: 'twitter',
            title: 'Twitter',
            urlsByUserCountry: {
                [Country.ICELAND]: 'https://twitter.com/coolbetisland',
                [Country.NORWAY]: 'https://twitter.com/Coolbet_',
                [Country.FINLAND]: 'https://twitter.com/Coolbet_',
                [Country.SWEDEN]: 'https://twitter.com/coolbetsverige',
                [Country.CHILE]: 'https://twitter.com/coolbetchile',
                [Country.MEXICO]: 'https://twitter.com/CoolbetMx',
                [Country.PERU]: 'https://x.com/coolbetperu',
            },
        },
        {
            icon: 'facebook',
            title: 'Facebook',
            urlsByUserCountry: {
                [Country.ICELAND]: 'https://www.facebook.com/CoolbetIsland/',
                [Country.ESTONIA]: 'https://www.facebook.com/CoolbetEesti',
                [Country.SWEDEN]: 'https://www.facebook.com/CoolbetSverige/',
                [Country.CHILE]: 'https://www.facebook.com/CoolbetChile/',
                [Country.MEXICO]: 'https://www.facebook.com/coolbetmexico',
                [Country.PERU]: 'https://www.facebook.com/coolbetperu',
            },
        },
        {
            icon: 'instagram',
            title: 'Instagram',
            urlsByUserCountry: {
                [Country.CHILE]: 'https://www.instagram.com/coolbetchile/',
                [Country.ESTONIA]: 'https://www.instagram.com/coolbeteesti/',
                [Country.SWEDEN]: 'https://www.instagram.com/coolbetsverige/',
                [Country.MEXICO]: 'https://www.instagram.com/coolbetmx/',
                [Country.PERU]: 'https://www.instagram.com/coolbetperu',
            },
        },
        {
            icon: 'linkedin',
            title: 'LinkedIn',
            urlsByUserCountry: {
                default: 'https://www.linkedin.com/company/coolbetofficial/',
            },
            hideFrom: [Country.MEXICO, Country.PERU],
        },
    ].filter((platform) => !platform.hideFrom?.includes(userCountry));

    const filteredSocialMedia = useMemo(
        () =>
            socialMedia.reduce((filteredMedia: { icon: string; title: string; url: string }[], currentMedia) => {
                const mediaObject = {
                    icon: currentMedia.icon,
                    title: currentMedia.title,
                    url: currentMedia.urlsByUserCountry[userCountry] || currentMedia.urlsByUserCountry.default,
                };
                if (mediaObject.url) {
                    filteredMedia.push(mediaObject);
                }
                return filteredMedia;
            }, []),
        [isAuthenticated],
    );

    return (
        <Wrapper className="footer-links">
            <div className="coolbet-footer-links-container">
                <div className="coolbet-footer-social-media-container">
                    {filteredSocialMedia.map((socialMedia) => (
                        <a
                            className="coolbet-footer-social-media"
                            href={socialMedia.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={socialMedia.title}
                        >
                            <Svg className="coolbet-footer-social-media-icon" icon={socialMedia.icon} />
                            <div className="coolbet-footer-social-media-title">{socialMedia.title}</div>
                        </a>
                    ))}
                </div>
            </div>
        </Wrapper>
    );
}
