import moment from 'moment';
import { translate } from '../services/translate';

interface Props {
    date: string;
    gap?: number;
}

const DIFFERENCES_TO_SHOW = {
    seconds: 60,
    minutes: 60 * 60,
    hours: 60 * 60 * 24,
    days: 60 * 60 * 24,
};

export const useTimeAgo = ({ date, gap = 7 }: Props) => {
    const timeNow = moment();
    const timeToTest = moment(date);
    const difference = timeNow.diff(timeToTest, 'seconds');

    if (difference < DIFFERENCES_TO_SHOW.seconds) {
        return `${difference}${translate('second', 'ui.duration')[0]}`;
    }

    if (difference < DIFFERENCES_TO_SHOW.minutes) {
        return `${Math.trunc(difference / DIFFERENCES_TO_SHOW.seconds)}${translate('minute', 'ui.duration')[0]}`;
    }

    if (difference < DIFFERENCES_TO_SHOW.hours) {
        return `${Math.trunc(difference / DIFFERENCES_TO_SHOW.minutes)}${translate('hour', 'ui.duration')[0]}`;
    }

    if (difference < DIFFERENCES_TO_SHOW.days * gap) {
        return `${Math.trunc(difference / DIFFERENCES_TO_SHOW.hours)}${translate('day', 'ui.duration')[0]}`;
    }

    return moment(date).format('DD.MM.YYYY');
};
