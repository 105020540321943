import { stores } from '../../stores';
import { environment } from '../../stores/environment/environment';
import { geocomply } from '../../stores/geocomply';
import { getStoreValue } from '../../stores/store/utils';
import { getDecodedToken } from '../token';
import { GEOCOMPLY_ENVIRONMENT, GEOCOMPLY_REASON, GeocomplyDesktopSettings } from './types';

export function getGeocomplyDesktopSettings(reason: GEOCOMPLY_REASON): GeocomplyDesktopSettings {
    const user = getStoreValue(stores.user);
    const token = getStoreValue(stores.token);
    const license = getStoreValue(geocomply.license);
    const installerID = getStoreValue(environment).GEOCOMPLY?.DESKTOP_INSTALLER_KEY || '';
    const envId = (getStoreValue(environment).GEOCOMPLY?.ENVIRONMENT || '') as GEOCOMPLY_ENVIRONMENT;

    return {
        installerID,
        envId,
        license: license.license,
        userId: user?.id ?? null,
        reason,
        customFields: {
            sessionKey: token ? getDecodedToken(token).login_session_id : null,
        },
    };
}
