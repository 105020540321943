import React from 'react';
import Wrapper from './styles';
import UiBadge from '../../../ui/badge/UiBadge';

interface Props {
    label: string;
    badge?: number;
}

export default function AccountMenuBadgeLabel({ label, badge }: Props) {
    return (
        <Wrapper>
            <span>{label}</span>
            <UiBadge badge={badge} />
        </Wrapper>
    );
}
