import {
    Jackpot,
    JackpotPotsByCurrency,
    JackpotRecentWin,
    JackpotWinEventData,
} from '../types/casino-thrilltech-jackpot/types';
import { createStores } from './store/utils';

export const casinoThrilltechJackpotStore = createStores({
    availableJackpots: [] as Jackpot[],
    jackpotsDataById: {} as Record<string, JackpotPotsByCurrency>,
    recentWinsByJackpotId: {} as Record<number, JackpotRecentWin[]>,
    currentWin: undefined as unknown as JackpotWinEventData | undefined,
    winsQueue: [] as JackpotWinEventData[],
});
