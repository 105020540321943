import xor from 'lodash/xor';
import { useEffect } from 'react';
import { fromEvent } from 'rxjs';
import io, { Socket } from 'socket.io-client';
import { getProxyUrl, getServiceUrl } from '../services/api';
import { logger } from '../services/logger';
import { getToken } from '../services/token';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { openMessageBySystem } from '../services/crm';
import isEqual from 'lodash/isEqual';
import { isLocalDevelopment } from '../services/environment';
import { Language } from '../services/language/types';
import { OddsPublishType } from '@staycool/odds-types';
import { FromEventTarget } from 'rxjs/internal/observable/fromEvent';
import { Currency } from '../services/wallet/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getUrl = (url) => getServiceUrl('pusher', url);

export const socket = io(isLocalDevelopment() ? getProxyUrl() : window.location.host, {
    transports: ['websocket'],
    path: '/s/pusher/socket.io/',
    closeOnBeforeunload: false,
    reconnectionDelay: 2000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 100, // about 12 minutes?
});

const onConnect = fromEvent(socket as FromEventTarget<Socket>, 'connect');
const onDisconnect = fromEvent(socket as FromEventTarget<Socket>, 'disconnect');
const onError = fromEvent(socket as FromEventTarget<Socket>, 'error');
const onReconnect = fromEvent(socket as FromEventTarget<Socket>, 'reconnect');
const onAuthenticate = fromEvent(socket as FromEventTarget<Socket>, 'authenticate');
const onAuthenticated = fromEvent(socket as FromEventTarget<Socket>, 'authenticated');
const onAuthenticateFailed = fromEvent(socket as FromEventTarget<Socket>, 'authenticate-failed');
const onOddsChange = fromEvent<OddsPublishType>(socket as FromEventTarget<OddsPublishType>, 'odds-update');
export const onBalanceUpdate = fromEvent<{ user: string; balance: number; currency: string }>(
    socket as FromEventTarget<{ user: string; balance: number; currency: string }>,
    'user-balance-update',
);
const onInplayMatchUpdate = fromEvent<{ match_id: string }>(
    socket as FromEventTarget<{ match_id: string }>,
    'in-play-match-update',
);
export const onLiveTreeChange = fromEvent(socket as FromEventTarget<Socket>, 'inplayMatch-tree-change');
export const onResponsibleGamingSessionReminder = fromEvent(
    socket as FromEventTarget<Socket>,
    'responsible-gaming-reminders',
);
export const onVerifiedUsersStatsUpdate = fromEvent(socket as FromEventTarget<Socket>, 'verified-users-stats-update');
export const subscribeToOddsChangesByMarketIds = (marketIds: number[]) => {
    for (const marketId of marketIds) {
        socket.emit('public-subscribe', { service: 'odds', channel: `market-odds-${marketId}` });
    }
};
export const unsubscribeFromOddsChangesByMarketIds = (marketIds: number[]) => {
    for (const marketId of marketIds) {
        socket.emit('public-unsubscribe', { service: 'odds', channel: `market-odds-${marketId}` });
    }
};
export const subscribeToLivebetMatchChanges = () =>
    socket.emit('public-subscribe', { service: 'sbgate', channel: 'live-tree' });
export const unsubscribeFromLivebetMatchChanges = () =>
    socket.emit('public-unsubscribe', { service: 'sbgate', channel: 'live-tree' });
export const subscribeToVerifiedUsersStats = () => socket.emit('verified-users-stats-subscribe');
export const unsubscribeFromVerifiedUsersStats = () => socket.emit('verified-users-stats-unsubscribe');

export const onRaceStart = fromEvent(socket as FromEventTarget<Socket>, 'casino-race-game-start');
export const onRaceEnd = fromEvent(socket as FromEventTarget<Socket>, 'casino-race-game-end');
export const onRacePoints = fromEvent(socket as FromEventTarget<Socket>, 'casino-race-points-update');
export const onRaceMessageUpdate = fromEvent(socket as FromEventTarget<Socket>, 'casino-race-message-update');

const onNewOnsiteMessage = fromEvent<{ id: string }>(socket as FromEventTarget<{ id: string }>, 'onsite-message');

export function socketAuthenticate() {
    const token = getToken();

    if (token) {
        socket.emit('authenticate', token);
    }
}

export function socketLogout() {
    socket.emit('authenticate-logout');
}

export function createSocketSubscribeTopics(topic: string) {
    const topicSubscribe = `${topic}-subscribe`;
    const topicUnsubscribe = `${topic}-unsubscribe`;
    return { topicSubscribe, topicUnsubscribe };
}

interface Subscribes {
    public: {
        service: string;
        channel: string;
    };
    private: {
        service: string;
        channel: string;
    };
    geoTransactions: {
        type: string;
        currency: Currency;
        language: Language;
    };
    'casino-payback-booster-balance-update': [];
    'casino-race-leaderboard': number[];
    'casino-payback-booster-transaction-notification': [];
    'casino-payback-booster-payback-booster-update': [];
    'casino-payback-booster-turnover-update': [];
}

type SubscribeType = keyof Subscribes;
type SubscribeDataType<T extends SubscribeType> = Subscribes[T];

interface SubscribeParams<T extends SubscribeType> {
    params: SubscribeDataType<T>;
    watchParams?: any[];
    guardFunction?: () => boolean;
    resubscribeOnReconnect?: boolean;
    unsubscribeIfOtherSubs?: boolean;
}

export function useSocketSubscribeUnsubscribe<
    T extends SubscribeType = any,
    U extends SubscribeParams<T> = SubscribeParams<any>,
>(
    topic: T,
    {
        params,
        watchParams = [],
        guardFunction = () => true,
        resubscribeOnReconnect = true,
        unsubscribeIfOtherSubs = true,
    }: U,
) {
    const { topicSubscribe, topicUnsubscribe } = createSocketSubscribeTopics(topic);

    useEffect(() => {
        if (guardFunction()) {
            socket.emit(topicSubscribe, params);
            if (resubscribeOnReconnect) {
                stores.webSocketSubscriptions.set((subscriptions) => [...subscriptions, { topicSubscribe, params }]);
            }
            return () => {
                const subscriptions = getStoreValue(stores.webSocketSubscriptions).filter((subscription) => {
                    return subscription.topicSubscribe === topicSubscribe && isEqual(subscription.params, params);
                });
                stores.webSocketSubscriptions.set(
                    xor(
                        getStoreValue(stores.webSocketSubscriptions),
                        subscriptions.slice(0, unsubscribeIfOtherSubs ? undefined : 1),
                    ),
                );

                if (unsubscribeIfOtherSubs || subscriptions.length <= 1) {
                    socket.emit(topicUnsubscribe, params);
                }
            };
        }
    }, watchParams);
}

export function useSocketTopicEvents(topic, callback, watchParams?: any[], shouldTrigger = true) {
    useEffect(() => {
        if (shouldTrigger) {
            const event = fromEvent(socket as FromEventTarget<Socket>, topic);
            const subscription = event.subscribe(callback);
            return () => subscription.unsubscribe();
        }
    }, watchParams || []);
}

export const subscribeToInplayMatchData = (matchIds) => {
    for (const matchId of matchIds) {
        const settings = {
            service: 'sports',
            channel: `in-play-match-${matchId}`,
        };
        socket.emit(`public-subscribe`, settings);
    }
};
export const unsubscribeFromInplayMatchData = (matchIds) => {
    for (const matchId of matchIds) {
        const settings = {
            service: 'sports',
            channel: `in-play-match-${matchId}`,
        };
        socket.emit(`public-unsubscribe`, settings);
    }
};

onConnect.subscribe(() => {
    logger.dev('PusherMicroservice', 'onConnect');
});

onDisconnect.subscribe((reason) => {
    logger.dev('PusherMicroservice', 'onDisconnect', reason);
});

onError.subscribe((err) => {
    logger.dev('PusherMicroservice', 'onError', err);
});

onReconnect.subscribe(() => {
    logger.dev('PusherMicroservice', 'onReconnect');
    const subscriptions = getStoreValue(stores.webSocketSubscriptions);
    subscriptions.forEach(({ topicSubscribe, params }) => {
        socket.emit(topicSubscribe, params);
    });
});

onAuthenticate.subscribe(() => {
    logger.dev('PusherMicroservice', 'onAuthenticate');
    socketAuthenticate();
});

onAuthenticated.subscribe(() => {
    logger.dev('PusherMicroservice', 'onAuthenticated');
});

onAuthenticateFailed.subscribe(() => {
    logger.dev('PusherMicroservice', 'onAuthenticateFailed');
});

onNewOnsiteMessage.subscribe((message) => {
    logger.dev('PusherMicroservice', 'onNewOnsiteMessage', message);
    openMessageBySystem(message.id);
});

onOddsChange.subscribe((oddsChangeCompact) => {
    const [odds_id, match_id, market_id, outcome_id, status, value] = oddsChangeCompact;
    const oddsChange = {
        [outcome_id]: { odds_id, market_id, match_id, outcome_id, status, value },
    };

    const oddsByOutcomeId = getStoreValue(stores.sports.oddsByOutcomeId);
    stores.sports.oddsByOutcomeId.set({ ...oddsByOutcomeId, ...oddsChange });
});

onInplayMatchUpdate.subscribe((inplayDataChange) => {
    if (!inplayDataChange) {
        return;
    }
    stores.sports.inplayMatchData.set((inplayDataByMatchId) => {
        inplayDataByMatchId[inplayDataChange.match_id] = inplayDataChange;
    });
});
