import { getStoreValue } from '../stores/store/utils';
import { storageGet, storageRemove, storageSet } from './storage';
import { ClientName } from './utils/types';
import { Environment } from '../stores/environment/types';
import { environment } from '../stores/environment/environment';
import { LocalStorage } from './local-storage/types';
import { ObjectValues } from './ts-utils';

export const ENVIRONMENT_PARAMS = {
    IS_BYOD: 'IS_BYOD',
    CLIENT_NAME: 'CLIENT_NAME',
};

async function fetchEnvironment(): Promise<Environment> {
    return fetch(`/environment.json`).then((response) => response.json());
}

export async function loadEnvironment() {
    const environmentVariables = await fetchEnvironment();

    for (const environmentParam of Object.keys(ENVIRONMENT_PARAMS)) {
        if (storageGet(environmentParam)) {
            environmentVariables[environmentParam] = String(storageGet(environmentParam));
        }
    }

    environment.set(environmentVariables);
}

export function resetEnvironment() {
    for (const environmentParam of Object.keys(ENVIRONMENT_PARAMS)) {
        if (storageGet(environmentParam)) {
            storageRemove(environmentParam);
        }
    }
}

export function saveAvailableLicenes() {
    const storedLicense = storageGet<string>(LocalStorage.AVAILABLE_LICENCES);

    if (storedLicense) {
        return;
    }

    const environmentLicense = getStoreValue(environment).AVAILABLE_LICENSES;
    if (environmentLicense && localStorage) {
        storageSet(LocalStorage.AVAILABLE_LICENCES, environmentLicense.join(','));
    }
}

export function isProd(): boolean {
    // 'prod' when deploying to prod, 'production' when running locally against prod
    return ['prod', 'production'].includes(getStoreValue(environment).ENVIRONMENT);
}

export function isStage(): boolean {
    return getStoreValue(environment).ENVIRONMENT === 'stage';
}

export function isDevelopment() {
    return getStoreValue(environment).ENVIRONMENT === 'dev';
}

export function isLocalDevelopment() {
    return (
        window.location.hostname === 'localhost' ||
        window.location.hostname.startsWith('10.') ||
        window.location.hostname.startsWith('192.168.') ||
        window.location.hostname.startsWith('172.16.')
    );
}

export function isBYOD(): boolean {
    return getStoreValue(environment).IS_BYOD === 'true' || getStoreValue(environment).isBYOD === 'true';
}

export function isSIM(): boolean {
    return Boolean(getStoreValue(environment).isSIM);
}

export function getClient(): ObjectValues<typeof ClientName> {
    return getClientWithoutFallback() || ClientName.COOLBET;
}

export function getClientWithoutFallback(): ObjectValues<typeof ClientName> {
    return getStoreValue(environment).CLIENT_NAME;
}

export function isB2B(): boolean {
    return getClient() !== ClientName.COOLBET;
}

export function isOfficeIp(): boolean {
    return window.isOfficeIp || false;
}
